import React, { useState, useRef } from 'react'
import { LineaType } from '@next-nx/utils/types'
import { useTrans } from '@next-nx/hooks'
import { getIcon } from '@next-nx/utils/icons'
import { CheckboxMarked, CheckboxUnmarked, ChevronDown, ChevronUp } from '@next-nx/shared-ui/icons'
import styles from './LineaCheckbox.module.sass'


interface Props {
  linea: LineaType
  selected: boolean
  setSelected: (v: boolean) => void
  collapsable?: boolean // Solo nella desktop - mobile sempre non collassabile
  className?: string
}

const LineaCheckbox = (props: Props) => {

  const { 
    linea = null,
    selected = false,
    setSelected = () => undefined,
    collapsable = false,
    className = '',
  } = props

  const t = useTrans()
  const [open, setOpen] = useState(!collapsable) // Se è collassabile inizialmente è chiuso, altrimenti è aperto
  const [hover, setHover] = useState(false)
  const innerContentRef = useRef<HTMLDivElement>(null)

  return linea ? (
    <div 
      onClick={collapsable ? undefined : () => setSelected(!selected)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`${styles.lineaCheckbox} ${
        selected ? styles['lineaCheckbox--selected'] : ''
      } ${hover ? styles['lineaCheckbox--hovered'] : ''} ${
        collapsable ? styles['lineaCheckbox--collapsable'] : ''
      } ${className ? ` ${className}` : ''}`}
    >
      <div className={styles.head} onClick={collapsable ? () => setSelected(!selected) : undefined}>
        <span className={styles.checkbox}>
          {selected ? (
            <CheckboxMarked />
          ) : (
            <CheckboxUnmarked
              className={`${
                hover && (!collapsable || (collapsable && open))
                  ? styles['checkbox--hovered']
                  : ''
              }`}
            />
          )}
        </span>
        {linea?.icon && (
          <span className={styles.icon}>
            {getIcon(linea.icon, 'lineacheckbox_' + linea?.pk?.toString())}
          </span>
        )}
        <span className={styles.title}>{linea.titolo}</span>
        {linea.prezzo && <span className={styles.price}>€{linea.prezzo}/mese</span>}
      </div>
      {linea.descrizioneBreve && (
        <div className={styles.accordion}>
          {collapsable && (
            <button type="button" className={styles.accordionTrigger} onClick={() => setOpen(!open)}>
              <span>{t(open ? "Chiudi dettagli" : "Vedi dettagli")}</span>
              <span className={styles.accordionArrow}>{open ? <ChevronUp /> : <ChevronDown />}</span>
            </button>
          )}
          <div className={styles.descriptionWrap} style={collapsable && innerContentRef?.current && open ? {
            maxHeight: innerContentRef.current.clientHeight,
          } : {}}>
            <div 
              className={styles.description} 
              ref={innerContentRef} 
              dangerouslySetInnerHTML={{ __html: linea.descrizioneBreve }} 
            />
          </div>
        </div>
      )}
    </div>
  ) : <></>
}

export default LineaCheckbox
