import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { dateFormat } from '@next-nx/utils/safe'
import { ValorizzazioneType } from '@next-nx/utils/types'
import { ArrowRight } from '@next-nx/shared-ui/icons'
import { Button } from '@next-nx/shared-ui/atoms'
import styles from './ValorizzazioneCard.module.sass'
import { sendGTMData } from '../../../utils/gtm'


interface Props {
  valorizzazione: ValorizzazioneType
  className?: string
}

const ValorizzazioneCard = (props: Props) => {

  const {
    valorizzazione = undefined,
    className = '',
  } = props

  const t = useTrans()

  const getLastItem = (thePath: any) => thePath.substring(thePath.lastIndexOf('/') + 1);

  return valorizzazione ? (
    <div className={`${styles.valorizzazioneCard} ${className ? ` ${className}` : ''}`}>
      {valorizzazione.valoreQuota?.data && <p className={styles.data}>{t('Data')}: <span className={styles.value}>{dateFormat(valorizzazione.valoreQuota?.data)}</span></p>}
      {valorizzazione.descrizioneCompleta && <p className={styles.title}>{valorizzazione.descrizioneCompleta}</p>}
      <div className={styles.attrs}>
        {valorizzazione.codiceFondo && <p className={styles.attr}>{t('Codice Sicav')}: <span className={styles.value}>{valorizzazione.codiceFondo}</span></p>}
        {valorizzazione.codiceIsin && <p className={styles.attr}>{t('Codice ISIN')}: <span className={styles.value}>{valorizzazione.codiceIsin}</span></p>}
      </div>
      {valorizzazione.valoreQuota?.valore && <p className={styles.valore}>{t('Valore quota')} ({valorizzazione.valoreQuota?.divisa}): <span className={styles.value}>{valorizzazione.valoreQuota?.valore}</span></p>}
      {valorizzazione.prospettoUrl && (
        <div className={styles.ctaBox}>
          <Button label={t("Vai al PDF")} newPage={true} rightIcon={<ArrowRight />} variant="ghost" href={valorizzazione.prospettoUrl} onClick={() => sendGTMData({
            event: "GAevent",
            eventCategory: "valorizzazione",
            eventAction: valorizzazione.descrizioneCompleta?.toGTMFormat(),
            eventLabel: getLastItem(valorizzazione.prospettoUrl)
          })} />
        </div>
      )}
    </div>
  ) : <></>
}

export default ValorizzazioneCard
