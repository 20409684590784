import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'
import { useTrans, useSite } from '@next-nx/hooks'
import { getFormFieldArgs } from '@next-nx/utils/safe'
import { Alert } from '@next-nx/shared-ui/icons'
import {
  Button,
  FormLayout,
  FormField,
  FormInput,
  FormTextarea,
  FormDate,
  FormCheckbox,
  FormFile,
  FormSelect,
  NotificationBar,
} from '@next-nx/shared-ui/atoms'
import styles from './ContattaciForm.module.sass'
import {
  useCreateRichiestaContattoMutation as useCreateRichiestaContattoMutationAssicurazioni,
  useTipologieContattoQuery as useTipologieContattoQueryAssicurazioni,
} from '@next-nx/shared-graphql/assicurazioni'
import {
  useCreateRichiestaContattoMutation as useCreateRichiestaContattoMutationVita,
  useTipologieContattoQuery as useTipologieContattoQueryVita,
} from '@next-nx/shared-graphql/vita'
import { useRouter } from 'next/router'
import { sendGTMData } from '../../../utils/gtm'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface Props {
  id?: string
  className?: string
  formContattiRef?: any
}

const ContattaciForm = (props: Props) => {
  const { id = '', className = '', formContattiRef = null } = props

  const t = useTrans()
  const site = useSite()
  const router = useRouter()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const useCreateRichiestaContattoMutation =
    site === ASSICURAZIONI
      ? useCreateRichiestaContattoMutationAssicurazioni
      : useCreateRichiestaContattoMutationVita

  const [createRichiestaContattoMutation] = useCreateRichiestaContattoMutation()

  const useTipologieContattoQuery =
    site === ASSICURAZIONI ? useTipologieContattoQueryAssicurazioni : useTipologieContattoQueryVita

  const { data } = useTipologieContattoQuery()

  const campiVariabili: {
    pk: number
    key: string
    yupType: any
  }[] = [
    { pk: 1, key: 'nome', yupType: yup.string() },
    { pk: 2, key: 'cognome', yupType: yup.string() },
    { pk: 3, key: 'email', yupType: yup.string().email(t("Inserire un'email valida")) },
    { pk: 4, key: 'numero_polizza', yupType: yup.string() },
    { pk: 5, key: 'allegati', yupType: yup.mixed() },
    { pk: 6, key: 'messaggio', yupType: yup.string() },
    { pk: 7, key: 'cf', yupType: yup.string() },
    { pk: 8, key: 'telefono', yupType: yup.string() },
    { pk: 9, key: 'documento', yupType: yup.mixed() },
    { pk: 10, key: 'data_avvenimento', yupType: yup.date() },
    { pk: 11, key: 'luogo_avvenimento', yupType: yup.string() },
    { pk: 12, key: 'nome_cognome_danneggiati', yupType: yup.string() },
    { pk: 13, key: 'targhe_coinvolti', yupType: yup.string() },
    { pk: 14, key: 'descrizione_evento', yupType: yup.string() },
  ]

  const formData: {
    [key: string]: {
      titolo: string
      destinatario: string
      campiObbligatori: string[]
      campiFacoltativi: string[]
    }
  } = data?.tipologieContatto?.reduce((a, v) => (v ? { ...a, [v['key']]: v } : a), {}) || {}

  const [error, setError] = useState<string | null>(null)

  const [tipoContatto, setTipoContatto] = useState<string | number | undefined>(undefined)

  const formHasField = (key: string) => {
    return tipoContatto
      ? formData[tipoContatto].campiObbligatori
          .concat(formData[tipoContatto].campiFacoltativi)
          .indexOf(key) > -1
      : false
  }

  const validationSchemaShape: { [key: string]: any } = {
    tipo_contatto: yup.string().required(t('Campo obbligatorio')),
    privacy: yup.bool().oneOf([true], t('Campo obbligatorio')),
  }

  if (tipoContatto)
    for (const index in campiVariabili)
      if (formHasField(campiVariabili[index].key))
        validationSchemaShape[campiVariabili[index].key] =
          formData[tipoContatto].campiObbligatori.indexOf(campiVariabili[index].key) > -1
            ? campiVariabili[index].yupType.required(t('Campo obbligatorio'))
            : campiVariabili[index].yupType

  const validationSchema = yup.object().shape(validationSchemaShape)

  const initialValues: {
    [key: string]: any
  } = {
    nome: '',
    cognome: '',
    email: '',
    tipo_contatto: undefined,
    numero_polizza: '',
    cf: '',
    telefono: '',
    allegati: null,
    documento: null,
    messaggio: '',
    privacy: true,
    data_avvenimento: '',
    luogo_avvenimento: '',
    nome_cognome_danneggiati: '',
    targhe_coinvolti: '',
    descrizione_evento: '',
  }

  const setFormError = (actions: any) => {
    actions.setSubmitting(false)
    setError(t("Errore nell'invio dei dati, riprova più tardi"))
    formContattiRef?.current && (formContattiRef.current.scrollTop = 0)
  }

  return (
    <div className={`${styles.contattaciForm} ${className ? ` ${className}` : ''}`}>
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html: t('<strong>Hai bisogno di assistenza?</strong> Contattaci'),
        }}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          if (!executeRecaptcha) {
            setFormError(actions)
            return
          }

          executeRecaptcha('richiesta_contatto_submit')
            .then((token) => {
              createRichiestaContattoMutation({
                variables: {
                  input: {
                    tipologia: values.tipo_contatto,
                    nome: values.nome,
                    cognome: values.cognome,
                    email: values.email,
                    numeroPolizza: values.numero_polizza,
                    cf: values.cf,
                    telefono: values.telefono,
                    documento: values.documento,
                    allegati: values.allegati,
                    messaggio: values.messaggio,
                    dataAvvenimento: values.data_avvenimento,
                    luogoAvvenimento: values.luogo_avvenimento,
                    nomeCognomeDanneggiati: values.nome_cognome_danneggiati,
                    targheCoinvolti: values.targhe_coinvolti,
                    descrizioneEvento: values.descrizione_evento,
                    privacy: values.privacy,
                    captcha: token,
                  },
                },
              })
                .then((res) => {
                  setError(null)
                  sendGTMData({
                    event: 'GAevent',
                    eventCategory: 'form_contatto',
                    eventAction: values.tipo_contatto?.toString().toGTMFormat(),
                    eventLabel: 'ok',
                  })
                  router.push('/contattaci/thank-you')
                })
                .catch((err) => {
                  setFormError(actions)
                })
            })
            .catch((err) => {
              setFormError(actions)
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form>
            {error && (
              <div className={styles.error}>
                <NotificationBar variant="alert" label={error} icon={<Alert />} />
              </div>
            )}
            <FormLayout>
              {(formHasField('nome') || !values.tipo_contatto) && (
                <FormField label={t('Nome')} {...getFormFieldArgs('nome', values, errors, touched)}>
                  <FormInput
                    name="nome"
                    placeholder={t('Inserisci il tuo nome')}
                    value={values.nome}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {(formHasField('cognome') || !values.tipo_contatto) && (
                <FormField
                  label={t('Cognome')}
                  {...getFormFieldArgs('cognome', values, errors, touched)}
                >
                  <FormInput
                    name="cognome"
                    placeholder={t('Inserisci il tuo cognome')}
                    value={values.cognome}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {(formHasField('email') || !values.tipo_contatto) && (
                <FormField
                  label={t('E-mail')}
                  {...getFormFieldArgs('email', values, errors, touched)}
                  layout="full"
                >
                  <FormInput
                    name="email"
                    placeholder={t('Inserisci la tua e-mail')}
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              <FormField
                label={t('Tipologia di richiesta')}
                {...getFormFieldArgs('tipo_contatto', values, errors, touched)}
                layout="full"
              >
                <FormSelect
                  name="tipo_contatto"
                  value={values.tipo_contatto}
                  placeholder={t('Seleziona la richiesta')}
                  options={Object.keys(formData).map((key) => ({
                    label: formData[key].titolo,
                    value: key,
                  }))}
                  onChange={(e) => {
                    handleChange(e)
                    const newTipoContatto = e.target.value || undefined
                    // elimina tutti i valori dei campi non presenti per il nuovo tipo_contatto
                    if (newTipoContatto && tipoContatto) {
                      const newCampi = [
                        ...formData[newTipoContatto]['campiObbligatori'],
                        ...formData[newTipoContatto]['campiFacoltativi'],
                      ]
                      const campi = [
                        ...formData[tipoContatto]['campiObbligatori'],
                        ...formData[tipoContatto]['campiFacoltativi'],
                      ]
                      campi.forEach((c) => {
                        if (!newCampi.includes(c)) {
                          setFieldValue(c, initialValues[c])
                        }
                      })
                    }

                    setTipoContatto(newTipoContatto)
                  }}
                  onBlur={handleBlur}
                />
              </FormField>
              {formHasField('numero_polizza') && (
                <FormField
                  label={t('Numero di polizza')}
                  {...getFormFieldArgs('numero_polizza', values, errors, touched)}
                >
                  <FormInput
                    name="numero_polizza"
                    placeholder={t('Inserisci il numero di polizza')}
                    value={values.numero_polizza}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {formHasField('data_avvenimento') && (
                <FormField
                  label={t('Data avvenimento')}
                  {...getFormFieldArgs('data_avvenimento', values, errors, touched)}
                >
                  <FormDate
                    name="data_avvenimento"
                    placeholder={t('gg/mm/aaaa')}
                    value={values.data_avvenimento}
                    maxDate={new Date()}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {formHasField('luogo_avvenimento') && (
                <FormField
                  label={t('Luogo di avvenimento')}
                  {...getFormFieldArgs('luogo_avvenimento', values, errors, touched)}
                  layout="full"
                >
                  <FormInput
                    name="luogo_avvenimento"
                    placeholder={t('Inserisci il luogo di avvenimento')}
                    value={values.luogo_avvenimento}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {formHasField('nome_cognome_danneggiati') && (
                <FormField
                  label={t('Nome e cognome danneggati')}
                  {...getFormFieldArgs('nome_cognome_danneggiati', values, errors, touched)}
                  layout="full"
                >
                  <FormInput
                    name="nome_cognome_danneggiati"
                    placeholder={t('Inserisci il nome e cognome dei danneggati')}
                    value={values.nome_cognome_danneggiati}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {formHasField('targhe_coinvolti') && (
                <FormField
                  label={t('Targhe dei coinvolti')}
                  {...getFormFieldArgs('targhe_coinvolti', values, errors, touched)}
                  layout="full"
                >
                  <FormInput
                    name="targhe_coinvolti"
                    placeholder={t('Inserisci le targhe dei coinvolti')}
                    value={values.targhe_coinvolti}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {formHasField('descrizione_evento') && (
                <FormField
                  label={t("Descrizione dell'evento")}
                  {...getFormFieldArgs('descrizione_evento', values, errors, touched)}
                  layout="full"
                >
                  <FormTextarea
                    name="descrizione_evento"
                    placeholder={t("Inserisci qui la descrizione dell'evento")}
                    value={values.descrizione_evento}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
            </FormLayout>
            <FormLayout>
              {formHasField('cf') && (
                <FormField
                  label={t('Codice Fiscale / P.IVA')}
                  {...getFormFieldArgs('cf', values, errors, touched)}
                >
                  <FormInput
                    name="cf"
                    placeholder={t('Inserisci il codice fiscale o P.IVA')}
                    value={values.cf}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {formHasField('telefono') && (
                <FormField
                  label={t('Telefono cellulare')}
                  {...getFormFieldArgs('telefono', values, errors, touched)}
                >
                  <FormInput
                    name="telefono"
                    placeholder={t('Inserisci il tuo numero')}
                    value={values.telefono}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {formHasField('documento') && (
                <FormField
                  label={t('Documento di identità')}
                  {...getFormFieldArgs('documento', values, errors, touched)}
                  layout="full"
                >
                  <FormFile
                    label={
                      values.documento
                        ? values.documento.name
                        : t('Carica il tuo documento d’identità')
                    }
                    id={id ? `documento-${id}` : 'documento'}
                    name="documento"
                    onChange={(e: any) => setFieldValue('documento', e.target.files[0])}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {formHasField('allegati') && (
                <FormField
                  label={t('Allega qui eventuali documenti a supporto della tua richiesta')}
                  {...getFormFieldArgs('allegati', values, errors, touched)}
                  layout="full"
                >
                  <FormFile
                    label={
                      values.allegati
                        ? values.allegati.map((file) => file.name).join(', ')
                        : t('Aggiungi un allegato')
                    }
                    id={id ? `allegati-${id}` : 'allegati'}
                    name="allegati"
                    multiple
                    onChange={(e: any) => {
                      const files = Array.from(e.target.files).map((file) => file)
                      setFieldValue('allegati', files)
                    }}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              {(formHasField('messaggio') || !values.tipo_contatto) && (
                <FormField
                  label={t('Testo della richiesta')}
                  {...getFormFieldArgs('messaggio', values, errors, touched)}
                  layout="full"
                >
                  <FormTextarea
                    name="messaggio"
                    placeholder={t('Inserisci qui il testo della richiesta')}
                    value={values.messaggio}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormField>
              )}
              <FormField layout="full" space="lg">
                <div
                  className={styles.privacyDisclaimer}
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'La Compagnia tratterà i tuoi dati nel rispetto del Regolamento Europeo sulla protezione dei dati  2016/679 (“GDPR”), per maggiori dettagli prendi visione dell’informativa sul trattamento disponibile al seguente {0}link{1}',
                      ['<a href="/privacy">', '</a>']
                    ),
                  }}
                />
              </FormField>
            </FormLayout>
            <div className={styles.submitBox}>
              <Button
                type="submit"
                label={t('Invia messaggio')}
                className={styles.submit}
                disabled={!Object.keys(touched).length || !isValid || isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ContattaciForm
