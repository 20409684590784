import React, { useEffect } from 'react'
import styles from './QuotazioneGaranziaHomeBoxForm.module.sass'
import { GaranziaQuotazioneType } from '@next-nx/utils/types'
import { QuotazioneGaranziaBox } from '@next-nx/shared-ui/molecules'

interface Props {
  garanzia: GaranziaQuotazioneType
  garanziaSelezionata?: string
  setGaranziaSelezionata?: (v: string) => void
  description?: string
  className?: string
  handleGtm?: (
    type: 'add' | 'remove',
    garanzia: GaranziaQuotazioneType,
    garanziaPadre?: string
  ) => void
}

const QuotazioneGaranziaHomeBoxForm = (props: Props) => {
  const {
    garanzia,
    garanziaSelezionata = undefined,
    setGaranziaSelezionata = () => undefined,
    description = '',
    className = '',
    handleGtm = () => undefined,
  } = props

  useEffect(() => {
    const firstSottoGaranzia = garanzia?.children?.[0]
    if (firstSottoGaranzia) {
      setGaranziaSelezionata(firstSottoGaranzia.codice)
    }
  }, [])

  return (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      {description && (
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
      )}
      <div className={styles.list}>
        {garanzia.children?.map((y) => (
          <QuotazioneGaranziaBox
            key={y.pk}
            garanzia={y}
            variant="prestazione"
            checked={garanziaSelezionata === y.codice}
            onClick={() => {
              setGaranziaSelezionata(y.codice)

              const prevGaranziaSelezionata = garanzia.children?.find(
                (x) => x.codice === garanziaSelezionata
              )
              prevGaranziaSelezionata &&
                handleGtm('remove', prevGaranziaSelezionata, garanzia.titolo)
              handleGtm('add', y, garanzia.titolo)
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default QuotazioneGaranziaHomeBoxForm
