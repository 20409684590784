import React from 'react'

function ChevronRight({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--chevron-right${className ? ' ' + className : ''}`}
    >
      <path d="M17.17 32.92l9.17-9.17-9.17-9.17 2.83-2.83 12 12-12 12z" />
      <path d="M0-.25h48v48h-48z" fill="none" />
    </svg>
  )
}

export default ChevronRight
