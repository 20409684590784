import React, { useEffect, useRef, useState } from 'react'
import { GaranziaQuotazioneType, QuotazioneType } from '@next-nx/utils/types'
import { useTrans } from '@next-nx/hooks'
import { priceFormat, roundNumber } from '@next-nx/utils/safe'
import { ChevronUp, ChevronDown, Check } from '@next-nx/shared-ui/icons'
import { Button } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneRiepilogo.module.sass'
import { getPremioLordoAnnuoTotale, TIPO_GARANZIA_OBBLIGATORIA } from '@next-nx/utils/fastquote'

interface Props {
  quotazione: QuotazioneType
  totaleQuotazione: number
  setTotaleQuotazione: (v: number) => void
  garanzie: GaranziaQuotazioneType[]
  garanzieSelezionate: string[]
  garanziaHomeBoxSelezionata?: string
  isValid?: boolean
  onClick: () => void
  className?: string
}

const QuotazioneRiepilogo = (props: Props) => {
  const {
    quotazione = undefined,
    totaleQuotazione,
    setTotaleQuotazione = () => undefined,
    garanzie,
    garanzieSelezionate,
    garanziaHomeBoxSelezionata = undefined,
    isValid = true,
    onClick = () => undefined,
    className = '',
  } = props

  const t = useTrans()
  const [garanzieOpen, setGaranzieOpen] = useState(false)
  const [swipeStart, setSwipeStart] = useState<number | null>(null)
  const [mobileOpen, setMobileOpen] = useState(false)
  const innerContentRef = useRef()
  const accordionInnerContentRef = useRef()
  const bottomInnerContentRef = useRef()

  useEffect(() => {
    if (mobileOpen) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [mobileOpen])

  useEffect(() => {
    let newTotaleQuotazione = 0
    garanzie
      ?.filter((x) => x.tipo === TIPO_GARANZIA_OBBLIGATORIA)
      .forEach((g) => {
        newTotaleQuotazione += getPremioLordoAnnuoTotale(g)
      })
    garanzie
      ?.filter((x) => garanzieSelezionate.indexOf(x?.codice) > -1)
      .forEach((g) => {
        newTotaleQuotazione += getPremioLordoAnnuoTotale(g)
      })

    setTotaleQuotazione(roundNumber(newTotaleQuotazione))
  }, [garanzie, garanzieSelezionate, garanziaHomeBoxSelezionata])

  return quotazione ? (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      <button
        role="button"
        className={styles.mobileToggler}
        onClick={() => {
          if (!mobileOpen) setGaranzieOpen(true) // nel mobile di default le garanzie sono aperte
          setMobileOpen(!mobileOpen)
        }}
        onTouchStart={(e) => setSwipeStart(e.nativeEvent?.targetTouches[0]?.pageY)}
        onTouchEnd={(e) => {
          if (swipeStart !== null) {
            if (mobileOpen && e.nativeEvent?.changedTouches[0]?.pageY > swipeStart + 20)
              // un po' di offset per verificare che swipino in giù
              setMobileOpen(false)
            if (!mobileOpen && e.nativeEvent?.changedTouches[0]?.pageY < swipeStart - 20) {
              // un po' di offset per verificare che swipino in su
              setMobileOpen(true)
              setGaranzieOpen(true) // nel mobile di default le garanzie sono aperte
            }
          }
          setSwipeStart(null)
        }}
      >
        <span className={styles.mobileTogglerIcon}>
          {mobileOpen ? <ChevronDown /> : <ChevronUp />}
        </span>
        <span className={styles.mobileTogglerLabel}>
          {t(mobileOpen ? 'Chiudi dettagli quotazione' : 'Vedi dettagli quotazione')}
        </span>
      </button>
      <div
        className={styles.content}
        style={{
          maxHeight:
            innerContentRef?.current && mobileOpen
              ? innerContentRef.current.clientHeight +
                (garanzieOpen && accordionInnerContentRef?.current
                  ? accordionInnerContentRef.current.clientHeight
                  : 0)
              : 0,
        }}
      >
        <div className={styles.innerContent} ref={innerContentRef}>
          <p className={styles.title}>{t('La tua quotazione')}</p>
          <div className={styles.list}>
            {garanzie
              ?.filter((x) => x.tipo === TIPO_GARANZIA_OBBLIGATORIA)
              .map((x) => (
                <div className={styles.item} key={x.pk}>
                  <span className={styles.itemLabel}>{x.titolo}</span>
                  <span className={styles.itemValue}>
                    {priceFormat(getPremioLordoAnnuoTotale(x))}
                  </span>
                </div>
              ))}
          </div>
          {!!garanzie?.filter((x) => garanzieSelezionate.indexOf(x?.codice) > -1)?.length && (
            <div className={styles.accordion}>
              <button
                role="button"
                className={styles.accordionTrigger}
                onClick={() => setGaranzieOpen(!garanzieOpen)}
              >
                <span className={styles.accordionTriggerLabel}>
                  {t(garanzieOpen ? 'Chiudi dettagli garanzie' : 'Vedi dettagli garanzie')}
                </span>
                <span className={styles.accordionTriggerIcon}>
                  {garanzieOpen ? <ChevronUp /> : <ChevronDown />}
                </span>
              </button>
              <div
                className={styles.accordionContent}
                style={{
                  maxHeight:
                    accordionInnerContentRef?.current && garanzieOpen
                      ? accordionInnerContentRef.current.clientHeight
                      : 0,
                }}
              >
                <div className={styles.accordionInnerContent} ref={accordionInnerContentRef}>
                  <div className={styles.listSm}>
                    {garanzie
                      ?.filter((x) => garanzieSelezionate.indexOf(x?.codice) > -1)
                      .map((x) => (
                        <div className={styles.item} key={x.pk}>
                          <span className={styles.itemLabel}>{x.titolo}</span>
                          <span className={styles.itemValue}>
                            {priceFormat(getPremioLordoAnnuoTotale(x))}
                          </span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.totale}>
        <span className={styles.totaleLabel}>{t('Totale')}</span>
        <div className={styles.totaleValue}>{priceFormat(totaleQuotazione)}</div>
      </div>
      <div>
        <p className={styles.discount}>
          <span className={styles.discountIcon}>
            <Check />
          </span>
          <span className={styles.discountLabel}>{t('Prezzo già scontato del')} </span>
          <span className={styles.discountValue}>{t('quotazione_casa_discount')}</span>
        </p>
      </div>
      <div
        className={styles.bottomContent}
        style={{
          maxHeight:
            bottomInnerContentRef?.current && mobileOpen
              ? bottomInnerContentRef.current.clientHeight
              : 0,
        }}
      >
        <div className={styles.bottomInnerContent} ref={bottomInnerContentRef}>
          <p className={styles.disclaimer}>
            {t(
              '*Il prezzo è già scontato del {0} ed è valido solo per i clienti banca correntisti e con accredito sul conto.',
              [t('quotazione_casa_discount')]
            )}
          </p>

        </div>
      </div>
       <div className={styles.ctaBox}>
         <Button label={t('Continua')} disabled={!isValid} onClick={onClick} />
       </div>
    </div>
  ) : (
    <></>
  )
}

export default QuotazioneRiepilogo
