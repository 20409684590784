import React, { useEffect, useState } from 'react'
import { useTrans } from '@next-nx/hooks'
import { WContainer, Breadcrumbs, MainTitle, Accordion } from '@next-nx/shared-ui/atoms'
import { Page, FaqSearchForm } from '@next-nx/shared-ui/organisms'
import styles from './FaqDetailTemplate.module.sass'
import FaqSidebarMenu from '../../molecules/FaqSidebarMenu/FaqSidebarMenu'
import { sendGTMData } from '../../../utils/gtm'


interface Props {
  pagina: any
  categorieFaq: any
  categoria?: any
  domanda?: string
  query?: string
  risultati?: any
}

const FaqDetailTemplate = (props: Props) => {

  const {
    pagina = undefined,
    categorieFaq = [],
    categoria = null,
    domanda = null,
    query = null,
    risultati = [],
  } = props

  const t = useTrans()

  const [categoriaSelected, setCategoriaSelected] = useState<any>(null)

  const handleSelectCategory = (pk) => {
    const categoriaSelected = categorieFaq.find(cat => cat.pk === pk)
    setCategoriaSelected({...categoriaSelected, faq: risultati.filter((item) => item.categoriaFaq.pk === pk)})
    sendGTMData({
      event: "GAevent",
      eventCategory: 'faq_category',
      eventAction: categoriaSelected?.titolo?.toGTMFormat()
    })
  }

  useEffect(() => {
    if (query && categorieFaq.length > 0) {
      setCategoriaSelected({
        ...categorieFaq[0],
        faq: risultati.filter(
          (item) => item.categoriaFaq.pk === categorieFaq[0].pk
        ),
      })
    }
    else setCategoriaSelected(null)
  }, [risultati])

  return (
    <Page pagina={pagina} item={{}} contentClassName={styles.faqListingContent}>
      <div className={`${styles.headBg} bg-grey`}>
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs.slice(0, -1)} />
          <MainTitle label={t("Hai bisogno di <strong>aiuto</strong>?")} variant="light" className={styles.title} />
        </WContainer>
        <WContainer variant="xs">
          <FaqSearchForm />
        </WContainer>
      </div>
      {query && query.length > 0 ?
      (<div className={styles.listing}>
        <WContainer>
          <div className={styles.layout}>
            <div className={styles.sidebar}>
              <FaqSidebarMenu items={categorieFaq} isSearchFaq handleSelectCategory={handleSelectCategory} pkCategoriaSelected={categoriaSelected?.pk} />
            </div>
            <div className={styles.main}>
              {!!categoriaSelected?.titolo && <p className={styles.title}>{categoriaSelected.titolo}</p>}
              {!!categoriaSelected?.descrizione && <div className={styles.description} dangerouslySetInnerHTML={{ __html: categoriaSelected.descrizione }} />}
              {categoriaSelected?.faq.length > 0 && (
                <div className={styles.listing}>
                  {categoriaSelected.faq.map(faq => (
                    <Accordion
                      key={faq.pk}
                      label={faq.domanda}
                      content={faq.risposta}
                      isOpen={faq.slug === domanda}
                      onClick={() => sendGTMData({
                        event: "GAevent",
                        eventCategory: "faq",
                        eventAction: faq.domanda?.toGTMFormat(),
                        eventLabel: "faq_page"
                      })}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </WContainer>
      </div>)
       :
      (<div className={styles.listing}>
        <WContainer>
          <div className={styles.layout}>
            <div className={styles.sidebar}>
              <FaqSidebarMenu items={categorieFaq} handleSelectCategory={handleSelectCategory} />
            </div>
            <div className={styles.main}>
              {!!categoria?.titolo && <p className={styles.title}>{categoria.titolo}</p>}
              {!!categoria?.descrizione && <div className={styles.description} dangerouslySetInnerHTML={{ __html: categoria.descrizione }} />}
              {categoria?.faq.length > 0 && (
                <div className={styles.listing}>
                  {categoria.faq.map(faq => (
                    <Accordion
                      key={faq.pk}
                      label={faq.domanda}
                      content={faq.risposta}
                      isOpen={faq.slug === domanda}
                      onClick={() => sendGTMData({
                        event: "GAevent",
                        eventCategory: "faq",
                        eventAction: faq.domanda?.toGTMFormat(),
                        eventLabel: "faq_page"
                      })}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </WContainer>
      </div>)}
    </Page>
  )
}

export default FaqDetailTemplate
