import React from 'react'
import Link from 'next/link'
import { getIcon } from '@next-nx/utils/icons'
import styles from './FaqCategoryCard.module.sass'

interface Props {
  item: {
    pk: number
    style?: string
    titolo: string
    descrizione?: string
    url?: string
  }
  className?: string
  onClick?: () => void
}

const FaqCategoryCard = (props: Props) => {
  const { item = undefined, className = '', onClick = () => undefined } = props

  return item ? (
    <Link legacyBehavior key={item.pk} href={item?.url || ''}>
      <a
        className={`${styles.faqCategoryCard} ${className ? ` ${className}` : ''}`}
        onClick={onClick}
      >
        {!!item.style && (
          <span className={styles.icon}>
            {getIcon(item.style, 'faqcategorycard_' + item.pk?.toString())}
          </span>
        )}
        {!!item.titolo && <p className={styles.title}>{item.titolo}</p>}
        {!!item.descrizione && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.descrizione }}
          />
        )}
      </a>
    </Link>
  ) : (
    <></>
  )
}

export default FaqCategoryCard
