import React from 'react'

function Navigation({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--navigation${className ? ' ' + className : ''}`}
    >
      <path d="M36 16c0-6.63-5.37-12-12-12s-12 5.37-12 12c0 9 12 22 12 22s12-13 12-22zm-16 0c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4-4-1.79-4-4zm-10 24v4h28v-4h-28z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  )
}

export default Navigation
