import { useEditor, ROOT_NODE } from '@craftjs/core';
import React, { useEffect, useState, useContext } from 'react';
import { ToolbarSection } from '../../../../editor/Viewport/Sidebar/Toolbar/ToolbarSection';
import {
  serializeNodeTree,
  isDisabled,
  getAncestors,
} from '../../../../utils/node';
import { CustomBlocksContext } from '../../../../services/customBlocksContext';
import { NotificationBar } from '../../../../pb-components/atoms';
import { FormField, FormInput, Button } from '../../../../pb-components/atoms';
import { TabsMenu, TabsContent, TabPane } from '../../../../components';
import { DynamicDataBlock } from '../../../../components/DynamicDataBlock';


export const FormCustomBlock = ({ nodeId }) => {
  const { query } = useEditor();

  const [name, setName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessagee] = useState('');
  const [open, setOpen] = useState(false);

  const { addCustomBlock } = useContext(CustomBlocksContext);

  const onCreateCustomBlock = () => {
    const nodes = query.node(nodeId).toNodeTree();
    const serializedNodes = serializeNodeTree(nodes, query);
    const json_content = JSON.stringify(serializedNodes);

    addCustomBlock(name, json_content)
      .then((data) => {
        setErrorMessagee('');
        setSuccessMessage('Custom Block creato con successo');
        setTimeout(() => {
          setSuccessMessage('');
        }, 5000);
      })
      .catch((err) => {
        setSuccessMessage('');
        setErrorMessagee('Impossibile creare il Custom Block');
      });
  };

  const isFormDisabled = () => {
    if (!nodeId) {
      return true;
    }

    if (nodeId === ROOT_NODE) {
      return true;
    }

    if (isDisabled(nodeId, query)) {
      return true;
    }

    const ancestors = getAncestors(nodeId, query);
    const ddb = ancestors.find((nodeId) => {
      return query.node(nodeId).get().data.type === DynamicDataBlock;
    });
    if (ddb) {
      return true;
    }

    const nodeType = query.node(nodeId).get().data.type;
    if (
      nodeType === TabsMenu ||
      nodeType === TabsContent ||
      nodeType === TabPane
    ) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <ToolbarSection
        title="Create new Custom Block"
        disabled={isFormDisabled()}
        buttonTooltip={
          isFormDisabled()
            ? {
                id: 'create-custom-block-button',
                label: 'Click on a component in your page',
                pos: 'right',
              }
            : null
        }
        open={open}
        onChange={setOpen}
      >
        <div>
          {successMessage && (
            <NotificationBar type="success">{successMessage}</NotificationBar>
          )}
          {errorMessage && (
            <NotificationBar type="alert">{errorMessage}</NotificationBar>
          )}
          <FormField label="Name">
            <FormInput value={name} onChange={(e) => setName(e.target.value)} />
          </FormField>
          <div className="form-buttons">
            <Button
              variant="ghost"
              size="sm"
              label="Cancel"
              onClick={() => setOpen(false)}
            />
            <Button
              variant="primary"
              size="sm"
              label="Create"
              onClick={() => onCreateCustomBlock()}
              disabled={name.trim() === ''}
            />
          </div>
        </div>
      </ToolbarSection>
    </div>
  );
};
