import React from 'react'
import Link from 'next/link'
import { useTrans } from '@next-nx/hooks'
import { WImage } from '@next-nx/shared-ui/atoms'
import styles from './ArticoloPrimoPiano.module.sass'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  title?: string
  articolo: {
    publicationDate?: string
    title?: string
    abstract?: string
    mainImage?: string
    url?: string
  }
  className?: string
}

const ArticoloPrimoPiano = (props: Props) => {
  const { title = '', articolo = null, className = '' } = props

  const t = useTrans()

  return articolo ? (
    <div className={`${styles.articoloPrimoPiano} ${className ? ` ${className}` : ''}`}>
      {!!title && <p className={styles.mainTitle} dangerouslySetInnerHTML={{ __html: title }} />}
      <div className={styles.layout}>
        <div className={styles.imageBox}>
          <WImage
            src={articolo.mainImage}
            maxWidth={542}
            maxHeight={309}
            alt={articolo.title}
            title={articolo.title}
            layout="intrinsic"
            objectFit="cover"
            priority={true}
            loading="eager"
          />
        </div>
        <div className={styles.content}>
          {!!articolo.publicationDate && <p className={styles.date}>{articolo.publicationDate}</p>}
          {!!articolo.title && <p className={styles.title}>{articolo.title}</p>}
          {!!articolo.abstract && (
            <div
              className={styles.abstract}
              dangerouslySetInnerHTML={{ __html: articolo.abstract }}
            />
          )}
          {!!articolo.url && (
            <div className={styles.ctaBox}>
              <Link legacyBehavior href={articolo.url}>
                <a
                  className={styles.cta}
                  onClick={() =>
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'articolo',
                      eventAction: articolo?.title?.toGTMFormat(),
                      eventLabel: 'magazine',
                    })
                  }
                >
                  {t('Leggi di più')}
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ArticoloPrimoPiano
