import React, { useEffect, useState } from 'react'
import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

import { defaultProps, dynamicProps } from './index'
import { getAllPaths, getDynamicValue, setDynamicValue } from '../../utils/dynamic'
//import { useQuery, gql } from '@apollo/client'
//import { initializeApollo } from 'utils/apollo'
//import { PAGINE } from 'utils/queries'

const variantOpts = [
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'Tertiary', value: 'tertiary' },
  { label: 'Tertiary 2', value: 'tertiary2' },
  { label: 'Ghost', value: 'ghost' },
]

const sizeOptions = [
  { label: 'Small', value: 'sm' },
  { label: 'Medium', value: 'md' },
  { label: 'Large', value: 'lg' },
]

export const ButtonSettings = (props) => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }))

  // const client = initializeApollo()

  const [urlOpts, setUrlOpts] = useState([])

  useEffect(() => {
    //const { data } = await client.query({ query: PAGINE })
    const data = null
    if (data) {
      const pagine = data.pagine.edges
      const onlyDetailPagine = pagine.filter((paginaNode) => {
        return paginaNode.node.tipologia === 'PAGE_APP'
      })
      const finalUrlOpts = onlyDetailPagine.map((paginaNode) =>
        paginaNode.node.path === '' ? '/' : paginaNode.node.path
      )
      setUrlOpts(finalUrlOpts)
    }
  }, [])

  return (
    <>
      <ToolbarSection title="Style" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="variant"
          type="select"
          label="Variante"
          options={variantOpts}
          dynamic={dynamicProps.includes('variant') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['variant']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="size"
          type="select"
          label="Size"
          options={sizeOptions}
          dynamic={dynamicProps.includes('size') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['size']}
        />
      </ToolbarSection>
      {!props.floating && (
        <ToolbarSection title="Settings" initOpen>
          <ToolbarItem
            propKey="block"
            propSubKey="url"
            type="text"
            label="URL"
            dynamic={dynamicProps.includes('url') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['url']}
          />
          {urlOpts && (
            <ToolbarItem
              propKey="block"
              propSubKey="url"
              type="select"
              label="URL"
              values={urlOpts}
              nullable
              defaultValue={defaultProps['url']}
            />
          )}
          <ToolbarItem
            propKey="block"
            propSubKey="openNewTab"
            type="switch"
            label="Open in new tab"
            dynamic={dynamicProps.includes('openNewTab') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['openNewTab']}
          />
        </ToolbarSection>
      )}
    </>
  )
}

export const ButtonFloatingSettings = () => React.createElement(ButtonSettings, { floating: true })
