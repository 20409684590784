import React, { useEffect, useState } from 'react'
import { WImage, Button } from '@next-nx/shared-ui/atoms'
import styles from './AppBox.module.sass'
import { AssistenzaButton } from '@next-nx/shared-ui/atoms'
import { MenuType } from '@next-nx/utils/types'
import { AppModal } from '@next-nx/shared-ui/organisms'
import { sendGTMData } from '../../../utils/gtm'
import { useIsMobile } from '@next-nx/hooks'
import { AppStore, GooglePlay, ArrowRight } from '@next-nx/shared-ui/icons'

interface Props {
  item?: MenuType
  className?: string
  controlled?: boolean
  cta?: boolean
  defaultAppModal?: MenuType | undefined
  setDefaultAppModal?: (m: MenuType | undefined) => void
  defaultOpenAppModal?: boolean
  setDefaultOpenAppModal?: (b: boolean) => void
}

const AppBox = (props: Props) => {
  const {
    item = undefined,
    className = '',
    controlled = false,
    defaultAppModal = null,
    setDefaultAppModal = () => {},
    defaultOpenAppModal = false,
    setDefaultOpenAppModal = () => {},
  } = props

  const isMobile = useIsMobile()
  const [appModalOpen, setAppModalOpen] = useState<boolean>(false)
  const [appModal, setAppModal] = useState<MenuType | undefined>(undefined)

  const sezioneStores = () => {
    if (item && item.children && item.children.edges.length > 0) {
      if (
        item.children.edges[0].node &&
        item.children.edges[0].node.children &&
        item.children.edges[0].node.children.edges.length > 0
      )
        return item.children.edges[0].node
    }
    return null
  }

  const getAppStores = () => {
    if (item && item.children && item.children.edges.length > 0) {
      if (
        item.children.edges[0].node &&
        item.children.edges[0].node.children &&
        item.children.edges[0].node.children.edges.length > 0
      ) {
        return item.children.edges[0].node.children.edges.filter((child) => {
          if (child.node && child.node.key) {
            return child
          }
          return false
        })
      }
    }
    return []
  }

  useEffect(() => {
    if (appModal) {
      setAppModalOpen(true)
    }
    if (controlled && defaultAppModal) {
      setDefaultOpenAppModal(true)
    }
  }, [appModal, defaultAppModal])

  useEffect(() => {
    if (!appModalOpen) {
      setAppModal(undefined)
    }
    if (controlled && !defaultOpenAppModal) {
      setDefaultAppModal(undefined)
    }
  }, [appModalOpen, defaultOpenAppModal])

  return item ? (
    <div className={`${styles.appBox} ${className ? ` ${className}` : ''}`}>
      <div className={`${styles.imageBox} ${controlled ? ` ${styles.imageBoxControlled}` : ''}`}>
        <WImage
          maxWidth={400}
          maxHeight={480}
          src={isMobile ? item.mainImageResponsive : item.mainImage}
          className={styles.image}
          layout="intrinsic"
          objectFit="contain"
          key={`app-box-${item.pk}-main-image${isMobile ? '-mobile' : ''}`}
        />
      </div>
      <div className={`${styles.main} ${controlled ? ` ${styles.mainControlled}` : ''}`}>
        <div className={styles.content}>
          <div
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: item?.name || '' }}
          ></div>
          {!!item.description && (
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
        </div>
        {controlled && (
          <div className={styles.stores}>
            <div className={styles.qrcode}>
              <WImage
                src={isMobile ? sezioneStores()?.mainImageResponsive : sezioneStores()?.mainImage}
                maxWidth={isMobile ? 150 : 150}
                maxHeight={isMobile ? 150 : 150}
                layout="intrinsic"
                objectFit="contain"
                priority={true}
                loading="eager"
                key={`app-main-image${isMobile ? '-mobile' : ''}`}
              />
            </div>
            <div className={styles.containerLinks}>
              <div
                className={styles.titleLinks}
                dangerouslySetInnerHTML={{ __html: sezioneStores()?.name || '' }}
              ></div>
              <div className={styles.links}>
                {getAppStores().map((child, n) => (
                  <div className={styles.logo} key={n}>
                    <a
                      href={child.node.url}
                      target="_blank"
                      onClick={() => {
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'app',
                          eventAction: child.node.style?.toGTMFormat() || '',
                          eventLabel: 'download',
                        })
                      }}
                    >
                      {child.node.style === 'apple' ? (
                        <AppStore idVariant={'appbox_' + n} />
                      ) : (
                        <GooglePlay idVariant={'appbox_' + n} />
                      )}
                    </a>
                  </div>
                ))}
              </div>
              <div
                className={styles.subtitleLinks}
                dangerouslySetInnerHTML={{ __html: sezioneStores()?.description || '' }}
              ></div>
            </div>
            <div className={styles.linksImage}>
              <div className={styles.screenshot}>
                <WImage
                  src={isMobile ? item?.mainImageResponsive : item?.mainImage}
                  maxWidth={isMobile ? 170 : 442}
                  maxHeight={isMobile ? 197 : 513}
                  layout="intrinsic"
                  objectFit="contain"
                  priority={true}
                  loading="eager"
                  key={`app-main-image${isMobile ? '-mobile' : ''}`}
                />
              </div>
            </div>
          </div>
        )}
        {!controlled && (
          <div className={styles.list}>
            {item.children?.edges &&
              item.children.edges.map((child) => (
                <AssistenzaButton
                  key={child.node.pk}
                  label={child.node.description || ''}
                  url={child.node.url}
                  newPage={child.node.blank}
                  onClick={() => {
                    setAppModal(child.node)
                    setDefaultAppModal(child.node)
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'app',
                      eventAction: child.node.name?.toGTMFormat() || '',
                      eventLabel: 'download',
                    })
                  }}
                />
              ))}
          </div>
        )}
      </div>
      {!controlled && <AppModal app={appModal} open={appModalOpen} setOpen={setAppModalOpen} />}
    </div>
  ) : (
    <></>
  )
}

export default AppBox
