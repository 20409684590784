import React, { useState } from 'react'
import * as yup from 'yup'
import { useSite, useTrans } from '@next-nx/hooks'
import { getFormFieldArgs, datePickerFormat } from '@next-nx/utils/safe'
import { Info } from '@next-nx/shared-ui/icons'
import { Formik, Form } from 'formik'
import {
  Button,
  FormDate,
  FormInput,
  Tooltip,
  FormField,
  FormError,
} from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneVerificaForm.module.sass'
import { QuotazioneType } from '@next-nx/utils/types'
import { useGetPremioCasaFastFullDetailMutation } from '@next-nx/shared-graphql/assicurazioni'
import { ASSICURAZIONI, SITES_ID } from 'libs/shared-ui/utils/settings'
import { validateDataDecorrenza } from '@next-nx/utils/fastquote'


interface Props {
  quotazione: QuotazioneType
  setQuotazione?: (q: any) => void
  setSubmitting?: (v: boolean) => void
  showValore: boolean
  className?: string
}

const QuotazioneVerificaForm = (props: Props) => {
  const {
    quotazione = undefined,
    setQuotazione = () => { },
    setSubmitting = () => { },
    showValore = false,
    className = '',
  } = props

  const t = useTrans()
  const site = useSite()

  const [getPremioCasaFast] = useGetPremioCasaFastFullDetailMutation()
  const [error, setError] = useState<string | null>(null)

  const onVerificaDati = (values: any, actions: any) => {
    setSubmitting(true)
    getPremioCasaFast({
      variables: {
        input: {
          siteId: SITES_ID[site],
          proprieta: quotazione?.proprieta,
          cap: quotazione?.cap,
          tipoResidenza: quotazione?.tipoResidenza,
          tipoAbitazione: quotazione?.tipoAbitazione,
          metriQuadrati: quotazione?.metriQuadrati,
          email: quotazione?.email,
          consensoTrattamento: quotazione?.consensoTrattamento,
          piano: quotazione?.piano ? parseInt(quotazione.piano) : 0,
          valArredamento: values?.valArredamento || quotazione?.valArredamento,
          dataDecorrenza: values?.dataDecorrenza || quotazione?.dataDecorrenza,
        },
      },
    })
      .then((res) => {
        if (res?.data?.getPremioCasaFast?.status) {
          setError(null)
          setQuotazione(res?.data?.getPremioCasaFast?.quotazione)
          window.scrollTo({ top: 0, behavior: 'instant' })
        } else {
          if (
            res?.data?.getPremioCasaFast?.message &&
            res?.data?.getPremioCasaFast?.message != 'KO'
          ) {
            setError(res?.data?.getPremioCasaFast?.message)
          } else {
            setError(t("c'è stato un errore, ti invitiamo a riprovare più tardi"))
          }
        }
        setSubmitting(false)
        actions.setSubmitting(false)
      })
      .catch((err) => {
        console.log(err)
        setSubmitting(false)
        actions.setSubmitting(false)
        setError(t("c'è stato un errore, ti invitiamo a riprovare più tardi"))
      })
  }

  const validationSchema = yup.object().shape({
    valArredamento: yup
      .number()
      .min(15000, t('Non sono accettati valori inferiori a 15.000'))
      .max(300000, t('Non sono accettati valori superiori a 300.000'))
      .required(t('Campo obbligatorio')),
    dataDecorrenza: yup
      .string().typeError(t('É necessario specificare una data di inizio copertura'))
      .test('dataDecorrenza', t('Non è accettata una data passata o futura oltre 359 giorni'), (value) => {
        return validateDataDecorrenza(value)
      })
      .required(t('Campo obbligatorio')),
  })

  const initialValues = {
    valArredamento: quotazione?.valArredamento ? parseInt(quotazione.valArredamento) : '',
    dataDecorrenza: quotazione?.dataDecorrenza ? datePickerFormat(quotazione.dataDecorrenza) : '',
  }

  const getDataDecorrenzaMaxDate = () => {
    const maxDate = new Date()
    maxDate.setDate(maxDate.getDate() + 359)
    return maxDate;
  }

  return (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: t('Verifica e <strong>modifica i dati</strong>') }}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnMount
        enableReinitialize
        onSubmit={async (values, actions) => {
          onVerificaDati(values, actions)
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <div className={styles.list}>
              {showValore && (
                <div className={styles.item}>
                  <div className={styles.itemLabel}>
                    <span className={styles.itemTitle}>{t('Valore del contenuto (€)')}</span>
                    <span className={styles.itemHelper}>
                      <Tooltip
                        id={'quotazioneverificaform'}
                        label={t('Non sono accettati valori inferiori a 15.000€')}
                      >
                        <span className={styles.itemHelperIcon}>
                          <Info />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                  <div className={styles.itemField}>
                    <FormField
                      {...getFormFieldArgs('valArredamento', values, errors, touched)}
                      layout="full"
                      className={styles.field}
                    >
                      <FormInput
                        name="valArredamento"
                        bgVariant="white"
                        type="number"
                        step={1000}
                        value={values.valArredamento}
                        onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                        onBlur={handleBlur}
                      />
                    </FormField>
                  </div>
                </div>
              )}
              <div className={styles.item}>
                <div className={styles.itemLabel}>
                  <span className={styles.itemTitle}>{t('Data di inizio copertura')}</span>
                </div>
                <div className={styles.itemField}>
                  <FormField
                    space='lg'
                    status={errors['dataDecorrenza'] ? 'error' : ''}
                    errorMessage={errors['dataDecorrenza']}
                    layout="full"
                    className={styles.field}
                  >
                    <FormDate
                      name="dataDecorrenza"
                      bgVariant="white"
                      placeholder={t('gg/mm/aaaa')}
                      value={values.dataDecorrenza}
                      minDate={new Date()} // today
                      maxDate={getDataDecorrenzaMaxDate()} // 359 days from today
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                        setFieldTouched(e.target.name, true, false)
                      }}
                      onBlur={handleBlur}
                    />
                  </FormField>
                </div>
              </div>
            </div>
            {error && (
              <div className={styles.error}>
                <FormError message={`<strong>Attenzione</strong>: ${error}`} visible />
              </div>
            )}
            <div className={styles.submitBox}>
              <Button
                label={t('Conferma i dati inseriti')}
                type="submit"
                disabled={!Object.keys(touched).length || !isValid || isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default QuotazioneVerificaForm
