import { ConditionsSettings } from '../conditions/ConditionsSettings'
import {
  canDrag as _canDrag,
  canDrop as _canDrop,
  canMoveIn as _canMoveIn,
  canMoveOut as _canMoveOut,
} from '../utils/rules'
import { defaultConditionsProps } from '../conditions'

export const withCraft = ({
  name,
  defaultProps,
  settings,
  floatingSettings,
  canDrag,
  canDrop,
  canMoveIn,
  canMoveOut,
}) => {
  return {
    displayName: name,
    props: {
      block: defaultProps,
      conditions: defaultConditionsProps,
    },
    related: {
      toolbar: settings,
      floatingToolbar: floatingSettings ? floatingSettings : settings,
      conditions: ConditionsSettings,
    },
    rules: {
      canDrag: (currentNode, helpers) => _canDrag(currentNode, helpers, canDrag),
      canDrop: (targetNode, helpers) => _canDrop(targetNode, helpers, canDrop),
      canMoveIn: (incomingNodes, currentNode, helpers) =>
        _canMoveIn(incomingNodes, currentNode, helpers, canMoveIn),
      canMoveOut: (outgoingNodes, currentNode, helpers) =>
        _canMoveOut(outgoingNodes, currentNode, helpers, canMoveOut),
    },
  }
}
