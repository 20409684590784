import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperType } from 'swiper'
import { useFindMenu, useSite, useTrans, useUtente } from '@next-nx/hooks'
import { scrollTo } from '@next-nx/utils/safe'
import { Breadcrumbs, WContainer } from '@next-nx/shared-ui/atoms'
import {
  AllegatiBox,
  AppBox,
  ContentBox,
  FilledTextBox,
  OutlineTextCtaBox,
  TextCtaBox,
} from '@next-nx/shared-ui/molecules'
import {
  LineeSection,
  MotiviSection,
  Page,
  ProdottiSection,
  PreventivatoreModal,
  AppModal,
  PreventivatoreFastQuoteModal,
  PreventivatoreFastQuoteLoader,
} from '@next-nx/shared-ui/organisms'
import styles from './ProdottoTemplate.module.sass'
import FaqSection from '../../organisms/FaqSection/FaqSection'
import ProdottoHead from '../../organisms/ProdottoHead/ProdottoHead'
import { MenuType, ProdottoType } from '@next-nx/utils/types'
import { getIcon } from '@next-nx/utils/icons'
import { VITA } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'
import { isFastQuoteAuto, isFastQuoteCasa } from '@next-nx/utils/fastquote'

interface Props {
  prodotto: ProdottoType
  pagina?: {
    breadcrumbs: {
      titolo: string
      url?: string
    }[]
  }
}

const ProdottoTemplate = (props: Props) => {
  const { prodotto, pagina = undefined } = props

  const t = useTrans()
  const { utente } = useUtente()
  const toolbarPresente = utente && (utente.isStaff || utente.isImpersonate)

  const menuRef = useRef<HTMLDivElement>(null)
  const sectionRefs = useRef<HTMLDivElement[]>([])

  const [menuActive, setMenuActive] = useState<string | null>('descrizione')
  const [appModalOpen, setAppModalOpen] = useState<boolean>(false)
  const [appModal, setAppModal] = useState<MenuType | undefined>(undefined)
  const [preventivatoreModalOpen, setPreventivatoreModalOpen] = useState<boolean>(false)
  const [selectedLineePreventivatore, setSelectedLineePreventivatore] = useState<number[]>([])
  const [fastQuoteSubmitting, setFastQuoteSubmitting] = useState(false)

  const addSectionRef = (ref: HTMLDivElement) => {
    if (ref) {
      if (sectionRefs.current.filter((x) => x.id === ref.id).length > 0)
        sectionRefs.current = sectionRefs.current.map((x) => (x.id === ref.id ? ref : x))
      else sectionRefs.current.push(ref)
    }
  }

  const site = useSite()

  const prodottoSharedMenu = useFindMenu('prodotto')

  const topBoxesProdottoMenu = useFindMenu('top-boxes', prodotto.menuItems)
  const topBoxesSharedMenu = useFindMenu('top-boxes', prodottoSharedMenu)
  const topBoxesMenu = topBoxesProdottoMenu || topBoxesSharedMenu

  const descrizioneBoxesMenu = useFindMenu('card_descrizione', prodotto.menuItems)

  const offertaMenu = useFindMenu('offerta', prodotto.menuItems)

  const motiviMenu = useFindMenu('motivi', prodotto.menuItems)

  const vantaggiMenu = useFindMenu('vantaggi', prodotto.menuItems)

  const lineeMenu = useFindMenu('linee', prodotto.menuItems)

  const faqProdottoMenu = useFindMenu('faq', prodotto.menuItems)
  const faqSharedMenu = useFindMenu('faq', prodottoSharedMenu)
  const faqMenu = faqProdottoMenu || faqSharedMenu

  const areaClientiProdottoMenu = useFindMenu('area_clienti', prodotto.menuItems)
  const areaClientiSharedMenu = useFindMenu('area_clienti', prodottoSharedMenu)
  const areaClientiMenu = areaClientiProdottoMenu || areaClientiSharedMenu

  const fondiMenu = site === VITA ? useFindMenu('fondi', prodotto.menuItems) : null

  const allegatiMenu = useFindMenu('allegati', prodotto.menuItems)

  const appProdottoMenu = useFindMenu('app', prodotto.menuItems)
  const appSharedMenu = useFindMenu('app', prodottoSharedMenu)
  const appMenu = appProdottoMenu || appSharedMenu

  const disclaimerMenu = useFindMenu('disclaimer', prodotto.menuItems)

  const [menuSwiper, setMenuSwiper] = useState<SwiperType>()
  const [scrolling, setScrolling] = useState(false)

  const menuItems = ([] as { pk?: string; name?: string }[])
    .concat(descrizioneBoxesMenu ? [{ pk: 'descrizione', name: 'Descrizione' }] : [])
    .concat(offertaMenu ? [{ pk: 'offerta', name: 'Che cosa offre' }] : [])
    .concat(vantaggiMenu ? [{ pk: 'vantaggi', name: 'I Vantaggi' }] : [])
    .concat(lineeMenu && prodotto.linee?.length > 0 ? [{ pk: 'linee', name: 'Linee' }] : [])
    //.concat([{ pk: 'garanzie', name: 'Garanzie' }])
    //.concat([{ pk: 'condizioni', name: 'Condizioni' }])
    .concat(
      faqMenu && prodotto.faqs?.length > 0 ? [{ pk: 'faqs', name: 'Domande e Risposte' }] : []
    )
    .concat(fondiMenu ? [{ pk: 'fondi', name: 'Andamento fondi' }] : [])
    .concat(allegatiMenu ? [{ pk: 'allegati', name: 'Allegati' }] : [])
    .concat(appMenu ? [{ pk: 'app', name: 'App' }] : [])

  const handleScroll = () => {
    if (menuRef.current) {
      if (window.scrollY >= menuRef.current.offsetTop - (toolbarPresente ? 80 : 0) - 80)
        document.body.classList.add('prodotto-menu-sticky')
      else document.body.classList.remove('prodotto-menu-sticky')
    }

    // Calcolo se sono all'altezza di un box del menu
    const scrollOffset = window.scrollY + (toolbarPresente ? 80 : 0) + 80 + 80 + 10 // + header + menu + correzzione
    const match = sectionRefs.current.filter(
      (x) => scrollOffset >= x.offsetTop && scrollOffset <= x.offsetTop + x.clientHeight
    )
    // Potrebbero esserci box sovrapposti (es. linee che va sopra a vantaggi), prendo l'ultimo match
    setMenuActive(match.length > 0 ? match[match.length - 1].id?.replace('menu_', '') : null)
  }

  useEffect(() => {
    if (menuActive && !scrolling) {
      const index = menuItems.findIndex((item) => item.pk === menuActive)
      if (index !== -1) menuSwiper?.slideTo(index)
    }
  }, [menuActive])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return prodotto ? (
    <Page
      pagina={pagina}
      item={prodotto}
      contentClassName={styles.prodottoContent}
      recaptchaRequired={prodotto.preventivatore}
    >
      <WContainer>
        {pagina?.breadcrumbs && (
          <Breadcrumbs breadcrumbs={pagina.breadcrumbs} className={styles.prodottoBreadcrumbs} />
        )}
      </WContainer>
      <ProdottoHead
        prodotto={prodotto}
        selectedLineePreventivatore={selectedLineePreventivatore}
        setSelectedLineePreventivatore={setSelectedLineePreventivatore}
        setPreventivatoreModalOpen={setPreventivatoreModalOpen}
        fastQuoteSubmitting={fastQuoteSubmitting}
        setFastQuoteSubmitting={setFastQuoteSubmitting}
        className={styles.head}
      />
      {topBoxesMenu && topBoxesMenu.children?.edges.length && (
        <WContainer variant="sm">
          <div className={styles.topBoxes}>
            {topBoxesMenu.children.edges.map((child) => (
              <OutlineTextCtaBox
                key={child.node.pk}
                item={child.node}
                onClick={() =>
                  sendGTMData({
                    event: 'GAevent',
                    eventCategory: 'cta',
                    eventAction: child.node?.ctaLabel?.toGTMFormat(),
                  })
                }
              />
            ))}
          </div>
        </WContainer>
      )}
      <div className={styles.menuSectionRef} ref={menuRef}>
        <div className={styles.menuSection}>
          <WContainer>
            <div className={styles.menu}>
              <Swiper slidesPerView="auto" onSwiper={(swiper) => setMenuSwiper(swiper)}>
                {menuItems.map((child, index) => (
                  <SwiperSlide key={child.pk}>
                    <a
                      key={child.pk}
                      href={`#menu_${child.pk}`}
                      className={`${styles.menuItem} ${
                        menuActive === child.pk ? styles['menuItem--active'] : ''
                      }`}
                      onClick={(e) => {
                        e.preventDefault()
                        setScrolling(true)
                        scrollTo(sectionRefs.current[index], toolbarPresente, 80) // Offset: 80 di menu
                        setTimeout(() => setScrolling(false), 1000)
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'sotto_menu',
                          eventAction: child.name?.toGTMFormat() || '',
                        })
                      }}
                    >
                      <span dangerouslySetInnerHTML={{ __html: child.name || '' }} />
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </WContainer>
        </div>
      </div>
      {descrizioneBoxesMenu && descrizioneBoxesMenu.children?.edges.length && (
        <div id="menu_descrizione" ref={addSectionRef} className={styles.descrizione}>
          <WContainer variant="sm">
            <div className={styles.descrizioneBoxes}>
              {descrizioneBoxesMenu.name && (
                <p
                  dangerouslySetInnerHTML={{ __html: descrizioneBoxesMenu.name }}
                  className={styles.descrizioneTitle}
                />
              )}
              <div className={styles.descrizioneBoxesLayout}>
                {descrizioneBoxesMenu.children.edges.map((item) => (
                  <FilledTextBox key={item.node.pk} item={item.node} />
                ))}
              </div>
            </div>
          </WContainer>
        </div>
      )}
      {offertaMenu && offertaMenu.children?.edges.length && (
        <div id="menu_offerta" ref={addSectionRef} className="bg-grey">
          <WContainer>
            <div className={styles.offerta}>
              {offertaMenu.name && (
                <p
                  dangerouslySetInnerHTML={{ __html: offertaMenu.name }}
                  className={styles.offertaTitle}
                />
              )}
              <div className={styles.offertaLayout}>
                {offertaMenu.children.edges.map((item) => (
                  <div key={item.node.pk} className={styles.offertaItem}>
                    {getIcon(item.node.style) && (
                      <span className={styles.offertaItemIcon}>
                        {getIcon(item.node.style, 'offertamenu_' + item.node.pk.toString())}
                      </span>
                    )}
                    {item.node.description && (
                      <span
                        className={styles.offertaItemContent}
                        dangerouslySetInnerHTML={{ __html: item.node.description }}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </WContainer>
        </div>
      )}
      {motiviMenu && (
        <div id="menu_motivi" className="bg-grey-white">
          <MotiviSection item={motiviMenu} />
        </div>
      )}
      {vantaggiMenu && (
        <div id="menu_vantaggi" ref={addSectionRef} className={styles.vantaggi}>
          <ContentBox item={vantaggiMenu} />
        </div>
      )}
      {!!prodotto.linee && !!lineeMenu && (
        <div id="menu_linee" ref={addSectionRef} className={`${styles.linee} bg-grey`}>
          <LineeSection
            title={lineeMenu.name}
            linee={prodotto.linee}
            note={lineeMenu.description}
          />
        </div>
      )}
      {!!prodotto.faqs && !!faqMenu && (
        <div id="menu_faqs" ref={addSectionRef} className={styles.faqs}>
          <WContainer>
            <FaqSection faqMenu={faqMenu} faqs={prodotto.faqs} />
          </WContainer>
        </div>
      )}
      {!!areaClientiMenu && (
        <div className={styles.areaClienti}>
          <WContainer>
            <TextCtaBox item={areaClientiMenu} />
          </WContainer>
        </div>
      )}
      {fondiMenu && fondiMenu.children?.edges.length && (
        <div id="menu_fondi" ref={addSectionRef} className={styles.fondi}>
          <WContainer>
            {fondiMenu.name && (
              <p
                className={styles.fondiTitle}
                dangerouslySetInnerHTML={{ __html: fondiMenu.name }}
              />
            )}
            <div className={styles.fondiList}>
              {fondiMenu.children.edges.map((child) => (
                <OutlineTextCtaBox
                  key={child.node.pk}
                  item={child.node}
                  onClick={() =>
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'andamento_fondi',
                      eventAction: 'scopri_andamento_dei_tuoi_fondi',
                      eventLabel: child.node?.url?.toGTMFormat(),
                    })
                  }
                  onChildrenClick={child.node.children?.edges.map((child) => {
                    return {
                      pk: child.node.pk,
                      onClick: () =>
                        sendGTMData({
                          event: 'GAevent',
                          eventCategory: 'andamento_fondi',
                          eventAction: 'scopri_andamento_dei_tuoi_fondi',
                          eventLabel: child.node?.url?.toGTMFormat(),
                        }),
                    }
                  })}
                />
              ))}
            </div>
          </WContainer>
        </div>
      )}
      {!!allegatiMenu && (
        <div id="menu_allegati" ref={addSectionRef} className={styles.allegati}>
          <WContainer>
            <AllegatiBox item={allegatiMenu} />
          </WContainer>
        </div>
      )}
      {!!appMenu && (
        <div id="menu_app" ref={addSectionRef} className={`${styles.app} bg-white-grey`}>
          <WContainer variant="lg">
            <AppBox item={appMenu} controlled />
          </WContainer>
        </div>
      )}
      {!!prodotto.prodottiCorrelati && prodotto.prodottiCorrelati.edges.length > 0 && (
        <div className={`${styles.correlati} bg-grey`}>
          <ProdottiSection
            item={{
              description: t('Prodotti <strong>correlati</strong>'),
            }}
            prodotti={
              prodotto.prodottiCorrelati && prodotto.prodottiCorrelati.edges.length
                ? prodotto.prodottiCorrelati.edges.map((p) => {
                    return p.node
                  })
                : []
            }
            whatIs="prodottiCorrelati"
          />
        </div>
      )}
      {!!disclaimerMenu && (
        <div className={styles.disclaimer}>
          <WContainer variant="sm">
            {disclaimerMenu.description && (
              <div
                className={`${styles.disclaimerContent} custom-content`}
                dangerouslySetInnerHTML={{ __html: disclaimerMenu.description }}
              />
            )}
          </WContainer>
        </div>
      )}
      {isFastQuoteCasa(prodotto) || isFastQuoteAuto(prodotto) ? (
        <>
          <PreventivatoreFastQuoteModal
            prodotto={prodotto}
            open={!!preventivatoreModalOpen}
            onClose={() => setPreventivatoreModalOpen(false)}
            submitting={fastQuoteSubmitting}
            setSubmitting={setFastQuoteSubmitting}
            variant={'prodotto'}
          />
          <PreventivatoreFastQuoteLoader open={fastQuoteSubmitting} prodotto={prodotto} />
        </>
      ) : (
        <PreventivatoreModal
          title={t('Consulta il nostro <strong>preventivatore</strong>')}
          prodotto={prodotto}
          open={preventivatoreModalOpen}
          setOpen={setPreventivatoreModalOpen}
          selectedLinee={selectedLineePreventivatore}
        />
      )}
      <AppModal app={appModal} open={appModalOpen} setOpen={setAppModalOpen} />
    </Page>
  ) : (
    <></>
  )
}

export default ProdottoTemplate
