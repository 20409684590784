import axios from 'axios'
import { useState, useEffect, useContext } from 'react'
import { GlobalContext } from '../../../../../services/globalContext'
import { API_HOST } from '@next-nx/utils'
import { adminApi } from 'libs/shared-ui/utils'
import { Button } from '../../../../../pb-components/atoms'

export const FileToolbarItem = ({ propKey, propSubKey, value, setProp, onChange }) => {
  const { leftSidebarTab, setLeftSidebarTab, assetId, setAssetId } = useContext(GlobalContext)

  const [waitingForFile, setWaitingForFile] = useState(false)

  const openFiler = (event) => {
    event.preventDefault()
    setWaitingForFile(true)
    setLeftSidebarTab('Assets')

    /* window.open(
      'http://localhost:9999/d/filer-wrapper/',
      'upload',
      'width=500,height=400'
    ) */
  }

  useEffect(() => {
    if (leftSidebarTab !== 'Assets') {
      setWaitingForFile(false)
    }
  }, [leftSidebarTab])

  useEffect(() => {
    const setFileFromServer = async () => {
      if (assetId) {
        if (!waitingForFile) {
          return
        }

        const response = await adminApi.get(
          //API_HOST + '/d/filebrowser_filer/url_image/' + assetId + '/'
          'filer_files/' + assetId + '/'
        )
        const data = response.data
        const url = API_HOST + data.url

        setProp((props) => {
          props[propKey][propSubKey] = onChange ? onChange(url) : url
        }, 500)

        setLeftSidebarTab(null)
        setAssetId(null)
      }
    }
    setFileFromServer()
  }, [assetId])

  return (
    <>
      {!!value && (
        <div className="toolbar-section__item__header">
          <div
            className="toolbar-section__item__header__description"
            style={{ wordBreak: 'break-all', marginBottom: '10px' }}
          >
            <p style={{ color: '#484b52' }}>File selezionato:</p>
            <p>{value}</p>
          </div>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button label="Scegli file" variant="primary" size="sm" onClick={(e) => openFiler(e)} />
        {!!value && (
          <Button
            label="Rimuovi file"
            variant="secondary"
            size="sm"
            onClick={() => {
              setProp((props) => {
                props[propKey][propSubKey] = onChange ? onChange('') : ''
              }, 500)
            }}
          />
        )}
      </div>
    </>
  )
}
