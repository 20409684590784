import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { Loader, WContainer } from '@next-nx/shared-ui/atoms'
import styles from './PreventivatoreFastQuoteCasaLoader.module.sass'

interface Props {
  className?: string
}

const PreventivatoreFastQuoteCasaLoader = (props: Props) => {

  const { 
    className = '',
  } = props

  const t = useTrans()

  const classNames = `
    ${styles.root}
    ${className ? `${className}` : ''}
  `

  return (
    <div className={classNames}>
      <WContainer>
        <div className={styles.loaderBox}><Loader className={styles.loader} /></div>
        <p className={styles.text} dangerouslySetInnerHTML={{ __html: t("Stiamo <strong>elaborando</strong> i dati raccolti") }} />
      </WContainer>
    </div>
  )
}

export default PreventivatoreFastQuoteCasaLoader
