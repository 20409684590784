import React, { useContext } from 'react';
import { useFindMenu, useIsMobile } from '@next-nx/hooks';
import {
  Breadcrumbs,
  MainDescription,
  MainTitle,
  WContainer,
  WImage,
  AssistenzaButton,
} from '@next-nx/shared-ui/atoms';
import { Page } from '@next-nx/shared-ui/organisms';
import styles from './AreaClientiTemplate.module.sass';
import { ChatBotContext } from 'libs/shared-ui/contexts/chatBotContext';
import { getIcon } from '@next-nx/utils/icons';

interface Props {
  pagina?: any;
}

const AreaClientiTemplate = (props: Props) => {
  const { pagina = {} } = props;

  const isMobile = useIsMobile()
  const chatbotCtx = useContext(ChatBotContext)

  const menuAreaClienti = useFindMenu('pagina_area_clienti');

  return (
    <Page
      pagina={pagina}
      item={{}}
      contentClassName={styles.areaClientiContent}
    >
      <div className={`${styles.headBg} bg-grey`}>
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
          <div className={styles.headLayout}>
            <div
              className={`${styles.headContent} ${
                !pagina?.mainImage ? styles['headContent--full'] : ''
              }`}
            >
              <MainTitle
                label={pagina?.nome}
                variant="light"
                className={styles.title}
              />
              <MainDescription
                html={pagina?.descrizione}
                className={styles.description}
              />
            </div>
            {pagina?.mainImage && (
              <div className={styles.headImage}>
                <WImage
                  src={isMobile ? pagina?.previewImage : pagina?.mainImage}
                  maxWidth={536}
                  maxHeight={350}
                  layout="intrinsic"
                  objectFit="cover"
                  priority={true}
                  loading="eager"
                  key={`area-clienti-main-image${isMobile ? '-mobile' : ''}`}
                />
              </div>
            )}
          </div>
        </WContainer>
      </div>
      {menuAreaClienti && menuAreaClienti.children?.edges && (
        <WContainer>
          <div className={styles.sectionContainer}>
          {menuAreaClienti.children.edges.map((child) => (
            <div
              key={`menu-area-clienti-${child.node.pk}`}
              className={styles.section}
            >
              <div className={styles.sectionTitle}>
                <p>{child.node.name}</p>
              </div>
              {!!child.node.description && 
              <div
                className={styles.sectionDescription}
                dangerouslySetInnerHTML={{
                  __html: child.node.description || '',
                }}
              />
              }
              {child.node.children?.edges && (
                <div className={styles.list}>
                  {child.node.children.edges.map((item) => (
                    <AssistenzaButton
                      key={item.node.pk}
                      label={item.node.name || ''}
                      icon={getIcon(child.node.style, 'areaclienti_button_' + item.node.pk.toString())}
                      newPage={child.node.blank}
                      {...(item.node.url
                        ? {
                            url: item.node.url,
                          }
                        : {
                            onClick: () => {
                              chatbotCtx.openChatBot(item.node);
                            },
                          })}
                    />
                  ))}
                </div>
              )}
            </div>
          ))}
          </div>
        </WContainer>
      )}
    </Page>
  );
};

export default AreaClientiTemplate;
