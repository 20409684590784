import React, { useRef, useState } from 'react'
import { useTrans } from '@next-nx/hooks'
import { getIcon } from '@next-nx/utils/icons'
import { Button, FormToggle } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneGaranziaAutoBlackBox.module.sass'
import { GaranziaQuotazioneAutoType } from '@next-nx/utils/types'
import {
  STATO_GARANZIA_OBBLIGATORIA,
} from '@next-nx/utils/fastquote'

interface Props {
  garanzia: GaranziaQuotazioneAutoType
  checked?: boolean
  onClick?: () => void
  showCheckbox?: boolean
  className?: string
}

const QuotazioneGaranziaAutoBlackBox = (props: Props) => {
  const {
    garanzia = undefined,
    checked = false,
    onClick = () => undefined,
    showCheckbox = true,
    className = '',
  } = props

  const t = useTrans()
  const [open, setOpen] = useState(false)
  const innerContentRef = useRef<HTMLDivElement>(null)

  const classeNames = `
    ${styles.root}
    ${checked ? styles['root--checked'] : ''}
    ${className ? ` ${className}` : ''}
  `

  return garanzia ? (
    <div className={classeNames}>
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.head}>
            <div className={styles.headTitle}>
              {!!getIcon(garanzia.icon) && (
                <span className={styles.icon}>
                  {getIcon(garanzia.icon, `quotazionegaranziablackbox_${garanzia.pk}`)}
                </span>
              )}
              {!!garanzia.titolo && <p className={styles.title}>{garanzia.titolo}</p>}
            </div>
            {showCheckbox && (
              <FormToggle
                id="toggle-blackbox"
                checked={checked}
                onChange={garanzia.statoIniziale !== STATO_GARANZIA_OBBLIGATORIA ? onClick : undefined}
              />
              )}
          </div>
          {!!garanzia.abstract && (
            <div
              className={styles.abstract}
              dangerouslySetInnerHTML={{ __html: garanzia.abstract }}
            />
          )}
          {!!garanzia.descrizione && (
            <>
              <div className={styles.descriptionBox}>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: garanzia.descrizione }}
                  ref={innerContentRef}
                />
                {!!garanzia.descrizioneAggiuntiva && open && (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: garanzia.descrizioneAggiuntiva }}
                  />
                )}
              </div>
              {!!garanzia.descrizioneAggiuntiva && (
                <div className={styles.descriptionCtaBox}>
                  <Button
                    variant="ghost"
                    label={t(open ? 'Chiudi descrizione' : 'Espandi descrizione')}
                    onClick={() => setOpen(!open)}
                    size="sm"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default QuotazioneGaranziaAutoBlackBox
