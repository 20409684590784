import React from 'react'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { TabPaneSettings } from './TabPaneSettings'


export const defaultProps = {}

export const dynamicProps = []

export const TabPane = withBlock(
    (props) => {
      const blockProps = {
        ...defaultProps,
        ...props.block,
      }
  
      const { children } = props
  
      const _ = props.renderValue
  
      return (
        <div
            ref={(ref) => props.retRef(ref)}
            className={'block-tab-pane ' + props.className}
        >
          {children}
        </div>
      )
    },
    defaultProps,
    dynamicProps
  )

TabPane.craft = withCraft({
  name: 'TabPane',
  defaultProps: defaultProps,
  settings: TabPaneSettings,
})
