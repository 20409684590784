import React from 'react'
import { MenuType } from '@next-nx/utils/types'
import { PdfLink } from '@next-nx/shared-ui/atoms'
import styles from './AllegatiBox.module.sass'


interface Props {
  item: MenuType
  sidebar?: boolean
  className?: string
}

const AllegatiBox = (props: Props) => {

  const { 
    item = null,
    sidebar = false,
    className = '',
  } = props

  return item ? (
    <div className={`${styles.allegatiBox} ${sidebar ? styles['allegatiBox--sidebar'] : ''} ${className ? ` ${className}` : ''}`}>
      <div className={styles.titleBox}>
        {item.name && <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.name }} />}
        {item.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />}
      </div>
      <div className={styles.content}>
        {!!item.children && item.children?.edges?.map((child) => (
          <PdfLink key={child.node.pk} name={child.node.name} url={child.node.url} />
        ))}
      </div>
    </div>
  ) : <></>
}

export default AllegatiBox
