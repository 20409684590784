import React, {useContext} from 'react'
import { ArrowRight } from '@next-nx/shared-ui/icons'
import { Button, Chip, IntroTitle } from '@next-nx/shared-ui/atoms'
import styles from './TrovaSubitoSection.module.sass'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'
import { ChatBotContext } from 'libs/shared-ui/contexts/chatBotContext'

interface Props {
  item?: MenuType
  className?: string
}

const TrovaSubitoSection = (props: Props) => {

  const {
    item = undefined,
    className = '',
  } = props

  const chatbotCtx = useContext(ChatBotContext)

  return item ? (
    <div className={`${styles.trovaSubitoSection} ${className ? ` ${className}` : ''}`}>
      {!!item.name && <IntroTitle label={item.name} className={styles.intro} />}
      {!!item.description && <div className={styles.title} dangerouslySetInnerHTML={{ __html: item.description }} />}
      {!!item.children?.edges.length && (
        <div className={styles.list}>
          {item.children.edges.map((child) => (
            <Chip
              key={child.node.pk}
              label={child.node.name}
              size="xl"
              href={child.node.url}
              onClick={() => {
                chatbotCtx.openChatBot(child.node)
                sendGTMData({
                  event: "GAevent",
                  eventCategory: "box_info",
                  eventAction: "trova_subito",
                  eventLabel: child.node.name?.toGTMFormat() || ''
                })
              }}
            />
          ))}
        </div>
      )}
      {!!item.ctaLabel && !!item.chatbotUrl && (
        <div className={styles.ctaBox}>
          <Button
            label={item.ctaLabel}
            rightIcon={<ArrowRight />}
            variant="ghost"
            onClick={() => {
              chatbotCtx.openChatBot(item)
              sendGTMData({
                event: "GAevent",
                eventCategory: "box_info",
                eventAction: "trova_subito",
                eventLabel: "non è quello che stavi cercando"
              })
            }}
          />
        </div>
      )}
    </div>
  ) : <></>
}

export default TrovaSubitoSection
