import React from 'react'

function Image({ className }) {
  return (
    <svg
      viewBox="-32 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--image ${className ? className : ''}`}
    >
      <path
        fill="currentColor"
        d="M416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-16 400H48v-64h352zm0-112h-16l-97.07-121c-7.46-9.31-22.4-9.31-29.86 0l-63.38 79-33.05-45.78c-7.92-11-25.36-11-33.28 0L64 320H48V80h352zM144 176a32 32 0 1 0-32-32 32 32 0 0 0 32 32z"
      ></path>
    </svg>
  )
}

export default Image
