import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { WContainer } from '@next-nx/shared-ui/atoms'
import { LineaBox } from '@next-nx/shared-ui/molecules'
import styles from './LineeSection.module.sass'


interface Props {
  title?: string
  linee?: any[]
  note?: string
  className?: string
}

const LineeSection = (props: Props) => {

  const { 
    title = '',
    linee = [],
    note = '',
    className = '',
  } = props

  const [garanzieOpzionaliOpen, setGaranzieOpzionaliOpen] = useState<boolean>(false)
  const [garanzieIncluseListHeight, setGaranzieIncluseListHeight] = useState<number>(0) 
  const onChangeGaranzieIncluseListHeight = (h: number) => {
    if (h > garanzieIncluseListHeight)
      setGaranzieIncluseListHeight(h)
  }

  return linee?.length ? (
    <div className={`${styles.section} ${className}`}>
      <WContainer>
        <p className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />
        {linee.length === 1 ?
        <div className={styles.lineaSingola}>
          <LineaBox 
            linea={linee[0]} 
            garanzieIncluseListHeight={garanzieIncluseListHeight}
            onChangeGaranzieIncluseListHeight={onChangeGaranzieIncluseListHeight}
            garanzieOpzionaliOpen={garanzieOpzionaliOpen}
            setGaranzieOpzionaliOpen={setGaranzieOpzionaliOpen}
          />
        </div>
        :
        <div className={styles.slider}>
          <Swiper
            slidesPerView={1}
            watchOverflow
            pagination={{ clickable: true }}
            spaceBetween={30}
            breakpoints={{
              768: {
                slidesPerView: 2
              },
              1100: {
                slidesPerView: 3
              }
            }}
          >
            {linee.map((linea) => (
              <SwiperSlide key={linea.pk}>
                <LineaBox 
                  linea={linea} 
                  garanzieIncluseListHeight={garanzieIncluseListHeight}
                  onChangeGaranzieIncluseListHeight={onChangeGaranzieIncluseListHeight}
                  garanzieOpzionaliOpen={garanzieOpzionaliOpen}
                  setGaranzieOpzionaliOpen={setGaranzieOpzionaliOpen}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        }
        {!!note && (
          <WContainer variant="sm">
            <div className={`${styles.note} ${linee.length === 1 ? styles["note--lineaSingola"] : ''}`} dangerouslySetInnerHTML={{ __html: note }} />
          </WContainer>
        )}
      </WContainer>
    </div>
  ) : <></>
}

export default LineeSection
