import React from 'react'
import { Button, Modal } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneAutoUpsellingModal.module.sass'
import {
  GaranziaQuotazioneAutoType,
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoType,
} from '@next-nx/utils/types'
import QuotazioneGaranziaAutoBox from '../../molecules/QuotazioneGaranziaAutoBox/QuotazioneGaranziaAutoBox'
import { getScontoPacchetto } from '@next-nx/utils/fastquote'
import { useTrans } from '@next-nx/hooks'

interface Props {
  pacchetto: PacchettoGaranziaQuotazioneAutoType
  garanzia: GaranziaQuotazioneAutoType
  quotazione: QuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  handleGaranzieAndPacchetti: (
    variant: 'pacchetto' | 'garanzia',
    action: 'add' | 'remove' | 'substitute',
    pacchettiCodici: string[],
    garanzieCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto?: boolean,
    lazy?: boolean,
    garanzieSostituite?: string[]
  ) => string[] | undefined
  open: boolean
  setOpen: (v: boolean) => void
  handleGtm: (
    v:
      | 'no_grazie'
      | 'aggiungi_garanzie'
      | 'chiudi_popup'
      | 'rimuovi_garanzie'
      | 'sostituisci_garanzie'
      | 'annulla_rimozione'
  ) => void
  className?: string
}

const QuotazioneAutoUpsellingModal = (props: Props) => {
  const {
    pacchetto,
    garanzia,
    quotazione,
    garanzie,
    garanzieSelezionate,
    handleGaranzieAndPacchetti = () => undefined,
    open = false,
    setOpen = () => undefined,
    handleGtm = () => undefined,
    className = '',
  } = props

  const t = useTrans()

  const scontoValue = getScontoPacchetto(quotazione, pacchetto.codice)

  const garanziePacchettiRimanenti = garanzie.filter(
    (g) =>
      g.pacchetto?.codice === pacchetto.codice &&
      g.codice !== garanzia.codice &&
      !garanzieSelezionate.includes(g.codice)
  )

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      closeButton
      onCloseClick={() => {
        handleGtm('chiudi_popup')
      }}
      className={`${styles.quotazioneAutoUpsellingModal}
      ${className ? ` ${className}` : ''}`}
      buttons={
        <>
          <Button
            label={t('No, grazie')}
            variant="ghost"
            onClick={() => {
              setOpen(false)
              handleGtm('no_grazie')
            }}
          />
          <Button
            label={
              garanziePacchettiRimanenti.length > 1
                ? t('Aggiungi le {0} garanzie', [garanziePacchettiRimanenti.length.toString()])
                : t('Aggiungi la garanzia')
            }
            onClick={() => {
              const response = handleGaranzieAndPacchetti(
                'pacchetto',
                'add',
                [pacchetto.codice],
                [],
                []
              )
              if (response) {
                handleGtm('aggiungi_garanzie')
                setOpen(false)
              }
            }}
          />
        </>
      }
    >
      <div className={styles.content}>
        <p className={styles.title} dangerouslySetInnerHTML={{ __html: pacchetto.descrizione }} />
        <div className={styles.description}>
          Aggiungi le garanzie incluse in “{pacchetto.titolo}” per ottenere il{' '}
          <span>{scontoValue}% di sconto</span>
        </div>
        <div className={styles.list}>
          {garanziePacchettiRimanenti.map((x) => (
            <QuotazioneGaranziaAutoBox
              garanzia={x}
              key={x.pk}
              showCheckbox={false}
              showCondizioni={false}
              showPrezzi={false}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default QuotazioneAutoUpsellingModal
