import React from 'react'
import { WContainer, Breadcrumbs, MainTitle, MainDescription, WImage } from '@next-nx/shared-ui/atoms'
import { PlaceholderReact } from '@next-nx/pagebuilder'
import { Page } from '@next-nx/shared-ui/organisms'
import styles from './SinistroTemplate.module.sass'
import { useIsMobile } from '@next-nx/hooks'


interface Props {
  pagina?: any
}

const SinistroTemplate = (props: Props) => {

  const { 
    pagina = null,
  } = props

  const isMobile = useIsMobile()

  return (
    <Page pagina={pagina} item={{}} contentClassName={styles.sinistroContent}>
      <div className={`${styles.headBg} bg-grey`}>
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
          <div className={styles.headLayout}>
            <div className={styles.headContent}>
              <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
              <MainDescription html={pagina?.descrizione} className={styles.description} />
            </div>
            <div className={styles.headImage}>
              <WImage
                src={isMobile ? pagina?.previewImage : pagina?.mainImage}
                maxWidth={536}
                maxHeight={350}
                layout="intrinsic"
                objectFit="cover"
                priority={true}
                loading="eager"
                key={`sinistro-main-image${isMobile ? '-mobile' : ''}`}
              />
            </div>
          </div>
        </WContainer>
      </div>
      <div className="bg-white">
        <WContainer variant="xs">
          <PlaceholderReact
            pkey="content"
            contentTypeId={pagina.contentTypeId}
            objectId={pagina.pk}
          />
        </WContainer>
      </div>
    </Page>
  )
}

export default SinistroTemplate
