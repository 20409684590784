import React from 'react'
import { TabsMenuSettings } from './TabsMenuSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { Chip } from '@next-nx/shared-ui/atoms'
import { useEditor, useNode } from '@craftjs/core'

export const defaultProps = {};

export const dynamicProps = [];

export const TabsMenu = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    };

    const { children } = props;

    const _ = props.renderValue;

    const {
        id,
        parent,
        nodes,
    } = useNode((state) => ({
        parent: state.data.parent,
        nodes: state.data.nodes,
    }));

    const { parentNode, actions } = useEditor((state, query) => ({
        parentNode: parent ? query.node(parent).get() : null,
    }));

    const active = parentNode?.data.custom.active

    const setActive = (index) => {
      actions.history.ignore().setCustom(parentNode.id, (customs) => {
        customs.active = index
      })
    }

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-tabs-menu ' + props.className}
      >
        {children.props.children.map((tab, index) => {
          return (
            <div key={index} className='block-tabs-menu__item'>
              <Chip
                label={tab}
                onClick={() => setActive(index)}
                active={active === index}
              />
            </div>
          )
        })}
      </div>
    );
  },
  defaultProps,
  dynamicProps
);

TabsMenu.craft = withCraft({
  name: 'TabsMenu',
  defaultProps: defaultProps,
  settings: TabsMenuSettings,
});
