import { Element } from '@craftjs/core';
import { 
  Button,
  Image,
  PdfLink,
  Tabs,
  TabsMenu,
  TabsContent,
  TabPane,
  Text,
  TextEditor,
  Video 
} from '../../../../components';
import {
  Text as TextIcon,
  Image as ImageIcon,
  Button as ButtonIcon,
  Video as VideoIcon,
  PdfLink as PdfLinkIcon,
  Tabs as TabsIcon,
} from '../../../../pb-components/icons';

export const TEXT = (
  <Element is={Text}>
    <TextEditor
      block={{
        text: '<p>Hello world!</p>',
        className: 'text__content',
      }}
    />
  </Element>
);


export const BUTTON = (
  <Element is={Button}>
    <TextEditor
      block={{
        text: 'Click me\n', // '\n' alla fine perchè Quill lo aggiunge in automatico se noHtml
        noHtml: true,
      }}
    />
  </Element>
);

export const IMAGE = (
  <Image block={{ src: 'https://www.w3schools.com/css/paris.jpg' }} />
);

export const PDF_LINK = (
  <PdfLink block={{ src: 'https://www.w3schools.com/css/paris.jpg' }} />
);

export const VIDEO = (
  <Video block={{ src: '' }} />
);

export const TABS = (
  <Element is={Tabs}>
    <Element is={TabsMenu}>
      <TextEditor
        block={{
          text: 'Tab 1\n', // '\n' alla fine perchè Quill lo aggiunge in automatico se noHtml
          noHtml: true,
        }}
      />
      <TextEditor
        block={{
          text: 'Tab 2\n', // '\n' alla fine perchè Quill lo aggiunge in automatico se noHtml
          noHtml: true,
        }}
      />
    </Element>
    <Element is={TabsContent}>
        <Element is={TabPane} canvas></Element>
        <Element is={TabPane} canvas></Element>
    </Element>
  </Element>
)

// possibilità di inserire icona per il block
export const conf = [
  {
    title: 'Basic',
    blocks: {
      text: {
        name: 'Text',
        icon: <TextIcon />,
        block: TEXT,
      },
      button: {
        name: 'Button',
        icon: <ButtonIcon />,
        block: BUTTON,
      },
      pdf_link: {
        name: 'PdfLink',
        icon: <PdfLinkIcon />,
        block: PDF_LINK,
      },
      image: {
        name: 'Image',
        icon: <ImageIcon />,
        block: IMAGE,
      },
      video: {
        name: 'Video',
        icon: <VideoIcon />,
        block: VIDEO,
      },
      tabs: {
        name: 'Tabs',
        icon: <TabsIcon />,
        block: TABS,
      }
    },
  },
];
