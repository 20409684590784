import React from 'react';
import { Element } from '@craftjs/core';
import { resolver } from '../editor';

export const parseNodeTree = (
  nodes,
  id,
  index = 0,
  global = null,
  disabled = null
) => {
  const type = nodes.nodes[id]['data']['name'];

  const Block = resolver[type];

  let props = JSON.parse(JSON.stringify(nodes.nodes[id]['data']['props']));
  let custom = JSON.parse(JSON.stringify(nodes.nodes[id]['data']['custom']));
  if (global) {
    custom['globalId'] = global;
  }
  if (disabled) {
    props.conditions['disabled'] = disabled;
  }

  const children = nodes.nodes[id]['data']['nodes'];

  const canvas = nodes.nodes[id]['data']['isCanvas'];

  return (
    <Element is={Block} canvas={canvas} custom={custom} {...props} key={index}>
      {children.map((childId, index) =>
        parseNodeTree(nodes, childId, index, null, disabled)
      )}
    </Element>
  );
};

export const removeCustomPropsFromSerializedNodes = (
  serializedNodes,
  query
) => {
  let newSerializedNodes = { ...serializedNodes };
  Object.keys(serializedNodes).forEach((nodeId) => {
    newSerializedNodes[nodeId].custom = {};
  });

  return newSerializedNodes;
};
