import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { useTrans } from '@next-nx/hooks'
import { isDateValid, getFormFieldArgs } from '@next-nx/utils/safe'
import { Button, FormLayout, FormField, FormSelect, FormInput, FormCheckbox, FormError } from '@next-nx/shared-ui/atoms'
import styles from './Preventivatore.module.sass'


interface Props {
  title?: string
  className?: string
}

const Preventivatore = (props: Props) => {

  const { 
    title = '',
    className = '',
  } = props

  const t = useTrans()

  // FAKE
  const tipoVeicoloOptions = [
    { label: 'Tipo 1', value: 1 }
  ]
  const numLinee = 3
  const linee = [
    { pk: 1, style: 'beni', nome: 'Sharing', prezzo: 4, descrizione: '<p>Tutela in <strong>caso di infortunio</strong> personale quali sinistri occorsi durante la guida di mezzi di sharing e noleggio (bici, auto, moto) con copertura per il contraente.</p>' },
    { pk: 2, style: 'beni', nome: 'Bike', prezzo: 4, descrizione: '<p>Offre copertura in caso di danni a terzi occorsi con utilizzo di biciclette con copertura estesa a tutta la famiglia.</p>' },
    { pk: 3, style: 'beni', nome: 'Cyber Risk', prezzo: 7, descrizione: '<p>Offre supporto legale per alcuni eventi come lesione reputazione online, furto d’identità digitale, controversie su acquisti online, con copertura estesa a tutta la famiglia.</p>' },
  ].slice(0, numLinee)


  const validationSchema = yup.object().shape({
    tipo_veicolo: yup.number().required(t("Campo obbligatorio")),
    targa: yup.string().required(t("Campo obbligatorio")),
    data_nascita: yup.string().required(t("Campo obbligatorio"))
      .matches(/[0-9]{2}\/[0-9]{2}\/[0-9]{4}/, t("Utilizzare il formato GG/MM/AAAA"))
      .test('validDate',
        t("Inserire una data valida"),
        (item?: string) => !item || isDateValid(item, 'DD/MM/YYYY')
      ),
    email: yup.string().required(t("Campo obbligatorio")).email('Inserire un\'email valida'),
    privacy: yup.bool().oneOf([true], t("Campo obbligatorio")),
  })

  const initialValues = {
    tipo_veicolo: undefined,
    targa: '',
    data_nascita: undefined,
    email: '',
    privacy: false
  }

  return (
    <div className={`${styles.preventivatore} ${className ? ` ${className}` : ''}`}>
      {!!title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, errors) => {
          // TODO: implementare logica qui
          console.log('Submin preventivatore', values, errors)
        }}
      >
        {({ values, errors, touched, setFieldValue }) => (
          <Form>
            <FormLayout>
              <FormField label={t("Tipo veicolo")} {...getFormFieldArgs('tipo_veicolo', values, errors, touched)}>
                <FormSelect
                  name="tipo_veicolo"
                  options={tipoVeicoloOptions}
                  value={values.tipo_veicolo}
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />
              </FormField>
              <FormField label={t("Targa da assicurare")} {...getFormFieldArgs('targa', values, errors, touched)}>
                <FormInput
                  name="targa"
                  value={values.targa}
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />
              </FormField>
              <FormField label={t("Data di nascita del proprietario")} {...getFormFieldArgs('data_nascita', values, errors, touched)}>
                <FormInput
                  name="data_nascita"
                  value={values.data_nascita}
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />
                <ErrorMessage name="data_nascita" render={(msg) => <FormError message={msg} />} />
              </FormField>
              <FormField label={t("La tua e-mail")} {...getFormFieldArgs('email', values, errors, touched)}>
                <FormInput
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />
                <ErrorMessage name="email" render={(msg) => <FormError message={msg} />} />
              </FormField>
              <FormField {...getFormFieldArgs('privacy', values, errors, touched)}>
                <FormCheckbox
                  id="privacy"
                  name="privacy"
                  checked={values.privacy}
                  label={t("Ho letto e accetto l'informativa {0}Privacy{1}", ['<a href="#">', '</a>'])}
                  onChange={(e) => setFieldValue(e.target.name, e.target.checked)}
                />
                <ErrorMessage name="privacy" render={(msg) => <FormError message={msg} />} />
              </FormField>
              <div className={styles.submit}>
                <Button type="submit" label={t("Richiedi un preventivo")} />
              </div>
            </FormLayout>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default Preventivatore
