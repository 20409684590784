import React from 'react'

function Tabs({ className }) {
  return (
      
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--tabs${className ? ' ' + className : ''}`}
    >
      <path d="M12,10V5H4C2.9,5,2,5.9,2,7v4v14c0,1.1,0.9,2,2,2h24c1.1,0,2-0.9,2-2V10H12z" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit={10}/>
      <path d="M30,10H12V5h16c1.1,0,2,0.9,2,2V10z" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit={10}/>
      <line x1="18" x2="18" y1="5" y2="10" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit={10}/>
      <line x1="24" x2="24" y1="5" y2="10" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit={10}/>
      <line x1="6" x2="8" y1="8" y2="8" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap='round' strokeLinejoin='round' strokeMiterlimit={10} />    
    </svg>
  )
}

export default Tabs