import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from './globalContext'
import { api } from './api'

export const GlobalCustomBlocksContext = React.createContext()

const GlobalCustomBlocksContextProvider = ({ children }) => {
  const { lang, editMode } = useContext(GlobalContext)

  const [globalCustomBlocks, setGlobalCustomBlocks] = useState()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (editMode) {
      setLoading(true)
      api
        .getGlobalCustomBlocks({ lang })
        .then((data) => {
          setGlobalCustomBlocks(data)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }, [editMode])

  const addGlobalCustomBlock = (name, json_content) => {
    return api
      .createGlobalCustomBlock({ lang, name, json_content })
      .then((data) => {
        setGlobalCustomBlocks([...globalCustomBlocks, data])
        return data
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  }

  const updateGlobalCustomBlock = (id, name, json_content) => {
    const blockIndex = globalCustomBlocks.findIndex((block) => block.id === id)
    return api
      .updateGlobalCustomBlock(id, { lang, name, json_content })
      .then((data) => {
        const newGlobalCustomBlocks = [...globalCustomBlocks]
        newGlobalCustomBlocks.splice(blockIndex, 1)
        setGlobalCustomBlocks([...newGlobalCustomBlocks, data])
        return data
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  }

  const deleteGlobalCustomBlock = (name) => {
    const blockIndex = globalCustomBlocks.findIndex((block) => block.name === name)
    return api
      .deleteGlobalCustomBlock(globalCustomBlocks[blockIndex].id)
      .then((data) => {
        const newGlobalCustomBlocks = [...globalCustomBlocks]
        newGlobalCustomBlocks.splice(blockIndex, 1)
        setGlobalCustomBlocks(newGlobalCustomBlocks)
        return data
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  }

  return (
    <>
      {editMode ? (
        <GlobalCustomBlocksContext.Provider
          value={{
            globalCustomBlocks,
            addGlobalCustomBlock,
            updateGlobalCustomBlock,
            deleteGlobalCustomBlock,
            isLoading,
          }}
        >
          {children}
        </GlobalCustomBlocksContext.Provider>
      ) : (
        children
      )}
    </>
  )
}

export default GlobalCustomBlocksContextProvider
