import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useSite, useTrans, useFindMenu } from '@next-nx/hooks'
import { getIcon } from '@next-nx/utils/icons'
import { ArrowRight, Close, Search } from '@next-nx/shared-ui/icons'
import { WContainer, Loader, NotificationBar, Button, TabGroup } from '@next-nx/shared-ui/atoms'
import styles from './SearchOverlay.module.sass'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'
import { useLazyQuery } from '@apollo/client'
import { SearchDocument as SearchDocumentAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { SearchDocument as SearchDocumentVita } from '@next-nx/shared-graphql/vita'
import { mappingRisultatiSearchOverlay, MODELLI_SEARCH } from 'libs/shared-ui/utils/search'
import { SEARCH_OVERLAY_ITEMS_PER_PAGE } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  visible: boolean
  setVisible: (v: boolean) => void
  className?: string
}

interface ResultProps {
  pk: number
  name: string
  style: string
  items: {
    pk: number
    text: string
    url: string
  }[]
}

const SearchOverlay = (props: Props) => {
  const { visible = false, setVisible = undefined, className = '' } = props

  const t = useTrans()
  const site = useSite()
  const SearchDocument = site === ASSICURAZIONI ? SearchDocumentAssicurazioni : SearchDocumentVita
  const [getSearch, { data: searchData, error: searchError, loading: searchLoading }] =
    useLazyQuery(SearchDocument)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const resultsPerPage = SEARCH_OVERLAY_ITEMS_PER_PAGE

  const [loading, setLoading] = useState<boolean>(false)
  const [risultati, setRisultati] = useState<ResultProps[]>([])
  const error = false
  const menuTopSearch = useFindMenu('top-search')
  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newQuery = e.target.q.value

    if (newQuery && newQuery.trim() !== '' && newQuery !== query) {
      setSubmitted(true)
      setLoading(true)
      setQuery(newQuery)
      sendGTMData({
        event: 'GAevent',
        eventCategory: 'search',
        eventAction: newQuery?.toString()?.toGTMFormat(),
        eventLabel: 'free_search',
      })

      getSearch({
        variables: {
          query: newQuery,
          models: MODELLI_SEARCH,
        },
      })
    }
  }

  useEffect(() => {
    if (searchData?.search?.edges?.length && !searchLoading)
      setRisultati(mappingRisultatiSearchOverlay(searchData))
    else setRisultati([])
    if (!searchLoading) setLoading(false)
  }, [searchData, searchLoading])

  return visible ? (
    <div className={`${styles.searchOverlay} ${className ? ` ${className}` : ''}`}>
      {!!setVisible && (
        <button
          type="button"
          aria-label={t('Chiudi')}
          onClick={() => setVisible(false)}
          className={styles.close}
        >
          <Close />
        </button>
      )}
      <WContainer variant="sm">
        <form className={styles.form} onSubmit={submit} autoComplete="off">
          <div className={styles.inputWrap}>
            <input
              type="text"
              name="q"
              className={styles.input}
              placeholder={t('Cerca')}
              autoComplete="off"
            />
            <button type="submit" className={styles.submit}>
              <Search />
            </button>
          </div>
        </form>
        <p className={styles.helpText}>{t('Premi invio per cercare')}</p>
        {!submitted ? (
          <div className={styles.suggestions}>
            {menuTopSearch &&
              menuTopSearch.children?.edges &&
              menuTopSearch.children.edges.slice(0, 4).map((item) => (
                <Link legacyBehavior href={item.node.url ? item.node.url : '/'} key={item.node.pk}>
                  <a
                    className={styles.suggestionItem}
                    onClick={() =>
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'search',
                        eventAction: item.node.name?.toGTMFormat(),
                        eventLabel: 'suggested',
                      })
                    }
                  >
                    {!!item.node.style && (
                      <span className={styles.suggestionItemIcon}>
                        {getIcon(item.node.style, 'searchoverlay_menu_' + item.node.pk.toString())}
                      </span>
                    )}
                    {!!item.node.name && (
                      <p className={styles.suggestionItemTitle}>{item.node.name}</p>
                    )}
                  </a>
                </Link>
              ))}
          </div>
        ) : loading ? (
          <Loader />
        ) : error ? (
          <NotificationBar variant="alert" label={error} />
        ) : risultati.length ? (
          <div className={styles.results}>
            <TabGroup
              triggerSize="lg"
              tabs={risultati.map((resultType) => ({
                key: resultType.pk.toString(),
                label: resultType.name,
                icon: getIcon(resultType.style, 'searchoverlay_tab_' + resultType.pk.toString()),
                content: (
                  <div className={styles.tabContent}>
                    <div className={styles.page}>
                      {resultType.items.slice(0, resultsPerPage).map((item) => (
                        <Link legacyBehavior href={item.url} key={item.pk}>
                          <a
                            className={styles.pageItem}
                            dangerouslySetInnerHTML={{ __html: `<span>${item.text}</span>` }}
                          />
                        </Link>
                      ))}
                    </div>
                    <div className={styles.pageActions}>
                      <p
                        className={styles.pageIndicator}
                        dangerouslySetInnerHTML={{
                          __html: t('{0} di {1}', [
                            `<strong>${
                              resultType.items.length > resultsPerPage
                                ? resultsPerPage
                                : resultType.items.length
                            }</strong>`,
                            resultType.items.length.toString(),
                          ]),
                        }}
                      />
                      <Button
                        label={t('Vedi tutti i risultati')}
                        rightIcon={<ArrowRight />}
                        variant="ghost"
                        external
                        href={'/ricerca?q=' + query}
                        onClick={() =>
                          sendGTMData({
                            event: 'GAevent',
                            eventCategory: 'search',
                            eventAction: 'ricerca_suggerita',
                            eventLabel: 'vedi_tutti_risultati',
                          })
                        }
                      />
                    </div>
                  </div>
                ),
              }))}
            />
          </div>
        ) : (
          <p className={styles.noResults}>{t('Non ci sono risultati per questa ricerca')}</p>
        )}
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default SearchOverlay
