import React from 'react'
import styles from './FilledTextBox.module.sass'
import { getIcon } from '@next-nx/utils/icons'
import { MenuType } from '@next-nx/utils/types'


interface Props {
  item: MenuType
  className?: string
}

const FilledTextBox = (props: Props) => {

  const { 
    item = undefined,
    className = '',
  } = props

  const icon = getIcon(item?.style, 'filledtextbox_' + item?.pk?.toString())  

  return item ? (
    <div className={`${styles.filledTextBox} ${className ? ` ${className}` : ''}`}>
      <div className={styles.content}>
        {!!item.name && (
          <p className={styles.title}>
            {!!icon && <span className={styles.icon}>{icon}</span>}
            <span>{item.name}</span>
          </p>
        )}
        {!!item.description && (
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />
        )}
      </div>
    </div>
  ) : <></>
}

export default FilledTextBox
