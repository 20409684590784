import React, { useState, useEffect, useRef } from 'react'
import { getIcon } from '@next-nx/utils/icons'
import { useTrans } from '@next-nx/hooks'
import { Button, Chip, Tooltip } from '@next-nx/shared-ui/atoms'
import { ChevronDown, ChevronUp, Info } from '@next-nx/shared-ui/icons'
import styles from './LineaBox.module.sass'


interface GaranzieBlockProps {
  title: string
  garanzie: {
    pk: number
    style: string
    info?: string
    titolo: string
  }[]
  collapsable?: boolean
  listHeight?: number
  onChangeListHeight?: (h: number) => void
  open?: boolean
  setOpen?: (v: boolean) => void
}


interface Props {
  linea: any
  garanzieIncluseListHeight?: number
  onChangeGaranzieIncluseListHeight: (h: number) => void
  garanzieOpzionaliOpen?: boolean
  setGaranzieOpzionaliOpen?: (v: boolean) => void
  className?: string
}


const GaranzieBlock = (props: GaranzieBlockProps) => {

  const { 
    title = '',
    garanzie = [],
    collapsable = false,
    listHeight = 0,
    onChangeListHeight = undefined,
    open = false,
    setOpen = (v) => undefined,
  } = props

  const innerContentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (innerContentRef.current && onChangeListHeight)
      onChangeListHeight(innerContentRef.current.clientHeight)
  }, [innerContentRef, onChangeListHeight])

  return garanzie.length > 0 ? (
    <div className={`${styles.garanzieBlock} ${collapsable ? `${styles['garanzieBlock--collapsable']} ${open ? styles['garanzieBlock--collapsable--open'] : ''}` : ''}`}>
      {collapsable ? (
        <button className={styles.garanzieButton} onClick={() => setOpen(!open)}>
          <span className={styles.garanzieTitle}>{title}</span>
          <span className={styles.garanzieArrow}>{open ? <ChevronUp /> : <ChevronDown />}</span>
        </button>
      ) : (
        <p className={styles.garanzieTitle}>{title}</p>
      )}
      <div 
        className={styles.garanzieList}
        style={collapsable ? {
          maxHeight: innerContentRef?.current && open ? innerContentRef.current.clientHeight : 0,
        } : {
          minHeight: listHeight
        }}
      >
        <div className={styles.garanzieListContent} ref={innerContentRef}>
          {garanzie.map((garanzia) => (
            <p key={garanzia.pk} className={`${styles.garanzieItem} ${styles[`garanzieItem--${garanzia.style}`]}`}>
              <span className={styles.garanzieItemIcon}>{getIcon(garanzia.style, 'lineabox_garanzia_' + garanzia.pk?.toString())}</span>
              <span className={garanzia.info ? styles.garanzieItemLabel : ''}>{garanzia.titolo}</span>
              {!!garanzia.info &&
                <Tooltip
                  id={`tooltip-garanzia-${garanzia.pk}`}
                  label={garanzia.info}
                  size="sm"
                  pos="bottom"
                  autoWidth
                >
                  <span className={styles.garanzieItemInfo}><Info /></span>
                </Tooltip>
              }
            </p>
          ))}
        </div>
      </div>
    </div>
  ) : <></>

}

const LineaBox = (props: Props) => {

  const { 
    linea = undefined,
    garanzieIncluseListHeight = 0,
    onChangeGaranzieIncluseListHeight = (h) => undefined,
    garanzieOpzionaliOpen = false,
    setGaranzieOpzionaliOpen = (v) => undefined,
    className = '',
  } = props

  const t = useTrans()
  const [showFullTextMobile, setShowFullTextMobile] = useState(false)

  return linea ? (
    <div className={`${styles.lineaBox} ${linea.prezzo ? styles['lineaBox--footer'] : ''} ${className ? ` ${className}` : ''}`}>
      <div className={styles.head}>
        {linea.label && <Chip className={styles.chip} label={linea.label} variant="light" weight="regular" size="sm" />}
        {linea.titolo && <p className={`${styles.title} ${styles[`title--${linea.style}`]}`} dangerouslySetInnerHTML={{ __html: linea.titolo }} />}
      </div>
      {linea.description ? (
        <>
          <div className={`${styles.description} ${showFullTextMobile ? styles['description--mobile-full'] : ''} custom-content`} dangerouslySetInnerHTML={{ __html: linea.description}} />
          <Button 
            label={t(showFullTextMobile ? "Leggi meno" : "Leggi di più")} 
            variant="ghost"
            className={styles.readMore} 
            onClick={() => setShowFullTextMobile(!showFullTextMobile)}
          />
        </>
      ) : (
        <div>
          <GaranzieBlock title={t("Garanzie incluse")} garanzie={linea.garanzieIncluse} listHeight={garanzieIncluseListHeight} onChangeListHeight={onChangeGaranzieIncluseListHeight} />
          <GaranzieBlock title={t("Garanzie opzionali")} garanzie={linea.garanzieOpzionali} open={garanzieOpzionaliOpen} setOpen={setGaranzieOpzionaliOpen} collapsable />
        </div>
      )}
      {!!linea.prezzo && (
        <div className={styles.footer}>
          <span className={styles.priceLabel}>{t("Prezzo (€/mese)")}</span>  
          <span className={styles.priceValue}>€ {linea.prezzo}</span>  
        </div>
      )}
    </div>
  ) : <></>
}

export default LineaBox
