import React, { useState } from 'react'
import * as yup from 'yup'
import { useSite, useTrans } from '@next-nx/hooks'
import { getFormFieldArgs, datePickerFormat } from '@next-nx/utils/safe'
import { Formik, Form } from 'formik'
import { Button, FormDate, FormSelect, FormField, FormError } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneVerificaFormAuto.module.sass'
import { QuotazioneAutoType } from '@next-nx/utils/types'
import { SITES_ID } from 'libs/shared-ui/utils/settings'
import { getPremioAutoFast, validateDataDecorrenza } from '@next-nx/utils/fastquote'
import { useGetPremioAutoFastFullDetailMutation } from '@next-nx/shared-graphql/assicurazioni'

interface Props {
  quotazione: QuotazioneAutoType
  setQuotazione?: (q: any) => void
  setSubmitting?: (v: boolean) => void
  className?: string
}

const QuotazioneVerificaFormAuto = (props: Props) => {
  const { quotazione, setQuotazione = () => {}, setSubmitting = () => {}, className = '' } = props

  const t = useTrans()
  const site = useSite()

  const [getPremioAutoFastMutation] = useGetPremioAutoFastFullDetailMutation()
  const [error, setError] = useState<string | null>(null)

  const onVerificaDati = (values: any, actions: any) => {
    setSubmitting(true)
    getPremioAutoFast(
      getPremioAutoFastMutation,
      {
        siteId: SITES_ID[site],
        dataDecorrenza: values.dataDecorrenza,
        cap: values.cap,
        allestimentoCodice: values.allestimentoCodice,
        sistemaAdas: values.sistemaAdas,
        antifurto: values.antifurto,
        tipoGuida: values.tipoGuida,
      },
      quotazione
    )
      .then((res) => {
        if (res?.data?.getPremioAutoFast?.status) {
          setError(null)
          setQuotazione(res?.data?.getPremioAutoFast?.quotazione)
          window.scrollTo({ top: 0, behavior: 'instant' })
        } else {
          if (
            res?.data?.getPremioAutoFast?.message &&
            res?.data?.getPremioAutoFast?.message != 'KO'
          ) {
            setError(res?.data?.getPremioAutoFast?.message)
          } else {
            setError(t("c'è stato un errore, ti invitiamo a riprovare più tardi"))
          }
        }
        setSubmitting(false)
        actions.setSubmitting(false)
      })
      .catch((err) => {
        console.log(err)
        setSubmitting(false)
        actions.setSubmitting(false)
        setError(t("c'è stato un errore, ti invitiamo a riprovare più tardi"))
      })
  }

  const validationSchema = yup.object().shape({
    cap: yup.string().required(t('Campo obbligatorio')),
    tipoGuida: yup.string().required(t('Campo obbligatorio')),
    sistemaAdas: yup.string().required(t('Campo obbligatorio')),
    antifurto: yup.string().required(t('Campo obbligatorio')),
    allestimentoCodice: yup.string().required(t('Campo obbligatorio')),
    dataDecorrenza: yup
      .string()
      .typeError(t('É necessario specificare una data di inizio copertura'))
      .test(
        'dataDecorrenza',
        t('Non è accettata una data passata o futura oltre 359 giorni'),
        (value) => {
          return validateDataDecorrenza(value)
        }
      )
      .required(t('Campo obbligatorio')),
  })

  const initialValues = {
    cap: quotazione?.cap
      ? quotazione?.cap
      : quotazione?.listaCap && Array.isArray(quotazione?.listaCap)
      ? quotazione?.listaCap[0]
      : quotazione?.listaCap,
    tipoGuida: quotazione?.tipoGuida ? quotazione?.tipoGuida : quotazione.campiTipoGuida[0].value,
    sistemaAdas: quotazione?.sistemaAdas
      ? quotazione?.sistemaAdas
      : quotazione.campiSistemaAdas[0].value,
    antifurto: quotazione?.antifurto ? quotazione?.antifurto : quotazione.campiAntifurto[0].value,
    allestimentoCodice: quotazione?.allestimentoCodice
      ? quotazione.allestimentoCodice
      : quotazione?.allestimentiAuto && quotazione?.allestimentiAuto.length > 0
      ? quotazione?.allestimentiAuto[0].codice
      : '',
    dataDecorrenza: quotazione?.dataDecorrenza ? datePickerFormat(quotazione.dataDecorrenza) : '',
  }

  const getDataDecorrenzaMaxDate = () => {
    const maxDate = new Date()
    maxDate.setDate(maxDate.getDate() + 359)
    return maxDate
  }

  return (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: t('Verifica e <strong>modifica i dati</strong>') }}
      />
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnMount
        enableReinitialize
        onSubmit={async (values, actions) => {
          onVerificaDati(values, actions)
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <div className={styles.list}>
              <div className={styles.item}>
                <div className={styles.itemLabel}>
                  <span className={styles.itemTitle}>{t('Marca e modello')}</span>
                </div>
                <div className={styles.itemField}>
                  <span className={styles.itemDisabled}>
                    {quotazione?.marcaAuto} {quotazione?.modelloAuto}
                  </span>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.itemLabel}>
                  <span className={styles.itemTitle}>{t('Allestimento')}</span>
                </div>
                <div className={styles.itemField}>
                  <FormField
                    space="lg"
                    {...getFormFieldArgs('allestimentoCodice', values, errors, touched)}
                    layout="full"
                    className={styles.field}
                  >
                    <FormSelect
                      size="lg"
                      variant="dropdown"
                      name="allestimentoCodice"
                      value={values.allestimentoCodice}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                        setFieldTouched(e.target.name, true, false)
                      }}
                      onBlur={handleBlur}
                      options={
                        quotazione?.allestimentiAuto.map((x) => ({
                          label: x.descrizione,
                          value: x.codice,
                        })) || []
                      }
                    />
                  </FormField>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.itemLabel}>
                  <span className={styles.itemTitle}>{t('Tipologia di antifurto')}</span>
                </div>
                <div className={styles.itemField}>
                  <FormField
                    space="lg"
                    {...getFormFieldArgs('antifurto', values, errors, touched)}
                    layout="full"
                    className={styles.field}
                  >
                    <FormSelect
                      size="lg"
                      variant="dropdown"
                      name="antifurto"
                      value={values.antifurto}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                        setFieldTouched(e.target.name, true, false)
                      }}
                      onBlur={handleBlur}
                      options={quotazione.campiAntifurto.map((x) => ({
                        label: x.nome,
                        value: x.value,
                      }))}
                    />
                  </FormField>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.itemLabel}>
                  <span className={styles.itemTitle}>{t('Sistemi di sicurezza ADAS')}</span>
                </div>
                <div className={styles.itemField}>
                  <FormField
                    space="lg"
                    {...getFormFieldArgs('sistemaAdas', values, errors, touched)}
                    layout="full"
                    className={styles.field}
                  >
                    <FormSelect
                      size="lg"
                      variant="dropdown"
                      name="sistemaAdas"
                      value={values.sistemaAdas}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                        setFieldTouched(e.target.name, true, false)
                      }}
                      onBlur={handleBlur}
                      options={quotazione.campiSistemaAdas.map((x) => ({
                        label: x.nome,
                        value: x.value,
                      }))}
                    />
                  </FormField>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.itemLabel}>
                  <span className={styles.itemTitle}>{t('Data di inizio copertura')}</span>
                </div>
                <div className={styles.itemField}>
                  <FormField
                    space="lg"
                    status={errors['dataDecorrenza'] ? 'error' : ''}
                    errorMessage={errors['dataDecorrenza']}
                    layout="full"
                    className={styles.field}
                  >
                    <FormDate
                      name="dataDecorrenza"
                      bgVariant="white"
                      placeholder={t('gg/mm/aaaa')}
                      value={values.dataDecorrenza}
                      minDate={new Date()} // today
                      maxDate={getDataDecorrenzaMaxDate()} // 359 days from today
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                        setFieldTouched(e.target.name, true, false)
                      }}
                      onBlur={handleBlur}
                      status={touched.dataDecorrenza && errors.dataDecorrenza ? 'error' : ''}
                    />
                  </FormField>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.itemLabel}>
                  <span className={styles.itemTitle}>{t('Tipo di guida')}</span>
                </div>
                <div className={styles.itemField}>
                  <FormField
                    space="lg"
                    {...getFormFieldArgs('tipoGuida', values, errors, touched)}
                    layout="full"
                    className={styles.field}
                  >
                    <FormSelect
                      size="lg"
                      variant="dropdown"
                      name="tipoGuida"
                      value={values.tipoGuida}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                        setFieldTouched(e.target.name, true, false)
                      }}
                      onBlur={handleBlur}
                      options={quotazione.campiTipoGuida.map((x) => ({
                        label: x.nome,
                        value: x.value,
                      }))}
                    />
                  </FormField>
                </div>
              </div>
              <div className={styles.item}>
                <div className={styles.itemLabel}>
                  <span className={styles.itemTitle}>{t('CAP')}</span>
                </div>
                <div className={styles.itemField}>
                  <FormField
                    space="lg"
                    layout="full"
                    className={styles.field}
                    {...getFormFieldArgs('cap', values, errors, touched)}
                  >
                    <FormSelect
                      size="lg"
                      variant="dropdown"
                      name="cap"
                      value={values.cap}
                      onChange={(e) => {
                        setFieldValue(e.target.name, e.target.value)
                        setFieldTouched(e.target.name, true, false)
                      }}
                      onBlur={handleBlur}
                      options={
                        quotazione?.listaCap && quotazione?.listaCap?.split(',').length > 0
                          ? quotazione?.listaCap?.split(',')?.map((el) => {
                              return {
                                label: el,
                                value: el,
                              }
                            })
                          : [
                              {
                                label: quotazione?.listaCap,
                                value: quotazione?.listaCap,
                              },
                            ]
                      }
                    />
                  </FormField>
                </div>
              </div>
            </div>
            {error && (
              <div className={styles.error}>
                <FormError message={`<strong>Attenzione</strong>: ${error}`} visible />
              </div>
            )}
            <div className={styles.submitBox}>
              <p>{t('Il contraente coincide con il proprietario.')}</p>
              <Button
                label={t('Conferma i dati inseriti')}
                type="submit"
                disabled={!Object.keys(touched).length || !isValid || isSubmitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default QuotazioneVerificaFormAuto
