import React, { useEffect } from 'react'
import styles from './QuotazioneGaranziaProtezioneBaseBox.module.sass'
import { GaranziaQuotazioneType } from '@next-nx/utils/types'
import { QuotazioneGaranziaBox } from '@next-nx/shared-ui/molecules'

interface Props {
  garanzia: GaranziaQuotazioneType
  garanziaSelezionata?: string
  setGaranziaSelezionata?: (v: string) => void
  description?: string
  className?: string
  openChildren?: boolean
  checked?: boolean
  toggleChildren?: () => void
  handleGtm?: (
    type: 'add' | 'remove',
    garanzia: GaranziaQuotazioneType,
    garanziaPadre?: string
  ) => void
}

const QuotazioneGaranziaProtezioneBaseBox = (props: Props) => {
  const {
    garanzia,
    garanziaSelezionata = undefined,
    setGaranziaSelezionata = () => undefined,
    description = '',
    className = '',
    openChildren = false,
    checked = false,
    toggleChildren = () => undefined,
    handleGtm = () => undefined,

  } = props

  useEffect(() => {
    const firstSottoGaranzia = garanzia?.children?.[0]
    if (firstSottoGaranzia) {
      setGaranziaSelezionata(firstSottoGaranzia.codice)
    }
  }, [])

  return (
    <QuotazioneGaranziaBox
      checked
      garanzia={garanzia}
      openChildren={openChildren}
      toggleChildren={toggleChildren}
    >
      {openChildren &&
        <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
          {description && (
            <div className={styles.description} dangerouslySetInnerHTML={{__html: description}}/>
          )}
          <div className={styles.list}>
            {garanzia.children?.map((y) => (
              <QuotazioneGaranziaBox
                key={y.pk}
                garanzia={y}
                variant="prestazione"
                checked={garanziaSelezionata === y.codice}
                onClick={() => {
                  setGaranziaSelezionata(y.codice)

                  const prevGaranziaSelezionata = garanzia.children?.find(
                    (x) => x.codice === garanziaSelezionata
                  )
                  prevGaranziaSelezionata &&
                  handleGtm('remove', prevGaranziaSelezionata, garanzia.titolo)
                  handleGtm('add', y, garanzia.titolo)
                }}
              />
            ))}
          </div>
        </div>
      }
    </QuotazioneGaranziaBox>
  )
}

export default QuotazioneGaranziaProtezioneBaseBox
