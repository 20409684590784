import React from 'react'

function CustomBlocks({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--custom-blocks${className ? ' ' + className : ''}`}
    >
      <path d="M6 10v28c0 2.21 1.79 4 4 4h28c2.21 0 4-1.79 4-4v-28c0-2.21-1.79-4-4-4h-28c-2.21 0-4 1.79-4 4zm24 8c0 3.32-2.69 6-6 6s-6-2.68-6-6c0-3.31 2.69-6 6-6s6 2.69 6 6zm-18 16c0-4 8-6.2 12-6.2s12 2.2 12 6.2v2h-24v-2z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  )
}

export default CustomBlocks
