import React, { useState } from 'react'
import Link from 'next/link'
import { MenuType } from '@next-nx/utils/types'
import { WContainer } from '@next-nx/shared-ui/atoms'
import { TextCtaBox } from '@next-nx/shared-ui/molecules'
import styles from './Nav.module.sass'
import { useFindMenu } from '@next-nx/hooks'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  className?: string
}

const Nav = (props: Props) => {
  const { className = '' } = props

  const [activeItem, setActiveItem] = useState<MenuType | null>(null)

  const mainMenu = useFindMenu('main')

  // Se il livello massimo è 2, non c'è lo stile dei titoletti (vita = 2, assicurazioni = 3)
  const maxLevelMenu =
    mainMenu &&
    mainMenu.children?.edges &&
    mainMenu.children.edges.filter(
      (x) =>
        x.node.children?.edges.length &&
        x.node.children.edges.filter((y) => y.node.children?.edges.length).length > 0
    ).length > 0
      ? 3
      : 2

  return (
    <div
      className={`${styles.nav} ${className ? ` ${className}` : ''}`}
      onMouseLeave={() => setActiveItem(null)}
    >
      <div className={styles.main}>
        {mainMenu &&
          mainMenu.children?.edges &&
          mainMenu.children.edges.map((item) => (
            <div
              key={item.node.pk}
              className={styles.mainItem}
              onMouseEnter={() => setActiveItem(item.node)}
            >
              {item.node.url ? (
                <Link legacyBehavior href={item.node.url}>
                  <a
                    className={`${styles.mainItemLabel} ${styles.link} ${
                      activeItem?.pk === item.node.pk ? styles['mainItemLabel--open'] : ''
                    }`}
                    onClick={() =>
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'menu',
                        eventAction: 'first_level_menu',
                        eventLabel: item.node.name?.toGTMFormat(),
                      })
                    }
                  >
                    {item.node.name}
                  </a>
                </Link>
              ) : (
                <span
                  className={`${styles.mainItemLabel} ${styles.noLink} ${
                    activeItem?.pk === item.node.pk ? styles['mainItemLabel--open'] : ''
                  }`}
                >
                  {item.node.name}
                </span>
              )}
            </div>
          ))}
      </div>
      <div className={`${styles.dropdown} ${activeItem ? styles['dropdown--open'] : ''}`}>
        <WContainer>
          {!!activeItem?.children?.edges && (
            <div className={styles.dropdownContent}>
              {maxLevelMenu === 3 ? (
                activeItem.children.edges.map((item) => (
                  <div
                    key={item.node.pk}
                    className={`${styles.dropdownCol} ${
                      item.node.style === 'box' ? styles['dropdownCol--auto'] : ''
                    }`}
                  >
                    {item.node.style === 'box' ? (
                      <TextCtaBox
                        item={item.node}
                        size="sm"
                        onClick={() =>
                          sendGTMData({
                            event: 'GAevent',
                            eventCategory: 'menu',
                            eventAction: 'banner',
                            eventLabel: item?.node?.name?.toGTMFormat(),
                          })
                        }
                      />
                    ) : (
                      <div className={styles.dropdownItem}>
                        {item.node.url ? (
                          <Link legacyBehavior href={item.node.url}>
                            <a
                              className={`${styles.dropdownItemLabel} ${styles.link}`}
                              onClick={() =>
                                sendGTMData({
                                  event: 'GAevent',
                                  eventCategory: 'menu',
                                  eventAction: 'second_level_menu',
                                  eventLabel: `${activeItem.name?.toGTMFormat()} | ${item.node.name?.toGTMFormat()}`,
                                })
                              }
                            >
                              {item.node.name}
                            </a>
                          </Link>
                        ) : (
                          <span className={`${styles.dropdownItemLabel} ${styles.noLink}`}>
                            {item.node.name}
                          </span>
                        )}
                        {item?.node?.children?.edges &&
                          item.node.children.edges.filter((child) => !!child.node.url).length >
                            0 && (
                            <div className={styles.dropdownItemChildren}>
                              {item.node.children.edges
                                .filter((child) => !!child.node.url)
                                .map((child) => (
                                  <div key={child.node.pk}>
                                    <Link legacyBehavior href={child.node.url || '#'}>
                                      <a
                                        className={styles.dropdownItemChild}
                                        onClick={() =>
                                          sendGTMData({
                                            event: 'GAevent',
                                            eventCategory: 'menu',
                                            eventAction: 'third_level_menu',
                                            eventLabel: `${activeItem.name?.toGTMFormat()} | ${item.node.name?.toGTMFormat()} | ${child.node.name?.toGTMFormat()}`,
                                          })
                                        }
                                      >
                                        {child.node.name}
                                      </a>
                                    </Link>
                                  </div>
                                ))}
                            </div>
                          )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <>
                  <div
                    className={`${styles.dropdownCol} ${
                      styles[
                        `dropdownCol--${Math.ceil(
                          activeItem.children.edges.filter(
                            (child) => !!child.node.url && child.node.style !== 'box'
                          ).length / 6
                        )}-cols`
                      ]
                    }`}
                  >
                    <div className={styles.dropdownItem}>
                      <div
                        className={`${styles.dropdownItemChildren} ${
                          styles[
                            `dropdownItemChildren--${Math.ceil(
                              activeItem.children.edges.filter(
                                (child) => !!child.node.url && child.node.style !== 'box'
                              ).length / 6
                            )}-cols`
                          ]
                        }`}
                      >
                        {activeItem?.children?.edges
                          ?.filter((child) => !!child.node.url && child.node.style !== 'box')
                          .map((child) => (
                            <div key={child.node.pk}>
                              <Link legacyBehavior href={child.node.url || '#'}>
                                <a
                                  className={styles.dropdownItemChild}
                                  onClick={() =>
                                    sendGTMData({
                                      event: 'GAevent',
                                      eventCategory: 'menu',
                                      eventAction: 'second_level_menu',
                                      eventLabel: `${activeItem.name?.toGTMFormat()} | ${child.node.name?.toGTMFormat()}`,
                                    })
                                  }
                                >
                                  {child.node.name}
                                </a>
                              </Link>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  {activeItem?.children?.edges
                    ?.filter((child) => child.node.style === 'box')
                    .map((child) => (
                      <div className={`${styles.dropdownCol} ${styles['dropdownCol--auto']}`}>
                        <TextCtaBox
                          item={child.node}
                          size="sm"
                          onClick={() =>
                            sendGTMData({
                              event: 'GAevent',
                              eventCategory: 'menu',
                              eventAction: 'banner',
                              eventLabel: child?.node?.name?.toGTMFormat(),
                            })
                          }
                        />
                      </div>
                    ))}
                </>
              )}
            </div>
          )}
        </WContainer>
      </div>
    </div>
  )
}

export default Nav
