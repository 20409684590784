import React, { useEffect, useState } from 'react'
import { useTrans, useSite, useFindMenu } from '@next-nx/hooks'
import { ProdottoType } from '@next-nx/utils/types'
import { WContainer } from '@next-nx/shared-ui/atoms'
import { TextCtaBox, AppBox, ImageTextBox } from '@next-nx/shared-ui/molecules'
import {
  AssistenzaSection,
  HeroSlider,
  NewsSection,
  Page,
  ProdottiSection,
  TestimonianzeSection,
  TrovaSubitoSection,
  VideoCampagnaSection,
  PreventivatoreHomeSection,
  PreventivatoreModal,
  PreventivatoreFastQuoteModal,
  PreventivatoreFastQuoteLoader,
} from '@next-nx/shared-ui/organisms'
import styles from './HomeTemplate.module.sass'
import { useArticoliMagazineQuery as useArticoliMagazineQueryAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { useArticoliMagazineQuery as useArticoliMagazineQueryVita } from '@next-nx/shared-graphql/vita'
import { ASSICURAZIONI, VITA } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  pagina?: any
  prodottiPreventivatore?: any[]
  className?: string
}

const HomeTemplate = (props: Props) => {
  const { pagina = null, prodottiPreventivatore = [], className = '' } = props

  const site = useSite()
  const useArticoliMagazineQuery =
    site === ASSICURAZIONI ? useArticoliMagazineQueryAssicurazioni : useArticoliMagazineQueryVita

  const t = useTrans()

  const [preventivatoreModalOpen, setPreventivatoreModalOpen] = useState<boolean>(false)
  const [prodottoPreventivatore, setProdottoPreventivatore] = useState<ProdottoType | undefined>(
    undefined
  )
  const [prodottoPreventivatoreModal, setProdottoPreventivatoreModal] = useState<
    ProdottoType | undefined
  >(undefined)
  const [prodottoPreventivatoreFastQuoteModal, setProdottoPreventivatoreFastQuoteModal] = useState<
    ProdottoType | undefined
  >(undefined)
  const [selectedLineePreventivatore, setSelectedLineePreventivatore] = useState<number[]>([])
  const [fastQuoteSubmitting, setFastQuoteSubmitting] = useState(false)

  useEffect(() => {
    if (prodottoPreventivatoreModal) {
      setPreventivatoreModalOpen(true)
    }
  }, [prodottoPreventivatoreModal])

  useEffect(() => {
    if (!preventivatoreModalOpen) {
      setProdottoPreventivatoreModal(undefined)
    }
  }, [preventivatoreModalOpen])

  const hpMenu = useFindMenu('hp')

  const heroSliderMenu = useFindMenu('hero', hpMenu)

  const trovaSubitoMenu = useFindMenu('trova-subito', hpMenu)

  const videoCampagnaMenu = useFindMenu('video-campagna', hpMenu)

  const assistenzaMenu = useFindMenu('assistenza', hpMenu)

  const areaClienteBoxMenu = useFindMenu('area-cliente', hpMenu)

  // SOLO ASSICURAZIONI
  const prodottiMenu = site === VITA ? null : useFindMenu('prodotti', hpMenu)

  const appMenu = useFindMenu('app', hpMenu)

  // SOLO ASSICURAZIONI
  const testimonianzeMenu = site === VITA ? null : useFindMenu('testimonianze', hpMenu)

  // SOLO VITA
  const vitaRendimentiMenu = site === VITA ? useFindMenu('rendimenti', hpMenu) : null

  // SOLO VITA
  const vitaModulisticaMenu = site === VITA ? useFindMenu('modulistica', hpMenu) : null

  const newsMenu = useFindMenu('news', hpMenu)

  const disclaimerMenu = useFindMenu('disclaimer', hpMenu)

  const articoliMagazineQuery = useArticoliMagazineQuery({
    variables: {
      first: 5,
    },
  })

  return (
    <Page
      pagina={pagina}
      item={{}}
      contentClassName={styles.homeContent}
      recaptchaRequired={site === ASSICURAZIONI && prodottiPreventivatore ? true : false}
    >
      <div className="bg-grey">
        {!!heroSliderMenu && <HeroSlider item={heroSliderMenu} />}
        {site === ASSICURAZIONI && prodottiPreventivatore && prodottiPreventivatore.length > 0 && (
          <div className={styles.preventivatore}>
            <WContainer variant="sm">
              <PreventivatoreHomeSection
                prodotti={prodottiPreventivatore}
                setProdottoPreventivatore={setProdottoPreventivatore}
                setProdottoPreventivatoreModal={setProdottoPreventivatoreModal}
                setProdottoPreventivatoreFastQuoteModal={setProdottoPreventivatoreFastQuoteModal}
                selectedLineePreventivatore={selectedLineePreventivatore}
                setSelectedLineePreventivatore={(linee_ids) =>
                  setSelectedLineePreventivatore(linee_ids)
                }
                fastQuoteSubmitting={fastQuoteSubmitting}
                setFastQuoteSubmitting={setFastQuoteSubmitting}
              />
            </WContainer>
          </div>
        )}
        {!!trovaSubitoMenu && (
          <div className={styles.trovaSubito}>
            <WContainer variant="sm">
              <TrovaSubitoSection item={trovaSubitoMenu} />
            </WContainer>
          </div>
        )}

        {!!videoCampagnaMenu && (
          <div className={styles.videoCampagna}>
            <WContainer variant={videoCampagnaMenu.style ? videoCampagnaMenu.style : 'md'}>
              <VideoCampagnaSection item={videoCampagnaMenu} />
            </WContainer>
          </div>
        )}
      </div>
      {!!assistenzaMenu && (
        <div className={`${styles.assistenza} bg-grey-white--30`}>
          <WContainer variant="lg">
            <AssistenzaSection item={assistenzaMenu} />
          </WContainer>
        </div>
      )}
      {!!areaClienteBoxMenu && (
        <div
          className={`${styles.areaCliente} ${
            site === ASSICURAZIONI ? 'bg-white-grey' : 'bg-grey'
          }`}
        >
          <WContainer>
            <TextCtaBox
              item={areaClienteBoxMenu}
              onClick={() =>
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'login',
                  eventAction: 'accedi | registrati',
                  eventLabel: 'step1',
                })
              }
            />
          </WContainer>
        </div>
      )}
      <div className="bg-grey">
        {!!prodottiMenu &&
          prodottiMenu.children?.edges &&
          prodottiMenu.children.edges.length > 0 && (
            <div className={styles.prodotti}>
              <ProdottiSection
                item={prodottiMenu}
                prodotti={
                  prodottiMenu && prodottiMenu.children?.edges.length
                    ? prodottiMenu.children?.edges.map((child) => {
                        return child.node.prodottoCollegato
                      })
                    : []
                }
                prev={{
                  event: 'GAevent',
                  eventCategory: 'box_info',
                  eventAction: 'esplora',
                  eventLabel: 'pre',
                }}
                next={{
                  event: 'GAevent',
                  eventCategory: 'box_info',
                  eventAction: 'esplora',
                  eventLabel: 'next',
                }}
                whatIs="prodottiMenu"
              />
            </div>
          )}
        {!!appMenu && (
          <div className={styles.app}>
            <WContainer variant="lg">
              <AppBox item={appMenu} controlled />
            </WContainer>
          </div>
        )}
        {!!testimonianzeMenu && (
          <div className={styles.testimonianze}>
            <TestimonianzeSection item={testimonianzeMenu} />
          </div>
        )}
        {!!vitaRendimentiMenu && (
          <div className={styles.rendimenti}>
            <WContainer>
              <ImageTextBox item={vitaRendimentiMenu} />
            </WContainer>
          </div>
        )}
      </div>
      {!!vitaModulisticaMenu && (
        <div className="bg-grey-white--70">
          <div className={styles.modulistica}>
            <WContainer>
              <ImageTextBox item={vitaModulisticaMenu} textPos="left" />
            </WContainer>
          </div>
        </div>
      )}
      {!!newsMenu && !!articoliMagazineQuery?.data?.articoliMagazine && (
        <div className={`${styles.news} bg-white`}>
          <NewsSection
            news={articoliMagazineQuery.data.articoliMagazine.edges}
            intro={newsMenu.name}
            title={newsMenu.description}
            ctaLabel={newsMenu.ctaLabel}
            url={newsMenu.url}
          />
        </div>
      )}
      {!!disclaimerMenu && (
        <div className={styles.disclaimer}>
          <WContainer variant="sm">
            {disclaimerMenu.description && (
              <div
                className={`${styles.disclaimerContent} custom-content`}
                dangerouslySetInnerHTML={{ __html: disclaimerMenu.description }}
              />
            )}
          </WContainer>
        </div>
      )}
      <PreventivatoreModal
        title={t('Consulta il nostro <strong>preventivatore</strong>')}
        prodotto={prodottoPreventivatoreModal}
        open={preventivatoreModalOpen}
        setOpen={setPreventivatoreModalOpen}
        selectedLinee={selectedLineePreventivatore}
      />
      <PreventivatoreFastQuoteModal
        prodotto={prodottoPreventivatoreFastQuoteModal}
        open={!!prodottoPreventivatoreFastQuoteModal}
        onClose={() => setProdottoPreventivatoreFastQuoteModal(undefined)}
        submitting={fastQuoteSubmitting}
        setSubmitting={setFastQuoteSubmitting}
      />
      <PreventivatoreFastQuoteLoader
        prodotto={prodottoPreventivatoreFastQuoteModal || prodottoPreventivatore}
        open={fastQuoteSubmitting}
      />
    </Page>
  )
}

export default HomeTemplate
