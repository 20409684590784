import React from 'react'
import FaqCategoryCard from '../../molecules/FaqCategoryCard/FaqCategoryCard'
import styles from './FaqCategoriesListing.module.sass'
import { sendGTMData } from '../../../utils/gtm'


interface Props {
  className?: string
  categorieFaq: any
}

const FaqCategoriesListing = (props: Props) => {

  const {
    className = '',
    categorieFaq = [],
  } = props

  return (
    <div className={`${styles.faqCategoriesListing} ${className ? ` ${className}` : ''}`}>
      {categorieFaq.map((item) => (
        <FaqCategoryCard key={item.pk} item={item} onClick={() => sendGTMData({
          event: "GAevent",
          eventCategory: 'faq_category',
          eventAction: item?.titolo?.toGTMFormat()
        })} />
      ))}
    </div>
  )
}

export default FaqCategoriesListing
