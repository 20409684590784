import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArrowLeft, ArrowRight } from '@next-nx/shared-ui/icons'
import { Button, WImage } from '@next-nx/shared-ui/atoms'
import styles from './HeroSlider.module.sass'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'
import { useIsMobile } from '@next-nx/hooks'


interface Props {
  item?: MenuType
  className?: string
}

const HeroSlider = (props: Props) => {

  const {
    item = undefined,
    className = '',
  } = props

  const isMobile = useIsMobile()

  const imagesSwiperSettings = {
    navigation: {
      nextEl: '.hero-custom-button-next',
      prevEl: '.hero-custom-button-prev'
    },
    loop: true
  }

  const contentSwiperSettings = {
    pagination: item?.children?.edges?.length === 1 ? false : { clickable: true },
    navigation: {
      nextEl: '.hero-custom-button-next',
      prevEl: '.hero-custom-button-prev'
    },
    loop: true,
    className: `${styles.slider} swiper-container--pagination swiper-container--pagination--white`
  }

  return item && !!item.children?.edges?.length ? (
    <div className={`${styles.heroSlider} ${className ? ` ${className}` : ''}`}>
      <div className={styles.imagesSlider}>
        <Swiper {...imagesSwiperSettings}>
          {item.children.edges.map((child, i) => (
            <SwiperSlide key={child.node.pk}>
              <div className={styles.imageBox}>
                <WImage
                  src={isMobile ? child.node.mainImageResponsive : child.node.mainImage}
                  maxWidth={1920}
                  maxHeight={575}
                  layout="intrinsic"
                  objectFit="cover"
                  priority={i === 0 ? true : false}
                  loading={i === 0 ? 'eager' : 'lazy'}
                  key={`hero-slide-${i}${isMobile ? '-mobile' : ''}`}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className={styles.contentSlider}>
        {item?.children?.edges?.length > 1 &&
        <>
        <button type="button" className="hero-custom-button-prev custom-button-prev custom-button-prev--white" onClick={() => sendGTMData({
          event: "GAevent",
          eventCategory: "slider",
          eventAction: "previous",
          eventLabel: "homepage"
        })}>
          <ArrowLeft />
        </button>
        <button type="button" className="hero-custom-button-next custom-button-next custom-button-prev--white" onClick={() => sendGTMData({
          event: "GAevent",
          eventCategory: "slider",
          eventAction: "next",
          eventLabel: "homepage"
        })}>
          <ArrowRight />
        </button>
        </>
        }
        <Swiper {...contentSwiperSettings}>
          {item.children.edges.map((child) => (
            <SwiperSlide key={child.node.pk}>
              <div className={styles.content}>
                {!!child.node.name && <p className={styles.title}>{child.node.name}</p>}
                {!!child.node.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: child.node.description }} />}
                {!!child.node.ctaLabel && !!child.node.url && (
                  <div className={styles.ctaBox}>
                    <Button label={child.node.ctaLabel} variant="tertiary2" href={child.node.url} onClick={() => sendGTMData({
                        event: "GAevent",
                        eventCategory: "slider",
                        eventAction: "scopri_offerta",
                        eventLabel: "homepage"
                        })} />
                  </div>
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  ) : <></>
}

export default HeroSlider
