import React from 'react'

function ImageCopy({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--image-copy${className ? ' ' + className : ''}`}
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M44 32v-24c0-2.21-1.79-4-4-4h-24c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h24c2.21 0 4-1.79 4-4zm-22-8l4.06 5.42 5.94-7.42 8 10h-24l6-8zm-18-12v28c0 2.21 1.79 4 4 4h28v-4h-28v-28h-4z" />
    </svg>
  )
}

export default ImageCopy
