import React from 'react'

function Unpublish({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--unpublish${className ? ' ' + className : ''}`}
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M38.71 20.07c-1.36-6.88-7.43-12.07-14.71-12.07-2.95 0-5.7.87-8.02 2.34l2.92 2.92c1.53-.79 3.26-1.26 5.1-1.26 6.08 0 11 4.92 11 11v1h3c3.31 0 6 2.69 6 6 0 2.27-1.27 4.22-3.13 5.24l2.9 2.9c2.55-1.81 4.23-4.77 4.23-8.14 0-5.28-4.11-9.56-9.29-9.93zm-32.71-9.52l5.5 5.48c-6.38.27-11.5 5.52-11.5 11.97 0 6.63 5.37 12 12 12h23.45l4 4 2.55-2.54-33.45-33.46-2.55 2.55zm9.45 9.45l16 16h-19.45c-4.42 0-8-3.58-8-8s3.58-8 8-8h3.45z" />
    </svg>
  )
}

export default Unpublish
