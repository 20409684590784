import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ProdottoType } from '@next-nx/utils/types'
import { useTrans } from '@next-nx/hooks'
import { getIcon } from '@next-nx/utils/icons'
import { TabGroup } from '@next-nx/shared-ui/atoms'
import { TabTriggerCard } from '@next-nx/shared-ui/molecules'
import { PreventivatoreIntro, PreventivatoreFastQuoteCasa, PreventivatoreFastQuoteAuto } from '@next-nx/shared-ui/organisms'
import styles from './PreventivatoreHomeSection.module.sass'
import { isFastQuoteAuto, isFastQuoteCasa } from '@next-nx/utils/fastquote'


interface Props {
  prodotti: ProdottoType[]
  setProdottoPreventivatore: (p: ProdottoType) => void
  setProdottoPreventivatoreModal: (p: ProdottoType) => void
  setProdottoPreventivatoreFastQuoteModal: (p: ProdottoType) => void
  selectedLineePreventivatore: number[]
  setSelectedLineePreventivatore: (linee_ids: number[]) => void
  fastQuoteSubmitting: boolean
  setFastQuoteSubmitting: (v: boolean) => void
  className?: string
}

const PreventivatoreHomeSection = (props: Props) => {

  const { 
    prodotti = [],
    setProdottoPreventivatore = () => undefined,
    setProdottoPreventivatoreModal = () => undefined,
    setProdottoPreventivatoreFastQuoteModal = () => undefined,
    selectedLineePreventivatore = [],
    setSelectedLineePreventivatore = () => undefined,
    fastQuoteSubmitting = false,
    setFastQuoteSubmitting = () => undefined,
    className = '',
  } = props

  const t = useTrans()
  
  const tabs = prodotti.map(p => ({ 
    p: p,
    key: `prodotto_${p.pk}`, 
    label: p.titolo,
    icon: getIcon(p.icon, `_preventivatorehomesection_tab_${p.pk}`),
    banner: isFastQuoteCasa(p) ? t("50€ di cashback") : undefined,
    content: isFastQuoteCasa(p) ? (
      <PreventivatoreFastQuoteCasa 
        prodotto={p} 
        submitting={fastQuoteSubmitting}
        setSubmitting={setFastQuoteSubmitting}
        idVariant={"_" + p.pk} 
      />
    ) : isFastQuoteAuto(p) ? (
      <PreventivatoreFastQuoteAuto 
        prodotto={p} 
        submitting={fastQuoteSubmitting}
        setSubmitting={setFastQuoteSubmitting}
        idVariant={"_" + p.pk} 
      />
    ) : (
      <PreventivatoreIntro 
        title={t("Consulta il nostro <strong>preventivatore</strong>")} 
        ctaLabel={t("Richiedi un preventivo personalizzato")} 
        prodotto={p} 
        setFormModalOpen={() => setProdottoPreventivatoreModal(p)}
        selectedLinee={selectedLineePreventivatore}
        setSelectedLinee={(linee_ids) => setSelectedLineePreventivatore(linee_ids)}
        className={styles.tabContent}
      />
    )
  }))

  return (
    <div
      className={`${styles.preventivatoreHomeSection} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className={styles.tabs}>
        <TabGroup 
          tabs={tabs} 
          bgColor="primary"
          clearSelectedLineePreventivatore={() => setSelectedLineePreventivatore([])}
          setProdottoPreventivatore={setProdottoPreventivatore}
        />
      </div>
      <div className={styles.slider}>
        <div
          className={styles.titleMobile}
          dangerouslySetInnerHTML={{
            __html: t('Consulta il nostro <strong>preventivatore</strong>'),
          }}
        />
        <Swiper
          spaceBetween={16}
          slidesPerView={1.5}
          pagination={{ clickable: true }}
          watchOverflow
        >
          {prodotti.map((p) => (
            <SwiperSlide key={p.pk}>
              <div className={styles.sliderItem}>
                <TabTriggerCard
                  label={p.titolo}
                  icon={getIcon(p.icon, `_preventivatorehomesection_slider_${p.pk}`)}
                  banner={isFastQuoteCasa(p) ? t("50€ di cashback") : undefined}
                  onClick={() => (isFastQuoteCasa(p) || isFastQuoteAuto(p)) ? setProdottoPreventivatoreFastQuoteModal(p) : setProdottoPreventivatoreModal(p)}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default PreventivatoreHomeSection
