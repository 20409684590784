import React from 'react'
import { useNode } from '@craftjs/core'


export const TabPaneSettings = (props) => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }));

  return (
    <div className="empty-message">
      <p>No options available</p>
    </div>
  )
};

