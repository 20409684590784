import React from 'react'

function Export({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--export${className ? ' ' + className : ''}`}
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M18 32h12v-12h8l-14-14-14 14h8zm-8 4h28v4h-28z" />
    </svg>
  )
}

export default Export
