import React, { useEffect, useState } from 'react'
import { Button, Tooltip } from '@next-nx/shared-ui/atoms'
import { Info } from '@next-nx/shared-ui/icons'
import { useRouter } from 'next/router'
import styles from './Toolbar.module.sass'
import { api } from '@next-nx/utils'
import { useSite, useUtente } from '@next-nx/hooks'
import { TOOLBAR } from '@next-nx/utils/endpoints'
import { ASSICURAZIONI, BANCA } from 'libs/shared-ui/utils/settings'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

interface Props {
  page?: any
  item?: any
}

const Toolbar = (props: Props) => {
  const { page = null, item = null } = props

  const router = useRouter()
  const site = useSite()
  const { utente, loading } = useUtente()

  const previewModeAbilitata = site === ASSICURAZIONI || BANCA
  const [paginaContienePagebuilder, setPaginaContienePagebuilder] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setPaginaContienePagebuilder(document.getElementsByClassName('pb-content').length > 0)
    }, 1000)
  }, [router.asPath])

  if (!utente) return null

  if (!(utente.isStaff || utente.isImpersonate)) return null

  const handleSwitchMode = (edit: boolean) => {
    api
      .post(TOOLBAR + 'set_edit/', { edit })
      .then(() => {
        router.reload()
      })
      .catch((error) => console.log(error))
  }

  const VisibilitaTooltipDescription = (item: any) => {
    return (
      <div>
        <p>Stato pubblicazione: {item.pubblicato ? 'pubblicato' : 'non publicato'}</p>
        {item.visibilitaDal && <p>Visibilità dal: {item.visibilitaDal}</p>}
        {item.visibilitaAl && <p>Visibilità fino al: {item.visibilitaAl}</p>}
      </div>
    )
  }

  return (
    <div className={`${styles.toolbar} toolbar`}>
      {utente.isImpersonate ? (
        <div className={`${styles.user} ${styles['user--impersonate']}`}>
          Stai impersonando{' '}
          <span>
            {utente.nome} {utente.cognome}
          </span>{' '}
          - <a href={utente.stopImpersonateUrl}>Smetti di impersonare</a>
        </div>
      ) : (
        <>
          <Button label="Admin" size="sm" href="/d/admin/" newPage className={styles.adminLink} />
          <div className={styles.user}>
            {utente.nome} {utente.cognome}
          </div>
          <section className={styles.left}></section>
          <section className={styles.right}>
            {previewModeAbilitata &&
              (item && 'visibile' in item ? (
                <span>
                  Stato:{' '}
                  <span className={`${styles[`visibilita--${item.visibile}`]}`}>
                    {item.visibile ? 'visibile' : 'non visibile'}
                  </span>
                  <Tooltip
                    id={`tooltip-toolbar-item-visibilita`}
                    label={<VisibilitaTooltipDescription item={item} />}
                    pos="bottom"
                    className={styles.tooltip}
                  >
                    <span className={styles.info}>
                      <Info />
                    </span>
                  </Tooltip>
                </span>
              ) : (
                page &&
                'visibile' in page && (
                  <span>
                    Stato:{' '}
                    <span className={`${styles[`visibilita--${page.visibile}`]}`}>
                      {page.visibile ? 'visibile' : 'non visibile'}
                    </span>
                    <Tooltip
                      id={`tooltip-toolbar-page-visibilita`}
                      label={<VisibilitaTooltipDescription item={page} />}
                      pos="bottom"
                      className={styles.tooltip}
                    >
                      <span className={styles.info}>
                        <Info />
                      </span>
                    </Tooltip>
                  </span>
                )
              ))}

            {item && item.adminUrl && (
              <Button
                label={`Modifica ${item.modelClass}`}
                size="sm"
                variant="ghost"
                href={item.adminUrl}
                newPage
              />
            )}
            {page && page.adminUrl && (
              <Button label="Admin Pagina" size="sm" variant="ghost" href={page.adminUrl} newPage />
            )}
            {paginaContienePagebuilder &&
              (utente.edit ? (
                <Button label="VEDI PUBBLICATA" size="sm" onClick={() => handleSwitchMode(false)} />
              ) : (
                <Button label="MODIFICA" size="sm" onClick={() => handleSwitchMode(true)} />
              ))}
            {previewModeAbilitata &&
              (router.isPreview ? (
                <Tooltip
                  id={`tooltip-toolbar-stop-preview`}
                  label={
                    'Con la modalità preview disattivata non potrai visualizzare le pagine nello stato "non visibile"'
                  }
                  pos="bottom"
                  className={styles.tooltip}
                >
                  <div className={styles.button}>
                    <Button
                      external
                      label="Disattiva modalità preview"
                      size="sm"
                      href={`/api/stop-preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${router.asPath}`}
                      className={styles.button}
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip
                  id={`tooltip-toolbar-attiva-preview`}
                  label={
                    'Con la modalità preview attiva puoi visualizzare le pagine anche se nello stato "non visibile"'
                  }
                  pos="bottom"
                  className={styles.tooltip}
                >
                  <div className={styles.button}>
                    <Button
                      external
                      label="Attiva modalità preview"
                      size="sm"
                      href={`/api/preview?secret=${process.env.PREVIEW_MODE_SECRET}&redirect=${router.asPath}`}
                      className={styles.button}
                    />
                  </div>
                </Tooltip>
              ))}
          </section>
        </>
      )}
    </div>
  )
}

export default Toolbar
