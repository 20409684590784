import React from 'react'
import { MenuType } from '@next-nx/utils/types'
import { Button, WImage } from '@next-nx/shared-ui/atoms'
import styles from './ImageTextBox.module.sass'
import { sendGTMData } from '../../../utils/gtm'
import { useIsMobile } from '@next-nx/hooks'


interface Props {
  item?: MenuType
  textPos?: 'left' | 'right'
  className?: string
}

const ImageTextBox = (props: Props) => {

  const {
    item = undefined,
    textPos = 'right',
    className = '',
  } = props

  const isMobile = useIsMobile()

  return item ? (
    <div className={`${styles.imageTextBox} ${styles[`imageTextBox--text-${textPos}`]} ${className ? ` ${className}` : ''}`}>
      <div className={styles.imageBox}>
        <WImage
          maxWidth={966}
          maxHeight={474}
          src={isMobile ? item.mainImageResponsive : item.mainImage}
          layout="intrinsic"
          objectFit="cover"
          key={`image-text-box-${item.pk}-main-image${isMobile ? '-mobile' : ''}`}
        />
      </div>
      <div className={styles.content}>
        {!!item.name && <p className={styles.title}>{item.name}</p>}
        {!!item.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />}
        {!!item.ctaLabel && !!item.url && (
          <div className={styles.ctaBox}>
            <Button label={item.ctaLabel} variant="tertiary2" href={item.url} onClick={() => sendGTMData({
              event: "GAevent",
              eventCategory: "banner_promo",
              eventAction: item.name?.toGTMFormat() || '',
              eventLabel: "homepage"
            })}/>
          </div>
        )}
      </div>
    </div>
  ) : <></>
}

export default ImageTextBox
