import React from 'react'

function ChevronLeft({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--chevron-left${className ? ' ' + className : ''}`}
    >
      <path d="M30.83 32.67l-9.17-9.17 9.17-9.17-2.83-2.83-12 12 12 12z" />
      <path d="M0-.5h48v48h-48z" fill="none" />
    </svg>
  )
}

export default ChevronLeft
