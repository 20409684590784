import React from 'react'
import { MenuType } from '@next-nx/utils/types'
import { Button, WImage } from '@next-nx/shared-ui/atoms'
import styles from './ImageTextBoxSeparated.module.sass'
import { sendGTMData } from '../../../utils/gtm'
import { useIsMobile } from '@next-nx/hooks'

interface Props {
  item?: MenuType
  textPos?: 'left' | 'right'
  className?: string
}

const ImageTextBoxSeparated = (props: Props) => {
  const { item = undefined, textPos = 'right', className = '' } = props

  const isMobile = useIsMobile()

  return item ? (
    <div
      className={`${styles.imageTextBox} ${styles[`imageTextBox--text-${textPos}`]} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className={styles.imageBox}>
        <WImage
          maxWidth={350}
          maxHeight={isMobile ? 200 : 350}
          src={isMobile ? item.mainImageResponsive : item.mainImage}
          layout="responsive"
          objectFit="fill"
          key={`image-text-box-${item.pk}-main-image${isMobile ? '-mobile' : ''}`}
        />
      </div>
      <div className={styles.content}>
        {!!item.name && (
          <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.name }} />
        )}
        {!!item.description && (
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
        {!!item.ctaLabel && !!item.url && (
          <div className={styles.ctaBox}>
            <Button
              label={item.ctaLabel}
              variant="tertiary2"
              href={item.url}
              onClick={() =>
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'banner_promo',
                  eventAction: item.name?.toGTMFormat() || '',
                  eventLabel: 'homepage',
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ImageTextBoxSeparated
