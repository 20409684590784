import React from 'react'

function Text({ className }) {
  return (
    <svg
      viewBox="-76 -44 600 600"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--text ${className ? className : ''}`}
    >
      <path
        fill="currentColor"
        d="M432 32a16 16 0 0 1 16 16v80a16 16 0 0 1-16 16h-16a16 16 0 0 1-16-16V96H256v336h48a16 16 0 0 1 16 16v16a16 16 0 0 1-16 16H144a16 16 0 0 1-16-16v-16a16 16 0 0 1 16-16h48V96H48v32a16 16 0 0 1-16 16H16a16 16 0 0 1-16-16V48a16 16 0 0 1 16-16z"
      ></path>
    </svg>
  )
}

export default Text
