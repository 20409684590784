import React, { useState } from 'react'
import { useTrans } from '@next-nx/hooks'
import styles from './QuotazionePacchettoAutoSimpleBox.module.sass'
import {
  GaranziaQuotazioneAutoType,
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoType,
} from '@next-nx/utils/types'
import { Chip, FormToggle } from '@next-nx/shared-ui/atoms'
import { getScontoPacchetto } from '@next-nx/utils/fastquote'

interface Props {
  quotazione: QuotazioneAutoType
  pacchetto: PacchettoGaranziaQuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  checked?: boolean
  onClick?: () => void
  showCheckbox?: boolean
  showScontoChip?: boolean
  showScontoDescription?: boolean
  className?: string
}

const QuotazionePacchettoAutoSimpleBox = (props: Props) => {
  const {
    quotazione,
    pacchetto,
    garanzie = [],
    garanzieSelezionate = [],
    checked = false,
    onClick = () => undefined,
    showCheckbox = false,
    showScontoChip = false,
    showScontoDescription = false,
    className = '',
  } = props

  const t = useTrans()
  const [isHover, setHover] = useState(false)

  const classeNames = `
    ${styles.root}
    ${checked ? styles['root--checked'] : ''}
    ${showCheckbox ? styles['root--checkbox'] : ''}
    ${className ? ` ${className}` : ''}
  `

  const scontoValue = getScontoPacchetto(quotazione, pacchetto.codice)

  const garanziePacchetto = garanzie.filter((g) => g.pacchetto?.codice === pacchetto.codice)

  const ScontoChip = ({ className = '' }: { className?: string }) => (
    <>
      {!!scontoValue && (
        <div className={`${styles.scontoChip} ${className}`}>
          <Chip label={`-${scontoValue}% di sconto`} variant="light" />
        </div>
      )}
    </>
  )

  const ScontoContent = ({ className = '' }: { className?: string }) => (
    <>
      {!!scontoValue && (
        <div className={`${styles.scontoContent} ${className}`}>
          <ScontoChip />
          <span>{t('se includi le {0} garanzie', [garanziePacchetto.length.toString()])}</span>
        </div>
      )}
    </>
  )

  const Checkbox = ({ className = '' }: { className?: string }) => (
    <div className={`${styles.checkbox} ${className}`}>
      <FormToggle
        label={checked ? t('Aggiunto') : t('Aggiungi')}
        id={`toggle-simple-pacchetto-${pacchetto.codice}`}
        checked={checked}
        labelPos="left"
        onChange={onClick}
      />
    </div>
  )

  return pacchetto ? (
    <div
      className={`${classeNames} ${isHover ? styles['root--hovered'] : ''}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.head} onClick={onClick}>
            {showCheckbox && <Checkbox className={styles['checkbox--mobile']} />}
            {showScontoChip && <ScontoChip className={styles['scontoChip--mobile']} />}
            {showScontoDescription && <ScontoContent className={styles['scontoContent--mobile']} />}
            {!!pacchetto.titolo && <p className={styles.title}>{pacchetto.titolo}</p>}
            {showCheckbox && <Checkbox />}
            {showScontoChip && <ScontoChip />}
          </div>
          {showScontoDescription && <ScontoContent />}
          {!!pacchetto.descrizione && (
            <>
              <div className={styles.descriptionBox} onClick={onClick}>
                <div className={styles.description}>
                  Comprende{' '}
                  {garanziePacchetto.map((x, i) => (
                    <React.Fragment key={x.pk}>
                      <strong>{x.titolo}</strong>
                      {garanziePacchetto.length - 2 > i
                        ? ', '
                        : garanziePacchetto.length - 1 > i
                        ? ' e '
                        : ''}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default QuotazionePacchettoAutoSimpleBox
