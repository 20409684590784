import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { Formik, Form } from 'formik'
import { useSite, useTrans } from '@next-nx/hooks'
import { Alert } from '@next-nx/shared-ui/icons'
import {
  Button,
  FormDate,
  FormField,
  FormError,
  FormInput,
  FormLayout,
  FormRadio,
  Modal,
  NotificationBar,
} from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneAutoGaranzieEscluseModal.module.sass'
import { getFormFieldArgs } from '@next-nx/utils/safe'
import { useCalcolaCodiceFiscaleMutation } from '@next-nx/shared-graphql/assicurazioni'
import { useSalvaTrasformaQuotazioneFastQuoteCasaMutation } from '@next-nx/shared-graphql/assicurazioni'
import { useCreateRichiestaPreventivatoreMutation } from '@next-nx/shared-graphql/assicurazioni'
import { ASSICURAZIONI, BANCA, SITES_ID } from 'libs/shared-ui/utils/settings'
import {
  GaranziaQuotazioneAutoType,
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoType,
} from '@next-nx/utils/types'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useRouter } from 'next/router'
import { sendGTMData } from 'libs/shared-ui/utils/gtm'
import QuotazioneGaranziaAutoBox from '../../molecules/QuotazioneGaranziaAutoBox/QuotazioneGaranziaAutoBox'

interface Props {
  pacchetto: PacchettoGaranziaQuotazioneAutoType | undefined
  garanzia: GaranziaQuotazioneAutoType | undefined
  quotazione: QuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  handleGaranzieAndPacchetti: (
    variant: 'pacchetto' | 'garanzia',
    action: 'add' | 'remove' | 'substitute',
    pacchettiCodici: string[],
    garanzieCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto?: boolean,
    lazy?: boolean,
    garanzieSostituite?: string[]
  ) => string[] | undefined
  open: boolean
  setOpen: (v: boolean) => void
  handleGtm: (
    v:
      | 'no_grazie'
      | 'aggiungi_garanzie'
      | 'chiudi_popup'
      | 'rimuovi_garanzie'
      | 'sostituisci_garanzie'
      | 'annulla_rimozione'
  ) => void
  className?: string
}

const QuotazioneAutoGaranzieEscluseModal = (props: Props) => {
  const {
    pacchetto,
    garanzia,
    quotazione,
    garanzie,
    garanzieSelezionate,
    handleGaranzieAndPacchetti = () => undefined,
    open = false,
    setOpen = () => undefined,
    handleGtm = () => undefined,
    className = '',
  } = props

  const t = useTrans()

  const garanzieEscluse = garanzie.filter(
    (x) => x.garanzieEscluse.includes(garanzia.codice) && garanzieSelezionate.includes(x.codice)
  )

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      closeButton
      onCloseClick={() => {
        handleGtm('chiudi_popup')
      }}
      className={`${styles.QuotazioneAutoGaranzieEscluseModal}
      ${className ? ` ${className}` : ''}`}
      buttons={
        <>
          <Button
            label={t('Annulla')}
            variant="ghost"
            onClick={() => {
              setOpen(false)
              handleGtm('annulla_rimozione')
            }}
          />
          <Button
            label={t('Sostituisci garanzie')}
            onClick={() => {
              const response = handleGaranzieAndPacchetti(
                'garanzia',
                'substitute',
                [],
                [garanzia.codice],
                garanzieSelezionate,
                true,
                true,
                [...garanzieEscluse.map((x) => x.codice)]
              )

              if (response) {
                handleGtm('sostituisci_garanzie')
                setOpen(false)
              }
            }}
          />
        </>
      }
    >
      <div className={styles.content}>
        <p
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t('Vuoi <strong>aggiungere</strong> la garanzia <strong>{0}</strong>?', [
              garanzia.titolo || '',
            ]),
          }}
        />
        <div className={styles.description}>Per aggiungere la garanzia:</div>
        <div className={styles.list}>
          <QuotazioneGaranziaAutoBox
            garanzia={garanzia}
            key={garanzia.pk}
            showCheckbox={false}
            showCondizioni={false}
            showPrezzi={false}
          />
        </div>
        <div className={styles.description}>
          Rimuoverai {garanzieEscluse.length > 1 ? 'le garanzie' : 'la garanzia'}:
        </div>
        <div className={styles.list}>
          {garanzieEscluse.map((x) => (
            <QuotazioneGaranziaAutoBox
              garanzia={x}
              key={x.pk}
              showCheckbox={false}
              showCondizioni={false}
              showPrezzi={false}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default QuotazioneAutoGaranzieEscluseModal
