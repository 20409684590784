import React, { useEffect, useState } from 'react'
import { useTrans } from '@next-nx/hooks'
import { Button, Modal } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneAutoGaranzieNecessariePacchettoModal.module.sass'
import {
  GaranziaQuotazioneAutoType,
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoType,
} from '@next-nx/utils/types'
import QuotazionePacchettoAutoSimpleBox from '../../molecules/QuotazionePacchettoAutoSimpleBox/QuotazionePacchettoAutoSimpleBox'
import { STATO_GARANZIA_ESCL } from '@next-nx/utils/fastquote'

interface Props {
  pacchetto: PacchettoGaranziaQuotazioneAutoType | undefined
  garanzia: GaranziaQuotazioneAutoType | undefined
  quotazione: QuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  handleGaranzieAndPacchetti: (
    variant: 'pacchetto' | 'garanzia',
    action: 'add' | 'remove' | 'substitute',
    pacchettiCodici: string[],
    garanzieCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto?: boolean,
    lazy?: boolean,
    garanzieSostituite?: string[]
  ) => string[] | undefined
  open: boolean
  setOpen: (v: boolean) => void
  handleGtm: (
    v:
      | 'no_grazie'
      | 'aggiungi_garanzie'
      | 'chiudi_popup'
      | 'rimuovi_garanzie'
      | 'sostituisci_garanzie'
      | 'annulla_rimozione'
  ) => void
  className?: string
}

const QuotazioneAutoGaranzieNecessariePacchettoModal = (props: Props) => {
  const {
    pacchetto,
    garanzia,
    quotazione,
    garanzie,
    garanzieSelezionate,
    handleGaranzieAndPacchetti = () => undefined,
    open = false,
    setOpen = () => undefined,
    handleGtm = () => undefined,
    className = '',
  } = props

  const t = useTrans()

  const [pacchettiSelezionati, setPacchettiSelezionati] = useState<string[]>([])

  useEffect(() => {
    if (!open) setPacchettiSelezionati([])
  }, [open])

  const pacchettiSelezionabili = quotazione.pacchetti.filter((p) => {
    return (
      garanzie.filter(
        (g) => g.pacchetto?.codice === p.codice && g.statoIniziale !== STATO_GARANZIA_ESCL
      ).length == garanzie.filter((g) => g.pacchetto?.codice === p.codice).length &&
      garanzia?.[`garanzieNecessariePacchetto${p.codice}`].length > 0 &&
      !garanzie
        .filter((x) => x.pacchetto?.codice === p.codice)
        .map((x) => x.codice)
        .every((v) => garanzieSelezionate.includes(v))
    )
  })

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      closeButton
      onCloseClick={() => {
        handleGtm('chiudi_popup')
      }}
      className={`${styles.QuotazioneAutoGaranzieNecessariePacchettoModal}
      ${className ? ` ${className}` : ''}`}
      buttons={
        <>
          <Button
            label={t('Annulla')}
            variant="ghost"
            onClick={() => {
              setOpen(false)
              handleGtm('no_grazie')
            }}
          />
          <Button
            label={t('Aggiungi pacchetto')}
            onClick={() => {
              const newGaranzieSelezionate = handleGaranzieAndPacchetti(
                'pacchetto',
                'add',
                pacchettiSelezionati,
                [],
                []
              )

              const response = handleGaranzieAndPacchetti(
                'garanzia',
                'add',
                [],
                [garanzia?.codice],
                newGaranzieSelezionate,
                false,
                true
              )

              if (response) {
                handleGtm('aggiungi_garanzie')
                setOpen(false)
              }
            }}
            disabled={pacchettiSelezionati.length === 0}
          />
        </>
      }
    >
      <div className={styles.content}>
        <p
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t('Garanzia <strong>{0}</strong>', [garanzia.titolo || '']),
          }}
        />
        <div className={styles.description}>
          {pacchettiSelezionabili.length > 1
            ? t('Per aggiungere la garanzia devi attivare almeno un pacchetto:')
            : t('Per aggiungere la garanzia devi attivare il pacchetto:')}
        </div>
        <div className={styles.list}>
          {pacchettiSelezionabili.map((p) => (
            <QuotazionePacchettoAutoSimpleBox
              key={p.codice}
              quotazione={quotazione}
              pacchetto={p}
              garanzie={garanzie}
              garanzieSelezionate={garanzieSelezionate}
              checked={pacchettiSelezionati.indexOf(p.codice) > -1}
              onClick={() => {
                // se checked
                if (pacchettiSelezionati.indexOf(p.codice) > -1) {
                  setPacchettiSelezionati((pacchettiSelezionati) =>
                    pacchettiSelezionati.filter((x) => x !== p.codice)
                  )
                } else {
                  setPacchettiSelezionati((pacchettiSelezionati) => [
                    ...pacchettiSelezionati,
                    p.codice,
                  ])
                }
              }}
              showCheckbox
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default QuotazioneAutoGaranzieNecessariePacchettoModal
