import React, { useState, useEffect } from 'react'
import { useNode, useEditor } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

import { getAllPaths, getArrayPaths, setDynamicValue } from '../../utils/dynamic'

export const DynamicDataBlockSettings = () => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }))

  return (
    <>
      <ToolbarSection title="Settings">
        <ToolbarItem
          propKey="block"
          propSubKey="apiUrl"
          type="text"
          label="API url"
          description="Paste your url"
        />
        {/* @TODO da rimuovere */}
        <div style={{ fontSize: '1em' }}>
          <p>Try:</p>
          <p>https://reqres.in/api/users/2</p>
          <p>https://reqres.in/api/users?page=2</p>
        </div>
        <ToolbarItem
          propKey="block"
          propSubKey="repeatForEach"
          type="dynamic"
          label="Repeat for each"
          values={getArrayPaths(customs.dynamicData)}
          defaultValue={null}
        />
      </ToolbarSection>
    </>
  )
}
