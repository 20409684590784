import React from 'react'
import { useEditor } from '@craftjs/core'
import { PdfLinkSettings } from './PdfLinkSettings'

import { withBlock } from '../withBlock';
import { withCraft } from '../withCraft';

import { PdfLink as OriginalPdfLink } from '@next-nx/shared-ui/atoms';

export const defaultProps = {
  name: 'Nome del file',
  url: '#'
};

export const dynamicProps = [];

export const PdfLink = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    };
    const { name, url } = blockProps

    const _ = props.renderValue;

    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }));

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-pdflink ' + (enabled ? 'block-pdflink--editing ' : '') + props.className}
      >
        <OriginalPdfLink name={name} url={url} />
      </div>
    );
  },
  defaultProps,
  dynamicProps
);

PdfLink.craft = withCraft({
  name: 'PdfLink',
  defaultProps: defaultProps,
  settings: PdfLinkSettings,
});
