import React from 'react'
import { ProdottoType } from '@next-nx/utils/types'
import { Modal } from '@next-nx/shared-ui/atoms'
import styles from './PreventivatoreFastQuoteModal.module.sass'
import {
  PreventivatoreFastQuoteAuto,
  PreventivatoreFastQuoteCasa,
} from '@next-nx/shared-ui/organisms'
import { isFastQuoteAuto, isFastQuoteCasa } from '@next-nx/utils/fastquote'

interface Props {
  prodotto?: ProdottoType
  open?: boolean
  onClose?: () => void
  submitting: boolean
  setSubmitting: (v: boolean) => void
  variant?: 'home' | 'prodotto'
  className?: string
}

const PreventivatoreFastQuoteModal = (props: Props) => {
  const {
    prodotto = undefined,
    open = false,
    onClose = () => undefined,
    submitting = false,
    setSubmitting = () => undefined,
    variant = 'home',
    className = '',
  } = props

  return prodotto ? (
    <Modal
      open={open}
      setOpen={(v) => {
        if (!v) onClose()
      }}
      closeButton
      className={`${styles.root}
        ${submitting ? styles['root--hidden'] : ''}
      ${className ? ` ${className}` : ''}`}
      overlayClassName={submitting ? styles['overlay--hidden'] : ''}
    >
      <div className={styles.content}>
        {isFastQuoteCasa(prodotto) ? (
          <PreventivatoreFastQuoteCasa
            prodotto={prodotto}
            formClassName={styles.formContent}
            submitting={submitting}
            setSubmitting={setSubmitting}
            idVariant={'_modal'}
            isModal
            variant={variant}
          />
        ) : isFastQuoteAuto(prodotto) ? (
          <PreventivatoreFastQuoteAuto
            prodotto={prodotto}
            formClassName={styles.formContent}
            submitting={submitting}
            setSubmitting={setSubmitting}
            idVariant={'_modal'}
            isModal
            variant={variant}
          />
        ) : null}
      </div>
    </Modal>
  ) : (
    <></>
  )
}

export default PreventivatoreFastQuoteModal
