export const deepValue = (obj, path) => {
  const paths = path.split('.')
  for (let i = 0; i < paths.length; i++) {
    try {
      obj = obj[paths[i]]
    } catch (err) {
      return undefined
    }
  }
  return obj
}

export const getAllPaths = (root) => {
  let paths = []
  let nodes = [
    {
      obj: root,
      path: '',
    },
  ]
  while (nodes.length > 0) {
    let n = nodes.pop()
    Object.keys(n.obj).forEach((k) => {
      let path = n.path
      if (path !== '') {
        path = path.concat('.')
      }
      path = path.concat(k)
      if (typeof n.obj[k] === 'object') {
        nodes.unshift({
          obj: n.obj[k],
          path: path,
        })
      } else {
        paths.push(path)
      }
    })
  }
  return paths
}

export const getArrayPaths = (root) => {
  let paths = []

  if (!root) {
    return paths
  }

  let nodes = [
    {
      obj: root,
      path: '',
    },
  ]
  while (nodes.length > 0) {
    let n = nodes.pop()
    if (n && n.obj) {
      Object.keys(n.obj).forEach((k) => {
        let path = n.path
        if (path !== '') {
          path = path.concat('.')
        }
        path = path.concat(k)
        if (typeof n.obj[k] === 'object') {
          nodes.unshift({
            obj: n.obj[k],
            path: path,
          })
        }
        if (Array.isArray(n.obj[k])) {
          paths.push(path)
        }
      })
    }
  }
  return paths
}

export const getDynamicValue = (value) => {
  try {
    return value.substring(2, value.length - 1)
  } catch (err) {
    return undefined
  }
}

export const setDynamicValue = (value) => {
  return '${' + value + '}'
}

export const isDynamicValue = (value) => {
  if (typeof value === 'string') {
    return value.substring(0, 2) === '${'
  }
  return false
}
