import React from 'react'

const FormError = ({ message = '', visible = false, className = '' }) => {
  return visible && message ? (
    <div className={'pb-form-error' + (className ? ' ' + className : '')}>{message}</div>
  ) : (
    <></>
  )
}

export default FormError
