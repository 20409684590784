import React, { useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { useRouter } from 'next/router'
import { useTrans } from '@next-nx/hooks'
import { ProdottoType } from '@next-nx/utils/types'
import { getFormFieldArgs } from '@next-nx/utils/safe'
import {
  Button,
  FormLayout,
  FormField,
  FormInput,
  FormError,
  FormRadio,
  FormDate,
} from '@next-nx/shared-ui/atoms'
import styles from './PreventivatoreFastQuoteAuto.module.sass'
import { useGetPremioAutoFastMutation } from '@next-nx/shared-graphql/assicurazioni'
import { sendGTMData } from 'libs/shared-ui/utils/gtm'

interface Props {
  title?: string
  prodotto: ProdottoType
  formClassName?: string
  submitting: boolean
  setSubmitting: (v: boolean) => void
  idVariant?: string
  variant?: 'home' | 'prodotto'
  isModal?: boolean
  className?: string
}

const PreventivatoreFastQuoteAuto = (props: Props) => {
  const {
    prodotto = undefined,
    formClassName = '',
    submitting = false,
    setSubmitting = () => undefined,
    idVariant = '',
    variant = 'home',
    isModal = false,
    className = '',
  } = props

  const t = useTrans()
  const [error, setError] = useState<string | null>(null)
  const [inputError, setInputError] = useState<string | null>(null)

  const validationSchema = yup.object().shape({
    targa: yup
      .string()
      .matches(/[A-Za-z]{2}[0-9]{3}[A-Za-z]{2}/, t('Il formato della targa non è valido'))
      .length(7, t('Il formato della targa non è valido'))
      .required(t('Campo obbligatorio')),
    dataNascita: yup
      .string()
      .typeError(t('É necessario specificare una data di nascita'))
      .required(t('Campo obbligatorio'))
      .nullable(),
    mail: yup.string().required(t('Campo obbligatorio')).email(t("Inserire un'email valida")),
    consensoTrattamento: yup.string().required(t('Campo obbligatorio')),
  })

  const [getPremioAutoFastMutation] = useGetPremioAutoFastMutation()

  const flexFrom = isModal ? 'sm' : variant === 'prodotto' ? 'lg' : 'md'

  const initialValues = {
    targa: '',
    dataNascita: '',
    mail: '',
    consensoTrattamento: 'SI',
  }

  return prodotto ? (
    <div
      className={`${styles.root} ${styles[`root--${variant}`]} ${className ? ` ${className}` : ''}`}
    >
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values, actions) => {
          setSubmitting(true)
          getPremioAutoFastMutation({
            variables: {
              input: {
                targa: values.targa,
                dataNascita: values.dataNascita,
                email: values.mail,
                consensoTrattamento: values.consensoTrattamento,
              },
            },
          })
            .then((res) => {
              if (res?.data?.getPremioAutoFast?.status) {
                setError(null)
                setInputError(null)
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'preventivatore',
                  eventAction: 'timeout_page',
                  eventLabel: 200,
                })
                window.location.href =
                  '/quotazione/auto/' + res?.data?.getPremioAutoFast?.quotazione?.idQuotazione
                // router.push('/quotazione/auto/' + res?.data?.getPremioAutoFast?.quotazione?.idQuotazione)
              } else {
                setSubmitting(false)
                actions.setSubmitting(false)
                sendGTMData({
                  event: 'GAevent',
                  eventCategory: 'preventivatore',
                  eventAction: 'timeout_page',
                  eventLabel: 404,
                })
                if (
                  res?.data?.getPremioAutoFast?.message &&
                  res?.data?.getPremioAutoFast?.message != 'KO'
                ) {
                  setError(res?.data?.getPremioAutoFast?.message)
                  setInputError(res?.data?.getPremioAutoFast?.inputError || null)
                } else {
                  setError(t("c'è stato un errore, ti invitiamo a riprovare più tardi"))
                  setInputError(null)
                }
              }
            })
            .catch((err) => {
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'preventivatore',
                eventAction: 'timeout_page',
                eventLabel: 400,
              })
              console.log(err)
              setSubmitting(false)
              actions.setSubmitting(false)
              setError(t("c'è stato un errore, ti invitiamo a riprovare più tardi"))
              setInputError(null)
            })
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <div className={`${styles.formContent} ${formClassName}`}>
              {/* Questo scrolla nella Modal */}
              <p
                className={styles.title}
                dangerouslySetInnerHTML={{
                  __html: t('<strong>Richiedi una quotazione</strong> in pochi click'),
                }}
              />
              <p className={styles.description}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t(
                      'Puoi richiedere una quotazione solo se sei <strong>Cliente di Crédit Agricole Italia</strong>.<br>Vuoi diventare Cliente?'
                    ),
                  }}
                />
                <span className={styles.noClienteCta}>
                  <a
                    href="https://conti.credit-agricole.it/"
                    target="_blank"
                    onClick={() => {
                      sendGTMData({
                        event: 'GAevent',
                        eventCategory: 'preventivatore',
                        eventAction: prodotto?.titolo?.toGTMFormat(),
                        eventLabel: 'non_sei_cliente',
                      })
                    }}
                  >
                    <strong>{t('Clicca qui')}</strong>
                  </a>
                </span>
              </p>
              <FormLayout space="sm" className={styles.formLayout} flexFrom={flexFrom}>
                <FormField
                  label={t('Targa')}
                  flexFrom={flexFrom}
                  layout={variant === 'prodotto' ? 'full' : 'md'}
                  {...getFormFieldArgs('targa', values, errors, touched)}
                  className={`${styles.field} ${styles['field--md']}`}
                  helper={{
                    id: 'preventivatore_fast_quote_targa_tooltip',
                    label: t('Targa del veicolo da assicurare'),
                    pos: 'bottom',
                  }}
                >
                  <FormInput
                    name="targa"
                    placeholder={t('Inserisci la targa del veicolo')}
                    value={values.targa}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value.toUpperCase())
                      inputError === 'targa' && setInputError(null)
                    }}
                    onBlur={handleBlur}
                    status={
                      inputError === 'targa' || (touched.targa && errors.targa) ? 'error' : ''
                    }
                  />
                </FormField>
                <FormField
                  label={t('Data di nascita')}
                  flexFrom={flexFrom}
                  layout={variant === 'prodotto' ? 'full' : 'md'}
                  {...getFormFieldArgs('dataNascita', values, errors, touched)}
                  className={`${styles.field} ${styles['field--sm']}`}
                  helper={{
                    id: 'preventivatore_fast_quote_dataNascita_tooltip',
                    label: t('La data di nascita deve coincidere con quella del proprietario'),
                    pos: 'bottom',
                  }}
                >
                  <FormDate
                    name="dataNascita"
                    placeholder={t('gg/mm/aaaa')}
                    value={values.dataNascita}
                    maxDate={new Date()}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value)
                      setFieldTouched(e.target.name, true, false)
                    }}
                    onBlur={handleBlur}
                    status={touched.dataNascita && errors.dataNascita ? 'error' : ''}
                  />
                </FormField>
                <FormField
                  label={t('E-mail')}
                  flexFrom={flexFrom}
                  layout={variant === 'prodotto' ? 'full' : 'md'}
                  {...getFormFieldArgs('mail', values, errors, touched)}
                  className={`${styles.field} ${styles['field--lg']}`}
                >
                  <FormInput
                    name="mail"
                    type="email"
                    placeholder={t('Inserisci la tua e-mail')}
                    value={values.mail}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    onBlur={handleBlur}
                    status={touched.mail && errors.mail ? 'error' : ''}
                  />
                </FormField>
                {/* <FormField
                  className={styles.consensoTrattamentoField}
                  {...getFormFieldArgs('consensoTrattamento', values, errors, touched)}
                  layout="full"
                >
                  <p className={styles.consensoTrattamentoLabel}>
                    {t(
                      'Acconsento, anche ai fini del trattamento dei dati personali, a ricevere informazioni, promozioni e comunicazioni commerciali di prodotti e servizi, anche tramite tecniche di comunicazioni a distanza ai recapiti da me forniti.'
                    )}
                  </p>
                  <FormRadio
                    id={'consenso_trattamento' + idVariant}
                    name="consensoTrattamento"
                    value={values.consensoTrattamento}
                    options={[
                      {
                        id: 'consenso_trattamento_si' + idVariant,
                        value: 'SI',
                        label: 'Acconsento',
                      },
                      {
                        id: 'consenso_trattamento_no' + idVariant,
                        value: 'NO',
                        label: 'Non acconsento',
                      },
                    ]}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    status={
                      touched.consensoTrattamento && errors.consensoTrattamento ? 'error' : ''
                    }
                  />
                  <ErrorMessage
                    name="consensoTrattamento"
                    render={(msg) => <FormError message={msg} />}
                  />
                </FormField> */}
                {error && (
                  <div className={styles.error}>
                    <FormError message={`<strong>Attenzione</strong>: ${error}`} visible />
                  </div>
                )}
              </FormLayout>
              <div className={styles.bottom}>
                <p
                  className={styles.privacyDisclaimer}
                  dangerouslySetInnerHTML={{
                    __html: t('Proseguendo, si accetta l’informativa {0}Privacy{1}.', [
                      '<a href="/privacy" target="_blank">',
                      '</a>',
                    ]),
                  }}
                />
                <Button
                  type="submit"
                  label={t('Calcola subito il preventivo')}
                  className={styles.submit}
                  loading={submitting}
                  disabled={!Object.keys(touched).length || !isValid || isSubmitting || submitting}
                  onClick={() => {
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'preventivatore',
                      eventAction: prodotto?.titolo?.toGTMFormat(),
                      eventLabel: 'step1',
                    })
                  }}
                />
              </div>
            </div>
            <div className={styles.ctaBoxMobile}>
              <Button
                type="submit"
                label={t('Prosegui con la quotazione')}
                className={styles.submitMobile}
                loading={submitting}
                disabled={!Object.keys(touched).length || !isValid || isSubmitting || submitting}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <></>
  )
}

export default PreventivatoreFastQuoteAuto
