import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from '@next-nx/hooks'
import { Alert } from '@next-nx/shared-ui/icons'
import { Chip, NotificationBar, Loader } from '@next-nx/shared-ui/atoms'
import { ProdottoCard } from '@next-nx/shared-ui/molecules'
import styles from './ProdottiListing.module.sass'
import { ProdottoCardType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'


interface Props {
  prodotti: ProdottoCardType[]
  className?: string
}

const ProdottiListing = (props: Props) => {

  const {
    prodotti = [],
    className = '',
  } = props

  const t = useTrans()

  const [filtriAttivi, setFiltriAttivi] = useState<number[]>([])

  const toggleFiltro = (id: number) => {
    if (filtriAttivi.indexOf(id) > -1) setFiltriAttivi(filtriAttivi.filter((x) => x !== id))
    else setFiltriAttivi(filtriAttivi.concat([id]))
  }

  // FAKE
  const tags: any = []
  /* const tags = [
    { pk: 1, titolo: 'Lorem', num: 16 },
    { pk: 2, titolo: 'Ipsum', num: 12 },
  ] */

  return (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      <p className={styles.title}><strong>{t("Risultati")}</strong> ({prodotti.length})</p>
      <div className={styles.filters}>
        {tags.length > 0 && (
          <Swiper spaceBetween={16} slidesPerView="auto" watchOverflow>
            <SwiperSlide>
              <Chip
                label={`${t('Tutti')} (${prodotti.length})`}
                onClick={() => {
                  setFiltriAttivi([])
                  sendGTMData({
                    event: "GAevent",
                    eventCategory: 'filtri',
                    eventAction: 'tutti',
                    eventLabel: 'plp'
                  })
                }}
                active={filtriAttivi.length === 0}
              />
            </SwiperSlide>
            {tags.map((tag) => (
              <SwiperSlide key={tag.pk}>
                <Chip
                  label={`${tag.titolo} (${tag.num})`}
                  onClick={() => {
                    toggleFiltro(tag.pk)
                    sendGTMData({
                      event: "GAevent",
                      eventCategory: 'filtri',
                      eventAction: tag.titolo?.toGTMFormat() || '',
                      eventLabel: 'plp'
                    })
                  }}
                  active={filtriAttivi.indexOf(tag.pk) > -1}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <div className={styles.main}>
        <div className={styles.list}>
          {prodotti.map((prodotto) => (
            <div
              className={styles.item}
              key={prodotto.pk}
            >
              <ProdottoCard prodotto={prodotto} onClick={() => sendGTMData({
                event: "GAevent",
                eventCategory: 'listing_prodotti',
                eventAction: prodotto.titolo?.toGTMFormat() || '',
                eventLabel: 'click'
              })} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProdottiListing
