import React from 'react'
import { WImage } from '@next-nx/shared-ui/atoms'
import styles from './AppBoxMini.module.sass'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'
import { AppStore, GooglePlay } from '@next-nx/shared-ui/icons'

interface Props {
  item?: MenuType
  className?: string
}

const AppBoxMini = (props: Props) => {
  const { item = undefined, className = '' } = props

  const getAppStores = () => {
    if (item && item.children && item.children.edges.length > 0) {
      return item.children.edges.filter((child) => {
        if (child.node && child.node.key) {
          return child
        }
        return false
      })
    }
    return []
  }

  return item ? (
    <div className={`${styles.appBox} ${className ? ` ${className}` : ''}`}>
      <div className={styles.imageBox}>
        <WImage
          maxWidth={45}
          maxHeight={50}
          src={item.mainImage}
          className={styles.image}
          layout="fixed"
          objectFit="contain"
          key="app-box-mini-main-image-mobile"
        />
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <p className={styles.title}>{item.name}</p>
        </div>
        <div className={styles.links}>
          {getAppStores().map((child, n) => (
            <div className={styles.logo} key={n}>
              <a href={child.node.url} target="_blank">
                {child.node.style === 'apple' ? (
                  <AppStore idVariant={'appboxmini_' + n} />
                ) : (
                  <GooglePlay idVariant={'appboxmini_' + n} />
                )}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default AppBoxMini
