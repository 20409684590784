import React from 'react'

function Smartphone({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--smartphone${className ? ' ' + className : ''}`}
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M34 2.02l-20-.02c-2.21 0-4 1.79-4 4v36c0 2.21 1.79 4 4 4h20c2.21 0 4-1.79 4-4v-36c0-2.21-1.79-3.98-4-3.98zm0 35.98h-20v-28h20v28z" />
    </svg>
  )
}

export default Smartphone
