import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { ArrowLeft, ArrowRight } from '@next-nx/shared-ui/icons'
import { WContainer, Breadcrumbs, MainTitle, WImage, Button } from '@next-nx/shared-ui/atoms'
import { PlaceholderReact } from '@next-nx/pagebuilder'
import { Page } from '@next-nx/shared-ui/organisms'
import styles from './ArticoloTemplate.module.sass'


interface Props {
  articolo: {
    pk: number
    contentTypeId: number
    title?: string
    publicationDate?: string
    mainImage?: string
    next?: {
      url?: string
    }
  }
  pagina?: {
    breadcrumbs: { 
      titolo: string
      url?: string
    }[]
  }
}

const ArticoloTemplate = (props: Props) => {

  const { 
    pagina = null,
    articolo = null,
  } = props

  const t = useTrans()

  return articolo ? (
    <Page pagina={pagina} item={articolo} contentClassName={styles.articoloContent}>
      <WContainer>
        {pagina?.breadcrumbs &&
          <Breadcrumbs breadcrumbs={pagina.breadcrumbs} mobileHideLast />
        }
      </WContainer>
      {!!articolo.title && (
        <WContainer variant="sm">
          <MainTitle label={articolo.title} className={styles.title} />
        </WContainer>
      )}
      <WContainer variant="xs">
        {!!articolo?.mainImage && (
          <div className={styles.imageBox}>
            <WImage
              src={articolo?.mainImage}
              maxWidth={730}
              maxHeight={261}
              layout="intrinsic"
              objectFit="cover"
              priority={true}
              loading="eager"
            />
          </div>
        )}
        {!!articolo?.publicationDate && <p className={styles.date}>{articolo.publicationDate}</p>}
        <div className={styles.content}>
          <PlaceholderReact
            contentTypeId={articolo.contentTypeId}
            objectId={articolo.pk}
            pkey="content"
          />
        </div>
      </WContainer>
      <WContainer>
        <div className={styles.actions}>
          {pagina?.breadcrumbs && 
            pagina.breadcrumbs.length > 1 && (
              <Button label={t("Torna alla lista")} variant="ghost" leftIcon={<ArrowLeft />} href={pagina.breadcrumbs[1].url} />
          )}
          {!!articolo.next?.url && <Button label={t("Articolo successivo")} variant="ghost" rightIcon={<ArrowRight />} href={articolo.next.url} />}
        </div>
      </WContainer>
    </Page>
  ) : <></>
}

export default ArticoloTemplate
