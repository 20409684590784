import React from 'react'

function Import({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--import${className ? ' ' + className : ''}`}
    >
      <path d="M24 32.5l8-8h-6v-18h-4v18h-6l8 8zm18-26h-12v3.97h12v28.06h-36v-28.06h12v-3.97h-12c-2.21 0-4 1.79-4 4v28c0 2.21 1.79 4 4 4h36c2.21 0 4-1.79 4-4v-28c0-2.21-1.79-4-4-4z" />
    </svg>
  )
}

export default Import
