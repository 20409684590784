import { useEditor, ROOT_NODE } from '@craftjs/core';
import { useContext, useEffect } from 'react';
import { GlobalContext } from '../services/globalContext';

export const ConditionsWrapper = ({ children }) => {
  const { actions, query, nodes } = useEditor((state) => ({
    nodes: state.nodes,
  }));

  const { utente, windowMode } = useContext(GlobalContext);

  useEffect(() => {
    // Nascondi tutto al primo render
    actions.setHidden(ROOT_NODE, true);
  }, []);

  useEffect(() => {
    if (nodes && Object.keys(nodes).length > 0) {
      Object.keys(nodes).forEach((id) => {
        const conditionsProps = query.node(id).get().data.props.conditions;

        let toHide = false;
        if (
          (utente && conditionsProps.hiddenIfLoggedIn) ||
          (!utente && conditionsProps.hiddenIfNotLoggedIn) ||
          (windowMode === 'desktop' && conditionsProps.hiddenIfDesktop) ||
          (windowMode === 'tablet' && conditionsProps.hiddenIfTablet) ||
          (windowMode === 'mobile' && conditionsProps.hiddenIfMobile)
        ) {
          toHide = true;
        }

        actions.setHidden(id, toHide);
      });

      // Finito di valutare le condizioni, rendi di nuovo visibile la ROOT
      // actions.setHidden(ROOT_NODE, false)
    }
  }, [windowMode, nodes]);

  return children;
};
