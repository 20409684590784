import React, { useState } from 'react'
import { useIsMobile, useTrans } from '@next-nx/hooks'
import { ProdottoType } from '@next-nx/utils/types'
import { Button, Chip, WContainer, WImage } from '@next-nx/shared-ui/atoms'
import {
  PreventivatoreIntro,
  PreventivatoreFastQuoteCasa,
  PreventivatoreFastQuoteAuto,
} from '@next-nx/shared-ui/organisms'
import styles from './ProdottoHead.module.sass'
import { isFastQuoteAuto, isFastQuoteCasa } from '@next-nx/utils/fastquote'

interface Props {
  prodotto: ProdottoType
  setPreventivatoreModalOpen: (v: boolean) => void
  selectedLineePreventivatore: number[]
  setSelectedLineePreventivatore: (v: number[]) => void
  fastQuoteSubmitting: boolean
  setFastQuoteSubmitting: (v: boolean) => void
  className?: string
}

const ProdottoHead = (props: Props) => {
  const {
    prodotto,
    setPreventivatoreModalOpen = () => undefined,
    selectedLineePreventivatore = [],
    setSelectedLineePreventivatore = () => undefined,
    fastQuoteSubmitting = false,
    setFastQuoteSubmitting = () => undefined,
    className = '',
  } = props

  const t = useTrans()
  const isMobile = useIsMobile()

  return (
    <div
      className={`${styles.prodottoHead} ${
        prodotto.preventivatore ? styles['prodottoHead--preventivatore'] : ''
      } ${className ? ` ${className}` : ''}`}
    >
      <div className={styles.head}>
        <div className={styles.imageBox}>
          <WImage
            key={`main-image-prodotto-${prodotto.pk}${isMobile ? '-mobile' : ''}`}
            src={isMobile ? prodotto.previewImage : prodotto.mainImage}
            maxWidth={1920}
            maxHeight={340}
            layout="intrinsic"
            objectFit="cover"
            priority={true}
            loading="eager"
          />
        </div>
        <div className={styles.heroBox}>
          <h1 className={styles.heroTitle}>{prodotto.titolo}</h1>
          {prodotto.descrizioneBreve && (
            <div
              className={styles.heroDescription}
              dangerouslySetInnerHTML={{ __html: prodotto.descrizioneBreve }}
            />
          )}
        </div>
      </div>
      <WContainer>
        <div className={styles.main}>
          {prodotto.preventivatore && (
            <div className={styles.preventivatoreMobile}>
              <p className={styles.preventivatoreMobileTitle}>{t('Consulta il preventivatore')}:</p>
              <div className={styles.preventivatoreMobileCtaBox}>
                <Button
                  label={t(
                    isFastQuoteCasa(prodotto) || isFastQuoteAuto(prodotto)
                      ? 'Fai una quotazione'
                      : 'Fai un preventivo'
                  )}
                  className={styles.preventivatoreMobileCta}
                  onClick={() => setPreventivatoreModalOpen(true)}
                />
              </div>
            </div>
          )}

          <div className={styles.content}>
            <div className={styles.descriptionContainer}>
              {prodotto.descrizione && (
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: prodotto.descrizione }}
                />
              )}
              {prodotto.badgeImage && (
                <div className={styles.badgeimage}>
                  <WImage
                    key={`badge-image-prodotto-${prodotto.pk}${isMobile ? '-mobile' : ''}`}
                    src={isMobile ? prodotto.badgeImageMobile : prodotto.badgeImage}
                    maxWidth={isMobile ? 156 : 160}
                    maxHeight={isMobile ? 156 : 160}
                    layout="intrinsic"
                    objectFit="contain"
                    priority={true}
                    loading="eager"
                  />
                </div>
              )}
            </div>

            {prodotto.tags?.length > 0 && (
              <div className={styles.tags}>
                <p className={styles.tagsTitle}>{t('Tag prodotto')}</p>
                <div className={styles.tagsList}>
                  {prodotto.tags.map((tag) => (
                    <Chip
                      key={tag.pk}
                      variant="blue"
                      size="sm"
                      label={tag.titolo}
                      weight="regular"
                    />
                  ))}
                </div>
              </div>
            )}
          </div>

          {prodotto.preventivatore && (
            <div className={styles.preventivatore}>
              {isFastQuoteCasa(prodotto) ? (
                <PreventivatoreFastQuoteCasa
                  prodotto={prodotto}
                  submitting={fastQuoteSubmitting}
                  setSubmitting={setFastQuoteSubmitting}
                  idVariant={'_' + prodotto.pk}
                  variant="prodotto"
                />
              ) : isFastQuoteAuto(prodotto) ? (
                <PreventivatoreFastQuoteAuto
                  prodotto={prodotto}
                  submitting={fastQuoteSubmitting}
                  setSubmitting={setFastQuoteSubmitting}
                  idVariant={'_' + prodotto.pk}
                  variant="prodotto"
                />
              ) : (
                <PreventivatoreIntro
                  title={t('Consulta il <strong>preventivatore</strong> per {0}', [
                    prodotto.titolo,
                  ])}
                  ctaLabel={t('Richiedi un preventivo')}
                  prodotto={prodotto}
                  productPage
                  setFormModalOpen={(v) => setPreventivatoreModalOpen(v)}
                  selectedLinee={selectedLineePreventivatore}
                  setSelectedLinee={(linee_ids) => setSelectedLineePreventivatore(linee_ids)}
                  className={styles.tabContent}
                />
              )}
            </div>
          )}
        </div>
      </WContainer>
    </div>
  )
}

export default ProdottoHead
