import React, { useContext, useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { MetaSeo } from '@next-nx/shared-ui/atoms'
import {
  Toolbar,
  Header,
  Footer,
  ChatBotOverlay,
  GoogleReCaptchaWrapper,
} from '@next-nx/shared-ui/organisms'
import { ChatBotContext } from 'libs/shared-ui/contexts/chatBotContext'
import styles from './Page.module.sass'

interface Props {
  pagina: any
  item: any
  header?: any
  footer?: any
  children: React.ReactNode
  className?: string
  contentClassName?: string
  pageWrapClassName?: string
  recaptchaRequired?: boolean
}

const Page = (props: Props) => {
  const {
    pagina = {},
    item = {},
    header = undefined,
    footer = undefined,
    children = null,
    className = '',
    contentClassName = '',
    pageWrapClassName = '',
    recaptchaRequired = false,
  } = props

  const router = useRouter()

  const handleScroll = () => {
    if (window.scrollY > 0) document.body.classList.add('w-scrolled')
    else document.body.classList.remove('w-scrolled')
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const chatbotCtx = useContext(ChatBotContext)

  useEffect(() => {
    if (router?.query && router.query.from_chatbot == '1') {
      setTimeout(() => {
        chatbotCtx.openChatBot({ chatbotUrl: 'lp-feedback.html' })
      }, 20000)
    }
  }, [router.query])

  return (
    <GoogleReCaptchaWrapper recaptchaRequired={recaptchaRequired}>
      <div className={`${styles.pageWrap} ${pageWrapClassName}`}>
        <Toolbar page={pagina} item={item} />
        <div className={`${styles.page} page ${className || ''}`}>
          {pagina?.seo && <MetaSeo seo={pagina.seo} />}
          {header ? header : <Header />}
          <div className={`${styles.content} ${contentClassName}`}>{children}</div>
          {footer ? footer : <Footer />}
        </div>
        <ChatBotOverlay />
      </div>
    </GoogleReCaptchaWrapper>
  )
}

export default Page
