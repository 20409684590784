import { useNode, useEditor } from '@craftjs/core'
import React, { useState, useEffect } from 'react'

import { DynamicDataBlockSettings } from './DynamicDataBlockSettings'
import { DynamicDataContext } from '../../services/dynamicDataContext'

import axios from 'axios'

import { cloneNodeTree, addNodeTree, deleteNodeTree } from '../../utils/node'
import {
  deepValue,
  getArrayPaths,
  getAllPaths,
  getDynamicValue,
  setDynamicValue,
  isDynamicValue,
} from '../../utils/dynamic'
import { withCraft } from '../withCraft'

export const update = (id, query, actions) => {
  const node = query.node(id).get()
  const data = node.data.custom['dynamicData']
  const nodes = node.data.nodes
  const repeatForEach = node.data.props.block.repeatForEach
  if (data) {
    if (repeatForEach) {
      const repeatValue = deepValue(data, getDynamicValue(repeatForEach))
      if (repeatValue) {
        for (let i = 1; i < nodes.length; i++) {
          deleteNodeTree(nodes[i], actions, true)
        }
        const firstNodeId = nodes[0]
        for (let i = 1; i < repeatValue.length; i++) {
          const nodeTree = cloneNodeTree(firstNodeId, query, true) // true = disabled
          addNodeTree(nodeTree, id, actions, true) // true = history ignore
        }
      } else {
        for (let i = 1; i < nodes.length; i++) {
          deleteNodeTree(nodes[i], actions, true)
        }
        actions.setProp(id, (props) => {
          props.block.repeatForEach = defaultProps.repeatForEach
        })
      }
    } else {
      for (let i = 1; i < nodes.length; i++) {
        deleteNodeTree(nodes[i], actions, true)
      }
    }
  } else {
    for (let i = 1; i < nodes.length; i++) {
      deleteNodeTree(nodes[i], actions, true)
    }
    actions.setProp(id, (props) => {
      props.block.repeatForEach = defaultProps.repeatForEach
    })
  }
}

export const defaultProps = {
  apiUrl: 'https://reqres.in/api/users/2',
  /*
        Response

        {
            "data": {
                "id":2,
                "email":"janet.weaver@reqres.in",
                "first_name":"Janet",
                "last_name":"Weaver",
                "avatar":"https://reqres.in/img/faces/2-image.jpg"
            },
            "support": {
                "url":"https://reqres.in/#support-heading",
                "text":"To keep ReqRes free, contributions towards server costs are appreciated!"
            }
        }
    */
  repeatForEach: null,
  needUpdate: false,
}

export const DynamicDataBlock = (props) => {
  const blockProps = {
    ...defaultProps,
    ...props.block,
  }
  const { apiUrl, repeatForEach } = blockProps

  const { children } = props

  const {
    id,
    connectors: { connect, drag },
    selected,
    hovered,
    actions: { setCustom, setProp },
    nodes,
    node,
    customs,
  } = useNode((state) => ({
    selected: state.events.selected,
    hovered: state.events.hovered,
    nodes: state.data.nodes,
    node: state.data,
    customs: state.data.custom,
  }))

  const { query, actions, enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }))

  let className = `${id} dynamic-data-block `
  if (selected) {
    className += 'component-selected'
  } else if (hovered) {
    className += 'component-hovered'
  }

  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    axios
      .get(apiUrl)
      .then((res) => {
        setData(res.data)

        actions.history.ignore().setCustom(id, (custom) => {
          custom['dynamicData'] = res.data
        })

        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setData(null)

        actions.history.ignore().setCustom(id, (custom) => {
          delete custom['dynamicData']
        })

        setLoading(false)
      })
  }, [apiUrl])

  const [isMounting, setMounting] = useState()

  useEffect(() => {
    setMounting(true)
  }, [])

  useEffect(() => {
    if (isMounting) {
      setMounting(false)
    } else if (!loading) {
      update(id, query, actions)
    }
  }, [customs, repeatForEach])

  /* const [ needUpdate, setNeedUpdate ] = useState(false)

    useEffect(() => {
        if(needUpdate) {
            update(id, query, actions)
            setNeedUpdate(false)
        }
    }, [needUpdate]) */

  /*
    const update = () => {
        console.log("DDB is updating ...")
        if(data) {
            if(repeatForEach) {
                for(let i = 1; i < nodes.length; i++){
                    deleteNode(nodes[i], query, actions)
                }
                const firstNodeId = nodes[0]
                for(let i = 1; i < deepValue(data, getDynamicValue(repeatForEach)).length; i++) {
                    const nodeTree = cloneNodeTree(firstNodeId, query, true)
                    addNodeTree(nodeTree, id, actions)
                }
            }
            else {
                for(let i = 1; i < nodes.length; i++){
                    deleteNode(nodes[i], query, actions)
                }
            }
        }
    }
    */

  return (
    <div ref={(ref) => connect(drag(ref))} className={className} style={{ position: 'relative' }}>
      {!loading &&
        (repeatForEach && deepValue(data, getDynamicValue(repeatForEach)) ? (
          React.Children.map(children.props.children, (child, i) => {
            return (
              <DynamicDataContext.Provider
                value={deepValue(data, getDynamicValue(repeatForEach))[i]}
                key={i}
              >
                {child}
              </DynamicDataContext.Provider>
            )
          })
        ) : (
          <DynamicDataContext.Provider value={data}>{children}</DynamicDataContext.Provider>
        ))}
    </div>
  )
}

DynamicDataBlock.craft = withCraft({
  name: 'DynamicDataBlock',
  defaultProps: defaultProps,
  settings: DynamicDataBlockSettings,
})
