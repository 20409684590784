import React, { useEffect, useRef, useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import * as yup from 'yup'
import { ProdottoType } from '@next-nx/utils/types'
import { useSite, useTrans } from '@next-nx/hooks'
import { getFormFieldArgs } from '@next-nx/utils/safe'
import { Alert } from '@next-nx/shared-ui/icons'
import {
  Modal,
  Button,
  FormLayout,
  FormField,
  FormInput,
  FormCheckbox,
  FormError,
  NotificationBar,
  Tooltip,
} from '@next-nx/shared-ui/atoms'
import { PreventivatoreLinee } from '@next-nx/shared-ui/organisms'
import styles from './PreventivatoreModal.module.sass'
import { useCreateRichiestaPreventivatoreMutation as useCreateRichiestaPreventivatoreMutationAssicurazioni } from '@next-nx/shared-graphql/assicurazioni'
import { useCreateRichiestaPreventivatoreMutation as useCreateRichiestaPreventivatoreMutationVita } from '@next-nx/shared-graphql/vita'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'
import { useRouter } from 'next/router'
import { sendGTMData } from '../../../utils/gtm'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface Props {
  title?: string
  prodotto?: ProdottoType
  open: boolean
  setOpen: (v: boolean) => void
  selectedLinee: number[]
  className?: string
}

const PreventivatoreModal = (props: Props) => {
  const {
    title = '',
    prodotto = undefined,
    open = false,
    setOpen = () => undefined,
    selectedLinee = [],
    className = '',
  } = props

  const t = useTrans()
  const router = useRouter()
  const site = useSite()

  const { executeRecaptcha } = useGoogleReCaptcha()

  const contentRef = useRef<HTMLDivElement>(null)

  const createRichiestaPreventivatoreMutation =
    site === ASSICURAZIONI
      ? useCreateRichiestaPreventivatoreMutationAssicurazioni
      : useCreateRichiestaPreventivatoreMutationVita

  const [createRichiestaPreventivatore] = createRichiestaPreventivatoreMutation()

  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (!open) setError(null)
  }, [open])

  const validationSchema = yup.object().shape({
    nome: yup.string().required(t('Campo obbligatorio')),
    cognome: yup.string().required(t('Campo obbligatorio')),
    telefono: yup.string().required(t('Campo obbligatorio')),
    email: yup.string().required(t('Campo obbligatorio')).email(t("Inserire un'email valida")),
    privacy: yup.bool().oneOf([true], t('Campo obbligatorio')),
    linee_ids: yup.array().min(1, t('Seleziona almeno una linea')),
  })

  const initialValues = {
    nome: '',
    cognome: '',
    telefono: '',
    email: '',
    privacy: false,
    linee_ids: selectedLinee,
  }

  const getLineeNames = (linee_ids: number[]): string => {
    return linee_ids
      .map((ids) => prodotto?.linee.find((l) => l.pk === ids)?.titolo.toGTMFormat())
      .join(' | ')
  }

  const setFormError = (actions: any) => {
    actions.setSubmitting(false)
    setError(t("Errore nell'invio dei dati, riprova più tardi"))
    contentRef?.current && (contentRef.current.scrollTop = 0)
  }

  return prodotto ? (
    <Modal
      open={open}
      setOpen={setOpen}
      closeButton
      className={`${styles.preventivatoreModal} ${className ? ` ${className}` : ''}`}
    >
      <div className={styles.content} ref={contentRef}>
        {title && <p className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            if (!executeRecaptcha) {
              setFormError(actions)
              return
            }

            executeRecaptcha('richiesta_preventivatore_submit')
              .then((token) => {
                createRichiestaPreventivatore({
                  variables: {
                    input: {
                      nome: values.nome,
                      cognome: values.cognome,
                      email: values.email,
                      telefono: values.telefono,
                      prodottoId: prodotto.pk,
                      lineeIds: values.linee_ids,
                      privacy: values.privacy,
                      captcha: token,
                    },
                  },
                })
                  .then((res) => {
                    setError(null)
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'preventivatore',
                      eventAction: prodotto.titolo?.toGTMFormat() || '',
                      eventLabel: 'ok',
                      linea_preventivatore: getLineeNames(values.linee_ids),
                    })
                    router.push(`${prodotto.url}/thank-you`)
                  })
                  .catch((err) => {
                    setFormError(actions)
                  })
              })
              .catch((err) => {
                setFormError(actions)
              })
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isValid,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              {error && (
                <div className={styles.error}>
                  <NotificationBar variant="alert" label={error} icon={<Alert />} />
                </div>
              )}
              <div className={styles.lineeFormField}>
                <PreventivatoreLinee
                  linee={prodotto.linee.filter((linea) => linea.inPreventivatore)}
                  sconto={prodotto.sconto}
                  className={styles.linee}
                  collapsable
                  errorMessage={
                    touched.linee_ids && errors.linee_ids ? errors.linee_ids : undefined
                  }
                  selectedLinee={values.linee_ids}
                  setSelectedLinee={(v) => {
                    setFieldTouched('linee_ids')
                    setFieldValue('linee_ids', v)
                  }}
                />
              </div>
              <FormLayout>
                <FormField label={t('Nome')} {...getFormFieldArgs('nome', values, errors, touched)}>
                  <FormInput
                    name="nome"
                    placeholder={t('Inserisci il tuo nome')}
                    value={values.nome}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    onBlur={handleBlur}
                  />
                </FormField>
                <FormField
                  label={t('Cognome')}
                  {...getFormFieldArgs('cognome', values, errors, touched)}
                >
                  <FormInput
                    name="cognome"
                    placeholder={t('Inserisci il tuo cognome')}
                    value={values.cognome}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    onBlur={handleBlur}
                  />
                </FormField>
                <FormField
                  label={t('Telefono cellulare')}
                  {...getFormFieldArgs('telefono', values, errors, touched)}
                >
                  <FormInput
                    name="telefono"
                    placeholder={t('Inserisci il tuo numero')}
                    value={values.telefono}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    onBlur={handleBlur}
                  />
                </FormField>
                <FormField
                  label={t('La tua e-mail')}
                  {...getFormFieldArgs('email', values, errors, touched)}
                >
                  <FormInput
                    name="email"
                    placeholder={t('Inserisci la tua e-mail')}
                    type="email"
                    value={values.email}
                    onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                    onBlur={handleBlur}
                  />
                </FormField>
              </FormLayout>
              <div className={styles.bottomDesktop}>
                <FormField
                  className={styles.privacyField}
                  {...getFormFieldArgs('privacy', values, errors, touched)}
                >
                  <FormCheckbox
                    id="privacy"
                    name="privacy"
                    checked={values.privacy}
                    label={t("Ho letto e accetto l'informativa {0}Privacy{1}", [
                      '<a href="/privacy">',
                      '</a>',
                    ])}
                    onChange={(e) => setFieldValue(e.target.name, e.target.checked)}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage name="privacy" render={(msg) => <FormError message={msg} />} />
                </FormField>
                <div className={styles.bottomMobile}>
                  {!Object.keys(touched).length || !isValid ? (
                    <Tooltip
                      id="preventivatore-tooltip"
                      label={t(
                        'É necessario selezionare almeno una linea e compilare tutti i campi per richiedere un preventivo'
                      )}
                      className="preventivatore-tooltip"
                      size="md"
                    >
                      <Button
                        type="submit"
                        label={t('Richiedi un preventivo')}
                        className={styles.submit}
                        disabled={!Object.keys(touched).length || !isValid || isSubmitting}
                      />
                    </Tooltip>
                  ) : (
                    <Button
                      type="submit"
                      label={t('Richiedi un preventivo')}
                      className={styles.submit}
                      disabled={!Object.keys(touched).length || !isValid || isSubmitting}
                    />
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  ) : (
    <></>
  )
}

export default PreventivatoreModal
