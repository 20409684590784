import React, { useEffect } from 'react'
import { useTrans } from '@next-nx/hooks'
import { Add, Bin } from '@next-nx/shared-ui/icons'
import {
  FormLayout,
  FormField,
  FormRadio,
  Button,
  FormInput,
  FormDate,
} from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneGaranziaPetForm.module.sass'
import { AnimaleQuotazioneType, GaranziaQuotazioneType } from '@next-nx/utils/types'
import { subtractMonths } from '@next-nx/utils/safe'
import { TIPO_ANIMALE_CANE, TIPO_ANIMALE_GATTO } from '@next-nx/utils/fastquote'

interface Props {
  animali: AnimaleQuotazioneType[]
  setAnimali: (x: AnimaleQuotazioneType[]) => void
  description?: string
  className?: string
  minMeseCane: number
  maxMeseCane: number
  minMeseGatto: number
  maxMeseGatto: number
}

const QuotazioneGaranziaPetForm = (props: Props) => {
  const {
    animali = [],
    setAnimali = () => null,
    description = '',
    className = '',
    minMeseCane = 0,
    maxMeseCane = 0,
    minMeseGatto = 0,
    maxMeseGatto = 0,
  } = props

  const t = useTrans()
  const maxNumAnimali = 5
  const tipoAnimaleOptions = [
    { id: TIPO_ANIMALE_CANE, label: t('Cane'), value: TIPO_ANIMALE_CANE },
    { id: TIPO_ANIMALE_GATTO, label: t('Gatto'), value: TIPO_ANIMALE_GATTO },
  ]

  useEffect(() => {
    !animali?.length && setAnimali([emptyRow])
  }, [])

  const emptyRow = {
    tipo: tipoAnimaleOptions[0].value,
    nome: '',
    dataNascita: '',
  }

  return (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      {description && (
        <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
      )}
      <div className={styles.list}>
        {animali.map((x, i) => (
          <div className={styles.item} key={i}>
            <FormLayout>
              <FormField layout="full">
                <FormRadio
                  id={`quotazionegaranziapetform_${i}`}
                  name={'tipo'}
                  options={tipoAnimaleOptions}
                  value={x.tipo}
                  onChange={(e) =>
                    setAnimali(
                      animali.map((y, k) =>
                        k === i
                          ? {
                              ...y,
                              tipo: tipoAnimaleOptions.filter(
                                (ta) => ta.value === e.target.value
                              )[0].value,
                            }
                          : y
                      )
                    )
                  }
                />
              </FormField>
              <FormField label={t('Come si chiama?')}>
                <FormInput
                  name={'nome'}
                  value={x.nome}
                  size="md"
                  onChange={(e) =>
                    setAnimali(
                      animali.map((y, k) =>
                        k === i
                          ? {
                              ...y,
                              nome: e.target.value,
                            }
                          : y
                      )
                    )
                  }
                  placeholder={
                    x.tipo === TIPO_ANIMALE_CANE
                      ? t('Inserisci il nome del cane')
                      : t('Inserisci il nome del gatto')
                  }
                  status={(x.nome.length == 0) ? 'error' : ''}
                  errorMessage={(x.nome.length == 0) ? 'Campo obbligatorio' : ''}
                />
              </FormField>
              <FormField
                className={styles.fieldSm}
                label={t('Quando è nato?')}
              >
                <FormDate
                  name={'dataNascita'}
                  size="md"
                  value={x.dataNascita}
                  onChange={(e) =>
                    setAnimali(
                      animali.map((y, k) =>
                        k === i
                          ? {
                              ...y,
                              dataNascita: e.target.value,
                            }
                          : y
                      )
                    )
                  }
                  placeholder={t('gg/mm/aaaa')}
                  popperPlacement="top-start"
                  maxDate={
                    x.tipo === TIPO_ANIMALE_CANE
                      ? subtractMonths(new Date(), minMeseCane)
                      : subtractMonths(new Date(), minMeseGatto)
                  }
                  minDate={
                    x.tipo === TIPO_ANIMALE_CANE
                      ? subtractMonths(new Date(), maxMeseCane)
                      : subtractMonths(new Date(), maxMeseGatto)
                  }
                  status={(x.dataNascita?.length == 0 || !x.dataNascita) ? 'error' : ''}
                  errorMessage={(x.dataNascita?.length == 0 || !x.dataNascita) ? 'Campo obbligatorio' : ''}
                />
              </FormField>
              {i > 0 && (
                <div className={styles.removeBox}>
                  <Button
                    labelMobile={t('Rimuovi animale')}
                    leftIcon={<Bin />}
                    variant="ghost"
                    size="md"
                    onClick={() => setAnimali(animali.filter((y, k) => k !== i))}
                  />
                </div>
              )}
            </FormLayout>
          </div>
        ))}
      </div>
      {animali.length < maxNumAnimali && (
        <div className={styles.addBox}>
          <Button
            label={t('Aggiungi un altro animale domestico')}
            leftIcon={<Add />}
            size="md"
            variant="secondary"
            onClick={() => setAnimali(animali.concat(emptyRow))}
          />
        </div>
      )}
    </div>
  )
}

export default QuotazioneGaranziaPetForm
