import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'
import { getAllPaths, getDynamicValue, setDynamicValue } from '../../utils/dynamic'

export const alignOpts = ['left', 'center', 'right']

export const ImageSettings = () => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }))

  return (
    <>
      <ToolbarSection title="Settings" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="src"
          type="text"
          label="Src"
          description="Paste your url or upload a file"
          dynamic={dynamicProps.includes('src') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['src']}
        />
        <ToolbarItem propKey="block" propSubKey="src" type="file" />
        <ToolbarItem
          propKey="block"
          propSubKey="alt"
          type="text"
          label="Alt Text"
          dynamic={dynamicProps.includes('alt') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['alt']}
        />
      </ToolbarSection>
      <ToolbarSection title="Style" initOpen>
        <ToolbarItem
          propKey="block"
          propSubKey="align"
          type="select"
          label="Alignment"
          values={alignOpts}
          dynamic={dynamicProps.includes('align') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['align']}
        />
        <ToolbarItem
          propKey="block"
          propSubKey="fullWidth"
          type="switch"
          label="Full Width"
          dynamic={dynamicProps.includes('fullWidth') && customs.dynamicData}
          dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
          defaultValue={defaultProps['fullWidth']}
        />
      </ToolbarSection>
    </>
  )
}
