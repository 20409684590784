import React from 'react'

function Tablet({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--tablet${className ? ' ' + className : ''}`}
    >
      <path d="M36 0h-24c-3.31 0-6 2.69-6 6v36c0 3.31 2.69 6 6 6h24c3.31 0 6-2.69 6-6v-36c0-3.31-2.69-6-6-6zm-8 44h-8v-2h8v2zm10.5-6h-29v-32h29v32z" />
    </svg>
  )
}

export default Tablet
