import React from 'react'
import { WContainer, Breadcrumbs, MainTitle, MainDescription } from '@next-nx/shared-ui/atoms'
import { Page, ValorizzazioniListing } from '@next-nx/shared-ui/organisms'
import styles from './PortaleValorizzazioniTemplate.module.sass'


interface Props {
  pagina?: any
  slugCategoria?: string|null
}

const PortaleValorizzazioniTemplate = (props: Props) => {

  const { 
    pagina = null,
    slugCategoria = null,
  } = props

  return (
    <Page pagina={pagina} item={{}} contentClassName={styles.portaleValorizzazioniContent}>
      <div className={`${styles.headBg} bg-grey`}>
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
          <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
          <MainDescription html={pagina?.descrizione} />
        </WContainer>
      </div>
      <div className="bg-white">
        <WContainer>
          <ValorizzazioniListing slugCategoria={slugCategoria} />
        </WContainer>
      </div>
    </Page>
  )
}

export default PortaleValorizzazioniTemplate
