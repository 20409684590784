import { useNode, Element, useEditor } from '@craftjs/core'
import React, { useState, useEffect } from 'react'
import { VideoSettings } from './VideoSettings'

import {
  deepValue,
  getAllPaths,
  getDynamicValue,
  setDynamicValue,
  isDynamicValue,
} from '../../utils/dynamic'

import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

export const providers = {
  youtube: {
    url: 'https://www.youtube.com/embed/',
  },
  youtube_nocookie: {
    url: 'https://www.youtube-nocookie.com/embed/',
  },
  vimeo: {
    url: 'https://player.vimeo.com/video/',
  },
}

export const defaultProps = {
  src: '', // "https://www.youtube.com/embed/BWLRMBrKH_c"
  provider: 'youtube',
  videoId: '',
  autoplay: false,
  loop: false,
  controls: true,
  related: true,
  modest: false,
}

export const dynamicProps = ['src']

export const Video = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { src, provider, videoId, autoplay, loop, controls, related, modest } = blockProps

    const _ = props.renderValue

    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }))

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode((state) => ({
      selected: state.events.selected,
      dragged: state.events.dragged,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const getSrc = () => {
      if (src) {
        return _(src)
      }
      if (provider) {
        let url = providers[provider].url + videoId + '?'
        if (autoplay) {
          url += '&autoplay=1'
        }
        if (loop) {
          url += `&loop=1&playlist=${videoId}`
        }
        if (provider !== 'vimeo') {
          if (!controls) {
            url += '&controls=0&showinfo=0'
          }
          if (!related) {
            url += '&rel=0'
          }
          if (modest) {
            url += '&modestbranding=1'
          }
        }

        return url
      }
      return _(src)
    }

    return (
      <div ref={(ref) => props.retRef(ref)} className={'block-video ' + props.className}>
        <iframe
          src={getSrc()}
          frameBorder="0"
          allowFullScreen="1"
          allow={enabled ? '' : 'autoplay'}
          style={{
            pointerEvents: enabled ? 'none' : 'auto',
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    )
  },
  defaultProps,
  dynamicProps
)

Video.craft = withCraft({
  name: 'Video',
  defaultProps: defaultProps,
  settings: VideoSettings,
})
