import React from 'react'

function SmartphoneLandscape({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--smartphone-landscape${className ? ' ' + className : ''}`}
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M2.02 14l-.02 20c0 2.21 1.79 4 4 4h36c2.21 0 4-1.79 4-4v-20c0-2.21-1.79-4-4-4h-36c-2.21 0-3.98 1.79-3.98 4zm35.98 0v20h-28v-20h28z" />
    </svg>
  )
}

export default SmartphoneLandscape
