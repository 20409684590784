import React from 'react'
import { WContainer, Breadcrumbs, MainTitle } from '@next-nx/shared-ui/atoms'
import { Page, FaqSearchForm, FaqCategoriesListing } from '@next-nx/shared-ui/organisms'
import styles from './FaqListingTemplate.module.sass'


interface Props {
  pagina: any
  categorieFaq: any
}

const FaqListingTemplate = (props: Props) => {

  const { 
    pagina = undefined,
    categorieFaq = []
  } = props

  return (
    <Page pagina={pagina} item={{}} contentClassName={styles.faqListingContent}>
      <div className={`${styles.headBg} bg-grey`}>
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
          <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
        </WContainer>
        <WContainer variant="xs">
          <FaqSearchForm />
        </WContainer>
      </div>
      <div className={styles.listing}>
        <WContainer>
          <FaqCategoriesListing categorieFaq={categorieFaq} />
        </WContainer>
      </div>
    </Page>
  )
}

export default FaqListingTemplate
