import React, { useState, useEffect, useRef } from 'react'
import { useIsMobile, useFindMenu } from '@next-nx/hooks'
import {
  Breadcrumbs,
  MainDescription,
  MainTitle,
  WContainer,
  WImage,
} from '@next-nx/shared-ui/atoms'
import { AppBox, ImageTextBoxSeparated } from '@next-nx/shared-ui/molecules'
import { Page, FaqSection } from '@next-nx/shared-ui/organisms'
import { MenuType } from '@next-nx/utils/types'
import styles from './AppTemplate.module.sass'
import { AppStore, GooglePlay } from '@next-nx/shared-ui/icons'

interface Props {
  pagina?: any
}

const AppTemplate = (props: Props) => {
  const { pagina = {} } = props

  const isMobile = useIsMobile()
  const menuApp = useFindMenu('landing-app')
  const sezioneStores = useFindMenu('sezione-stores', menuApp)

  const getAppStores = () => {
    if (sezioneStores && sezioneStores.children) {
      return sezioneStores.children.edges.filter((item) => {
        if (item.node && item.node.key) {
          return item.node.key.startsWith('stores_')
        }
        return false
      })
    }
    return []
  }

  const getFaqs = (node: MenuType) => {
    if (node && node.children && node.children.edges.length > 0) {
      return node.children.edges
        .filter((item) => {
          return item.node && item.node.style && item.node.style.startsWith('domanda')
        })
        .map((item, i) => {
          return {
            pk: i,
            domanda: item.node.name || '',
            risposta: item.node.description || '',
          }
        })
    }
    return []
  }

  return (
    <Page pagina={pagina} item={{}}>
      <WContainer>
        <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} className={styles.breads} />
      </WContainer>
      <div
        className={`${styles.headBg}`}
        style={{
          background: `no-repeat url('${isMobile ? pagina.previewImage : pagina.mainImage}')`,
          backgroundSize: 'cover',
        }}
      >
        <WContainer>
          <div className={styles.headLayout}>
            <div className={styles.headContent}>
              <MainTitle label={pagina?.nome} className={styles.title} />
              <MainDescription html={pagina?.descrizione} className={styles.description} />
              <div className={styles.stores}>
                <div className={styles.qrcode}>
                  <WImage
                    src={isMobile ? sezioneStores?.mainImageResponsive : sezioneStores?.mainImage}
                    maxWidth={isMobile ? 150 : 150}
                    maxHeight={isMobile ? 150 : 150}
                    layout="intrinsic"
                    objectFit="contain"
                    priority={true}
                    loading="eager"
                    key={`app-main-image${isMobile ? '-mobile' : ''}`}
                  />
                </div>
                <div className={styles.containerLinks}>
                  <div
                    className={styles.titleLinks}
                    dangerouslySetInnerHTML={{ __html: sezioneStores?.name || '' }}
                  ></div>
                  <div className={styles.links}>
                    {getAppStores().map((child, n) => (
                      <div className={styles.logo} key={n}>
                        <a href={child.node.url} target="_blank">
                          {child.node.style === 'apple' ? (
                            <AppStore idVariant={'app_' + n} />
                          ) : (
                            <GooglePlay idVariant={'app_' + n} />
                          )}
                        </a>
                      </div>
                    ))}
                  </div>
                  <div
                    className={styles.subtitleLinks}
                    dangerouslySetInnerHTML={{ __html: sezioneStores?.description || '' }}
                  ></div>
                </div>
                <div className={styles.linksImage}>
                  <div className={styles.screenshot}>
                    <WImage
                      src={isMobile ? menuApp?.mainImageResponsive : menuApp?.mainImage}
                      maxWidth={isMobile ? 170 : 442}
                      maxHeight={isMobile ? 197 : 513}
                      layout="intrinsic"
                      objectFit="contain"
                      priority={true}
                      loading="eager"
                      key={`app-main-image${isMobile ? '-mobile' : ''}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.headImage}>
              <WImage
                src={isMobile ? menuApp?.mainImageResponsive : menuApp?.mainImage}
                maxWidth={442}
                maxHeight={513}
                layout="intrinsic"
                objectFit="contain"
                priority={true}
                loading="eager"
                key={`app-main-image${isMobile ? '-mobile' : ''}`}
              />
            </div>
          </div>
        </WContainer>
      </div>
      {menuApp &&
        menuApp.children &&
        menuApp.children.edges
          .filter(
            (child) =>
              child &&
              child.node &&
              child.node.style &&
              (child.node.style.startsWith('codice_qr') ||
                child.node.style.startsWith('sezione-servizi') ||
                child.node.style.startsWith('faqs') ||
                child.node.style.startsWith('appbox'))
          )
          .map((child) =>
            child.node.style ? (
              <div
                className={styles.section}
                id={`menu_${child.node.pk}`}
                key={`menu_${child.node.pk}`}
              >
                {child.node.style.startsWith('sezione-servizi') &&
                  child.node.children &&
                  child.node.children.edges.map((subchild, subchildKey) => (
                    <WContainer variant="sm" key={subchildKey}>
                      <ImageTextBoxSeparated
                        item={subchild.node}
                        textPos={subchild.node.style === 'image_text_box_right' ? 'right' : 'left'}
                      />
                    </WContainer>
                  ))}
                {child.node.style.startsWith('appbox') && (
                  <WContainer variant="lg">
                    <AppBox item={child.node} controlled />
                  </WContainer>
                )}
                {child.node.style.startsWith('faqs') && (
                  <WContainer>
                    <FaqSection faqMenu={child.node} faqs={getFaqs(child.node)} />
                  </WContainer>
                )}
              </div>
            ) : (
              <></>
            )
          )}
    </Page>
  )
}

export default AppTemplate
