import React, { useRef } from 'react'
import { useTrans } from '@next-nx/hooks'
import styles from './QuotazionePacchettoAutoBox.module.sass'
import {
  GaranziaQuotazioneAutoType,
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoType,
} from '@next-nx/utils/types'
import QuotazioneGaranziaAutoBox from '../QuotazioneGaranziaAutoBox/QuotazioneGaranziaAutoBox'
import { Chip, FormToggle } from '@next-nx/shared-ui/atoms'
import {
  STATO_GARANZIA_ESCL,
  STATO_GARANZIA_OBBLIGATORIA,
  getScontoPacchetto,
  isPacchettoSelectable,
} from '@next-nx/utils/fastquote'

interface Props {
  quotazione: QuotazioneAutoType
  pacchetto: PacchettoGaranziaQuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  onClickGaranzia: (x: GaranziaQuotazioneAutoType) => void
  massimaleCristalliValue?: string
  setMassimaleCristalliValue: (v: string) => void
  checked?: boolean
  onClick?: () => void
  className?: string
}

const QuotazionePacchettoAutoBox = (props: Props) => {
  const {
    quotazione,
    pacchetto,
    garanzie = [],
    garanzieSelezionate = [],
    onClickGaranzia = () => undefined,
    checked = false,
    massimaleCristalliValue = undefined,
    setMassimaleCristalliValue = () => undefined,
    onClick = () => undefined,
    className = '',
  } = props

  const t = useTrans()

  const innerContentRef = useRef<HTMLDivElement>(null)

  const classeNames = `
    ${styles.root}
    ${checked ? styles['root--checked'] : ''}
    ${className ? ` ${className}` : ''}
  `

  const scontoValue = getScontoPacchetto(quotazione, pacchetto.codice)

  return pacchetto && garanzie.filter(g => g.statoIniziale !== STATO_GARANZIA_ESCL).length > 0 ? (
    <div className={classeNames}>
      <div className={styles.head}>
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: pacchetto.descrizione }} />
        {isPacchettoSelectable(pacchetto.codice, garanzie) && (
          <div className={styles.addCta}>
            <FormToggle
              label={checked ? t('Aggiunto') : t('Aggiungi')}
              id={`toggle-pacchetto-${pacchetto.codice}`}
              checked={checked}
              labelPos="left"
              onChange={onClick}
            />
          </div>
        )}
      </div>
      {isPacchettoSelectable(pacchetto.codice, garanzie) && !!scontoValue && (
        <div className={styles.sconto}>
          <Chip label={`${scontoValue}% di sconto`} variant="light" />
          <span>{t('se includi le {0} garanzie', [garanzie.length.toString()])}</span>
        </div>
      )}
      <div className={styles.garanziePacchettiList}>
        {garanzie.map((x) => (
          <QuotazioneGaranziaAutoBox
            garanzia={x}
            checked={
              x?.statoIniziale === STATO_GARANZIA_OBBLIGATORIA ||
              garanzieSelezionate.indexOf(x.codice) > -1
            }
            key={`${pacchetto.codice}-${x.pk}`}
            massimaleCristalliValue={massimaleCristalliValue}
            setMassimaleCristalliValue={setMassimaleCristalliValue}
            onClick={() => onClickGaranzia(x)}
          />
        ))}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default QuotazionePacchettoAutoBox
