import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


interface Props {
    recaptchaRequired?: boolean
    children: React.ReactNode
  }

const GoogleReCaptchaWrapper = (props: Props) => {

    const {
        recaptchaRequired = false,
        children = null,
      } = props

    return recaptchaRequired ? (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.RECAPTCHA_SITE_KEY}>
        {children}
        </GoogleReCaptchaProvider>
    ) : (
        <>{children}</>
    );
};

export default GoogleReCaptchaWrapper