import { useEditor } from '@craftjs/core'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Toolbox } from './Toolbox'

import { Close } from '@next-nx/shared-ui/icons'
import { CustomToolbox } from './CustomToolbox'
import { Asset } from './Asset'
import { GlobalContext } from '../../../services/globalContext'
import { Navigator } from './Navigator'
import {
  Navigation as NavigationIcon,
  Blocks as BlocksIcon,
  CustomBlocks as CustomBlocksIcon,
  Image as ImageIcon,
} from '../../../pb-components/icons'

const LeftSidebarPanel = ({ title, component, variant, onClose, open = false }) => {
  const onDrag = () => {
    onClose()
  }

  useEffect(() => {
    const panel = document.getElementById(`left-sidebar__panel-${title}`)
    if (panel) {
      panel.addEventListener('dragover', onDrag)
    }

    return () => {
      const panel = document.getElementById(`left-sidebar__panel-${title}`)
      if (panel) {
        panel.removeEventListener('dragover', onDrag)
      }
    }
  }, [])

  return (
    <div
      id={`left-sidebar__panel-${title}`}
      className={`left-sidebar__panel__overlay ${open ? 'left-sidebar__panel__overlay--open' : ''}`}
    >
      <div className={`left-sidebar__panel ${variant ? `left-sidebar__panel--${variant}` : ''}`}>
        <div className="left-sidebar__panel__header">
          <p className="left-sidebar__panel__header__title">{title}</p>
          <div className="left-sidebar__panel__header__close" onClick={onClose}>
            <Close />
          </div>
        </div>
        <div className="left-sidebar__panel__content">{component}</div>
      </div>
    </div>
  )
}

export const LeftSidebar = ({}) => {
  const { leftSidebarTab, setLeftSidebarTab, setAssetId } = useContext(GlobalContext)

  const currentRef = useRef()

  const onClickOutside = (event) => {
    if (currentRef && !currentRef.current.contains(event.target)) {
      closePanel()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside)

    return () => {
      document.removeEventListener('mousedown', onClickOutside)
    }
  }, [])

  const openTab = (name) => {
    if (leftSidebarTab === name) {
      closePanel()
    } else {
      setLeftSidebarTab(name)
    }
  }

  const closePanel = () => {
    setLeftSidebarTab(null)
    setAssetId(null)
  }

  return (
    <div className="left-sidebar" ref={currentRef}>
      <div className="left-sidebar__tabs">
        <div
          className={`left-sidebar__tab-item ${
            leftSidebarTab === 'Blocks' ? 'left-sidebar__tab-item--active' : ''
          }`}
          onClick={() => openTab('Blocks')}
        >
          <BlocksIcon />
        </div>
        <div
          className={`left-sidebar__tab-item ${
            leftSidebarTab === 'CustomBlocks' ? 'left-sidebar__tab-item--active' : ''
          }`}
          onClick={() => openTab('CustomBlocks')}
        >
          <CustomBlocksIcon />
        </div>
        <div
          className={`left-sidebar__tab-item ${
            leftSidebarTab === 'Navigator' ? 'left-sidebar__tab-item--active' : ''
          }`}
          onClick={() => openTab('Navigator')}
        >
          <NavigationIcon />
        </div>
        <div
          className={`left-sidebar__tab-item ${
            leftSidebarTab === 'Assets' ? 'left-sidebar__tab-item--active' : ''
          }`}
          onClick={() => openTab('Assets')}
        >
          <ImageIcon />
        </div>
      </div>

      <LeftSidebarPanel
        title={'Blocks'}
        component={<Toolbox />}
        onClose={closePanel}
        open={leftSidebarTab === 'Blocks'}
      />
      <LeftSidebarPanel
        title={'CustomBlocks'}
        component={<CustomToolbox />}
        onClose={closePanel}
        open={leftSidebarTab === 'CustomBlocks'}
      />
      <LeftSidebarPanel
        title={'Navigator'}
        component={<Navigator open={true} />}
        onClose={closePanel}
        open={leftSidebarTab === 'Navigator'}
      />
      <LeftSidebarPanel
        title={'Assets'}
        component={<Asset />}
        variant="lg"
        onClose={closePanel}
        open={leftSidebarTab === 'Assets'}
      />
    </div>
  )
}
