export const defaultConditionsProps = {
  hiddenIfLoggedIn: false,
  hiddenIfNotLoggedIn: false,
  hiddenIfDesktop: false,
  hiddenIfTablet: false,
  hiddenIfMobile: false,
}

export const conditions = {
  'Utente': {
    hiddenIfLoggedIn: {
      description: "Nascondi se l'utente è loggato",
    },
    hiddenIfNotLoggedIn: {
      description: "Nascondi se l'utente non è loggato",
    },
  },
  'Screen Size': {
    hiddenIfDesktop: {
      description: 'Nascondi se in modalità desktop',
    },
    hiddenIfTablet: {
      description: 'Nascondi se in modalità tablet',
    },
    hiddenIfMobile: {
      description: 'Nascondi se in modalità smartphone',
    },
  },
}
