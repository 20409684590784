import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from './globalContext'

import { api } from './api'

export const PagebuilderContext = React.createContext()

const PagebuilderContextProvider = ({ children }) => {
  const { contentTypeId, objectId, pkey, lang, editMode, utente } = useContext(GlobalContext)

  const [pagebuilder, setPagebuilder] = useState()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (pkey && lang && editMode !== undefined && editMode !== null) {
      setLoading(true)
      
      api
        .getPagebuilder({
          contentTypeId,
          objectId,
          key: pkey,
          lang,
          edit_mode: utente && utente.isStaff ? true : false,
        })
        .then((data) => {
          setPagebuilder(data)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }, [contentTypeId, objectId, pkey, lang, editMode, JSON.stringify(utente)])

  const update = (pagebuilder) => {
    setPagebuilder(pagebuilder)
  }

  const saveDraft = (json_draft, refresh = false) => {
    if (refresh) {
      setLoading(true)
    }
    api
      .saveDraftPagebuilder(pagebuilder.id, json_draft)
      .then((data) => {
        update(data)
        console.log(data)
        if (refresh) {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (refresh) {
          setLoading(false)
        }
      })
  }

  const publish = (refresh = false) => {
    if (refresh) {
      setLoading(true)
    }
    api
      .publishPagebuilder(pagebuilder.id)
      .then((data) => {
        update(data)
        console.log(data)
        if (refresh) {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (refresh) {
          setLoading(false)
        }
      })
  }

  const unpublish = (refresh = false) => {
    if (refresh) {
      setLoading(true)
    }
    api
      .unpublishPagebuilder(pagebuilder.id)
      .then((data) => {
        update(data)
        console.log(data)
        if (refresh) {
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log(err)
        if (refresh) {
          setLoading(false)
        }
      })
  }

  return (
    <PagebuilderContext.Provider value={{ pagebuilder, isLoading, saveDraft, publish, unpublish }}>
      {children}
    </PagebuilderContext.Provider>
  )
}

export default PagebuilderContextProvider
