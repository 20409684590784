import React, { useEffect, useRef, useState } from 'react'
import { GaranziaQuotazioneAutoType, QuotazioneAutoType } from '@next-nx/utils/types'
import { useTrans } from '@next-nx/hooks'
import { priceFormat, roundNumber } from '@next-nx/utils/safe'
import { ChevronUp, ChevronDown, Check } from '@next-nx/shared-ui/icons'
import { Button, Chip } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneAutoRiepilogo.module.sass'
import {
  CODICE_GARANZIA_RCA,
  CODICE_PACCHETTO_EVENTI,
  CODICE_PACCHETTO_PERSONA,
  CODICE_PACCHETTO_VEICOLO,
  getScontoPacchetto,
} from '@next-nx/utils/fastquote'

interface Props {
  quotazione: QuotazioneAutoType
  totaleScontatoQuotazione: number
  setTotaleQuotazione: (v: number) => void
  setTotaleScontatoQuotazione: (v: number) => void
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  pacchettiSelezionati: any
  isValid?: boolean
  onClick: () => void
  className?: string
}

const QuotazioneAutoRiepilogo = (props: Props) => {
  const {
    quotazione = undefined,
    totaleScontatoQuotazione,
    setTotaleQuotazione = () => undefined,
    setTotaleScontatoQuotazione = () => undefined,
    garanzie,
    garanzieSelezionate,
    pacchettiSelezionati,
    isValid = true,
    onClick = () => undefined,
    className = '',
  } = props

  const t = useTrans()
  const [garanzieOpen, setGaranzieOpen] = useState(false)
  const [swipeStart, setSwipeStart] = useState<number | null>(null)
  const [mobileOpen, setMobileOpen] = useState(false)
  const innerContentRef = useRef()
  const accordionInnerContentRef = useRef()
  const bottomInnerContentRef = useRef()

  const garanzieObbligatorie = garanzie?.filter((x) => x.codice === CODICE_GARANZIA_RCA)
  const garanziePacchetti = garanzie
    ?.filter((x) => x.pacchetto?.codice && pacchettiSelezionati?.[x.pacchetto.codice])
    ?.filter((x) => garanzieSelezionate.indexOf(x?.codice) > -1)
  const garanzieNoPacchetti = garanzie
    ?.filter((x) => x.codice !== CODICE_GARANZIA_RCA)
    ?.filter(
      (x) =>
        !x.pacchetto?.codice || (x.pacchetto?.codice && !pacchettiSelezionati?.[x.pacchetto.codice])
    )
    ?.filter((x) => garanzieSelezionate.indexOf(x?.codice) > -1)

  useEffect(() => {
    if (mobileOpen) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [mobileOpen])

  useEffect(() => {
    let newTotaleQuotazione = 0
    let newTotaleScontatoQuotazione = 0

    // prezzo garanzie obbligatorie
    garanzie
      ?.filter((x) => x.codice === CODICE_GARANZIA_RCA)
      .forEach((g) => {
        newTotaleQuotazione += g.importoLordoAnnuo
        newTotaleScontatoQuotazione += g.importoLordoAnnuoScontato
      })

    // prezzo garanzie pacchetto
    quotazione?.pacchetti.forEach((pacchetto) => {
      pacchetto.importoLordoAnnuo = 0
      pacchetto.importoLordoAnnuoScontato = 0
      if (pacchettiSelezionati?.[pacchetto.codice]) {
        const garanziePacchetto = garanzie.filter((x) => x.pacchetto?.codice === pacchetto.codice)
        garanziePacchetto.forEach((g) => {
          pacchetto.importoLordoAnnuo += g.importoLordoAnnuo
          pacchetto.importoLordoAnnuoScontato += g.importoLordoAnnuoScontato
        })
        newTotaleScontatoQuotazione += pacchetto.importoLordoAnnuoScontato
        newTotaleQuotazione += pacchetto.importoLordoAnnuo
      }
    })

    // prezzo garanzie senza pacchetto
    garanzie
      ?.filter(
        (x) => !x.pacchetto || (x.pacchetto?.codice && !pacchettiSelezionati?.[x.pacchetto.codice])
      )
      ?.filter((x) => garanzieSelezionate.indexOf(x?.codice) > -1)
      .forEach((g) => {
        newTotaleQuotazione += g.importoLordoAnnuo
        newTotaleScontatoQuotazione += g.importoLordoAnnuoScontato
      })

    setTotaleQuotazione(roundNumber(newTotaleQuotazione))
    setTotaleScontatoQuotazione(roundNumber(newTotaleScontatoQuotazione))
  }, [quotazione, garanzie, garanzieSelezionate])

  return quotazione ? (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      <button
        role="button"
        className={styles.mobileToggler}
        onClick={() => {
          if (!mobileOpen) setGaranzieOpen(true) // nel mobile di default le garanzie sono aperte
          setMobileOpen(!mobileOpen)
        }}
        onTouchStart={(e) => setSwipeStart(e.nativeEvent?.targetTouches[0]?.pageY)}
        onTouchEnd={(e) => {
          if (swipeStart !== null) {
            if (mobileOpen && e.nativeEvent?.changedTouches[0]?.pageY > swipeStart + 20)
              // un po' di offset per verificare che swipino in giù
              setMobileOpen(false)
            if (!mobileOpen && e.nativeEvent?.changedTouches[0]?.pageY < swipeStart - 20) {
              // un po' di offset per verificare che swipino in su
              setMobileOpen(true)
              setGaranzieOpen(true) // nel mobile di default le garanzie sono aperte
            }
          }
          setSwipeStart(null)
        }}
      >
        <span className={styles.mobileTogglerIcon}>
          {mobileOpen ? <ChevronDown /> : <ChevronUp />}
        </span>
        <span className={styles.mobileTogglerLabel}>
          {t(mobileOpen ? 'Chiudi dettagli quotazione' : 'Vedi dettagli quotazione')}
        </span>
      </button>
      <div
        className={styles.content}
        style={{
          maxHeight:
            innerContentRef?.current && mobileOpen
              ? innerContentRef.current.clientHeight +
                (garanzieOpen && accordionInnerContentRef?.current
                  ? accordionInnerContentRef.current.clientHeight
                  : 0)
              : 0,
        }}
      >
        <div className={styles.innerContent} ref={innerContentRef}>
          <p className={styles.title}>{t('La tua quotazione')}</p>
          <div className={styles.list}>
            {garanzieObbligatorie.map((x) => (
              <div className={styles.item} key={`riepilogo-obb-${x.pk}`}>
                <span className={styles.itemLabel}>{x.titolo}</span>
                <span className={styles.itemValue}>
                  {priceFormat(x.importoLordoAnnuoScontato)}
                </span>
              </div>
            ))}
            {quotazione.pacchetti
              .filter((p) => pacchettiSelezionati?.[p.codice])
              .map((p) => (
                <div className={styles.item} key={`riepilogo-pacchetto-${p.codice}`}>
                  <span className={styles.itemLabel}>
                    {p.titolo}
                    {!!p.scontoValue && (
                      <span>
                        <br />
                        <Chip label={`-${p.scontoValue}% di sconto`} variant="light" size="sm" />
                      </span>
                    )}
                  </span>
                  <span className={styles.itemValue}>
                    {p.importoLordoAnnuo != p.importoLordoAnnuoScontato && (
                      <span>
                        {/* <Chip label={`-${p.scontoValue}% di sconto`} variant="light" size="sm" /> */}
                        <span className={styles.itemValueSbarrato}>
                          {priceFormat(p.importoLordoAnnuo)}
                        </span>
                        <br />
                      </span>
                    )}
                    {!!p.importoLordoAnnuoScontato &&
                      priceFormat(p.importoLordoAnnuoScontato)}
                  </span>
                </div>
              ))}

            {!!garanziePacchetti?.length && (
              <div className={styles.accordion}>
                <button
                  role="button"
                  className={styles.accordionTrigger}
                  onClick={() => setGaranzieOpen(!garanzieOpen)}
                >
                  <span className={styles.accordionTriggerLabel}>
                    {t('Dettagli garanzie incluse')}
                  </span>
                  <span className={styles.accordionTriggerIcon}>
                    {garanzieOpen ? <ChevronUp /> : <ChevronDown />}
                  </span>
                </button>
                <div
                  className={styles.accordionContent}
                  style={{
                    maxHeight:
                      accordionInnerContentRef?.current && garanzieOpen
                        ? accordionInnerContentRef.current.clientHeight
                        : 0,
                  }}
                >
                  <div className={styles.accordionInnerContent} ref={accordionInnerContentRef}>
                    <div className={styles.listSm}>
                      {garanziePacchetti.map((x) => (
                        <div className={styles.item} key={`riepilogo-gar-pacchetto-${x.pk}`}>
                          <span>
                            {x.titolo}
                            <br />
                            <i>{t('Inclusa in {0}', [x.pacchetto.titolo])}</i>
                          </span>
                          {/* <span className={styles.itemValueSbarrato}>
                            {priceFormat(x.importoLordoAnnuo)}
                          </span> */}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {garanzieNoPacchetti.map((x) => (
              <div className={styles.item} key={`riepilogo-gar-${x.pk}`}>
                <span className={styles.itemLabel}>{x.titolo}</span>
                <span className={styles.itemValue}>
                  {priceFormat(x.importoLordoAnnuoScontato)}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.totale}>
        <span className={styles.totaleLabel}>{t('Totale')}</span>
        <div className={styles.totaleValue}>{priceFormat(totaleScontatoQuotazione)}</div>
      </div>
      <div>
        <p className={styles.discount}>
          <span className={styles.discountIcon}>
            <Check />
          </span>
          <span className={styles.discountLabel}>{t('Prezzo già scontato del')} </span>
          <span className={styles.discountValue}>{t('quotazione_auto_discount')}</span>
        </p>
      </div>
      <div
        className={styles.bottomContent}
        style={{
          maxHeight:
            bottomInnerContentRef?.current && mobileOpen
              ? bottomInnerContentRef.current.clientHeight
              : 0,
        }}
      >
        <div className={styles.bottomInnerContent} ref={bottomInnerContentRef}>
          <p className={styles.disclaimer}>
            {t(
              '*Il prezzo è già scontato del {0} ed è valido solo per i clienti banca correntisti e con accredito sul conto.',
              [t('quotazione_auto_discount')]
            )}
          </p>
        </div>
      </div>
      <div className={styles.ctaBox}>
        <Button label={t('Continua')} disabled={!isValid} onClick={onClick} />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default QuotazioneAutoRiepilogo
