import React, { ReactNode, useRef, useState } from 'react'
import { priceFormat } from '@next-nx/utils/safe'
import { useTrans } from '@next-nx/hooks'
import { getIcon } from '@next-nx/utils/icons'
import {
  CheckboxMarked,
  CheckboxUnmarked,
  Info,
  Bin,
  RadioMarked,
  RadioUnmarked,
} from '@next-nx/shared-ui/icons'
import { Button, FormSelect, Tooltip } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneGaranziaAutoBox.module.sass'
import { GaranziaQuotazioneAutoType } from '@next-nx/utils/types'
import {
  CODICE_GARANZIA_RCA,
  STATO_GARANZIA_OBBLIGATORIA,
  CODICE_GARANZIA_BLACKBOX,
  CODICE_GARANZIA_CRISTALLI,
  MASSIMALI_CRISTALLI,
  MASSIMALI_RCA,
  STATO_GARANZIA_ESCL,
} from '@next-nx/utils/fastquote'

interface Props {
  garanzia: GaranziaQuotazioneAutoType
  variant?: 'garanzia' | 'prestazione'
  massimaleRcaValue?: string
  massimaliRca?: any[]
  setMassimaleRcaValue?: (v: string) => void
  massimaleCristalliValue?: string
  massimaliCristalli?: any[]
  setMassimaleCristalliValue?: (v: string) => void
  checked?: boolean
  onClick?: () => void
  openChildren?: boolean
  toggleChildren?: () => void
  showCheckbox?: boolean
  showCondizioni?: boolean
  showPrezzi?: boolean
  children?: ReactNode
  extra?: ReactNode
  className?: string
}

const QuotazioneGaranziaAutoBox = (props: Props) => {
  const {
    garanzia = undefined,
    variant = 'garanzia',
    checked = false,
    massimaleRcaValue = undefined,
    massimaliRca = undefined,
    setMassimaleRcaValue = () => undefined,
    massimaleCristalliValue = undefined,
    massimaliCristalli = undefined,
    setMassimaleCristalliValue = () => undefined,
    onClick = () => undefined,
    openChildren = false,
    toggleChildren = () => undefined,
    showCheckbox = true,
    showCondizioni = true,
    showPrezzi = true,
    children = undefined,
    extra = undefined,
    className = '',
  } = props

  const t = useTrans()
  const [open, setOpen] = useState(false)
  const [isHover, setHover] = useState(false)
  const innerContentRef = useRef<HTMLDivElement>(null)

  const garanzieSenzaMassimale = []
  const premioLordoAnnuo = garanzia?.importoLordoAnnuoScontato || 0
  const massimaleValue =
    garanzia?.codice.startsWith('_') &&
    garanzia?.codice !== CODICE_GARANZIA_RCA &&
    !garanzia.massimale
      ? t('Vedi condizioni contrattuali')
      : garanzia.massimale
      ? priceFormat(garanzia?.massimale)
      : t('Nessuno')
  const franchigiaValue =
    garanzia?.codice.startsWith('_') &&
    garanzia?.codice !== CODICE_GARANZIA_RCA &&
    !parseFloat(garanzia.franchigia)
      ? t('Vedi condizioni contrattuali')
      : parseFloat(garanzia.franchigia)
      ? priceFormat(parseFloat(garanzia.franchigia))
      : garanzia.franchigia || t('Nessuna')
  // TODO: gestire link a file in caso di valore "Vedi condizioni contrattuali"

  const statoNoClick = [STATO_GARANZIA_OBBLIGATORIA, STATO_GARANZIA_ESCL]

  const classeNames = `
    ${styles.root}
    ${styles[`root--${variant}`]}
    ${checked ? styles['root--checked'] : ''}
    ${garanzia?.statoIniziale === STATO_GARANZIA_OBBLIGATORIA ? styles['root--required'] : ''}
    ${garanzia?.statoIniziale === STATO_GARANZIA_ESCL ? styles['root--disabled'] : ''}
    ${!showCheckbox ? styles['root--no-checkbox'] : ''}
    ${!showPrezzi ? styles['root--no-prezzi'] : ''}
    ${className ? ` ${className}` : ''}
  `

  return garanzia && garanzia.statoIniziale !== STATO_GARANZIA_ESCL ? (
    <div
      className={`${classeNames} ${
        isHover && !statoNoClick.includes(garanzia.statoIniziale) ? styles['root--hovered'] : ''
      }`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={styles.main}>
        {showCheckbox && (
          <button
            role="button"
            className={styles.checkbox}
            onClick={!statoNoClick.includes(garanzia.statoIniziale) ? onClick : undefined}
          >
            {variant === 'prestazione' ? (
              checked ? (
                <RadioMarked />
              ) : (
                <RadioUnmarked />
              )
            ) : checked ? (
              <CheckboxMarked />
            ) : (
              <CheckboxUnmarked />
            )}
          </button>
        )}
        <div className={styles.content}>
          <div
            className={styles.head}
            onClick={!statoNoClick.includes(garanzia.statoIniziale) ? onClick : undefined}
          >
            {!!getIcon(garanzia.icon) && (
              <span className={styles.icon}>
                {getIcon(garanzia.icon, `quotazionegaranziabox_${garanzia.pk}`)}
              </span>
            )}
            {!!garanzia.titolo && <p className={styles.title}>{garanzia.titolo}</p>}
          </div>
          {!!garanzia.abstract && (
            <div
              className={styles.abstract}
              dangerouslySetInnerHTML={{ __html: garanzia.abstract }}
            />
          )}
          {!!garanzia.descrizione && (
            <>
              <div
                className={styles.descriptionBox}
                onClick={!statoNoClick.includes(garanzia.statoIniziale) ? onClick : undefined}
              >
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: garanzia.descrizione }}
                  ref={innerContentRef}
                />
                {!!garanzia.descrizioneAggiuntiva && open && (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: garanzia.descrizioneAggiuntiva }}
                  />
                )}
              </div>
              {!!garanzia.descrizioneAggiuntiva && (
                <div className={styles.descriptionCtaBox}>
                  <Button
                    variant="ghost"
                    label={t(open ? 'Chiudi descrizione' : 'Espandi descrizione')}
                    onClick={() => setOpen(!open)}
                    size="sm"
                  />
                </div>
              )}
            </>
          )}
        </div>
        {showCondizioni && garanzia.codice !== CODICE_GARANZIA_BLACKBOX && (
          <>
            {/* Tenere il div */}
            {!garanzieSenzaMassimale.includes(garanzia?.codice) && (
              <div
                className={`${styles.condizioni} ${
                  garanzia?.codice === CODICE_GARANZIA_RCA ? styles['condizioni--rca'] : ''
                }`}
              >
                <div className={styles.item}>
                  <div className={styles.itemHead}>
                    <p className={styles.itemTitle}>{t('Massimale')}</p>
                    {!!garanzia.infoMassimale && (
                      <div className={styles.itemHelper}>
                        <Tooltip
                          id={`quotazionegaranziabox_massimale_${garanzia.pk}`}
                          label={
                            <div dangerouslySetInnerHTML={{ __html: garanzia.infoMassimale }} />
                          }
                        >
                          <span className={styles.itemHelperIcon}>
                            <Info />
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  {garanzia.codice === CODICE_GARANZIA_RCA &&
                  MASSIMALI_RCA &&
                  MASSIMALI_RCA.length > 0 ? (
                    <FormSelect
                      size="sm"
                      value={massimaleRcaValue}
                      onChange={(e) => setMassimaleRcaValue(e.target.value)}
                      options={MASSIMALI_RCA.map((x) => ({
                        ...x,
                        label: t(x.label),
                      }))}
                    />
                  ) : garanzia.codice === CODICE_GARANZIA_CRISTALLI &&
                    MASSIMALI_CRISTALLI &&
                    MASSIMALI_CRISTALLI.length > 0 ? (
                    <FormSelect
                      size="sm"
                      value={massimaleCristalliValue}
                      onChange={(e) => setMassimaleCristalliValue(e.target.value)}
                      options={MASSIMALI_CRISTALLI}
                    />
                  ) : (
                    <div className={styles.itemValue}>{massimaleValue}</div>
                  )}
                </div>
                <div className={styles.item}>
                  <div className={styles.itemHead}>
                    <p className={styles.itemTitle}>{t('Franchigia')}</p>
                    {!!garanzia.franchigiaDesc && (
                      <div className={styles.itemHelper}>
                        <Tooltip
                          id={`quotazionegaranziabox_franchigia_${garanzia.pk}`}
                          label={
                            <div dangerouslySetInnerHTML={{ __html: garanzia.franchigiaDesc }} />
                          }
                        >
                          <span className={styles.itemHelperIcon}>
                            <Info />
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className={styles.itemValue}>{franchigiaValue}</div>
                </div>
                <div>
                  {garanzia.codice === CODICE_GARANZIA_RCA ? (
                    <div className={styles.allegato}>
                      <Button
                        variant="secondary"
                        size="md"
                        label={t(openChildren ? 'Chiudi dettagli' : 'Vedi dettagli')}
                        onClick={toggleChildren}
                      />
                    </div>
                  ) : (
                    garanzia.statoIniziale === STATO_GARANZIA_OBBLIGATORIA &&
                    garanzia.file && (
                      <div className={styles.allegato}>
                        <Button
                          variant="secondary"
                          size="md"
                          label={t('Vedi dettagli')}
                          href={garanzia.file}
                          newPage
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {showPrezzi && garanzia.codice !== CODICE_GARANZIA_BLACKBOX && (
          <div
            className={`${styles.price} ${
              garanzia?.codice === CODICE_GARANZIA_RCA ? styles['price--rca'] : ''
            }`}
          >
            {typeof premioLordoAnnuo === 'number' ? (
              <>
                {garanzia.codice === CODICE_GARANZIA_RCA && (
                  <span>
                    {t('Premio annuo')}
                    <br />
                  </span>
                )}
                {priceFormat(premioLordoAnnuo)}
                {garanzia.codice !== CODICE_GARANZIA_RCA && (
                  <>
                    <br /> / anno
                  </>
                )}
              </>
            ) : (
              <>{premioLordoAnnuo}</>
            )}
          </div>
        )}
      </div>
      {!!children && <div className={styles.children}>{children}</div>}
      {!!extra && <div className={styles.extra}>{extra}</div>}
      {showCheckbox &&
        !statoNoClick.includes(garanzia.statoIniziale) &&
        variant !== 'prestazione' && (
          <div className={styles.mobileCtaBox}>
            <Button
              variant={checked ? 'ghost' : 'primary'}
              label={t(checked ? 'Rimuovi garanzia' : 'Aggiungi garanzia')}
              leftIcon={checked ? <Bin /> : undefined}
              onClick={onClick}
            />
          </div>
        )}
    </div>
  ) : (
    <></>
  )
}

export default QuotazioneGaranziaAutoBox
