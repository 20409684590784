import React from 'react'
import { MenuType } from '@next-nx/utils/types'
import { useTrans } from '@next-nx/hooks'
import { Modal } from '@next-nx/shared-ui/atoms'
import styles from './AppModal.module.sass'
import { AppStore, GooglePlay } from '@next-nx/shared-ui/icons'


interface Props {
  app?: MenuType
  open: boolean
  setOpen: (v: boolean) => void
  className?: string
}

const AppModal = (props: Props) => {

  const { 
    app = undefined,
    open = false,
    setOpen = () => undefined,
    className = '',
  } = props

  const t = useTrans()

  const getAppLogo = (key: string|null|undefined) => {
    if (!key) return null
    switch (key){
        case "apple": return <AppStore />
        case "android": return <GooglePlay />
      }
    return null
  }

  return app ? (
    <Modal
      open={open}
      setOpen={setOpen}
      closeButton
      className={`${styles.appModal} ${
        className ? ` ${className}` : ''
      }`}
    >
        <div className={styles.content}>
            <p
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: t("Scarica l'app") + ` <strong>${app.name}</strong>` }}
            />
            <p className={styles.description}>
                {t("Seleziona lo store di riferimento")}
            </p>
            <div className={styles.links}>
                {app.children?.edges &&
                app.children.edges.map((child) => (
                    <div className={styles.logo} key={child.node.pk}>
                        <a
                          href={child.node.url || ''}
                          className={styles.appButton}
                          {...(child.node.blank && { target: '_blank' })}
                        >
                          {getAppLogo(child.node.style)}
                        </a>
                    </div>
                ))
                }
            </div>
        </div>
    </Modal>
  ) : (
    <></>
  );
}

export default AppModal
