import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

import { conditions } from '.'

export const ConditionsSettings = () => {
  return (
    <>
      {Object.keys(conditions).map((category) => {
        return (
          <ToolbarSection title={category} key={category} initOpen>
            {Object.keys(conditions[category]).map((key) => {
              return (
                <ToolbarItem
                  inline={true}
                  propKey="conditions"
                  propSubKey={key}
                  type="switch"
                  label={conditions[category][key].description}
                  key={key}
                />
              )
            })}
          </ToolbarSection>
        )
      })}
    </>
  )
}
