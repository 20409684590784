import React, { useState, useContext, useEffect } from 'react'
import { useNode, useEditor, ROOT_NODE } from '@craftjs/core'
import {
  deepValue,
  getAllPaths,
  getDynamicValue,
  setDynamicValue,
  isDynamicValue,
} from '../utils/dynamic'

import { DynamicDataContext } from '../services/dynamicDataContext'
import { GlobalContext } from '../services/globalContext'

import { defaultConditionsProps } from '../conditions'

export const withBlock = (Component, defaultProps, dynamicProps) => (props) => {
  const {
    id,
    connectors: { connect, drag },
    actions: { setCustom, setProp },
    customs,
    blockProps,
    conditionsProps,
    selected,
    hovered,
  } = useNode((state) => ({
    selected: state.events.selected,
    hovered: state.events.hovered,
    customs: state.data.custom,
    blockProps: state.data.props.block,
    conditionsProps: state.data.props.conditions,
  }))

  const { query, actions, enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }))

  const { previewMode, wireframeMode, floatingSettingsOpen, setFloatingSettingsOpen } = useContext(
    GlobalContext
  )

  const value = useContext(DynamicDataContext)

  useEffect(() => {
    // seleziona nodo quando droppato (ma anche al render iniziale del pagebuilder)
    /* actions.selectNode(id)
    if(id === ROOT_NODE) {
      actions.selectNode(null)
    } */

    // imposta le defaultProps alle props non specificate
    Object.keys(defaultProps).forEach((propKey) => {
      if (blockProps[propKey] === undefined) {
        actions.history.ignore().setProp(id, (props) => {
          props.block[propKey] = defaultProps[propKey]
        })
      }
    })

    // imposta le defaultConditionsProps alle props non specificate
    Object.keys(defaultConditionsProps).forEach((propKey) => {
      if (conditionsProps[propKey] === undefined) {
        actions.history.ignore().setProp(id, (props) => {
          props.conditions[propKey] = defaultConditionsProps[propKey]
        })
      }
    })
  }, [])

  useEffect(() => {
    if (value) {
      actions.history.ignore().setCustom(id, (custom) => {
        custom['dynamicData'] = value
      })
    } else {
      actions.history.ignore().setCustom(id, (custom) => {
        delete custom['dynamicData']
      })
    }
  }, [value])

  /*  
        1)
        se cambiano i dati dinamici ed era settato un valore dinamico che non ha più
        un corrispondente, allora ripristiniamo il valore di default
        2)
        se non ci sono più i dati dinamici ed era settato un valore dinamico, 
        allora ripristiniamo il valore di default
    */
  useEffect(() => {
    if (value) {
      dynamicProps?.forEach((dprop) => {
        if (
          isDynamicValue(props.block[dprop]) &&
          !deepValue(value, getDynamicValue(props.block[dprop]))
        ) {
          //setProp((props) => {
          actions.history.ignore().setProp(id, (props) => {
            props.block[dprop] = defaultProps[dprop]
          })
        }
      })
    } else {
      dynamicProps?.forEach((dprop) => {
        if (isDynamicValue(props.block[dprop])) {
          //setProp((props) => {
          actions.history.ignore().setProp(id, (props) => {
            props.block[dprop] = defaultProps[dprop]
          })
        }
      })
    }
  }, [value])

  // funzione che ritorna il valore effettivo a seconda che sia dinamico oppure no
  const renderValue = (val) => {
    if (value && isDynamicValue(val)) {
      return deepValue(value, getDynamicValue(val))
    } else {
      return val
    }
  }

  const renderText = (text) => {
    if (value) {
      const index = text.indexOf('${')
      if (index === -1) {
        return text
      } else {
        const lastIndex = text.indexOf('}')
        if (lastIndex !== -1) {
          const val = text.substring(index, lastIndex + 1)
          const renderedVal = renderValue(val)
          let newT = ''
          if (renderedVal) {
            newT = text
              .substring(0, index)
              .concat(renderedVal)
              .concat(text.substr(lastIndex + 1))
          } else {
            newT = text.substring(0, index).concat(text.substr(lastIndex + 1))
          }
          return renderText(newT)
        } else {
          return text
        }
      }
    } else {
      return text
    }
  }

  const retRef = (ref, type = 'drag') => {
    if (!enabled) {
      return null
    } else if (props.conditions && props.conditions.disabled) {
      return null
    } else {
      if (type === 'drag') {
        return connect(drag(ref))
      } else if (type === 'connect') {
        return connect(ref)
      } else {
        return connect(null)
      }
    }
  }

  const [className, setClassName] = useState('')

  useEffect(() => {
    let className = `${id} ${enabled && !previewMode && wireframeMode ? 'with-wireframe' : ''}`
    if (selected) {
      if (!customs.editing) {
        className += ' component-selected'
      }
    } else if (hovered) {
      className += ' component-hovered'
    }
    setClassName(className)
  }, [selected, hovered, enabled, previewMode, wireframeMode, customs.editing])

  const onDoubleClick = (event, fun) => {
    event.stopPropagation()
    if (fun) {
      fun()
    } else {
      setFloatingSettingsOpen(true)
    }
  }

  return (
    <>
      <Component
        {...props}
        className={className}
        renderValue={renderValue}
        renderText={renderText}
        retRef={retRef}
        onDoubleClick={onDoubleClick}
      />
    </>
  )
}
