import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useFindMenu, useIsMobile, useUtente } from '@next-nx/hooks'
import { scrollTo } from '@next-nx/utils/safe'
import { Breadcrumbs, MainDescription, MainTitle, WContainer, WImage } from '@next-nx/shared-ui/atoms'
import { AllegatiBox, ContentBox, ImageTextBox } from '@next-nx/shared-ui/molecules'
import { Page } from '@next-nx/shared-ui/organisms'
import styles from './ChiSiamoTemplate.module.sass'
import { sendGTMData } from '../../../utils/gtm'


interface Props {
  pagina?: any
}

const ChiSiamoTemplate = (props: Props) => {

  const {
    pagina = {},
  } = props

  const isMobile = useIsMobile()
  const { utente } = useUtente()
  const toolbarPresente = utente && (utente.isStaff || utente.isImpersonate)

  const [menuActive, setMenuActive] = useState<string|null>('')
  const menuRef = useRef<HTMLDivElement>(null)
  const sectionRefs = useRef<HTMLDivElement[]>([])

  const addSectionRef = (ref: HTMLDivElement) => {
    if (ref){
      if (sectionRefs.current.filter((x) => x.id === ref.id).length > 0)
        sectionRefs.current = sectionRefs.current.map((x) => x.id === ref.id ? ref : x)
      else
        sectionRefs.current.push(ref)
    }
  }

  const handleScroll = () => {
    if (menuRef.current){
      if (window.scrollY >= menuRef.current.offsetTop - (toolbarPresente ? 40 : 0) - 80) document.body.classList.add('chi-siamo-menu-sticky')
      else document.body.classList.remove('chi-siamo-menu-sticky')
    }

    // Calcolo se sono all'altezza di un box del menu
    const scrollOffset = window.scrollY + (toolbarPresente ? 40 : 0) + 80 + 80 + 20 + 10 // + header + menu + margine + correzzione
    const match = sectionRefs.current.filter(
      (x) => (scrollOffset >= x.offsetTop) && (scrollOffset <= x.offsetTop + x.clientHeight)
    )
    // Potrebbero esserci box sovrapposti, prendo l'ultimo match
    setMenuActive(match.length > 0 ? match[match.length - 1].id?.replace('menu_', '') : null)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const menuChiSiamo = useFindMenu("chi-siamo")

  return (
    <Page pagina={pagina} item={{}} contentClassName={styles.chiSiamoContent}>
      <div className={`${styles.headBg} bg-grey`}>
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
          <div className={styles.headLayout}>
            <div className={styles.headContent}>
              <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
              <MainDescription html={pagina?.descrizione} className={styles.description} />
            </div>
            <div className={styles.headImage}>
              <WImage
                src={isMobile ? pagina?.previewImage : pagina?.mainImage}
                maxWidth={536}
                maxHeight={350}
                layout="intrinsic"
                objectFit="cover"
                priority={true}
                loading="eager"
                key={`chi-siamo-main-image${isMobile ? '-mobile' : ''}`}
              />
            </div>
          </div>
        </WContainer>
      </div>
      <div className={styles.contentSection}>
        <WContainer variant="xs">
          <div
            className={styles.customContent}
            dangerouslySetInnerHTML={{ __html: menuChiSiamo?.description || '' }}
          />
        </WContainer>
      </div>
      {menuChiSiamo && menuChiSiamo.children?.edges && (
        <>
          <div className={styles.menuSectionRef} ref={menuRef}>
            <div className={styles.menuSection}>
              <WContainer>
                <div className={styles.menu}>
                  <Swiper spaceBetween={0} slidesPerView="auto">
                    {menuChiSiamo.children.edges.map((child, index) => (
                      <SwiperSlide key={child.node.pk} >
                        <a
                          href={`#menu_${child.node.pk}`}
                          className={`${styles.menuItem} ${menuActive === `${child.node.pk}` ? styles['menuItem--active'] : ''}`}
                          onClick={(e) => {
                            e.preventDefault()
                            scrollTo(sectionRefs.current[index], toolbarPresente, 100) // Offset: 80 di menu + 20 di margine
                            sendGTMData({
                              event: "GAevent",
                              eventCategory: "sotto_menu",
                              eventAction: child.node.name?.toGTMFormat(),
                              eventLabel: "chi_siamo"
                            })
                          }}
                        >
                          <span dangerouslySetInnerHTML={{ __html: child.node.name || '' }} />
                        </a>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </WContainer>
            </div>
          </div>
          {menuChiSiamo.children.edges.map((child) => (
            child.node.style ? (
              <div className={styles.section} id={`menu_${child.node.pk}`} key={`menu_${child.node.pk}`} ref={addSectionRef} >
                {child.node.style.startsWith('image_text_box_') && <WContainer><ImageTextBox item={child.node} textPos={child.node.style === 'image_text_box_right' ? 'right' : 'left'} /></WContainer>}
                {child.node.style.startsWith('content_box_') && <ContentBox item={child.node} titlePos={child.node.style === 'content_box_right' ? 'right' : 'left'} />}
                {child.node.style === 'allegati' && <WContainer><AllegatiBox item={child.node} /></WContainer>}
              </div>
            ) : <></>
          ))}
        </>
      )}
    </Page>
  )
}

export default ChiSiamoTemplate
