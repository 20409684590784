import { DynamicDataBlock } from '../components/DynamicDataBlock';
import { Button, Tabs, TabsMenu, TabsContent, TabPane } from '../components';
import { ROOT_NODE } from '@craftjs/core';

import { parseNodeTree } from './block';

export const isDisabled = (nodeId, query = null, helper = null) => {
  if (!nodeId) {
    return false;
  }

  let node = null;
  if (query) {
    node = query.node(nodeId).get();
  }
  if (helper) {
    node = helper(nodeId).get();
  }
  if (!node) {
    return false;
  }

  if (node.data.props.conditions.disabled) {
    return true;
  }

  return false;
};

export const isDeletable = (nodeId, query) => {
  if (!nodeId) {
    return false;
  }

  if (isDisabled(nodeId, query)) {
    return false;
  }

  let isDefaultDeletable = false;
  try {
    isDefaultDeletable = query.node(nodeId).isDeletable();
  } catch (err) {
    return isDefaultDeletable;
  }

  if (isDefaultDeletable) {
    const parentNodeId = query.node(nodeId).get().data.parent;
    const parentNode = query.node(parentNodeId).get();

    // Non si può eliminare il nodo (ROW) figlio di un DYNAMICDATABLOCK
    if (parentNode.data.type === DynamicDataBlock) {
      return false;
    }
    // Non si può eliminare il nodo (TEXT) figlio di un BUTTON
    else if (parentNode.data.type === Button) {
      return false;
    }
    // Non si può eliminare il nodo (TABSMENU, TABSCONTENT) figlio di un TABS
    else if (parentNode.data.type === Tabs) {
      return false;
    }
    // Non si può eliminare il nodo (TEXT) figlio di un TABSMENU
    else if (parentNode.data.type === TabsMenu) {
      return false;
    }
    // Non si può eliminare il nodo (TABPANE) figlio di un TABSCONTENT
    else if (parentNode.data.type === TabsContent) {
      return false;
    }
  }

  return isDefaultDeletable;
};

export const isMoveable = (nodeId, query) => {
  if (!nodeId) {
    return false;
  }

  if (isDisabled(nodeId, query)) {
    return false;
  }

  const isDefaultMoveable = query.node(nodeId).isDraggable();

  if (isDefaultMoveable) {
    const parentNodeId = query.node(nodeId).get().data.parent;
    const parentNode = query.node(parentNodeId).get();

    // Non si può spostare il nodo (ROW) figlio di un DYNAMICDATABLOCK
    if (parentNode.data.type === DynamicDataBlock) {
      return false;
    }
    // Non si può spostate il nodo (TEXT) figlio di un BUTTON
    else if (parentNode.data.type === Button) {
      return false;
    }
    // Non si può spostare il nodo (TABSMENU, TABSCONTENT) figlio di un TABS
    else if (parentNode.data.type === Tabs) {
      return false;
    }
    // Non si può spostare il nodo (TEXT) figlio di un TABSMENU
    else if (parentNode.data.type === TabsMenu) {
      return false;
    }
    // Non si può spostare il nodo (TABPANE) figlio di un TABSCONTENT
    else if (parentNode.data.type === TabsContent) {
      return false;
    }
  }

  return isDefaultMoveable;
};

export const isClonable = (nodeId, query) => {
  if (!nodeId) {
    return false;
  }

  if (isDisabled(nodeId, query)) {
    return false;
  }

  const isDefaultClonable = nodeId !== ROOT_NODE;

  if (isDefaultClonable) {
    const parentNodeId = query.node(nodeId).get().data.parent;
    const parentNode = query.node(parentNodeId).get();

    // Non si può clonare il nodo (ROW) figlio di un DYNAMICDATABLOCK
    if (parentNode.data.type === DynamicDataBlock) {
      return false;
    }
    // Non si può clonare il nodo (TEXT) figlio di un BUTTON
    else if (parentNode.data.type === Button) {
      return false;
    }
    // Non si può clonare il nodo (TABSMENU, TABSCONTENT) figlio di un TABS
    else if (parentNode.data.type === Tabs) {
      return false;
    }
    // Non si può clonare il nodo (TEXT) figlio di un TABSMENU
    else if (parentNode.data.type === TabsMenu) {
      return false;
    }
    // Non si può clonare il nodo (TABPANE) figlio di un TABSCONTENT
    else if (parentNode.data.type === TabsContent) {
      return false;
    }
  }

  return isDefaultClonable;
};

export const isCopyable = (nodeId, query) => {
  if (!nodeId) {
    return false;
  }

  if (isDisabled(nodeId, query)) {
    return false;
  }

  const isDefaultCopyable = nodeId !== ROOT_NODE;

  if (isDefaultCopyable) {
    const parentNodeId = query.node(nodeId).get().data.parent;
    const parentNode = query.node(parentNodeId).get();
    
    // Non si può copiare il nodo (TABSMENU, TABSCONTENT) figlio di un TABS
    if (parentNode.data.type === Tabs) {
      return false;
    }
    // Non si può copiare il nodo (TEXT) figlio di un TABSMENU
    else if (parentNode.data.type === TabsMenu) {
      return false;
    }
    // Non si può copiare il nodo (TABPANE) figlio di un TABSCONTENT
    else if (parentNode.data.type === TabsContent) {
      return false;
    }
  }

  return isDefaultCopyable;
};

export const isDroppable = (nodeId, query) => {
  if (!nodeId) {
    return false;
  }

  if (isDisabled(nodeId, query)) {
    return false;
  }

  const isDefaultDroppable = query.node(nodeId).isCanvas();

  return isDefaultDroppable;
};

export const isParentSelectable = (nodeId) => {
  if (!nodeId) {
    return false;
  }

  return nodeId !== ROOT_NODE;
};

export const getAncestors = (nodeId, query, ancestors = [], level = 0) => {
  const node = query.node(nodeId).get();
  if (!node) {
    return ancestors;
  }

  if (level !== 0) {
    ancestors.push(nodeId);
  }

  if (!node.data.parent) {
    return ancestors;
  }

  ancestors = getAncestors(node.data.parent, query, ancestors, level + 1);

  return ancestors;
};

export const serializeNodeTree = (nodeTree, query) => {
  const serializedNodeTree = {};
  serializedNodeTree['rootNodeId'] = nodeTree['rootNodeId'];
  serializedNodeTree['nodes'] = {};
  Object.keys(nodeTree.nodes).forEach((nodeId) => {
    serializedNodeTree['nodes'][nodeId] = query.node(nodeId).toSerializedNode();
  });
  return serializedNodeTree;
};

export const deserializeNodeTree = (serializedNodeTree, query) => {
  const nodeTree = {};
  nodeTree['rootNodeId'] = serializedNodeTree['rootNodeId'];
  nodeTree['nodes'] = {};
  Object.keys(serializedNodeTree.nodes).forEach((nodeId) => {
    nodeTree['nodes'][nodeId] = query
      .parseSerializedNode(serializedNodeTree['nodes'][nodeId])
      .toNode();
  });
  return nodeTree;
};

export const cloneNodeTree = (nodeId, query, disabled = null) => {
  const nodeTreeToClone = query.node(nodeId).toNodeTree();
  const serializedNodeTree = serializeNodeTree(nodeTreeToClone, query);
  const nodeTree = deserializeNodeTree(serializedNodeTree, query);
  const elements = parseNodeTree(
    nodeTree,
    nodeTree['rootNodeId'],
    0,
    null,
    disabled
  );
  const clonedNodeTree = query.parseReactElement(elements).toNodeTree();
  return clonedNodeTree;
};

export const addNodeTree = (
  nodeTree,
  targetId,
  actions,
  ignore = false,
  index = null,
  throttle = false,
) => {
  if (ignore) {
    actions.history.ignore().addNodeTree(nodeTree, targetId, index);
  } if (throttle) {
    actions.history.throttle().addNodeTree(nodeTree, targetId, index);
  } else {
    actions.addNodeTree(nodeTree, targetId, index);
  }
};

export const deleteNodeTree = (nodeId, actions, ignore = false, throttle = false) => {
  if (ignore) {
    actions.history.ignore().delete(nodeId);
  } if (throttle) {
    actions.history.throttle().delete(nodeId);
  } else {
    actions.delete(nodeId);
  }
};

export const isGlobal = (nodeId, query = null, helper = null) => {
  return (
    isGlobalParent(nodeId, query, helper) ||
    isGlobalChild(nodeId, query, helper)
  );
};

export const isGlobalParent = (nodeId, query = null, helper = null) => {
  if (!nodeId) {
    return false;
  }

  if (nodeId === ROOT_NODE) {
    return false;
  }

  let node = null;
  if (query) {
    node = query.node(nodeId).get();
  }
  if (helper) {
    node = helper(nodeId).get();
  }
  if (!node) {
    return false;
  }

  if (node.data.custom.globalId) {
    return true;
  }

  return false;
};

export const isGlobalChild = (nodeId, query = null, helper = null) => {
  if (!nodeId) {
    return false;
  }

  if (nodeId === ROOT_NODE) {
    return false;
  }

  let node = null;
  if (query) {
    node = query.node(nodeId).get();
  }
  if (helper) {
    node = helper(nodeId).get();
  }
  if (!node) {
    return false;
  }

  if (isGlobalParent(node.data.parent, query, helper)) {
    return true;
  }

  return isGlobalChild(node.data.parent, query, helper);
};

export const getGlobalParent = (nodeId, query) => {
  if (!nodeId) {
    return null;
  }

  if (nodeId === ROOT_NODE) {
    return null;
  }

  const node = query.node(nodeId).get();
  if (node.data.custom.globalId) {
    return nodeId;
  }

  return getGlobalParent(node.data.parent, query);
};

export const getGlobalId = (nodeId, query = null, helper = null) => {
  if (!nodeId) {
    return null;
  }

  if (nodeId === ROOT_NODE) {
    return null;
  }

  let node = null;
  if (query) {
    node = query.node(nodeId).get();
  }
  if (helper) {
    node = helper(nodeId).get();
  }

  if (!node) {
    return null;
  }

  if (node.data.custom.globalId) {
    return node.data.custom.globalId;
  }

  return getGlobalId(node.data.parent, query, helper);
};

// @TODO Rimuovere
export const insertExtNodeOnParentFromVar = (
  nodes,
  actions,
  query,
  nodeId,
  parentId,
  indexToInsert
) => {
  const node = nodes.nodes[nodeId];
  // console.log({node});
  const freshNode = {
    data: {
      ...node.data,
      nodes: [],
      linkedNodes: {},
    },
  };

  // console.log({freshNode});
  const nodeToAdd = query.parseFreshNode(freshNode).toNode();
  // console.log({nodeToAdd});

  actions.add(nodeToAdd, parentId, indexToInsert);

  if (node.data.nodes.length !== 0) {
    node.data.nodes.forEach((childNodeId, index) => {
      console.log({ childNodeId });
      insertExtNodeOnParentFromVar(
        nodes,
        actions,
        query,
        childNodeId,
        nodeToAdd.id,
        index
      );
    });
  }
};
