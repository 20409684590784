import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from '@next-nx/hooks'
import { Breadcrumbs, Chip, MainTitle, Pagination, WContainer } from '@next-nx/shared-ui/atoms'
import { Page } from '@next-nx/shared-ui/organisms'
import { ProdottoCard } from '@next-nx/shared-ui/molecules'
import styles from './SearchResultsTemplate.module.sass'
import { SEARCH_LISTING_ITEMS_PER_PAGE } from 'libs/shared-ui/utils/settings'

interface Props {
  pagination?: any[]
  query?: string
  risultati?: any[]
  totalCount?: number
  pagina?: any
}

type SearchResultType = {
  [key: string]: {
    name: string
    num: number
  }
}

const SearchResultsTemplate = (props: Props) => {
  const { risultati = [], pagina = null, query = '', totalCount = 0 } = props

  const t = useTrans()
  const [selectedType, setSelectedType] = useState<string | null>(null)
  const [countSelectedType, setCountSelectedType] = useState<number>(0)
  const [results, setResults] = useState([]) // risultati visualizzati nella pagina correntemente visualizzata (sezione altri)
  const [prodottiTop, setProdottiTop] = useState([])
  const [selectedPage, setSelectedPage] = useState<number>(1)

  const resultTypes: SearchResultType = risultati.reduce((res: SearchResultType, item) => {
    const key = item.pk.toString()
    return {
      ...res,
      [key]: { name: item.name, num: item.items.length },
    }
  }, {})

  const handleChangePage = (value: number) => {
    setSelectedPage(value)
  }

  const handleSelectType = (pk: string) => {
    setSelectedType(pk)
    setSelectedPage(1)
  }

  useEffect(() => {
    const filteredResults = risultati.find((categoria) => categoria.pk.toString() === selectedType)
    if (filteredResults && filteredResults?.items.length) {
      if (filteredResults?.name === 'Prodotti' && selectedPage === 1) {
        setResults(filteredResults.items.slice(4, SEARCH_LISTING_ITEMS_PER_PAGE))
        setProdottiTop(filteredResults.items.slice(0, 4))
      } else {
        setResults(
          filteredResults.items.slice(
            SEARCH_LISTING_ITEMS_PER_PAGE * (selectedPage - 1),
            SEARCH_LISTING_ITEMS_PER_PAGE * selectedPage
          )
        )
        setProdottiTop([])
      }
      setCountSelectedType(filteredResults.items?.length)
    }
  }, [selectedType, selectedPage])

  useEffect(() => {
    setSelectedType(risultati[0]?.pk.toString())
  }, [])

  return (
    <Page pagina={pagina} item={{}} contentClassName={styles.searchResultsContent}>
      <div className="bg-grey">
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina.breadcrumbs} />
          <h1 className={styles.mainTitle}>
            <strong>{totalCount.toString()}</strong>{' '}
            {totalCount === 1 ? t('Risultato per:') : t('Risultati per:')} <strong>{query}</strong>
          </h1>
          <div className={styles.filters}>
            {Object.keys(resultTypes).map((pk) => (
              <Chip
                key={pk}
                label={`${resultTypes[pk].name} (${resultTypes[pk].num})`}
                active={selectedType === pk}
                onClick={() => handleSelectType(pk)}
              />
            ))}
          </div>
        </WContainer>
      </div>
      <WContainer>
        {/* PRODOTTI IN PRIMO PIANO */}
        {prodottiTop.length > 0 && (
          <div className={styles.primoPiano}>
            <p
              className={styles.primoPianoTitle}
              dangerouslySetInnerHTML={{ __html: t('Prodotti in <strong>primo piano</strong>') }}
            />
            <div className={styles.primoPianoList}>
              {prodottiTop.map((prodotto) => (
                <ProdottoCard key={prodotto.pk} prodotto={prodotto} variant="horizontal" />
              ))}
            </div>
            <div className={styles.primoPianoSlider}>
              <Swiper
                pagination={{ clickable: true }}
                slidesPerView={1.2}
                spaceBetween={16}
                centeredSlides
                className="swiper-container--pagination"
              >
                {prodottiTop.map((prodotto) => (
                  <SwiperSlide key={prodotto.pk}>
                    <ProdottoCard prodotto={prodotto} className={styles.primoPianoSlideCard} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        )}

        {/* ALTRI RISULTATI */}
        {results.length ? (
          <div className={styles.results}>
            <p
              className={styles.resultsTitle}
              dangerouslySetInnerHTML={{
                __html:
                  prodottiTop.length > 0
                    ? t('Altri <strong>risultati</strong> ({0})', [
                        countSelectedType ? (countSelectedType - 4).toString() : '0',
                      ])
                    : t('<strong>Risultati</strong> ({0})', [
                        countSelectedType ? countSelectedType.toString() : '0',
                      ]),
              }}
            />
            <div className={styles.resultsList}>
              {results.map((item) => (
                <div className={styles.resultsItem} key={item.pk}>
                  <Link legacyBehavior href={item.url}>
                    <a className={styles.resultsItemTitle}>{item.titolo}</a>
                  </Link>
                  <div
                    className={styles.resultsItemDescription}
                    dangerouslySetInnerHTML={{ __html: item.descrizione }}
                  />
                </div>
              ))}
            </div>
            <Pagination
              currentPage={selectedPage}
              totalCount={countSelectedType ? countSelectedType : 0}
              pageSize={SEARCH_LISTING_ITEMS_PER_PAGE}
              onPageChange={(page: number) => handleChangePage(page)}
            />
          </div>
        ) : countSelectedType ? null : (
          <p className={styles.noResults}>{t('Non ci sono risultati')}</p>
        )}
      </WContainer>
    </Page>
  )
}

export default SearchResultsTemplate
