import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useTrans } from '@next-nx/hooks'
import { Alert, Filters, Search } from '@next-nx/shared-ui/icons'
import { Chip, Loader, NotificationBar, Pagination, Dropdown, FiltroValorizzazioni } from '@next-nx/shared-ui/atoms'
import { ValorizzazioneCard } from '@next-nx/shared-ui/molecules'
import styles from './ValorizzazioniListing.module.sass'
import { useCategorieFondoQuery as useCategorieFondoQueryVita } from '@next-nx/shared-graphql/vita'
import { useFondiQuery as useFondiQueryVita } from '@next-nx/shared-graphql/vita'
import { FONDI_LISTING_ITEMS_PER_PAGE } from 'libs/shared-ui/utils/settings'
import { useRouter } from 'next/router'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  slugCategoria?: string|null
  className?: string
}

const ValorizzazioniListing = (props: Props) => {

  const {
    slugCategoria = null,
    className = '',
  } = props

  const t = useTrans()
  const router = useRouter()

  const [categoriaAttiva, setCategoriaAttiva] = useState<string|null|undefined>(slugCategoria)
  const [searchInput, setSearchInput] = useState<string|null|undefined>()
  const [searchedInput, setSearchedInput] = useState<string|null|undefined>()
  const [page, setPage] = useState<number>(1)
  const [tipologiaSelezionata, setTipologiaSelezionata] = useState<string|null|undefined>(null)

  const { data: categorieFondoData } = useCategorieFondoQueryVita()
  const { data: dataFondi, loading: loadingFondi, error, refetch: refetchFondi } = useFondiQueryVita({
    variables: {
      offset: 0,
      first: FONDI_LISTING_ITEMS_PER_PAGE,
      categorie_Slug_Equals: categoriaAttiva,
      descrizioneCompleta_Icontains: searchedInput ? searchedInput : undefined,
      tipologia: tipologiaSelezionata,
    }
  })

  const totaleValorizzazioni = dataFondi?.fondi?.totalCount

  const selectCategoria = (slug: string|null|undefined) => {
    if (categoriaAttiva === slug) {
      setCategoriaAttiva(null)
      setPage(1)
      router.push('/portale-valorizzazioni')
    }
    else {
      setCategoriaAttiva(slug)
      setPage(1)
      router.push('/portale-valorizzazioni/' + slug)
    }
  }

  const handleChangePage = (value: number) => {
    setPage(value)

    refetchFondi({
      offset: (value - 1) * FONDI_LISTING_ITEMS_PER_PAGE,
      first: FONDI_LISTING_ITEMS_PER_PAGE,
      categorie_Slug_Equals: categoriaAttiva,
      descrizioneCompleta_Icontains: searchedInput ? searchedInput : undefined,
      tipologia: tipologiaSelezionata,
    })

  }

  const handleSearchSubmit = (value: string|null|undefined) => {
    setPage(1)
    setSearchedInput(value)

    sendGTMData({
      event: "GAevent",
      eventCategory: "search_portale_valorizzazioni",
      eventAction: value?.toGTMFormat(),
      eventLabel: "portale_valorizzazioni"
    })

    refetchFondi({
      offset: 0,
      first: FONDI_LISTING_ITEMS_PER_PAGE,
      categorie_Slug_Equals: categoriaAttiva,
      descrizioneCompleta_Icontains: value ? value : undefined,
      tipologia: tipologiaSelezionata,
    })
  }

  const handleTipologiaFilter = (value: string|null|undefined) => {
    setPage(1)
    setTipologiaSelezionata(value)

    sendGTMData({
      event: "GAevent",
      eventCategory: "filtri_avanzati",
      eventAction: value?.toGTMFormat(),
      eventLabel: "portale_valorizzazioni"
    })

    refetchFondi({
      offset: 0,
      first: FONDI_LISTING_ITEMS_PER_PAGE,
      categorie_Slug_Equals: categoriaAttiva,
      descrizioneCompleta_Icontains: searchedInput ? searchedInput : undefined,
      tipologia: value,
    })
  }

  return (
    <div className={`${styles.magazineListing} ${className ? ` ${className}` : ''}`}>
      {!!categorieFondoData?.categorieFondo?.edges.length && (
        <>
          <div className={styles.filtersList}>
            {categorieFondoData.categorieFondo.edges.map((filtro) => (
              filtro?.node ?
              <Chip
                key={filtro.node.pk}
                label={filtro.node.nome}
                onClick={() => {
                  selectCategoria(filtro.node?.slug)
                  sendGTMData({
                    event: "GAevent",
                    eventCategory: 'filtri',
                    eventAction: filtro?.node?.nome?.toGTMFormat() || '',
                    eventLabel: 'portale_valorizzazioni'
                  })
                  }}
                active={categoriaAttiva === filtro.node.slug}
              />
              : null
            ))}
          </div>
          <div className={styles.filtersSlider}>
            <Swiper spaceBetween={16} slidesPerView="auto" watchOverflow>
              {categorieFondoData.categorieFondo.edges.map((filtro) => (
                filtro?.node ?
                <SwiperSlide key={filtro.node.pk}>
                  <Chip
                    label={filtro.node.nome}
                    onClick={() => {
                      selectCategoria(filtro.node?.slug)
                      sendGTMData({
                        event: "GAevent",
                        eventCategory: 'filtri',
                        eventAction: filtro?.node?.nome?.toGTMFormat() || '',
                        eventLabel: 'portale_valorizzazioni'
                      })
                    }}
                    active={categoriaAttiva === filtro.node.slug}
                  />
                </SwiperSlide>
                : null
              ))}
            </Swiper>
          </div>
        </>
      )}
      <div className={styles.advanced}>
        <p className={styles.title}><strong>{t("Risultati")}</strong> ({totaleValorizzazioni})</p>
        <div className={styles.search}>
          <input
            type="text"
            placeholder={t("Cerca un fondo...")}
            onChange={(e) => {
              const value = e.target.value
              setSearchInput(value)
              if(!value) { // reset
                handleSearchSubmit(value)
              }
            }}
            className={styles.input}
            autoComplete="off"
            />
          <button type="submit" className={styles.submit} onClick={() => handleSearchSubmit(searchInput)}>
            <Search />
          </button>
        </div>
        <div className={styles.advancedFilters}>
          <Dropdown
            triggerProps={{
              label: t("Filtri avanzati"),
              labelMobile: t("Filtri"),
              rightIcon: <Filters />
            }}
            contentPos="right"
          >
            <div className={styles.advancedFiltersDropdown}>
              <FiltroValorizzazioni
                label={"Interno"}
                value={"interno"}
                selection={tipologiaSelezionata}
                handleTipologiaFilter={handleTipologiaFilter}
              />
              <FiltroValorizzazioni
                label={"Esterno"}
                value={"esterno"}
                selection={tipologiaSelezionata}
                handleTipologiaFilter={handleTipologiaFilter}
              />
            </div>
          </Dropdown>
          {tipologiaSelezionata && <div className={styles.advancedFiltersBadge}>1</div>}
        </div>
      </div>
      {loadingFondi && !dataFondi ? (
        <Loader className={styles.loader} />
      ) : error ? (
        <div className={styles.error}>
          <NotificationBar variant="alert" label={error.message} icon={<Alert />} />
        </div>
      ) : (
        <>
          <div className={styles.list}>
            {dataFondi?.fondi?.edges.map((valorizzazione) => (
              valorizzazione?.node &&
              <div
                className={styles.item}
                key={valorizzazione.node.pk}
              >
                <ValorizzazioneCard valorizzazione={valorizzazione.node} />
              </div>
            ))}
          </div>
          <Pagination
            currentPage={page}
            totalCount={totaleValorizzazioni ? totaleValorizzazioni : 0}
            pageSize={FONDI_LISTING_ITEMS_PER_PAGE}
            onPageChange={(page: number) => handleChangePage(page)}
            className={styles.pagination}
          />
        </>
      )}
    </div>
  )
}

export default ValorizzazioniListing
