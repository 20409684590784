import React from 'react'
import { LogoAssicurazioni, LogoCa, LogoVita } from '@next-nx/shared-ui/icons'
import { WContainer } from '@next-nx/shared-ui/atoms'
import styles from './SimpleHeader.module.sass'
import { useSite, useTrans } from '@next-nx/hooks'
import Link from 'next/link'
import { ASSICURAZIONI, VITA } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  className?: string
  fixed?: boolean
}

const SimpleHeader = (props: Props) => {
  const { className = '', fixed = true } = props

  const t = useTrans()
  const site = useSite()

  return (
    <div
      className={`${styles.simpleHeader} ${fixed ? styles['simpleHeader--fixed'] : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className={styles.main}>
        <WContainer variant="lg">
          <div className={styles.mainContent}>
            <div className={styles.logo}>
              <Link legacyBehavior href="/">
                <a
                  onClick={() =>
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'header',
                      eventAction: 'logo',
                    })
                  }
                >
                  {site === ASSICURAZIONI ? (
                    <LogoAssicurazioni />
                  ) : site === VITA ? (
                    <LogoVita />
                  ) : (
                    <LogoAssicurazioni />
                  )}
                  {/*} : (
                    <LogoCa />
                  )}*/}
                </a>
              </Link>
            </div>
          </div>
        </WContainer>
      </div>
    </div>
  )
}

export default SimpleHeader
