import React, { useEffect } from 'react'
import styles from './QuotazioneGaranziaRcaBox.module.sass'
import { GaranziaQuotazioneAutoType } from '@next-nx/utils/types'
import {
  QuotazioneGaranziaAutoBlackBox,
  QuotazioneGaranziaAutoBox,
} from '@next-nx/shared-ui/molecules'
import { CODICE_GARANZIA_BLACKBOX } from '@next-nx/utils/fastquote'

interface Props {
  garanzia: GaranziaQuotazioneAutoType
  garanziaSelezionata?: string
  setGaranziaSelezionata?: (v: string) => void
  description?: string
  className?: string
  openChildren?: boolean
  checked?: boolean
  toggleChildren?: () => void
  blackboxSelezionata?: boolean
  toggleBlackbox?: () => void
  massimaleRcaValue?: string
  setMassimaleRcaValue: (v: string) => void
  handleGtm?: (
    type: 'add' | 'remove',
    garanzia: GaranziaQuotazioneAutoType,
    garanziaPadre?: string
  ) => void
}

const QuotazioneGaranziaRcaBox = (props: Props) => {
  const {
    garanzia,
    garanziaSelezionata = undefined,
    setGaranziaSelezionata = () => undefined,
    description = '',
    className = '',
    openChildren = false,
    checked = false,
    toggleChildren = () => undefined,
    blackboxSelezionata = false,
    toggleBlackbox = () => undefined,
    massimaleRcaValue = undefined,
    setMassimaleRcaValue = () => undefined,
    handleGtm = () => undefined,
  } = props

  useEffect(() => {
    const firstSottoGaranzia = garanzia?.children?.[0]
    if (firstSottoGaranzia) {
      setGaranziaSelezionata(firstSottoGaranzia.codice)
    }
  }, [])

  return (
    <QuotazioneGaranziaAutoBox
      checked
      garanzia={garanzia}
      openChildren={openChildren}
      toggleChildren={toggleChildren}
      massimaleRcaValue={massimaleRcaValue}
      setMassimaleRcaValue={setMassimaleRcaValue}
      extra={
        <QuotazioneGaranziaAutoBlackBox
          garanzia={garanzia.children?.find((x) => x.codice === CODICE_GARANZIA_BLACKBOX)}
          checked={blackboxSelezionata}
          onClick={() => toggleBlackbox()}
        />
      }
    >
      {openChildren && (
        <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
          {description && (
            <div className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />
          )}
          <div className={styles.list}>
            {garanzia.children
              ?.filter((y) => y.codice !== CODICE_GARANZIA_BLACKBOX)
              .map((y) => (
                <QuotazioneGaranziaAutoBox
                  key={y.pk}
                  garanzia={y}
                  showCheckbox={false}
                  variant="prestazione"
                  checked={garanziaSelezionata === y.codice}
                  onClick={() => {
                    setGaranziaSelezionata(y.codice)

                    const prevGaranziaSelezionata = garanzia.children?.find(
                      (x) => x.codice === garanziaSelezionata
                    )
                    prevGaranziaSelezionata &&
                      handleGtm('remove', prevGaranziaSelezionata, garanzia.titolo)
                    handleGtm('add', y, garanzia.titolo)
                  }}
                />
              ))}
          </div>
        </div>
      )}
    </QuotazioneGaranziaAutoBox>
  )
}

export default QuotazioneGaranziaRcaBox
