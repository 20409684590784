import React from 'react'
import styles from './TabTriggerCard.module.sass'


interface Props {
  icon?: React.ReactNode
  label?: string
  banner?: string
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
  className?: string
}

const TabTriggerCard = (props: Props) => {

  const { 
    icon = undefined,
    label = '',
    banner = '',
    onClick = () => undefined,
    className = '',
  } = props

  return (
    <div onClick={onClick} className={`${styles.tabTriggerCard} ${className ? ` ${className}` : ''}`}>
      {!!icon && <span className={styles.icon}>{icon}</span>}
      {!!label && <span className={styles.label}>{label}</span>}
      {!!banner && <span className={styles.banner}>{banner}</span>}
    </div>
  )
}

export default TabTriggerCard
