import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { Button, Modal } from '@next-nx/shared-ui/atoms'
import { QuotazionePacchettoAutoSimpleBox } from '@next-nx/shared-ui/molecules'
import styles from './QuotazioneAutoRimozioneGaranziaModal.module.sass'
import {
  GaranziaQuotazioneAutoType,
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoType,
} from '@next-nx/utils/types'

interface Props {
  pacchetto: PacchettoGaranziaQuotazioneAutoType
  garanzia: GaranziaQuotazioneAutoType
  quotazione: QuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  handleGaranzieAndPacchetti: (
    variant: 'pacchetto' | 'garanzia',
    action: 'add' | 'remove' | 'substitute',
    pacchettiCodici: string[],
    garanzieCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto?: boolean,
    lazy?: boolean,
    garanzieSostituite?: string[]
  ) => string[] | undefined
  open: boolean
  setOpen: (v: boolean) => void
  handleGtm: (
    v:
      | 'no_grazie'
      | 'aggiungi_garanzie'
      | 'chiudi_popup'
      | 'rimuovi_garanzie'
      | 'sostituisci_garanzie'
      | 'annulla_rimozione'
  ) => void
  className?: string
}

const QuotazioneAutoRimozioneGaranziaModal = (props: Props) => {
  const {
    pacchetto,
    garanzia,
    quotazione,
    garanzie,
    garanzieSelezionate,
    handleGaranzieAndPacchetti = () => undefined,
    open = false,
    setOpen = () => undefined,
    handleGtm = () => undefined,
    className = '',
  } = props

  const t = useTrans()

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      closeButton
      onCloseClick={() => {
        handleGtm('chiudi_popup')
      }}
      className={`${styles.QuotazioneAutoRimozioneGaranziaModal}
      ${className ? ` ${className}` : ''}`}
      buttons={
        <>
          <Button
            label={t('Annulla')}
            variant="ghost"
            onClick={() => {
              setOpen(false)
              handleGtm('annulla_rimozione')
            }}
          />
          <Button
            label={t('Rimuovi la garanzia e lo sconto')}
            onClick={() => {
              const response = handleGaranzieAndPacchetti(
                'garanzia',
                'remove',
                [],
                [garanzia.codice],
                garanzieSelezionate,
                true,
                true
              )

              if (response) {
                handleGtm('rimuovi_garanzie')
                setOpen(false)
              }
            }}
          />
        </>
      }
    >
      <div className={styles.content}>
        <p
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t(
              'Sei sicuro di voler <strong>rimuovere la garanzia</strong> <strong>{0}</strong>?',
              [garanzia.titolo || '']
            ),
          }}
        />
        <div className={styles.description}>
          Rimuovendo la garanzia {garanzia.titolo} perderai lo sconto collegato al pacchetto{' '}
          <span>{pacchetto.titolo}</span>.
        </div>
        <div className={styles.list}>
          <QuotazionePacchettoAutoSimpleBox
            quotazione={quotazione}
            pacchetto={pacchetto}
            garanzie={garanzie}
            garanzieSelezionate={garanzieSelezionate}
            showScontoDescription
          />
        </div>
      </div>
    </Modal>
  )
}

export default QuotazioneAutoRimozioneGaranziaModal
