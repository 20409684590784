import React, { useEffect, useContext } from 'react'
import Link from 'next/link'
import { useTrans, useSite } from '@next-nx/hooks'
import { ChatBotContext } from 'libs/shared-ui/contexts/chatBotContext'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'
import { Close, LogoAssicurazioni, LogoVita } from '@next-nx/shared-ui/icons'
import styles from './ChatBotOverlay.module.sass'

interface Props {}

const ChatBotOverlay = (props: Props) => {
  const {} = props

  const t = useTrans()
  const site = useSite()

  const chatbotCtx = useContext(ChatBotContext)

  useEffect(() => {
    if (chatbotCtx.chatBotVisible) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [chatbotCtx.chatBotVisible])

  return (
    <div
      className={`${styles.chatBotOverlay} ${
        chatbotCtx.chatBotVisible ? styles['chatBotOverlay--visible'] : ''
      }`}
    >
      <div className={styles.head}>
        <div className={styles.headContent}>
          <div className={styles.logo}>
            <Link legacyBehavior href="/">
              <a>{site === ASSICURAZIONI ? <LogoAssicurazioni /> : <LogoVita />}</a>
            </Link>
          </div>
          {!!chatbotCtx.setChatBotVisible && (
            <button
              type="button"
              aria-label={t('Chiudi')}
              onClick={() => chatbotCtx.setChatBotVisible(false)}
              className={styles.close}
            >
              <Close />
            </button>
          )}
        </div>
      </div>
      <div id="xenioowebchat" className={styles.content} />
    </div>
  )
}

export default ChatBotOverlay
