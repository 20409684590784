import React from 'react'
import { WContainer, Breadcrumbs, Button } from '@next-nx/shared-ui/atoms'
import { Page, ProdottiSection } from '@next-nx/shared-ui/organisms'
import styles from './PreventivatoreThankYouTemplate.module.sass'
import { useTrans } from '@next-nx/hooks'


interface Props {
    pagina?: any
    prodotto?: any
}
  
const PreventivatoreThankYouTemplate = (props: Props) => {

    const { 
        pagina = null,
        prodotto = null,
    } = props

    const t = useTrans()

    return (
      <Page pagina={pagina} item={{}}>
        <div className={`${styles.headBg}`}>
          <WContainer>
            <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
          </WContainer>
        </div>
        <div className="bg-white">
          <WContainer>
            <div
              className={styles.title}
              dangerouslySetInnerHTML={{
                __html: pagina?.nome || '',
              }}
            />
            <div
              className={styles.descrizione}
              dangerouslySetInnerHTML={{
                __html: pagina?.descrizione || '',
              }}
            />
          </WContainer>
        </div>
        {!!prodotto.prodottiCorrelati && prodotto.prodottiCorrelati.edges.length > 0 && (
        <div className={`${styles.correlati} bg-grey`}>
          <WContainer>
            <ProdottiSection 
              item={{
                description: t('Scopri gli altri <strong>nostri prodotti</strong>')
              }} 
              prodotti={
                prodotto.prodottiCorrelati && prodotto.prodottiCorrelati.edges.length
                  ? prodotto.prodottiCorrelati.edges.map((p) => {
                      return p.node
                    })
                  : []
              }
            />
          </WContainer>
        </div>
        )}
        <div className={`${styles.homeBtnContainer} bg-grey`}>
            <Button label={t("Torna alla home")} href="/" />
        </div>
      </Page>
    );
}

export default PreventivatoreThankYouTemplate