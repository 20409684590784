import { Button } from '@next-nx/shared-ui/atoms'
import { MenuType } from '@next-nx/utils/types'
import React from 'react'
import styles from './OutlineTextCtaBox.module.sass'
import { sendGTMData } from '../../../utils/gtm'


interface Props {
  item: MenuType
  className?: string
  onClick?: () => void
  onChildrenClick?: {
    pk: number
    onClick?: () => void
  }[]
}

const OutlineTextCtaBox = (props: Props) => {

  const {
    item = undefined,
    className = '',
    onClick = () => undefined,
    onChildrenClick = [],
  } = props;

  return item ? (
    <div className={`${styles.outlineTextCtaBox} ${className ? ` ${className}` : ''}`}>
      <div className={styles.content}>
        {!!item.name && <p className={styles.title}>{item.name}</p>}
        {!!item.description && (
          <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />
        )}
      </div>
      {!!item.ctaLabel && !!item.url ? (
        <div className={styles.ctaBox}>
          <Button label={item.ctaLabel} variant="secondary" href={item.url} onClick={onClick} />
        </div>
      ) : (
        !!item.children && item.children?.edges.length > 0 && (
          <div className={styles.ctaBox}>
            {item.children.edges.map((child) => (
              <Button
                key={child.node.pk}
                label={child.node.ctaLabel}
                newPage={child.node.blank}
                variant="secondary"
                href={child.node.url}
                onClick={
                  onChildrenClick.find((i) => i.pk === child.node.pk)
                    ?.onClick || onClick
                }
              />
            ))}
          </div>
        )
      )}
    </div>
  ) : <></>
}

export default OutlineTextCtaBox
