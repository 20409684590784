import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { dateFormat, priceFormat } from '@next-nx/utils/safe'
import { Calendario, ChevronDown, Info, Profilo, Casa } from '@next-nx/shared-ui/icons'
import { Button, Tooltip } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneHeadBox.module.sass'
import { QuotazioneType } from '@next-nx/utils/types'

interface Props {
  quotazione: QuotazioneType
  onClick?: () => void
  className?: string
}

const QuotazioneHeadBox = (props: Props) => {
  const { quotazione = undefined, onClick = () => undefined, className = '' } = props

  const t = useTrans()

  return quotazione ? (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      <div className={`${styles.item} ${styles['item--user']}`}>
        <span className={styles.itemIcon}>
          <Profilo />
        </span>
        <div>
          <p className={styles.itemTitle}>{t('Email')}</p>
          <span className={styles.itemLabel}>
            {/* TODO: Non abbiamo a disposizione nome e cognome dell'utente in questo punto */}
            {/* {quotazione.nome} {quotazione.cognome} */}
            {quotazione.email}
          </span>
        </div>
      </div>
      <div className={`${styles.item} ${styles['item--date']}`}>
        <span className={styles.itemIcon}>
          <Calendario />
        </span>
        <div>
          <p className={styles.itemTitle}>{t('Data inizio copertura')}</p>
          <span className={styles.itemLabel}>{dateFormat(quotazione.dataDecorrenza)}</span>
        </div>
        {/* <span className={styles.itemHelperContainer}>
          <Tooltip id="quotazioneheadbox" label={t("Data di inizio polizza")}>
            <span className={styles.itemHelper}>
              <Info />
            </span>
          </Tooltip>
        </span> */}
      </div>
      <div className={`${styles.item} ${styles['item--contenuto']}`}>
        <span className={styles.itemIcon}>
          <Casa />
        </span>
        <div>
          <p className={styles.itemTitle}>{t('Valore del contenuto')}</p>
          <span className={styles.itemLabel}>{priceFormat(parseInt(quotazione.valArredamento))}</span>
        </div>
      </div>
      <div className={styles.ctaBox}>
        <Button
          label={t('Visualizza tutti i dati')}
          rightIcon={<ChevronDown />}
          variant="ghost"
          onClick={onClick}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default QuotazioneHeadBox
