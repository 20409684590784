import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { LineaType } from '@next-nx/utils/types'
import { useTrans } from '@next-nx/hooks'
import { SimpleMessage, FormError } from '@next-nx/shared-ui/atoms'
import { LineaCheckbox } from '@next-nx/shared-ui/molecules'
import styles from './PreventivatoreLinee.module.sass'


interface Props {
  linee: LineaType[]
  selectedLinee: number[]
  setSelectedLinee: (v: number[]) => void
  sconto?: string
  collapsable?: boolean
  productPage?: boolean
  errorMessage?: string | string[]
  className?: string
}

const PreventivatoreLinee = (props: Props) => {

  const { 
    linee = [],
    selectedLinee = [],
    setSelectedLinee = () => undefined,
    sconto = '',
    collapsable = false,
    productPage = false,
    errorMessage = undefined,
    className = '',
  } = props

  const t = useTrans()

  return linee && linee.length > 0 ? (
    <div className={`${styles.preventivatoreLinee} ${
      productPage ? styles['preventivatoreLinee--productPage'] : ''} ${
      className ? ` ${className}` : ''}`}
    >
      <p
        className={styles.title}
        dangerouslySetInnerHTML={{
          __html:
            linee.length === 1
              ? t('Seleziona la <strong>linea</strong> a cui sei interessato')
              : t('Seleziona le <strong>linee</strong> a cui sei interessato'),
        }}
      />
      <div className={`${styles.linee} ${collapsable ? styles['linee--collapsable'] : ''}`}>
        {/* In scheda prodotto nella versione desktop non c'è lo slider */}
        {productPage && (
          <div className={styles.list}>
            {linee.map(linea => (
              <div className={styles.listItem} key={linea.pk}>
                <LineaCheckbox 
                  linea={linea} 
                  selected={selectedLinee.indexOf(linea.pk) > -1} 
                  setSelected={(v) => setSelectedLinee(v ? selectedLinee.concat(linea.pk) : selectedLinee.filter(x => x !== linea.pk))} 
                  collapsable={collapsable}
                />
              </div>
            ))}
          </div>
        )}
        <div className={styles.slider}>
          <Swiper 
            spaceBetween={16} 
            slidesPerView={linee.length > 1 ? 1.2 : 1} 
            pagination={{ clickable: true }}
            watchOverflow
            breakpoints={{
              768: {
                slidesPerView: linee.length > 2 ? 2.5 : linee.length
              },
              992: {
                slidesPerView: linee.length > 3 ? 3 : linee.length
              }
            }}
          >
            {linee.map(linea => (
              <SwiperSlide key={linea.pk}>
                <div className={styles.sliderItem}>
                  <LineaCheckbox 
                    linea={linea} 
                    selected={selectedLinee.indexOf(linea.pk) > -1} 
                    setSelected={(v) => setSelectedLinee(v ? selectedLinee.concat(linea.pk) : selectedLinee.filter(x => x !== linea.pk))} 
                    collapsable={collapsable}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {errorMessage && <div className={styles.errorWrap}><FormError message={errorMessage} visible /></div>}
      {sconto && <div className={styles.discount}><SimpleMessage text={sconto} /></div>}
    </div>
  ) : <></>
}

export default PreventivatoreLinee
