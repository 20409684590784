import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IntroTitle, WContainer } from '@next-nx/shared-ui/atoms'
import { UseCaseCard } from '@next-nx/shared-ui/molecules'
import { ArrowLeft, ArrowRight } from '@next-nx/shared-ui/icons'
import styles from './TestimonianzeSection.module.sass'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  item: {
    name?: string
    description?: string
    children?: {
      edges: {
        node: {
          pk: number
          name?: string
          description?: string
          ctaLabel?: string
          url?: string
          mainImage?: string
        }
      }[]
    }
  }
  className?: string
}

const TestimonianzeSection = (props: Props) => {

  const {
    item = undefined,
    className = '',
  } = props

  const swiperSettings = {
    slidesPerView: 1.25,
    spaceBetween: 16,
    pagination: { clickable: true },
    navigation: {
      nextEl: '.testimonianze-button-next',
      prevEl: '.testimonianze-button-prev'
    },
    watchOverflow: true,
    className: 'swiper-container--pagination',
    breakpoints: {
      1100: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2.5,
        centeredSlides: false,
      },
      576: {
        slidesPerView: 1.5
      },
      0: {
        centeredSlides: true,
      }
    }
  }

  return item ? (
    <div className={`${styles.testimonianzeSection} ${className}`}>
      <WContainer variant="md">
        {!!item.name && <IntroTitle label={item.name} className={styles.intro} />}
        {!!item.description && <div className={styles.title} dangerouslySetInnerHTML={{ __html: item.description }} />}
        {!!item.children?.edges.length && (
            <div className={styles.list}>
              <button type="button" className="custom-button-prev testimonianze-button-prev" onClick={() => sendGTMData({
              event: "GAevent",
              eventCategory: "box_info",
              eventAction: "approfondisci",
              eventLabel: "prev"
            })}>
              <ArrowLeft />
            </button>
            <button type="button" className="custom-button-next testimonianze-button-next" onClick={() => sendGTMData({
              event: "GAevent",
              eventCategory: "box_info",
              eventAction: "approfondisci",
              eventLabel: "next"
            })}>
              <ArrowRight />
            </button>
            <Swiper {...swiperSettings}>
              {item.children.edges.slice(0, 3).map((child) => (
                <SwiperSlide key={child.node.pk}>
                  <div className={styles.item}>
                    <UseCaseCard item={child.node} onClick={() => sendGTMData({
                      event: "GAevent",
                      eventCategory: 'box_info',
                      eventAction: 'approfondisci',
                      eventLabel: child?.node?.name?.toGTMFormat() || ''
                    })} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </WContainer>
    </div>
  ) : <></>
}

export default TestimonianzeSection
