import React from 'react'

function Undo({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--undo${className ? ' ' + className : ''}`}
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M25 16c-5.29 0-10.11 1.97-13.8 5.2l-7.2-7.2v18h18l-7.23-7.23c2.77-2.33 6.32-3.77 10.23-3.77 7.09 0 13.09 4.61 15.19 11l4.73-1.56c-2.75-8.38-10.62-14.44-19.92-14.44z" />
    </svg>
  )
}

export default Undo
