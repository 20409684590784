import React from 'react'
import { MenuType } from '@next-nx/utils/types'
import { PdfLink, WContainer, WImage } from '@next-nx/shared-ui/atoms'
import styles from './ContentBox.module.sass'
import { useIsMobile } from '@next-nx/hooks'


interface Props {
  item?: MenuType
  titlePos?: 'left' | 'right'
  className?: string
}

const ContentBox = (props: Props) => {

  const { 
    item = null,
    titlePos = 'left',
    className = '',
  } = props

  const isMobile = useIsMobile()

  return item ? (
    <div className={`${styles.contentBox} ${styles[`contentBox--title-${titlePos}`]} ${className ? ` ${className}` : ''}`}>
      <WContainer>
        <div className={styles.layout}>
          <div className={`${styles.titleBox} ${item.mainImage ? styles["titleBox--image"] : ''}`}>
            {!!item.name && <p className={styles.title} dangerouslySetInnerHTML={{ __html: item.name }} />}
          </div>
          <div className={styles.main}>
            {!!item.description && <div className={`${styles.content} custom-content`} dangerouslySetInnerHTML={{ __html: item.description }} />}
            {!!item.children && item.children.edges.length > 0 && (
              <div className={styles.links}>
                {item.children.edges.map((x) => (
                  <PdfLink key={x.node.pk} name={x.node.name} url={x.node.url} />
                ))}
              </div>
            )}
          </div>
          {!!item.mainImage && (
            <div className={styles.imageCompositionBox}>
              <div className={styles.imageBox}>
                <WImage 
                  src={isMobile ? item.mainImageResponsive : item.mainImage}
                  maxWidth={610}
                  maxHeight={367}
                  layout="intrinsic"
                  objectFit="cover"
                  key={`content-box-${item.pk}-main-image${isMobile ? '-mobile' : ''}`}
                />
              </div>
            </div>
          )}
        </div>
      </WContainer>
    </div>
  ) : <></>
}

export default ContentBox
