import React from 'react'
import { useNode, useEditor } from '@craftjs/core'
import { ButtonFloatingSettings, ButtonSettings } from './ButtonSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'

import { Button as OriginalButton } from '@next-nx/shared-ui/atoms'

export const defaultProps = {
  url: '',
  openNewTab: false,
  variant: 'primary',
  size: 'md',
};

export const dynamicProps = ['url'];

export const Button = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    };
    const { url, openNewTab, variant, size } =
      blockProps;

    const { children } = props;

    const _ = props.renderValue;

    const {
      id,
      connectors: { connect },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode((state) => ({
      selected: state.events.selected,
      dragged: state.events.dragged,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }));

    const { enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }));

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-button ' + props.className}
      >
        {enabled ? (
          <OriginalButton
            variant={variant}
            label={children.props.children[0]}
            size={size}
          />
        ) : (
          <OriginalButton
            href={_(url)}
            newPage={openNewTab}
            variant={variant}
            label={children.props.children[0]}
            size={size}
          />
        )}
      </div>
    );
  },
  defaultProps,
  dynamicProps
);

Button.craft = withCraft({
  name: 'Button',
  defaultProps: defaultProps,
  settings: ButtonSettings,
  floatingSettings: ButtonFloatingSettings,
});
