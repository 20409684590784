import React, { useState, useEffect, useContext } from 'react'
import { GlobalContext } from './globalContext'
import { api } from './api'

export const CustomBlocksContext = React.createContext()

const CustomBlocksContextProvider = ({ children }) => {
  const { lang, editMode } = useContext(GlobalContext)

  const [customBlocks, setCustomBlocks] = useState()
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (editMode) {
      setLoading(true)
      api
        .getCustomBlocks({ lang })
        .then((data) => {
          setCustomBlocks(data)
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          console.log(err)
        })
    }
  }, [editMode])

  const addCustomBlock = (name, json_content) => {
    return api
      .createCustomBlock({ lang, name, json_content })
      .then((data) => {
        setCustomBlocks([...customBlocks, data])
        return data
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  }

  const deleteCustomBlock = (name) => {
    const blockIndex = customBlocks.findIndex((block) => block.name === name)
    return api
      .deleteCustomBlock(customBlocks[blockIndex].id)
      .then((data) => {
        const newCustomBlocks = [...customBlocks]
        newCustomBlocks.splice(blockIndex, 1)
        setCustomBlocks(newCustomBlocks)
        return data
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  }

  return (
    <>
      {editMode ? (
        <CustomBlocksContext.Provider
          value={{ customBlocks, addCustomBlock, deleteCustomBlock, isLoading }}
        >
          {children}
        </CustomBlocksContext.Provider>
      ) : (
        children
      )}
    </>
  )
}

export default CustomBlocksContextProvider
