import React from 'react'

function Empty({ className }) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--strokes icon--empty${className ? ' ' + className : ''}`}
    >
      <path d="M13 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9z" fill="none" />
      <polyline points="13 2 13 9 20 9" fill="none" />
    </svg>
  )
}

export default Empty
