import React from 'react'

function Desktop({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--desktop${className ? ' ' + className : ''}`}
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M42 4h-36c-2.21 0-4 1.79-4 4v24c0 2.21 1.79 4 4 4h14v4h-4v4h16v-4h-4v-4h14c2.21 0 4-1.79 4-4v-24c0-2.21-1.79-4-4-4zm0 28h-36v-24h36v24z" />
    </svg>
  )
}

export default Desktop
