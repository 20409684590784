import dayjs from 'dayjs'
import { ProdottoType, GaranziaQuotazioneType, QuotazioneAutoType, PacchettoGaranziaQuotazioneAutoType, GaranziaQuotazioneAutoType } from './types'
import { priceFormat } from './safe'

export const isFastQuoteCasa = (p: ProdottoType) => p.slug?.toLowerCase() === 'protezione-casa-piu'

export const TIPO_GARANZIA_OBBLIGATORIA = 'OBB'
export const CODICE_GARANZIA_HOMEBOX = 'HMBX'
export const CODICE_GARANZIA_HOMEBOX_SAFETY = 'HMBXSFT'
export const CODICE_GARANZIA_HOMEBOX_SECURITY = 'HMBXSEC'
export const CODICE_GARANZIA_PET = 'PET'
export const CODICE_GARANZIA_FURTO = 'VOL'
export const CODICE_GARANZIA_VALNUOVO = 'VALNUOVO'
export const CODICE_GARANZIA_CATASTROFI_NATURALI = 'CATNAT'
export const CODICE_GARANZIA_PROTEZIONE_BASE = '_PB'
export const TIPO_ANIMALE_CANE = 'CANE'
export const TIPO_ANIMALE_GATTO = 'GATTO'

export const getPremioLordoAnnuoTotale = (garanzia: GaranziaQuotazioneType | undefined) => {
  return garanzia?.premioLordoAnnuoTotale || garanzia?.premioLordoAnnuo
}

export const validateDataDecorrenza = (value: string | undefined) => {
  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 359)
  return value ? !dayjs(value).isBefore(new Date(), "day") || dayjs(value).isAfter(maxDate, "day") : false
}


export const isFastQuoteAuto = (p: ProdottoType) => p.slug?.toLowerCase() === 'protezione-guida'

export const KEY_PREMIO_DEFAULT = "NOBBP000"
export const CODICE_PACCHETTO_VEICOLO = "Veicolo"
export const CODICE_PACCHETTO_PERSONA = "Persona"
export const CODICE_PACCHETTO_EVENTI = "Eventi"
export const CODICI_PACCHETTI = [CODICE_PACCHETTO_VEICOLO, CODICE_PACCHETTO_PERSONA, CODICE_PACCHETTO_EVENTI]
export const CODICE_GARANZIA_RCA = "_RCA"
export const CODICE_GARANZIA_BLACKBOX = "_BB"
export const CODICE_GARANZIA_CRISTALLI = "BDG"
export const STATO_GARANZIA_OBBLIGATORIA = 'OBB'
export const STATO_GARANZIA_SEL = 'SEL'
export const STATO_GARANZIA_DESEL = 'DESEL'
export const STATO_GARANZIA_ESCL = 'ESCL'

export const MASSIMALI_CRISTALLI = [
  {label: priceFormat(1000, true), value: "1000"},
  {label: priceFormat(2000, true), value: "2000"},
]

export const MASSIMALI_RCA = [
  // {label: priceFormat(7750000, true), value: "7750000"},
  // {label: priceFormat(12000000, true), value: "12000000"},
  // {label: priceFormat(16000000, true), value: "16000000"},
  // {label: priceFormat(20000000, true), value: "20000000"},
  {label: "massimale_rca_7750000", value: "7750000"},
  {label: "massimale_rca_12000000", value: "12000000"},
  {label: "massimale_rca_16000000", value: "16000000"},
  {label: "massimale_rca_20000000", value: "20000000"},
]

export const getPacchetto = (quotazione: QuotazioneAutoType, codice: string) => {
  return quotazione?.pacchetti.find(p => p.codice === codice)
}

export const getScontoPacchetto = (quotazione: QuotazioneAutoType, pacchetto: string) => {
  return quotazione?.[`scontoPacchetto${pacchetto}`] || 0
}

/**
 * Ritorna la keyPremio a seconda che siano selezionati o no i pacchetti e la blackbox
 */
export const getKeyPremioFromSelected = (blackBox = false, pacchettoVeicolo = false, pacchettoPersona = false, pacchettoEventi = false) => {
  let keyPremio = "NOBBP000"

  if (blackBox) {
    keyPremio = keyPremio.replace("NOBB", "SIBB")
  }

  if (pacchettoVeicolo) {
    keyPremio = keyPremio.replaceAt(5, "1")
  }

  if (pacchettoPersona) {
    keyPremio = keyPremio.replaceAt(6, "1")
  }

  if (pacchettoEventi) {
    keyPremio = keyPremio.replaceAt(7, "1")
  }

  return keyPremio
}

/**
 * Ritorna se i pacchetti e la blackbox sono selezionati a seconda della keyPremio passata
 * (Opposto di getKeyPremioFromSelected)
 */
export const getSelectedFromKeyPremio = (keyPremio: string) => {
  let blackBox = false, pacchettoVeicolo = false, pacchettoPersona = false, pacchettoEventi = false
  if (keyPremio[0] == "S") {
    blackBox = true
  }
  if (keyPremio[5] == "1") {
    pacchettoVeicolo = true
  }
  if (keyPremio[6] == "1") {
    pacchettoPersona = true
  }
  if (keyPremio[7] == "1") {
    pacchettoEventi = true
  }
  return {
    blackBox,
    pacchettoVeicolo,
    pacchettoPersona,
    pacchettoEventi
  }
}

export const isPacchettoSelectable = (pacchettoCodice: string | undefined, garanzie: GaranziaQuotazioneAutoType[]) => {
  if (!pacchettoCodice) return false

  return garanzie
    .filter((x) => x.pacchetto?.codice === pacchettoCodice)
    .every((x) => x.statoIniziale !== STATO_GARANZIA_ESCL)
}

export const getPremioLordoAnnuoTotalePacchetto = (pacchetto: PacchettoGaranziaQuotazioneAutoType) => {
  return pacchetto?.premioLordoAnnuoTotale || pacchetto?.premioLordoAnnuo
}

export const getPremioAutoFast = (mutation, values, quotazione) => {
  return mutation({
    variables: {
      input: {
        siteId: values.siteId,
        targa: values.targa || quotazione.targa,
        dataNascita: values.dataNascita || quotazione.dataNascita,
        email: values.email || quotazione.email,
        consensoTrattamento: values.consensoTrattamento || quotazione.consensoTrattamento,
        massimaleRca: values.massimaleRca || quotazione.massimaleRca,
        massimaleCristalli: values.massimaleCristalli || quotazione.massimaleCristalli,
        dataDecorrenza: values.dataDecorrenza || quotazione.dataDecorrenza,
        cap: values.cap || quotazione.cap,
        allestimentoCodice: values.allestimentoCodice || quotazione.allestimentoCodice,
        sistemaAdas: values.sistemaAdas || quotazione.sistemaAdas,
        antifurto: values.antifurto || quotazione.antifurto,
        tipoGuida: values.tipoGuida || quotazione.tipoGuida,
      },
    },
  })

}