import React from 'react'
import Link from 'next/link'
import { Chip, WImage } from '@next-nx/shared-ui/atoms'
import styles from './ArticoloCard.module.sass'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  articolo: {
    categoria?: {
      title?: string
    }
    publicationDate?: string
    title?: string
    url: string
    mainImage?: string
  }
  className?: string
  onClick?: () => void
}

const ArticoloCard = (props: Props) => {
  const { articolo = undefined, className = '', onClick = () => undefined } = props

  return articolo ? (
    <Link legacyBehavior href={articolo.url}>
      <a className={`${styles.articoloCard} ${className}`} onClick={onClick}>
        <div className={styles.imageBox}>
          <WImage
            src={articolo.mainImage}
            maxWidth={350}
            maxHeight={124}
            layout="intrinsic"
            objectFit="cover"
          />
        </div>
        <div className={styles.content}>
          {!!articolo.categoria?.title && (
            <div className={styles.chips}>
              <Chip label={articolo.categoria.title} variant="light" size="sm" weight="regular" />
            </div>
          )}
          {!!articolo.publicationDate && <p className={styles.date}>{articolo.publicationDate}</p>}
          {!!articolo.title && <p className={styles.title}>{articolo.title}</p>}
        </div>
      </a>
    </Link>
  ) : (
    <></>
  )
}

export default ArticoloCard
