import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps, providers } from './index'
import { getAllPaths, getDynamicValue, setDynamicValue } from '../../utils/dynamic'

export const VideoSettings = () => {
  const { customs, props } = useNode((node) => ({
    customs: node.data.custom,
    props: node.data.props,
  }))

  return (
    <>
      <ToolbarSection title="Settings">
        <ToolbarSection title="Con url">
          <ToolbarItem
            propKey="block"
            propSubKey="src"
            type="text"
            label="URL"
            description="Paste your url"
            dynamic={dynamicProps.includes('src') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['src']}
          />
        </ToolbarSection>
        <ToolbarSection
          title="Con provider"
          description="*Nota: per utilizzare il provider è necessario lasciare vuoto il campo url"
        >
          <ToolbarItem
            propKey="block"
            propSubKey="provider"
            type="select"
            label="Provider"
            values={Object.keys(providers)}
            dynamic={dynamicProps.includes('provider') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['provider']}
          />
          <ToolbarItem
            propKey="block"
            propSubKey="videoId"
            type="text"
            label="Video ID"
            dynamic={dynamicProps.includes('videoId') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['videoId']}
          />
          <ToolbarItem
            propKey="block"
            propSubKey="autoplay"
            type="switch"
            label="Autoplay"
            dynamic={dynamicProps.includes('autoplay') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['autoplay']}
          />
          <ToolbarItem
            propKey="block"
            propSubKey="loop"
            type="switch"
            label="Loop"
            dynamic={dynamicProps.includes('loop') && customs.dynamicData}
            dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
            defaultValue={defaultProps['loop']}
          />
          {props.block.provider !== 'vimeo' && (
            <>
              <ToolbarItem
                propKey="block"
                propSubKey="controls"
                type="switch"
                label="Controls"
                dynamic={dynamicProps.includes('controls') && customs.dynamicData}
                dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
                defaultValue={defaultProps['controls']}
              />
              <ToolbarItem
                propKey="block"
                propSubKey="related"
                type="switch"
                label="Related"
                dynamic={dynamicProps.includes('related') && customs.dynamicData}
                dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
                defaultValue={defaultProps['related']}
              />
              <ToolbarItem
                propKey="block"
                propSubKey="modest"
                type="switch"
                label="Modest"
                dynamic={dynamicProps.includes('modest') && customs.dynamicData}
                dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
                defaultValue={defaultProps['modest']}
              />
            </>
          )}
        </ToolbarSection>
      </ToolbarSection>
    </>
  )
}
