import React from 'react'
import {
  WContainer,
  Breadcrumbs,
  MainDescription,
  MainTitle,
  WImage,
} from '@next-nx/shared-ui/atoms'
import { Page } from '@next-nx/shared-ui/organisms'
import { PlaceholderReact } from '@next-nx/pagebuilder'
import styles from './CookiePolicyTemplate.module.sass'
import { useIsMobile } from '@next-nx/hooks'

interface Props {
  pagina?: any
}

const CookiePolicyTemplate = (props: Props) => {
  const { pagina = null } = props

  const isMobile = useIsMobile()

  return (
    <Page pagina={pagina} item={{}}>
      <div className={`${styles.headBg} bg-grey`}>
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
          <div className={styles.headLayout}>
            <div
              className={`${styles.headContent} ${
                !pagina?.mainImage ? styles['headContent--full'] : ''
              }`}
            >
              <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
              <MainDescription html={pagina?.descrizione} className={styles.description} />
            </div>
            {pagina?.mainImage && (
              <div className={styles.headImage}>
                <WImage
                  src={isMobile ? pagina?.previewImage : pagina?.mainImage}
                  maxWidth={536}
                  maxHeight={350}
                  layout="intrinsic"
                  objectFit="cover"
                  priority={true}
                  loading="eager"
                  key={`${pagina?.pk}-main-image${isMobile ? '-mobile' : ''}`}
                />
              </div>
            )}
          </div>
        </WContainer>
      </div>
      <WContainer variant="xs">
        <div id="cookiebot_container" className={styles.cookiebotContainer}></div>
      </WContainer>
    </Page>
  )
}

export default CookiePolicyTemplate
