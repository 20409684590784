import { useEditor, ROOT_NODE } from '@craftjs/core'
import { isDisabled, isDeletable } from '../../../../utils/node'
import { useEffect, useState } from 'react'
import {
  ChevronDown,
  ChevronRight,
  VisibilityOn,
  VisibilityOff,
} from '../../../../pb-components/icons'
import GenericBlock from '../../../../pb-components/icons/GenericBlock'
import { conf } from '../../../../editor/Viewport/Sidebar/Toolbox/conf'
const getBlockConfByName = (name) => {
  for (let key in conf) {
    let blockConf = Object.values(conf[key].blocks).find((el) => el.name === name)
    if (blockConf) return blockConf
  }
  return null
}

const TreeNode = ({ id, level, open: defaultOpen }) => {
  const { selected, query, actions, nodes, connectors } = useEditor((state) => ({
    selected: [...state.events.selected]?.[0],
    nodes: state.nodes,
  }))

  const [open, setOpen] = useState(defaultOpen)
  const [nodeDetail, setNodeDetail] = useState()

  useEffect(() => {
    if (id && !isDisabled(id, query)) {
      setNodeDetail(query.node(id).get())
    }
  }, [id, nodes])

  const onSelectNode = () => {
    actions.selectNode(id)
    document.getElementsByClassName(id)[0].scrollIntoView({ behaviour: 'smooth' })
  }

  const onOpenTree = (event) => {
    event.stopPropagation()
    setOpen(!open)
  }

  const onHide = () => {
    const hidden = nodeDetail.data.hidden
    actions.history.ignore().setHidden(id, !hidden)
  }

  const blockConf = nodeDetail && getBlockConfByName(nodeDetail.data.name)

  return (
    <>
      {nodeDetail && (
        <div
          className="tree-node-container"
          ref={(ref) => connectors.hover(ref, id)}
          onDoubleClick={(e) => onOpenTree(e)}
        >
          <div
            className={`tree-node${nodeDetail.data.nodes.length === 0 ? ' tree-node--leaf' : ''} ${
              selected === id ? 'tree-node--active' : ''
            }`}
          >
            {nodeDetail.data.nodes.length !== 0 && (
              <div className="tree-node__dropdown-trigger" onClick={(e) => onOpenTree(e)}>
                {open ? <ChevronDown /> : <ChevronRight />}
              </div>
            )}
            <div className="tree-node__selection-scope" onClick={() => onSelectNode()}>
              {blockConf?.icon ? blockConf?.icon : <GenericBlock />}
              <span>
                {nodeDetail.data.displayName}{' '}
                {nodeDetail.data.nodes.length !== 0 && `(${nodeDetail.data.nodes.length})`}
              </span>
            </div>
            <div className="tree-node__actions">
              {isDeletable(id, query) && (
                <button type="button" onClick={() => onHide()}>
                  {nodeDetail.data.hidden ? <VisibilityOff /> : <VisibilityOn />}
                </button>
              )}
            </div>
          </div>
          {open &&
            nodeDetail.data.nodes.map((nodeId) => {
              return <TreeNode id={nodeId} key={nodeId} level={level + 1} open={defaultOpen} />
            })}
        </div>
      )}
    </>
  )
}

export const Navigator = ({ open }) => {
  const { selected, nodes, query, actions } = useEditor((state) => ({
    selected: [...state.events.selected]?.[0],
    nodes: state.nodes,
  }))

  return (
    <div className="navigator">
      <TreeNode id={ROOT_NODE} level={0} open={open} />
    </div>
  )
}
