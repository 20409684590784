import React, { useState } from 'react'
import {
  Close,
  LogoAssicurazioni,
  LogoCa,
  LogoVita,
  MenuRight,
  Profile,
  Search,
} from '@next-nx/shared-ui/icons'
import { Button, WContainer } from '@next-nx/shared-ui/atoms'
import { SearchOverlay, Nav, NavMobile } from '@next-nx/shared-ui/organisms'
import styles from './Header.module.sass'
import { useFindMenu, useSite, useTrans } from '@next-nx/hooks'
import Link from 'next/link'
import { ASSICURAZIONI, VITA } from 'libs/shared-ui/utils/settings'
import { sendGTMData } from '../../../utils/gtm'
import router from 'next/router'

interface Props {
  className?: string
}

const Header = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const site = useSite()

  const [searchOpen, setSearchOpen] = useState(false)
  const [navMobileOpen, setNavMobileOpen] = useState(false)

  const menuTop = useFindMenu('top_header')
  const menuAreaClienti = useFindMenu('header_area_clienti')

  return (
    <div className={`${styles.header} ${className ? ` ${className}` : ''}`}>
      <div className={styles.top}>
        <WContainer variant="lg">
          <div className={styles.topMenu}>
            {menuTop &&
              menuTop.children?.edges &&
              menuTop.children.edges
                .filter((el) => el && el.node && el.node.key != 'top_header_scopri_nuova_app')
                .map((item) => (
                  <a href={item.node.url} key={item.node.pk}>
                    {item.node.name}
                  </a>
                ))}
            {menuTop &&
              menuTop.children?.edges &&
              menuTop.children.edges
                .filter((el) => el && el.node && el.node.key == 'top_header_scopri_nuova_app')
                .map((item) => (
                  <>
                    <div className={styles.vl}></div>
                    <a
                      href={item.node.url}
                      key={item.node.pk}
                      dangerouslySetInnerHTML={{ __html: item.node.name || '' }}
                    />
                  </>
                ))}
          </div>
        </WContainer>
      </div>
      <div className={styles.main}>
        <WContainer variant="lg">
          <div className={styles.mainContent}>
            <div className={styles.logo}>
              <Link legacyBehavior href="/">
                <a
                  onClick={() =>
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'header',
                      eventAction: 'logo',
                    })
                  }
                >
                  {site === ASSICURAZIONI ? (
                    <LogoAssicurazioni />
                  ) : site === VITA ? (
                    <LogoVita />
                  ) : (
                    <LogoCa />
                  )}
                </a>
              </Link>
            </div>
            <div className={styles.navigation}>
              <Nav />
            </div>
            <div className={styles.search}>
              <Button
                label={t('Cerca')}
                leftIcon={<Search />}
                variant="ghost"
                onClick={() => setSearchOpen(!searchOpen)}
              />
            </div>
            {!!menuAreaClienti && (
              <div className={styles.areaClienti}>
                <Button
                  label={menuAreaClienti.ctaLabel}
                  href={menuAreaClienti.url}
                  external
                  newPage
                  onClick={() => {
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'header',
                      eventAction: 'area_clienti',
                    })
                  }}
                />
              </div>
            )}
            <div className={styles.mobileButtons}>
              {!!menuAreaClienti && (
                <a
                  href={menuAreaClienti.url}
                  className={styles.mobileButton}
                  style={navMobileOpen ? { visibility: 'hidden' } : {}}
                  onClick={() => {
                    sendGTMData({
                      event: 'GAevent',
                      eventCategory: 'header',
                      eventAction: 'area_clienti',
                    })
                  }}
                >
                  <Profile />
                </a>
              )}
              <button
                type="button"
                className={styles.mobileButton}
                onClick={() => setSearchOpen(!searchOpen)}
                style={navMobileOpen ? { visibility: 'hidden' } : {}}
              >
                <Search />
              </button>
              <button
                type="button"
                className={styles.mobileButton}
                onClick={() => setNavMobileOpen(!navMobileOpen)}
              >
                {navMobileOpen ? <Close /> : <MenuRight />}
              </button>
            </div>
          </div>
        </WContainer>
      </div>
      <SearchOverlay visible={searchOpen} setVisible={setSearchOpen} />
      <NavMobile open={navMobileOpen} setOpen={setNavMobileOpen} />
    </div>
  )
}

export default Header
