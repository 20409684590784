import { ROOT_NODE, useEditor, useNode } from '@craftjs/core'
import React, { useEffect } from 'react'
import { TextEditorSettings } from './TextEditorSettings'
import { withBlock } from '../withBlock'
import { getAncestors } from '../../utils/node'
import { withCraft } from '../withCraft'
import { SimpleTextEditor } from '../SimpleTextEditor'
import { RichTextEditor } from '../../plugins/RichTextEditor'

export const defaultProps = {
  text: '<p>Enter your text!</p>',
  noHtml: false,
}

export const dynamicProps = ['text']

export const TextEditor = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    }
    const { text, noHtml } = blockProps

    const _ = props.renderValue
    const __ = props.renderText

    const {
      id,
      connectors: { connect, drag },
      selected,
      hovered,
      actions: { setProp, setCustom },
      customs,
    } = useNode((state) => ({
      selected: state.events.selected,
      dragged: state.events.dragged,
      hovered: state.events.hovered,
      customs: state.data.custom,
    }))

    const { actions, query, enabled } = useEditor((state) => ({
      enabled: state.options.enabled,
    }))

    useEffect(() => {
      if (selected) {
        return
      }
      actions.history.ignore().setCustom(id, (customs) => {
        customs.editing = false
      })
    }, [selected])

    const enableEditable = () => {
      if (selected && (!props.conditions || !props.conditions.disabled)) {
        actions.history.ignore().setCustom(id, (customs) => {
          customs.editing = true
        })
      }
    }

    useEffect(() => {
      if (enabled) {
        const nodes = getAncestors(id, query)
        nodes.pop(ROOT_NODE)
        nodes.push(id)
        nodes.forEach((nodeId) => {
          const isDraggable = query.node(nodeId).isDraggable()
          if (isDraggable) {
            const element = document.getElementsByClassName(nodeId)[0]
            if (element) {
              if (customs.editing) {
                element.removeAttribute('draggable')
              } else {
                element.setAttribute('draggable', true)
              }
            }
          }
        })
      }
    }, [customs.editing])

    const className =
      'text-editor ' +
      (customs.editing ? '' : props.block?.className === 'quote__content' ? '' : 'ql-editor ') +
      (props.block?.className ? props.block.className + ' ' : '') +
      props.className

    return enabled ? (
      customs.editing ? (
        <div
          id={id}
          ref={(ref) => props.retRef(ref)}
          onDoubleClick={(e) => props.onDoubleClick(e, enableEditable)}
          className={className}
        >
          {noHtml ? (
            <SimpleTextEditor
              value={text}
              setValue={(value) => setProp((props) => (props.block.text = value), 500)}
            />
          ) : (
            <RichTextEditor
              value={text}
              setValue={(value) => setProp((props) => (props.block.text = value), 500)}
            />
          )}
        </div>
      ) : (
        <div
          id={id}
          ref={(ref) => props.retRef(ref)}
          onDoubleClick={(e) => props.onDoubleClick(e, enableEditable)}
          className={className}
          dangerouslySetInnerHTML={{ __html: __(text) }}
        />
      )
    ) : (
      <div id={id} className={className} dangerouslySetInnerHTML={{ __html: __(text) }} />
    )
  },
  defaultProps,
  dynamicProps
)

TextEditor.craft = withCraft({
  name: 'Text',
  defaultProps: defaultProps,
  settings: TextEditorSettings,
})
