import { monthYearFormat } from "./safe"
import { SearchType } from "./types"

export const MODELLI_SEARCH = ['prodotto','articolomagazine', "categoria", "faq", "fascicolo", "pagina"]

export const mappingRisultatiSearch = (risultatiSearch : SearchType) => {
  const prodotti = risultatiSearch.search.edges.filter((item) => item.node?.modelName === 'prodotto')
  const documenti = risultatiSearch.search.edges.filter((item) => item.node?.modelName === 'fascicolo')
  const faq = risultatiSearch.search.edges.filter((item) => item.node?.modelName === 'faq')
  const articoli = risultatiSearch.search.edges.filter((item) => item.node?.modelName === 'articolomagazine')
  const categorie = risultatiSearch.search.edges.filter((item) => item.node?.modelName === 'categoria')
  const pagine = risultatiSearch.search.edges.filter((item) => item.node?.modelName === 'pagina')
  
  return [
    ... prodotti.length ? [{ pk: 1, name: 'Prodotti', items: prodotti.map((item) => ({
      pk: item.node?.object?.pk, url: item.node?.object?.url, titolo: item.node?.object?.titolo, descrizione: item.node?.object?.descrizioneBreve, descrizioneBreve: item.node?.object?.descrizioneBreve, tags: item.node?.object?.tags, previewImage: item.node?.object?.previewImage
    }))}] : [],
    ... documenti.length ? [{ pk: 2, name: 'Documenti', items: documenti.map((item) => ({
      pk: item.node?.object?.pk, url: item.node?.object?.url, titolo: item.node?.object?.titolo + (item.node?.object?.edizione ? ` (${monthYearFormat(item.node?.object?.edizione)?.capitalize()})` : ''), descrizione: item.node?.object?.descrizione
    }))}] : [],
    ... articoli.length ? [{ pk: 3, name: 'Articoli', items: articoli.map((item) => ({
      pk: item.node?.object?.pk, url: item.node?.object?.url, titolo: item.node?.object?.title, descrizione: item.node?.object?.abstract
    }))}] : [],
    ... categorie.length ? [{ pk: 4, name: 'Categorie', items: categorie.map((item) => ({
      pk: item.node?.object?.pk, url: item.node?.object?.url, titolo: item.node?.object?.titolo, descrizione: item.node?.object?.descrizione
    }))}] : [],
    ... faq.length ? [{ pk: 5, name: 'Domande e risposte', items: faq.map((item) => ({
      pk: item.node?.object?.pk, url: item.node?.object?.url, titolo: item.node?.object?.domanda, descrizione: item.node?.object?.risposta
    }))}] : [],
    ... pagine.length ? [{ pk: 6, name: 'Pagine', items: pagine.map((item) => ({
      pk: item.node?.object?.pk, url: item.node?.object?.url, titolo: item.node?.object?.titolo, descrizione: item.node?.object?.descrizione
    }))}] : []
  ]
}

export const mappingRisultatiSearchOverlay = (searchData: SearchType) => {
  const prodotti = searchData.search.edges.filter((item) => item.node?.modelName === 'prodotto')
  const documenti = searchData.search.edges.filter((item) => item.node?.modelName === 'fascicolo')
  const altro = searchData.search.edges.filter((item) => item.node?.modelName !== 'prodotto' && item.node?.modelName !== 'fascicolo').map((item) => {
    if(item.node?.modelName === 'articolomagazine') return {pk: item.node?.object?.pk, url: item.node?.object?.url, text: item.node?.object?.title}
    else if(item.node?.modelName === 'categoria') return {pk: item.node?.object?.pk, url: item.node?.object?.url, text: item.node?.object?.titolo}
    else if(item.node?.modelName === 'faq') return {pk: item.node?.object?.pk, url: item.node?.object?.url, text: item.node?.object?.domanda}
    else if(item.node?.modelName === 'pagina') return {pk: item.node?.object?.pk, url: item.node?.object?.url, text: item.node?.object?.titolo}
    else return {}
  })
  return [
    ... prodotti.length ? [{ pk: 1, name: 'Prodotti', style: 'veicoli', items: prodotti.map((item) => ({
      pk: item.node?.object?.pk, url: item.node?.object?.url, text: item.node?.object?.titolo
    }))}] : [],
    ... documenti.length ? [{ pk: 2, name: 'Documenti', style: 'beni', items: documenti.map((item) => ({
      pk: item.node?.object?.pk, url: item.node?.object?.url, text: item.node?.object?.titolo + (item.node?.object?.edizione ? ` (${monthYearFormat(item.node?.object?.edizione)?.capitalize()})` : '')
    }))}] : [],
    ... altro.length ? [{ pk: 3, name: 'Altro', style: 'beni', items: altro.map((item) => ({
      pk: item?.pk, url: item?.url, text: item?.text
    }))}] : []
  ]
}