import React, { useEffect } from 'react'
import { useTrans } from '@next-nx/hooks'
import styles from './PreventivatoreFastQuoteLoader.module.sass'
import { ProdottoType } from '@next-nx/utils/types'
import { isFastQuoteAuto, isFastQuoteCasa } from '@next-nx/utils/fastquote'
import PreventivatoreFastQuoteCasaLoader from '../PreventivatoreFastQuoteCasaLoader/PreventivatoreFastQuoteCasaLoader'
import PreventivatoreFastQuoteAutoLoader from '../PreventivatoreFastQuoteAutoLoader/PreventivatoreFastQuoteAutoLoader'

interface Props {
  open: boolean
  className?: string
  prodotto?: ProdottoType
}

const PreventivatoreFastQuoteLoader = (props: Props) => {
  const { open = false, className = '', prodotto = undefined } = props

  useEffect(() => {
    if (open) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [open])

  const t = useTrans()

  const classNames = `
    ${styles.root}
    ${prodotto && isFastQuoteAuto(prodotto) ? styles['root--auto'] : ''}
    ${className ? `${className}` : ''}
  `

  return open ? (
    <div className={classNames}>
      {!prodotto || isFastQuoteCasa(prodotto) ? (
        <PreventivatoreFastQuoteCasaLoader />
      ) : prodotto && isFastQuoteAuto(prodotto) ? (
        <PreventivatoreFastQuoteAutoLoader prodotto={prodotto} />
      ) : null}
    </div>
  ) : (
    <></>
  )
}

export default PreventivatoreFastQuoteLoader
