import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useFindMenu, useTrans } from '@next-nx/hooks'
import styles from './NavMobile.module.sass'
import { ArrowLeft, ArrowRight, Mail } from '@next-nx/shared-ui/icons'
import { getIcon } from '@next-nx/utils/icons'
import { Button, WContainer } from '@next-nx/shared-ui/atoms'
import { AppBoxMini } from '@next-nx/shared-ui/molecules'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'

interface PanelProps {
  parent: MenuType
  open: boolean
  setOpen: (v: boolean) => void
  setNavOpen: (v: boolean) => void
  levelMenu?: number
}

interface ItemProps {
  item: MenuType
  variant?: 'primary' | 'secondary'
  open: boolean
  setOpen: (v: boolean) => void
  setNavOpen: (v: boolean) => void
  levelMenu?: number
}

interface Props {
  open: boolean
  setOpen: (v: boolean) => void
  className?: string
}

const NavMobileItem = (props: ItemProps) => {
  const {
    item = undefined,
    variant = 'primary',
    open = false,
    setOpen = (v) => undefined,
    setNavOpen = (v) => undefined,
    levelMenu = 0,
  } = props

  return item ? (
    <>
      <div
        className={`${styles.item} ${variant ? styles[`item--${variant}`] : ''} ${
          open ? styles['item--open'] : ''
        }`}
      >
        {item.url ? (
          <div className={`${styles.itemHead} ${styles['itemHead--link']}`}>
            <Link legacyBehavior href={item.url}>
              <a
                className={styles.itemLink}
                onClick={() => {
                  setNavOpen(false)
                  sendGTMData({
                    event: 'GAevent',
                    eventCategory: 'menu',
                    eventAction:
                      levelMenu === 1
                        ? 'first_level_menu'
                        : levelMenu === 2
                        ? 'second_level_menu'
                        : levelMenu === 3
                        ? 'third_level_menu'
                        : levelMenu === 0
                        ? 'top_menu'
                        : '',
                    eventLabel: item.name?.toGTMFormat(),
                  })
                }}
              >
                {!!item.style && (
                  <span className={styles.itemIcon}>
                    {getIcon(item.style, 'navmobile_' + item?.pk?.toString())}
                  </span>
                )}
                <span
                  className={styles.itemLabel}
                  dangerouslySetInnerHTML={{ __html: item.name || '' }}
                />
              </a>
            </Link>
            {!!item.children?.edges?.length && (
              <button type="button" onClick={() => setOpen(true)} className={styles.itemArrow}>
                <ArrowRight />
              </button>
            )}
          </div>
        ) : item.children?.edges?.length ? (
          <div
            className={`${styles.itemHead} ${styles['itemHead--noLink']}`}
            onClick={() => setOpen(true)}
          >
            {!!item.style && (
              <span className={styles.itemIcon}>
                {getIcon(item.style, 'navmobile_' + item?.pk?.toString())}
              </span>
            )}
            <span className={styles.itemLabel}>{item.name}</span>
            <span className={styles.itemArrow}>
              <ArrowRight />
            </span>
          </div>
        ) : (
          <></>
        )}
      </div>
      {!!item.children?.edges?.length && (
        <NavMobilePanel
          parent={item}
          open={open}
          setOpen={setOpen}
          setNavOpen={setNavOpen}
          levelMenu={levelMenu + 1}
        />
      )}
    </>
  ) : (
    <></>
  )
}

const NavMobilePanel = (props: PanelProps) => {
  const {
    parent = undefined,
    open = false,
    setOpen = (v) => undefined,
    setNavOpen = () => undefined,
    levelMenu = 0,
  } = props

  const [openChild, setOpenChild] = useState<number | null>(null)

  return parent ? (
    <div className={`${styles.panel} ${open ? styles['panel--open'] : ''}`}>
      <button type="button" className={styles.back} onClick={() => setOpen(false)}>
        <span className={styles.backArrow}>
          <ArrowLeft />
        </span>
        {!!parent.style && (
          <span className={styles.backIcon}>
            {getIcon(parent.style, 'navmobile_back_' + parent.pk?.toString())}
          </span>
        )}
        <span className={styles.backLabel}>{parent.name}</span>
      </button>
      {!!parent.children?.edges?.length && (
        <div className={styles.panelMenu}>
          {parent.children?.edges
            ?.filter((item) => item.node.style !== 'box')
            .map((item) => (
              <NavMobileItem
                key={item.node.pk}
                item={item.node}
                variant="secondary"
                open={openChild === item.node.pk}
                setOpen={(v: boolean) => setOpenChild(v ? item.node.pk : null)}
                setNavOpen={(v: boolean) => setOpen(v)}
                levelMenu={levelMenu}
              />
            ))}
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

const NavMobile = (props: Props) => {
  const { open = false, setOpen = () => undefined, className = '' } = props

  const t = useTrans()
  const [openChild, setOpenChild] = useState<number | null>(null)

  useEffect(() => {
    if (!open) setOpenChild(null)
    if (open) document.body.classList.add('w-noscroll')
    else document.body.classList.remove('w-noscroll')
  }, [open])

  const mainMenu = useFindMenu('main')
  const menuAreaClienti = useFindMenu('header_area_clienti')
  const mainExtraMobileMenu = useFindMenu('main_extra_mobile')
  const menuTop = useFindMenu('top_header')
  const menuScaricaApp = useFindMenu('nav_scarica_app')

  return (
    <div
      className={`${styles.navMobile} ${open ? styles['navMobile--open'] : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className={`${styles.menu} ${openChild ? styles['menu--openChild'] : ''}`}>
        {mainMenu &&
          mainMenu.children?.edges &&
          mainMenu.children.edges.map((item) => (
            <NavMobileItem
              key={item.node.pk}
              item={item.node}
              open={openChild === item.node.pk}
              setOpen={(v: boolean) => setOpenChild(v ? item.node.pk : null)}
              setNavOpen={(v: boolean) => setOpen(v)}
              levelMenu={1}
            />
          ))}
        {menuTop &&
          menuTop.children?.edges &&
          menuTop.children.edges.map((item) => (
            <NavMobileItem
              key={item.node.pk}
              item={item.node}
              variant="secondary"
              open={openChild === item.node.pk}
              setOpen={(v: boolean) => !!setOpenChild && setOpenChild(v ? item.node.pk : null)}
              setNavOpen={(v: boolean) => setOpen(v)}
              levelMenu={0}
            />
          ))}
        {menuScaricaApp && <AppBoxMini item={menuScaricaApp} />}
      </div>
      <div className={styles.actions}>
        {!!menuAreaClienti && (
          <Button
            className={styles.action}
            label={menuAreaClienti.ctaLabel}
            href={menuAreaClienti.url}
            external
            onClick={() => {
              sendGTMData({
                event: 'GAevent',
                eventCategory: 'header',
                eventAction: 'area_clienti',
              })
            }}
          />
        )}
        {mainExtraMobileMenu &&
          mainExtraMobileMenu.children?.edges &&
          mainExtraMobileMenu.children.edges.map((item) => (
            <Button
              key={item.node.pk}
              label={item.node.ctaLabel}
              href={item.node.url}
              leftIcon={getIcon(item.node.style, 'navmobile_extra_' + item?.node?.pk?.toString())}
              variant="ghost"
              className={styles.action}
            />
          ))}
      </div>
    </div>
  )
}

export default NavMobile
