import React, { useState, useEffect, useContext } from 'react';

import { Editor } from '../../editor';

import { PagebuilderContext } from '../../services/pagebuilderContext';
import { api } from '../../services/api';

import { GlobalContext } from '../../services/globalContext';
import { blankPlaceholder } from '../../utils/constants';

import { ErrorBoundary } from 'react-error-boundary';
import {
  PlusCopy,
  ImageCopy,
  Template,
  Empty,
  ChevronLeft,
} from '../../pb-components/icons';
import { Button } from '../../pb-components/atoms';

export const PlaceholderImport = () => {
  const { contentTypeId, lang, setShowImport } = useContext(GlobalContext);

  const { pagebuilder, saveDraft } = useContext(PagebuilderContext);

  const [placeholders, setPlaceholders] = useState([]);

  useEffect(() => {
    api
      .listPagebuilder({ contentTypeId: contentTypeId, lang: lang })
      .then((data) => {
        setPlaceholders(data.filter((p) => p.id !== pagebuilder.id));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const update = (placeholderId) => {
    const placeholder = placeholders.find((p) => p.id === placeholderId);
    if (placeholder) {
      saveDraft(placeholder.json_draft, true);
      setShowImport(false);
    }
  };

  const updateBlank = () => {
    saveDraft(blankPlaceholder, true);
    setShowImport(false);
  };

  // file upload
  const [loading, setLoading] = useState(false);
  const [jsonUploaded, setJsonUploaded] = useState(null);
  const [importType, setImportType] = useState('');

  const uploadFile = () => {
    saveDraft(jsonUploaded, true);
    setShowImport(false);
  };

  const renderListing = (listing) => {
    return (
      <div className="placeholder-listing">
        {listing.filter((item) => item.json_draft).map((item) => {
          const json_draft = item.json_draft ? JSON.parse(item.json_draft) : '';
          return (
            <div
              className="placeholder-listing-item"
              key={item.id}
              onClick={() => update(item.id)}
            >
              <p className="placeholder-listing-item__title">
                <span>{item.object_name}</span>
                <span>
                  {item.object_id} - {item.key}
                </span>
              </p>
              <div className="placeholder-listing-item__editor">
                {/* <iframe 
                        src={`http://localhost:3001/${lang}/${item.key !== "home" ? item.key : ''}`}
                    /> */}
                <Editor
                  json={json_draft}
                  editMode={false}
                  withConditions={false}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const importTypes = [
    {
      key: 'stesso_oggetto',
      icon: <PlusCopy />,
      label: 'Stesso oggetto',
      active:
        placeholders.filter(
          (p) =>
            p.content_type_id === pagebuilder.content_type_id &&
            p.object_id === pagebuilder.object_id &&
            p.json_draft
        ).length > 0,
    },
    {
      key: 'stesso_contenttype',
      icon: <ImageCopy />,
      label: 'Altra pagina dello stesso tipo',
      active:
        placeholders.filter(
          (p) =>
            p.content_type_id === pagebuilder.content_type_id &&
            p.object_id !== pagebuilder.object_id &&
            p.json_draft
        ).length > 0,
    },
    {
      key: 'file',
      icon: <Template />,
      label: 'Importa un template (file .json)',
      active: true,
    },
    {
      key: 'vuoto',
      icon: <Empty />,
      label: 'Inizia con un template vuoto',
      active: true,
      onClick: () => updateBlank(),
    },
  ];

  return (
    <div className="pagebuilder-import-page">
      {importType === '' ? (
        <>
          <p className="pagebuilder-import-page__title">
            Scegli la modalità di import
          </p>
          <div className="pagebuilder-import-page__options">
            {importTypes
              .filter((t) => t.active)
              .map((t) => (
                <button
                  type="button"
                  className="pagebuilder-import-page__option"
                  onClick={() =>
                    t.onClick ? t.onClick() : setImportType(t.key)
                  }
                  key={`pagebuilder-import-page-option__${t.key}`}
                >
                  {t.icon}
                  <span>{t.label}</span>
                </button>
              ))}
          </div>
        </>
      ) : (
        <>
          <div className="pagebuilder-import-page__step2">
            <Button
              label="Indietro"
              variant="ghost"
              icon={<ChevronLeft />}
              iconPos="left"
              onClick={() => setImportType('')}
              className="pagebuilder-import-page__step2__back"
            />

            {importType === 'stesso_oggetto' && (
              <>
                <p className="pagebuilder-import-page__title">Seleziona</p>
                <div className="pagebuilder-import-page__stesso-oggetto">
                  {renderListing(
                    placeholders.filter(
                      (p) =>
                        p.content_type_id === pagebuilder.content_type_id &&
                        p.object_id === pagebuilder.object_id
                    )
                  )}
                </div>
              </>
            )}

            {importType === 'stesso_contenttype' && (
              <>
                <p className="pagebuilder-import-page__title">Seleziona</p>
                <div className="pagebuilder-import-page__stesso-contenttype">
                  {renderListing(
                    placeholders.filter(
                      (p) =>
                        p.content_type_id === pagebuilder.content_type_id &&
                        p.object_id !== pagebuilder.object_id
                    )
                  )}
                </div>
              </>
            )}

            {importType === 'file' && (
              <>
                <p className="pagebuilder-import-page__title">
                  Carica il file .json
                </p>
                <div className="pagebuilder-import-page__file">
                  <input
                    type="file"
                    accept=".json"
                    onChange={(e) => {
                      setLoading(true);
                      const fileReader = new FileReader();
                      fileReader.readAsText(e.target.files[0], 'utf-8');
                      fileReader.onload = (e) => {
                        const data = e.target.result;
                        setJsonUploaded(data);
                        setLoading(false);
                      };
                    }}
                  />
                  {jsonUploaded && !loading && (
                    <div className="pagebuilder-import-page__file__import-result">
                      <ErrorBoundary
                        fallback={
                          <p className="pagebuilder-import-page__file__import-result__error">
                            Il file caricato non è valido
                          </p>
                        }
                        onError={(error, info) => {}}
                      >
                        <p className="pagebuilder-import-page__file__import-result__title">
                          Anteprima
                        </p>
                        <div className="pagebuilder-import-page__file__import-result__preview">
                          <div className="pagebuilder-import-page__file__import-result__preview__editor">
                            <Editor
                              json={jsonUploaded}
                              editMode={false}
                              withConditions={false}
                            />
                          </div>
                        </div>
                        <div className="pagebuilder-import-page__file__import-result__cta-box">
                          <Button
                            label="Conferma"
                            onClick={() => uploadFile()}
                          />
                        </div>
                      </ErrorBoundary>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
