import React from 'react'

function Fullscreen({ className }) {
  return (
    <svg
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--fullscreen${className ? ' ' + className : ''}`}
    >
      <path d="M29.92,2.62a1.15,1.15,0,0,0-.21-.33,1.15,1.15,0,0,0-.33-.21A1,1,0,0,0,29,2H21a1,1,0,0,0,0,2h5.59l-8.3,8.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L28,5.41V11a1,1,0,0,0,2,0V3A1,1,0,0,0,29.92,2.62Z" />
      <path d="M5.41,4H11a1,1,0,0,0,0-2H3a1,1,0,0,0-.38.08,1.15,1.15,0,0,0-.33.21,1.15,1.15,0,0,0-.21.33A1,1,0,0,0,2,3v8a1,1,0,0,0,2,0V5.41l8.29,8.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
      <path d="M29,20a1,1,0,0,0-1,1v5.59l-8.29-8.3a1,1,0,0,0-1.42,1.42L26.59,28H21a1,1,0,0,0,0,2h8a1,1,0,0,0,.38-.08.9.9,0,0,0,.54-.54A1,1,0,0,0,30,29V21A1,1,0,0,0,29,20Z" />
      <path d="M12.29,18.29,4,26.59V21a1,1,0,0,0-2,0v8a1,1,0,0,0,.08.38,1.15,1.15,0,0,0,.21.33,1.15,1.15,0,0,0,.33.21A1,1,0,0,0,3,30h8a1,1,0,0,0,0-2H5.41l8.3-8.29a1,1,0,0,0-1.42-1.42Z" />
    </svg>
  )
}

export default Fullscreen
