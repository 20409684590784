import React from 'react'
import { TabsContentSettings } from './TabsContentSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'
import { useNode, useEditor } from '@craftjs/core'


export const defaultProps = {};

export const dynamicProps = [];

export const TabsContent = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    };

    const { children } = props;

    const _ = props.renderValue;

    const {
        id,
        parent,
    } = useNode((state) => ({
        parent: state.data.parent,
    }));

    const { parentNode } = useEditor((state, query) => ({
        parentNode: parent ? query.node(parent).get() : null,
    }));

    const active = parentNode?.data.custom.active

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-tabs-content ' + props.className}
      >
        {children.props.children.map((tab, index) => {
            return active === index && (
                tab
            )
        })}
      </div>
    );
  },
  defaultProps,
  dynamicProps
);

TabsContent.craft = withCraft({
  name: 'TabsContent',
  defaultProps: defaultProps,
  settings: TabsContentSettings,
});
