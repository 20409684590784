import React from 'react'

function Add({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--add${className ? ' ' + className : ''}`}
    >
      <path d="M38 26h-12v12h-4v-12h-12v-4h12v-12h4v12h12v4z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  )
}

export default Add
