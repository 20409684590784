import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { defaultProps, dynamicProps } from './index'
import { getAllPaths } from '../../utils/dynamic'

export const PdfLinkSettings = () => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }))

  return (
    <ToolbarSection title="Settings" initOpen>
      <ToolbarItem
        propKey="block"
        propSubKey="name"
        type="text"
        label="Nome"
        dynamic={dynamicProps.includes('name') && customs.dynamicData}
        dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
        defaultValue={defaultProps['name']}
      />
      <ToolbarItem
        propKey="block"
        propSubKey="url"
        type="text"
        label="URL"
        dynamic={dynamicProps.includes('url') && customs.dynamicData}
        dynamicValues={customs.dynamicData ? getAllPaths(customs.dynamicData) : []}
        defaultValue={defaultProps['url']}
      />
      <ToolbarItem propKey="block" propSubKey="url" type="file" />
    </ToolbarSection>
  )
}
