import React, { useEffect, useState } from 'react'
import { useTrans } from '@next-nx/hooks'
import { Button, Modal } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneAutoGaranzieAbbinateModal.module.sass'
import { priceFormat } from '@next-nx/utils/safe'
import {
  GaranziaQuotazioneAutoType,
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoType,
} from '@next-nx/utils/types'
import QuotazioneGaranziaAutoBox from '../../molecules/QuotazioneGaranziaAutoBox/QuotazioneGaranziaAutoBox'

interface Props {
  pacchetto: PacchettoGaranziaQuotazioneAutoType | undefined
  garanzia: GaranziaQuotazioneAutoType | undefined
  quotazione: QuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  handleGaranzieAndPacchetti: (
    variant: 'pacchetto' | 'garanzia',
    action: 'add' | 'remove' | 'substitute',
    pacchettiCodici: string[],
    garanzieCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto?: boolean,
    lazy?: boolean,
    garanzieSostituite?: string[]
  ) => string[] | undefined
  open: boolean
  setOpen: (v: boolean) => void
  handleGtm: (
    v:
      | 'no_grazie'
      | 'aggiungi_garanzie'
      | 'chiudi_popup'
      | 'rimuovi_garanzie'
      | 'sostituisci_garanzie'
      | 'annulla_rimozione'
  ) => void
  className?: string
}

const QuotazioneAutoGaranzieAbbinateModal = (props: Props) => {
  const {
    pacchetto,
    garanzia,
    quotazione,
    garanzie,
    garanzieSelezionate,
    handleGaranzieAndPacchetti = () => undefined,
    open = false,
    setOpen = () => undefined,
    handleGtm = () => undefined,
    className = '',
  } = props

  const t = useTrans()
  const [premioLordoAnnuoTotale, setPremioLordoAnnuoTotale] = useState(0)

  const garanzieAbbinateNonSelezionate = garanzie.filter(
    (x) => garanzia?.garanzieAbbinate?.includes(x.codice) && !garanzieSelezionate.includes(x.codice)
  )

  useEffect(() => {
    let totale = 0
    garanzieAbbinateNonSelezionate.forEach((x) => {
      totale += x.importoLordoAnnuoScontato
    })
    setPremioLordoAnnuoTotale(totale)
  }, [garanzia])

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      closeButton
      onCloseClick={() => {
        handleGtm('chiudi_popup')
      }}
      className={`${styles.QuotazioneAutoGaranzieAbbinateModal}
      ${className ? ` ${className}` : ''}`}
      buttons={
        <>
          <Button
            label={t('Annulla')}
            variant="ghost"
            onClick={() => {
              setOpen(false)
              handleGtm('no_grazie')
            }}
          />
          <Button
            labelHtml={
              garanzieAbbinateNonSelezionate.length > 1
                ? t('Aggiungi le garanzie a <span>{0}</span>', [
                    priceFormat(premioLordoAnnuoTotale),
                  ])
                : t('Aggiungi la garanzia a <span>{0}</span>', [
                    priceFormat(premioLordoAnnuoTotale),
                  ])
            }
            onClick={() => {
              const newGaranzieSelezionate = handleGaranzieAndPacchetti(
                'garanzia',
                'add',
                [],
                [...garanzieAbbinateNonSelezionate.map((x) => x.codice)],
                garanzieSelezionate,
                true,
                true
              )

              const response = handleGaranzieAndPacchetti(
                'garanzia',
                'add',
                [],
                [garanzia?.codice],
                newGaranzieSelezionate,
                true,
                true
              )

              if (response) {
                handleGtm('aggiungi_garanzie')
                setOpen(false)
              }
            }}
          />
        </>
      }
    >
      <div className={styles.content}>
        <p
          className={styles.title}
          dangerouslySetInnerHTML={{
            __html: t('Garanzia <strong>{0}</strong>', [garanzia.titolo || '']),
          }}
        />
        <div className={styles.description}>Per selezionare questa garanzia devi aggiungere:</div>
        <div className={styles.list}>
          {garanzieAbbinateNonSelezionate.map((x) => (
            <QuotazioneGaranziaAutoBox
              garanzia={x}
              key={x.pk}
              showCheckbox={false}
              showCondizioni={false}
              showPrezzi={false}
            />
          ))}
        </div>
      </div>
    </Modal>
  )
}

export default QuotazioneAutoGaranzieAbbinateModal
