import React from 'react'
import { WImage, Button } from '@next-nx/shared-ui/atoms'
import styles from './UseCaseCard.module.sass'
import { useIsMobile } from '@next-nx/hooks'
import { MenuType } from '@next-nx/utils/types'


interface Props {
  item?: MenuType
  className?: string
  onClick?: () => void
}

const UseCaseCard = (props: Props) => {

  const {
    item = undefined,
    className = '',
    onClick = () => undefined
  } = props

  const isMobile = useIsMobile()

  return item ? (
    <div className={`${styles.useCaseCard} ${className}`}>
      <div className={styles.imageBox}>
        <WImage
          src={isMobile ? item.mainImageResponsive : item.mainImage}
          maxWidth={350}
          maxHeight={266}
          layout="intrinsic"
          objectFit="cover"
          key={`use-case-card-${item.pk}-main-image${isMobile ? '-mobile' : ''}`}
        />
      </div>
      <div className={styles.content}>
        {!!item.name && <p className={styles.title}>{item.name}</p>}
        {!!item.description && <div className={styles.description} dangerouslySetInnerHTML={{ __html: item.description }} />}
        {!!item.ctaLabel && !!item.url && (
          <div className={styles.ctaBox}>
            <Button
              label={item.ctaLabel}
              href={item.url}
              variant="ghost"
              onClick={onClick}
            />
          </div>
        )}
      </div>
    </div>
  ) : <></>
}

export default UseCaseCard
