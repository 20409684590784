import React from 'react'

function WireframeMode({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--wireframe-mode${className ? ' ' + className : ''}`}
    >
      <path d="M14 10h4v-4h-4v4zm0 16h4v-4h-4v4zm0 16h4v-4h-4v4zm8-8h4v-4h-4v4zm0 8h4v-4h-4v4zm-16 0h4v-4h-4v4zm0-8h4v-4h-4v4zm0-8h4v-4h-4v4zm0-8h4v-4h-4v4zm0-8h4v-4h-4v4zm16 16h4v-4h-4v4zm16 8h4v-4h-4v4zm0-8h4v-4h-4v4zm0 16h4v-4h-4v4zm0-24h4v-4h-4v4zm-16 0h4v-4h-4v4zm16-12v4h4v-4h-4zm-16 4h4v-4h-4v4zm8 32h4v-4h-4v4zm0-16h4v-4h-4v4zm0-16h4v-4h-4v4z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  )
}

export default WireframeMode
