import { useNode, useEditor, Element } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'
import { Button as PbButton } from '../../pb-components/atoms'
import { Bin, Edit } from '../../pb-components/icons'
import { TextEditor, TabPane } from '../../components'
import { addNodeTree, deleteNodeTree } from '../../utils/node'


export const TabsSettings = () => {

  const {
    id,
    props,
    active,
    nodes,
    actions: { setProp, setCustom },
  } = useNode((node) => ({
    props: node.data.props,
    active: node.data.custom.active,
    nodes: node.data.nodes,
  }))

  const {
    actions,
    query,
    tabsMenuNode,
    tabsMenuList,
    tabsContentNode,
    tabsContentList,
  } = useEditor((state, query) => ({
    tabsMenuNode: nodes?.length ? query.node(nodes[0]).get() : null,
    tabsMenuList: nodes?.length ? query.node(nodes[0]).get()?.data.nodes : [],
    tabsContentNode: nodes?.length ? query.node(nodes[1]).get() : null,
    tabsContentList: nodes?.length ? query.node(nodes[1]).get()?.data.nodes : [],
  }))

  const addSlide = () => {

    const tabsMenuId = tabsMenuNode.id

    const newTabsMenuElement = <TextEditor
      block={{
        text: `Tab ${tabsMenuList?.length + 1} \n`, // '\n' alla fine perchè Quill lo aggiunge in automatico se noHtml
        noHtml: true,
      }}
    />
    const newTabsMenuElementNodeTree = query.parseReactElement(newTabsMenuElement).toNodeTree()
    addNodeTree(newTabsMenuElementNodeTree, tabsMenuId, actions, false, null, true) // true = throttle

    const tabsContentId = tabsContentNode.id

    const newTabsContentElement = <Element is={TabPane} canvas></Element>
    const newTabsContentElementNodeTree = query.parseReactElement(newTabsContentElement).toNodeTree()
    addNodeTree(newTabsContentElementNodeTree, tabsContentId, actions, false, null, true) // true = throttle
  }
  
  const deleteSlide = (index) => {
    if (tabsMenuList.length > 2) {
      if(index < active) {
        setCustom((props) => {
          props.active = active - 1
        })
      }
      // il active è l'ultimo elemento ed elimino quello
      else if (active >= (tabsMenuList.length - 1)) {
        setCustom((props) => {
          props.active = active - 1
        })
      }

      const tabMenuId = tabsMenuList[index]
      deleteNodeTree(tabMenuId, actions, false, true) // true = throttle

      const tabContentId = tabsContentList[index]
      deleteNodeTree(tabContentId, actions, false, true) // true = throttle
    }
  }

  const selectSlide = (index) => {
    setCustom((props) => {
      props.active = index
    })
    actions.selectNode(tabsMenuList[index])
  }

  return (
    <>
    <ToolbarSection title="Tabs" initOpen>
      <ToolbarItem type="other">
        <p className="tabs-settings__tabs-num">
          # Tabs: <strong>{tabsMenuList.length}</strong>
        </p>
        <PbButton onClick={() => addSlide()} label="Add Tab" size="sm" />
        <p className="tabs-settings__list__title">Tabs list</p>
        <div className="tabs-settings__tabs">
          {Array.from(Array(tabsMenuList.length).keys()).map((tab, index) => {
            return (
              <div
                key={index}
                className={`tabs-settings__tabs-item ${
                  index === active ? 'active' : ''
                }`}
              >
                <PbButton
                  variant="ghost"
                  label={'Tab ' + (index + 1)}
                  onClick={() => selectSlide(index)}
                  icon={<Edit />}
                  iconPos="right"
                />
                {tabsMenuList.length > 2 && (
                  <PbButton variant="ghost" onClick={() => deleteSlide(index)} icon={<Bin />} />
                )}
              </div>
            )
          })}
        </div>
      </ToolbarItem>
    </ToolbarSection>
    </>
  )
};

