import React from 'react'
import { useTrans } from '@next-nx/hooks'
import { dateFormat } from '@next-nx/utils/safe'
import { Calendario, ChevronDown, Info, Modello, Veicoli, Auto, Intestatario } from '@next-nx/shared-ui/icons'
import { Button, Tooltip } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneAutoHeadBox.module.sass'
import { QuotazioneAutoType } from '@next-nx/utils/types'

interface Props {
  quotazione: QuotazioneAutoType
  onClick?: () => void
  className?: string
}

const QuotazioneAutoHeadBox = (props: Props) => {
  const { quotazione = undefined, onClick = () => undefined, className = '' } = props

  const t = useTrans()

  return quotazione ? (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      <div className={`${styles.item} ${styles['item--targa']}`}>
        <span className={styles.itemIcon}>
          <Veicoli />
        </span>
        <div>
          <p className={styles.itemTitle}>{t('Targa')}</p>
          <span className={styles.itemLabel}>
            {quotazione.targa}
          </span>
        </div>
      </div>
      <div className={`${styles.item} ${styles['item--marca']}`}>
        <span className={styles.itemIcon}>
          <Modello />
        </span>
        <div>
          <p className={styles.itemTitle}>{t('Marca e modello')}</p>
          <span className={styles.itemLabel}>
            {quotazione.marcaAuto} {quotazione.modelloAuto}
          </span>
        </div>
      </div>
      <div className={`${styles.item} ${styles['item--allestimento']}`}>
        <span className={styles.itemIcon}>
          <Modello />
        </span>
        <div>
          <p className={styles.itemTitle}>{t('Allestimento')}</p>
          <span className={styles.itemLabel}>
            {quotazione.allestimentoDescrizione}
          </span>
        </div>
      </div>
      <div className={`${styles.item} ${styles['item--tipo-guida']}`}>
        <span className={styles.itemIcon}>
          <Auto />
        </span>
        <div>
          <p className={styles.itemTitle}>{t('Tipo di guida')}</p>
          <span className={styles.itemLabel}>
            {quotazione.tipoGuida}
          </span>
        </div>
      </div>
      <div className={`${styles.item} ${styles['item--date']}`}>
        <span className={styles.itemIcon}>
          <Calendario />
        </span>
        <div>
          <p className={styles.itemTitle}>{t('Data inizio copertura')}</p>
          <span className={styles.itemLabel}>{dateFormat(quotazione.dataDecorrenza)}</span>
        </div>
        {/* <span className={styles.itemHelperContainer}>
          <Tooltip id="quotazioneheadbox" label={t("Data di inizio polizza")}>
            <span className={styles.itemHelper}>
              <Info />
            </span>
          </Tooltip>
        </span> */}
      </div>
      <div className={`${styles.item} ${styles['item--user']}`}>
        <span className={styles.itemIcon}>
          <Intestatario />
        </span>
        <div>
          <p className={styles.itemTitle}>{t('Email')}</p>
          <span className={styles.itemLabel}>
            {/* TODO: Non abbiamo a disposizione nome e cognome dell'utente in questo punto */}
            {/* {quotazione.nome} {quotazione.cognome} */}
            {quotazione.email}
          </span>
        </div>
      </div>
      <div className={styles.ctaBox}>
        <Button
          label={t('Visualizza tutti i dati')}
          rightIcon={<ChevronDown />}
          variant="ghost"
          onClick={onClick}
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default QuotazioneAutoHeadBox
