import React from 'react'

function Redo({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--redo${className ? ' ' + className : ''}`}
    >
      <path d="M0 0h48v48h-48z" fill="none" />
      <path d="M36.79 21.2c-3.68-3.23-8.5-5.2-13.79-5.2-9.3 0-17.17 6.06-19.92 14.44l4.73 1.56c2.1-6.39 8.1-11 15.19-11 3.91 0 7.46 1.44 10.23 3.77l-7.23 7.23h18v-18l-7.21 7.2z" />
    </svg>
  )
}

export default Redo
