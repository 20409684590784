import React, { ReactChildren, ReactNode, useRef, useState } from 'react'
import { priceFormat } from '@next-nx/utils/safe'
import { useTrans } from '@next-nx/hooks'
import { getIcon } from '@next-nx/utils/icons'
import {
  CheckboxMarked,
  CheckboxUnmarked,
  Info,
  Bin,
  RadioMarked,
  RadioUnmarked,
} from '@next-nx/shared-ui/icons'
import { Button, FormSelect, Tooltip } from '@next-nx/shared-ui/atoms'
import styles from './QuotazioneGaranziaBox.module.sass'
import { GaranziaQuotazioneType } from '@next-nx/utils/types'
import {
  CODICE_GARANZIA_HOMEBOX,
  CODICE_GARANZIA_HOMEBOX_SECURITY,
  CODICE_GARANZIA_HOMEBOX_SAFETY,
  getPremioLordoAnnuoTotale,
  TIPO_GARANZIA_OBBLIGATORIA,
  CODICE_GARANZIA_PROTEZIONE_BASE,
  CODICE_GARANZIA_CATASTROFI_NATURALI,
} from '@next-nx/utils/fastquote'

interface Props {
  garanzia: GaranziaQuotazioneType
  variant?: 'garanzia' | 'prestazione'
  massimaleValue?: number
  massimaliFurto?: number[]
  setMassimaleValue?: (v: number) => void
  checked?: boolean
  onClick?: () => void
  openChildren?: boolean
  toggleChildren?: () => void
  children?: ReactNode
  className?: string
}

const QuotazioneGaranziaBox = (props: Props) => {
  const {
    garanzia = undefined,
    variant = 'garanzia',
    checked = false,
    massimaleValue = undefined,
    massimaliFurto = undefined,
    setMassimaleValue = () => undefined,
    onClick = () => undefined,
    openChildren = false,
    toggleChildren = () => undefined,
    children = undefined,
    className = '',
  } = props

  const t = useTrans()
  const [open, setOpen] = useState(false)
  const innerContentRef = useRef<HTMLDivElement>(null)

  const garanzieSenzaMassimale = [
    CODICE_GARANZIA_HOMEBOX_SECURITY,
    CODICE_GARANZIA_HOMEBOX_SAFETY,
    CODICE_GARANZIA_HOMEBOX,
  ]
  const massimale = garanzia?.massimaleTotale || garanzia?.massimale
  const premioLordoAnnuo =
    garanzia?.children &&
    garanzia.children?.length > 0 &&
    (!checked || garanzia?.codice === CODICE_GARANZIA_HOMEBOX)
      ? t('Variabile*')
      : getPremioLordoAnnuoTotale(garanzia)

  const classeNames = `
    ${styles.root}
    ${styles[`root--${variant}`]}
    ${checked ? styles['root--checked'] : ''}
    ${garanzia?.tipo === TIPO_GARANZIA_OBBLIGATORIA ? styles['root--required'] : ''}
    ${className ? ` ${className}` : ''}
  `

  return garanzia ? (
    <div className={classeNames}>
      <div className={styles.main}>
        {(garanzia.tipo !== TIPO_GARANZIA_OBBLIGATORIA || garanzia.codice === CODICE_GARANZIA_PROTEZIONE_BASE) &&
        <button
          role="button"
          className={styles.checkbox}
          onClick={garanzia.tipo !== TIPO_GARANZIA_OBBLIGATORIA ? onClick : undefined}
        >
          {variant === 'prestazione' ? (
            checked ? (
              <RadioMarked />
            ) : (
              <RadioUnmarked />
            )
          ) : checked ? (
            <CheckboxMarked />
          ) : (
            <CheckboxUnmarked />
          )}
        </button>
        }
        <div className={styles.content}>
          <div className={styles.head} onClick={garanzia.tipo !== TIPO_GARANZIA_OBBLIGATORIA ? onClick : undefined}>
            {!!getIcon(garanzia.icon) && (
              <span className={styles.icon}>
                {getIcon(garanzia.icon, `quotazionegaranziabox_${garanzia.pk}`)}
              </span>
            )}
            {!!garanzia.titolo && <p className={styles.title}>{garanzia.titolo}</p>}
          </div>
          {!!garanzia.abstract && (
            <div
              className={styles.abstract}
              dangerouslySetInnerHTML={{ __html: garanzia.abstract }}
            />
          )}
          {!!garanzia.descrizione && (
            <>
              <div className={styles.descriptionBox} onClick={garanzia.tipo !== TIPO_GARANZIA_OBBLIGATORIA ? onClick : undefined}>
                <div
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: garanzia.descrizione }}
                  ref={innerContentRef}
                />
                {!!garanzia.descrizioneAggiuntiva && open && (
                  <div
                    className={styles.description}
                    dangerouslySetInnerHTML={{ __html: garanzia.descrizioneAggiuntiva }}
                  />
                )}
              </div>
              {!!garanzia.descrizioneAggiuntiva && (
                <div className={styles.descriptionCtaBox}>
                  <Button
                    variant="ghost"
                    label={t(open ? 'Chiudi descrizione' : 'Espandi descrizione')}
                    onClick={() => setOpen(!open)}
                    size="sm"
                  />
                </div>
              )}
            </>
          )}
        </div>
        {garanzia.codice !== CODICE_GARANZIA_PROTEZIONE_BASE && (
          <>
            {/* Tenere il div se garanzia è HomeBox */}
            {!garanzieSenzaMassimale.includes(garanzia?.codice) && (
              <div className={styles.condizioni}>
                <div className={styles.item}>
                  <div className={styles.itemHead}>
                    <p className={styles.itemTitle}>{t('Massimale')}</p>
                    {!!garanzia.infoMassimale && (
                      <div className={styles.itemHelper}>
                        <Tooltip
                          id={`quotazionegaranziabox_massimale_${garanzia.pk}`}
                          label={garanzia.infoMassimale}
                        >
                          <span className={styles.itemHelperIcon}>
                            <Info />
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  {massimaliFurto && massimaliFurto.length > 0 ? (
                    <FormSelect
                      size="sm"
                      value={massimaleValue}
                      onChange={(e) => setMassimaleValue(e.target.value)}
                      options={massimaliFurto.map((x) => ({
                        label: priceFormat(x, true),
                        value: x,
                      }))}
                    />
                  ) : (
                      <div className={styles.itemValue}>
                        {massimale ? priceFormat(massimale) : garanzia.codice === CODICE_GARANZIA_CATASTROFI_NATURALI && garanzia?.massimaleDesc ? garanzia?.massimaleDesc : t('Nessuno')}
                      </div>
                  )}
                </div>
                <div className={styles.item}>
                  <div className={styles.itemHead}>
                    <p className={styles.itemTitle}>{t('Franchigia')}</p>
                    {!!garanzia.franchigiaDesc && (
                      <div className={styles.itemHelper}>
                        <Tooltip
                          id={`quotazionegaranziabox_franchigia_${garanzia.pk}`}
                          label={garanzia.franchigiaDesc}
                        >
                          <span className={styles.itemHelperIcon}>
                            <Info />
                          </span>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <div className={styles.itemValue}>
                    {parseFloat(garanzia.franchigia) && garanzia.codice !== CODICE_GARANZIA_CATASTROFI_NATURALI
                      ? priceFormat(parseFloat(garanzia.franchigia))
                      : garanzia.franchigia || t('Nessuna')}
                  </div>
                </div>
              </div>

            )}
          </>
        )}
        {garanzia.codice === CODICE_GARANZIA_PROTEZIONE_BASE ? (
          <div className={styles.allegato}>
            <Button
              variant="secondary"
              size="md"
              label={t(openChildren ? 'Chiudi dettagli' : 'Vedi dettagli')}
              onClick={toggleChildren}
            />
          </div>
        ) : (
          garanzia.tipo === TIPO_GARANZIA_OBBLIGATORIA &&
          garanzia.file && (
            <div className={styles.allegato}>
              <Button
                variant="secondary"
                size="md"
                label={t('Vedi dettagli')}
                href={garanzia.file}
                newPage
              />
            </div>
          )
        )}
        <div className={styles.price}>
          {typeof premioLordoAnnuo === 'number' ? (
            <>
              {priceFormat(premioLordoAnnuo)}
              <br /> / anno
            </>
          ) : (
            <>{premioLordoAnnuo}</>
          )}
        </div>
      </div>
      {!!children && <div className={styles.children}>{children}</div>}
      {garanzia.tipo !== TIPO_GARANZIA_OBBLIGATORIA && variant !== 'prestazione' && (
        <div className={styles.mobileCtaBox}>
          <Button
            variant={checked ? 'ghost' : 'secondary'}
            label={t(checked ? 'Rimuovi garanzia' : 'Aggiungi garanzia')}
            leftIcon={checked ? <Bin /> : undefined}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default QuotazioneGaranziaBox
