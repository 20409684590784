import { useNode } from '@craftjs/core'
import { ToolbarSection } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarSection'
import { ToolbarItem } from '../../editor/Viewport/Sidebar/Toolbar/ToolbarItem'

import { defaultProps, dynamicProps } from './index'
import { getAllPaths, getDynamicValue, setDynamicValue } from '../../utils/dynamic'

export const TextSettings = () => {
  const { customs } = useNode((node) => ({
    customs: node.data.custom,
  }))

  return (
    <>
      {customs.dynamicData ? (
        <ToolbarSection title="Dynamic Data">
          <ToolbarItem
            propKey="block"
            propSubKey="text"
            type="text"
            label="Text"
            onlyDynamic={true}
            dynamic={dynamicProps.includes('text')}
            dynamicValues={getAllPaths(customs.dynamicData)}
            defaultValue={defaultProps['text']}
          />
        </ToolbarSection>
      ) : (
        <div className="empty-message">
          <p>No options available</p>
        </div>
      )}
    </>
  )
}
