import React, { useEffect } from 'react'
import { useNode, useEditor } from '@craftjs/core'
import { TabsSettings } from './TabsSettings'
import { withBlock } from '../withBlock'
import { withCraft } from '../withCraft'


export const defaultProps = {};

export const dynamicProps = [];

export const Tabs = withBlock(
  (props) => {
    const blockProps = {
      ...defaultProps,
      ...props.block,
    };

    const { current } = blockProps

    const { children } = props;

    const _ = props.renderValue;

    const {
      id,
    } = useNode();

    const { enabled, actions } = useEditor((state) => ({
      enabled: state.options.enabled,
    }));

    const tabsMenuComponent = children.props.children[0]
    const tabsContentComponent = children.props.children[1]

    useEffect(() => {
      actions.history.ignore().setCustom(id, (customs) => {
        customs.active = 0
      })
    }, [])

    return (
      <div
        ref={(ref) => props.retRef(ref)}
        className={'block-tabs ' + props.className}
      >
        {tabsMenuComponent}
        {tabsContentComponent}
      </div>
    );
  },
  defaultProps,
  dynamicProps
);

Tabs.craft = withCraft({
  name: 'Tabs',
  defaultProps: defaultProps,
  settings: TabsSettings,
});
