import React from 'react'
import SwiperCore, { Pagination, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArrowLeft, ArrowRight } from '@next-nx/shared-ui/icons'
import { IntroTitle, WContainer, Button } from '@next-nx/shared-ui/atoms'
import { ArticoloCard } from '@next-nx/shared-ui/molecules'
import styles from './NewsSection.module.sass'
import { sendGTMData } from '../../../utils/gtm'

SwiperCore.use([Pagination, Navigation])

interface Props {
  intro?: string
  title?: string
  ctaLabel?: string
  url?: string
  news: {
    node: {
      pk: number
      categoria?: {
        title?: string
      }
      publicationDate?: string
      title?: string
      url: string
      image?: string
    }
  }[]
  className?: string
}

const NewsSection = (props: Props) => {

  const {
    intro = '',
    title = '',
    ctaLabel = '',
    url = '',
    news = [],
    className = '',
  } = props

  const swiperSettings = {
    slidesPerView: 1.25,
    spaceBetween: 16,
    pagination: { clickable: true },
    navigation: {
      nextEl: '.news-button-next',
      prevEl: '.news-button-prev'
    },
    watchOverflow: true,
    centeredSlides: false,
    className: 'swiper-container--pagination',
    breakpoints: {
      1100: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 2.5,
        centeredSlides: false,
      },
      576: {
        slidesPerView: 1.5,
      },
      0: {
        centeredSlides: true,
      }
    }
  }

  return news.length ? (
    <div className={`${styles.newsSection} ${className}`}>
      <WContainer variant="md">
        {!!intro && <IntroTitle label={intro} className={styles.intro} />}
        {!!title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: title }} />}
        <div className={styles.list}>
          <button type="button" className="custom-button-prev news-button-prev" onClick={() => sendGTMData({
            event: "GAevent",
            eventCategory: "box_info",
            eventAction: "informati",
            eventLabel: "prev"
          })}>
            <ArrowLeft />
          </button>
          <button type="button" className="custom-button-next news-button-next" onClick={() => sendGTMData({
            event: "GAevent",
            eventCategory: "box_info",
            eventAction: "informati",
            eventLabel: "next"
          })}>
            <ArrowRight />
          </button>
          <Swiper {...swiperSettings}>
            {news.map((child) => (
              <SwiperSlide key={child.node.pk}>
                <div className={styles.item}>
                  <ArticoloCard articolo={child.node} onClick={() => sendGTMData({
              event: "GAevent",
              eventCategory: "box_info",
              eventAction: "informati",
              eventLabel: child.node?.title?.toGTMFormat() || ''
            })} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {!!ctaLabel && !!url && (
            <div className={styles.ctaBox} onClick={() => sendGTMData({
              event: "GAevent",
              eventCategory: "box_info",
              eventAction: "informati",
              eventLabel: "leggi tutte le news"
            })}>
              <Button
                label={ctaLabel}
                rightIcon={<ArrowRight />}
                href={url}
                variant="ghost"
              />
            </div>
          )}
        </div>
      </WContainer>
    </div>
  ) : <></>
}

export default NewsSection
