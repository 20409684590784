import React from 'react'
import { WContainer, Breadcrumbs, MainTitle, MainDescription, WImage } from '@next-nx/shared-ui/atoms'
import { OutlineTextCtaBox, AllegatiBox, ImageTextBox } from '@next-nx/shared-ui/molecules'
import { PlaceholderReact } from '@next-nx/pagebuilder'
import { Page } from '@next-nx/shared-ui/organisms'
import styles from './ReclamiTemplate.module.sass'
import { useFindMenu, useIsMobile } from '@next-nx/hooks'
import { sendGTMData } from '../../../utils/gtm'


interface Props {
  pagina?: any
}

const ReclamiTemplate = (props: Props) => {

  const {
    pagina = null,
  } = props

  const isMobile = useIsMobile()

  const menuReclami = useFindMenu("reclami")

  const menuAllegati = useFindMenu("allegati", menuReclami)
  const menuHelp = useFindMenu("help", menuReclami)
  const menuBottom = useFindMenu("bottom", menuReclami)

  return (
    <Page pagina={pagina} item={{}} contentClassName={styles.reclamiContent}>
      <div className={`${styles.headBg} bg-grey`}>
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} />
          <div className={styles.headLayout}>
            <div className={styles.headContent}>
              <MainTitle label={pagina?.nome} variant="light" className={styles.title} />
              <MainDescription html={pagina?.descrizione} className={styles.description} />
            </div>
            <div className={styles.headImage}>
              <WImage
                src={isMobile ? pagina?.previewImage : pagina?.mainImage}
                maxWidth={536}
                maxHeight={350}
                layout="intrinsic"
                objectFit="cover"
                priority={true}
                loading="eager"
                key={`reclami-main-image${isMobile ? '-mobile' : ''}`}
              />
            </div>
          </div>
        </WContainer>
      </div>
      <div className="bg-white">
        <WContainer>
          <div className={styles.layout}>
            <div className={styles.main}>
              <PlaceholderReact
                pkey="content"
                contentTypeId={pagina.contentTypeId}
                objectId={pagina.pk}
              />
            </div>
            <div className={styles.bottom}>
              <ImageTextBox item={menuBottom} />
            </div>
            <div className={styles.sidebar}>
              <AllegatiBox item={menuAllegati} sidebar />
              <OutlineTextCtaBox item={menuHelp} onClick={() => sendGTMData({
                event: "GAevent",
                eventCategory: 'contattaci',
                eventAction: 'click',
                eventLabel: menuHelp?.url?.toGTMFormat() || ''
              })} />
            </div>
          </div>
        </WContainer>
      </div>
    </Page>
  )
}

export default ReclamiTemplate
