import React from 'react'

function Conditions({ className }) {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--conditions${className ? ' ' + className : ''}`}
    >
      <path d="M40 20h-6v-2.28c3.45-.89 6-3.99 6-7.72h-6v-2c0-1.1-.89-2-2-2h-16c-1.1 0-2 .9-2 2v2h-6c0 3.72 2.56 6.83 6 7.72v2.28h-6c0 3.72 2.56 6.83 6 7.72v2.28h-6c0 3.72 2.56 6.83 6 7.72v2.28c0 1.1.9 2 2 2h16c1.11 0 2-.9 2-2v-2.28c3.45-.89 6-3.99 6-7.72h-6v-2.28c3.45-.89 6-4 6-7.72zm-16 18c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0-10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0-10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" />
      <path d="M0 0h48v48h-48z" fill="none" />
    </svg>
  )
}

export default Conditions
