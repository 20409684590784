import React from 'react'
import { LogoAssicurazioniMono, LogoCaMono, LogoVitaMono } from '@next-nx/shared-ui/icons'
import { WContainer } from '@next-nx/shared-ui/atoms'
import styles from './SimpleFooter.module.sass'
import { useSite, useTrans } from '@next-nx/hooks'
import { ASSICURAZIONI, VITA } from 'libs/shared-ui/utils/settings'

interface Props {
  className?: string
}

const SimpleFooter = (props: Props) => {
  const { className = '' } = props

  const t = useTrans()
  const site = useSite()

  return (
    <div className={`${styles.simpleFooter} ${className}`}>
      <WContainer variant="lg">
        <div className={styles.head}>
          <a href="/" className={styles.logo}>
            {site === ASSICURAZIONI ? (
              <LogoAssicurazioniMono />
            ) : site === VITA ? (
              <LogoVitaMono />
            ) : <LogoAssicurazioniMono />}
            {/* : (
              <LogoCaMono />
            )}*/}
          </a>
          <div className={styles.copyright}>
            {site === ASSICURAZIONI
              ? t('footer_copyright_assicurazioni')
              : site === VITA
              ? t('footer_copyright_vita')
              : t('footer_copyright_assicurazioni')}
              {/*t('footer_copyright_banca')}*/}
          </div>
        </div>
      </WContainer>
    </div>
  )
}

export default SimpleFooter
