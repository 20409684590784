import React, { useEffect, useState, useContext } from 'react'
import { Toolbar } from './Toolbar'
import { useEditor } from '@craftjs/core'
import { Layers } from './Layers'
import { Toolbox } from './Toolbox'
import { AdvancedSettings } from './AdvancedSettings'
import { Conditions } from './Conditions'
import { GlobalContext } from '../../../services/globalContext'
import { Conditions as ConditionsIcon, Options as OptionsIcon } from '../../../pb-components/icons'

export const Sidebar = () => {
  const { selectedNodeId } = useEditor((state) => ({
    selectedNodeId: [...state.events.selected]?.[0],
  }))

  const { sidebarTab, setSidebarTab } = useContext(GlobalContext)

  useEffect(() => {
    setSidebarTab('tab-options')
  }, [])

  useEffect(() => {
    if (selectedNodeId) {
      if (sidebarTab === 'tab-blocks') {
        setSidebarTab('tab-options')
      }
    }
  }, [selectedNodeId])

  /* useEffect(() => {
        // By default, react-hotkeys will match hotkey sequences on the keydown event
        // In questo modo, disabilitiamo KeyboardShortcuts nella sidebar
        document.getElementById("sidebar").addEventListener("keydown", (e) => {
            e.stopPropagation()
        })
    }, []) */

  return (
    <div id="sidebar" className="sidebar">
      <div className="sidebar__tabs">
        <div
          className={`sidebar__tab-item ${
            sidebarTab === 'tab-options' ? 'sidebar__tab-item--active' : ''
          }`}
          onClick={() => setSidebarTab('tab-options')}
        >
          <OptionsIcon />
          <span>Options</span>
        </div>
        <div
          className={`sidebar__tab-item ${
            sidebarTab === 'tab-conditions' ? 'sidebar__tab-item--active' : ''
          }`}
          onClick={() => setSidebarTab('tab-conditions')}
        >
          <ConditionsIcon />
          <span>Conditions</span>
        </div>
      </div>

      <div className={'sidebar__tab-content'}>
        {sidebarTab === 'tab-options' && <Toolbar />}
        {sidebarTab === 'tab-conditions' && <Conditions />}
      </div>
    </div>
  )
}
