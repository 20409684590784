import React from 'react';
import { Info } from '../../../pb-components/icons';
import { FormError, Tooltip } from '../../../pb-components/atoms';

const FormField = ({
  label = '',
  status = '',
  errorMessage = '',
  className = '',
  helper = null,
  children = null,
}) => {
  const classNames = `pb-form-field
    ${status ? ` pb-form-field--${status}` : ''}
    ${className ? ` ${className}` : ''}`;

  return (
    <div className={classNames}>
      <div className="pb-form-field__head">
        {label && <span className="pb-form-field__label">{label}</span>}
        {helper && (
          <Tooltip {...helper}>
            <span className="pb-form-field__helper">
              <Info />
            </span>
          </Tooltip>
        )}
      </div>
      <div className="pb-form-field__children">{children}</div>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  );
};

export default FormField;
