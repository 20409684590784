import { useState, useMemo, useEffect, useRef, useCallback } from 'react'

/*

https://medium.com/javascript-in-plain-english/usetimeout-react-hook-3cc58b94af1f

*/

/* export const useTimeout = (callback, timeout = 0) => {
      const timeoutIdRef = useRef();
      const cancel = useCallback(
          () => {
              const timeoutId = timeoutIdRef.current;
              if (timeoutId) {
                  timeoutIdRef.current = undefined;
                  clearTimeout(timeoutId);
              }
          },
          [timeoutIdRef],
      );
  
      useEffect(
          () => {
              timeoutIdRef.current = setTimeout(callback, timeout);
              return cancel;
          },
          [callback, timeout, cancel],
      );
  
      return cancel;
  } */

/*
    https://github.com/rally25rs/react-use-timeout
*/
export function useTimeout(callback, timeout = 0) {
  const timeoutId = useRef()
  const handler = useMemo(() => {
    return {
      start(overrideTimeout) {
        handler.stop()
        timeoutId.current = setTimeout(
          callback,
          overrideTimeout === undefined ? timeout : overrideTimeout
        )
      },

      stop() {
        if (timeoutId.current) {
          clearTimeout(timeoutId.current)
        }
      },

      restart() {
        handler.stop()
        handler.start()
      },
    }
  }, [callback, timeout])

  useEffect(() => {
    return () => {
      handler.stop()
    }
  }, [])

  return handler
}
