import axios from 'axios';
import { API_HOST, API_FULL_PATH } from '@next-nx/utils';

const SITE = '1';
const LANG = 'it';
const baseUrl = API_HOST + '/d/';
const apiBaseUrl = API_FULL_PATH + 'react-pagebuilder/';

const axiosInstance = axios.create({
  baseURL: baseUrl,
});

export const apiAxiosInstance = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
});

apiAxiosInstance.defaults.xsrfCookieName = 'csrftoken';
apiAxiosInstance.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

export const api = {
  listPagebuilder,
  getPagebuilder,
  getPagebuilderJsonFile,
  saveDraftPagebuilder,
  publishPagebuilder,
  unpublishPagebuilder,
  getCustomBlocks,
  createCustomBlock,
  deleteCustomBlock,
  getGlobalCustomBlocks,
  createGlobalCustomBlock,
  updateGlobalCustomBlock,
  deleteGlobalCustomBlock,
};

function listPagebuilder(params) {
  const contentTypeId = params?.contentTypeId ? `content_type_id=${params.contentTypeId}` : ''
  const lang = params?.lang ? `&lang=${params.lang}` : ''
  let url = `pagebuilder/?${contentTypeId}${lang}`

  return apiAxiosInstance
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getPagebuilder(params) {
  const contentTypeId = `content_type_id=${params.contentTypeId}`;
  const objectId = `&object_id=${params.objectId}`;
  const key = `&key=${params.key}`;
  const lang = `&lang=${params.lang}`;
  const edit_mode = params.edit_mode ? `&edit_mode=true` : '';
  let url = `pagebuilder/?${contentTypeId}${objectId}${key}${lang}${edit_mode}`;

  return apiAxiosInstance
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getPagebuilderJsonFile(params) {
  const id = params.id ? params.id : '';
  let url = `json-pagebuilder/${id}/`;

  return axiosInstance
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function saveDraftPagebuilder(pagebuilderId, json_draft) {
  let body = {
    json_draft: json_draft,
  };

  return apiAxiosInstance
    .post(`pagebuilder/${pagebuilderId}/save_draft/`, body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function publishPagebuilder(pagebuilderId) {
  return apiAxiosInstance
    .post(`pagebuilder/${pagebuilderId}/publish/`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function unpublishPagebuilder(pagebuilderId) {
  return apiAxiosInstance
    .post(`pagebuilder/${pagebuilderId}/unpublish/`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getCustomBlocks(params) {
  const lang = `&lang=${params.lang}`;

  return apiAxiosInstance
    .get(`custom-blocks/?${lang}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function createCustomBlock(params) {
  let body = {
    lang: params.lang,
    name: params.name,
    json_content: params.json_content,
  };

  return apiAxiosInstance
    .post('custom-blocks/', body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function deleteCustomBlock(id) {
  return apiAxiosInstance
    .delete('custom-blocks/' + id + '/')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function getGlobalCustomBlocks(params) {
  const lang = `&lang=${params.lang}`;

  return apiAxiosInstance
    .get(`global-custom-blocks/?${lang}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function createGlobalCustomBlock(params) {
  let body = {
    lang: params.lang,
    name: params.name,
    json_content: params.json_content,
  };

  return apiAxiosInstance
    .post('global-custom-blocks/', body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function updateGlobalCustomBlock(id, params) {
  let body = {
    lang: params.lang,
    name: params.name,
    json_content: params.json_content,
  };

  return apiAxiosInstance
    .put('react-pagebuilder/global-custom-blocks/' + id + '/', body)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

function deleteGlobalCustomBlock(id) {
  return apiAxiosInstance
    .delete('react-pagebuilder/global-custom-blocks/' + id + '/')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}
