import { useState, useEffect } from 'react'
import { useNode } from '@craftjs/core'

import { TextToolbarItem } from './TextToolbarItem'
import { FileToolbarItem } from './FileToolbarItem'
import { CodeToolbarItem } from './CodeToolbarItem'
import { SliderToolbarItem } from './SliderToolbarItem'
import { SelectToolbarItem } from './SelectToolbarItem'
import { RadioToolbarItem } from './RadioToolbarItem'
import { SwitchToolbarItem } from './SwitchToolbarItem'
import { ColorToolbarItem } from './ColorToolbarItem'
import { DynamicToolbarItem } from './DynamicToolbarItem'

export const ToolbarItem = ({
  inline,
  propKey,
  propSubKey,
  type,
  onChange,
  values,
  options,
  onlyDynamic,
  dynamic,
  dynamicValues,
  defaultValue,
  nullable,
  rte,
  children,
  ...props
}) => {
  const {
    actions: { setProp },
    ps,
  } = useNode((node) => ({
    ps: node.data.props,
  }))

  const [value, setValue] = useState()

  useEffect(() => {
    if (propKey && propSubKey) {
      setValue(ps[propKey][propSubKey])
    }
  }, [ps])

  return (
    <div className={`toolbar-section__item ${inline ? 'toolbar-section__item--inline' : ''}`}>
      <div className="toolbar-section__item__header">
        <p className="toolbar-section__item__header__label">{props.label}</p>
        {props.description && (
          <p className="toolbar-section__item__header__description">{props.description}</p>
        )}
      </div>
      {value !== undefined &&
        (type === 'text' ? (
          <TextToolbarItem
            propKey={propKey}
            propSubKey={propSubKey}
            value={value}
            setProp={setProp}
            onChange={onChange}
            onlyDynamic={onlyDynamic}
            dynamic={dynamic}
            dynamicValues={dynamicValues}
            defaultValue={defaultValue}
            rte={rte}
          />
        ) : type === 'file' ? (
          <FileToolbarItem
            propKey={propKey}
            propSubKey={propSubKey}
            value={value}
            setProp={setProp}
            onChange={onChange}
          />
        ) : type === 'code' ? (
          <CodeToolbarItem
            propKey={propKey}
            propSubKey={propSubKey}
            value={value}
            setProp={setProp}
            onChange={onChange}
            onlyDynamic={onlyDynamic}
            dynamic={dynamic}
            dynamicValues={dynamicValues}
            defaultValue={defaultValue}
          />
        ) : type === 'slider' ? (
          <SliderToolbarItem
            propKey={propKey}
            propSubKey={propSubKey}
            value={value}
            setProp={setProp}
            onChange={onChange}
            max={props.max}
            min={props.min}
            step={props.step}
            onlyDynamic={onlyDynamic}
            dynamic={dynamic}
            dynamicValues={dynamicValues}
            defaultValue={defaultValue}
          />
        ) : type === 'select' ? (
          <SelectToolbarItem
            propKey={propKey}
            propSubKey={propSubKey}
            options={options}
            values={values}
            value={value}
            setProp={setProp}
            onChange={onChange}
            onlyDynamic={onlyDynamic}
            dynamic={dynamic}
            dynamicValues={dynamicValues}
            defaultValue={defaultValue}
            nullable={nullable}
          />
        ) : type === 'radio' ? (
          <RadioToolbarItem
            propKey={propKey}
            propSubKey={propSubKey}
            values={values}
            value={value}
            setProp={setProp}
            onChange={onChange}
            onlyDynamic={onlyDynamic}
            dynamic={dynamic}
            dynamicValues={dynamicValues}
            defaultValue={defaultValue}
          />
        ) : type === 'switch' ? (
          <SwitchToolbarItem
            propKey={propKey}
            propSubKey={propSubKey}
            value={value}
            setProp={setProp}
            onChange={onChange}
            onlyDynamic={onlyDynamic}
            dynamic={dynamic}
            dynamicValues={dynamicValues}
            defaultValue={defaultValue}
          />
        ) : type === 'color' ? (
          <ColorToolbarItem
            propKey={propKey}
            propSubKey={propSubKey}
            value={value}
            setProp={setProp}
            onChange={onChange}
            onlyDynamic={onlyDynamic}
            dynamic={dynamic}
            dynamicValues={dynamicValues}
            defaultValue={defaultValue}
          />
        ) : type === 'dynamic' ? (
          <DynamicToolbarItem
            propKey={propKey}
            propSubKey={propSubKey}
            value={value}
            values={values}
            setProp={setProp}
            onChange={onChange}
            defaultValue={defaultValue}
          />
        ) : null)}
      {type === 'other' ? children : null}
    </div>
  )
}
