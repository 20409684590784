import React, { useState } from 'react'
import styles from './QuotazioneAutoGaranzieModal.module.sass'
import {
  QuotazioneAutoUpsellingModal,
  QuotazioneAutoGaranzieAbbinateModal,
  QuotazioneAutoGaranzieNecessariePacchettoModal,
  QuotazioneAutoGaranzieEscluseModal,
  QuotazioneAutoRimozionePacchettoModal,
  QuotazioneAutoRimozioneGaranziaModal,
  QuotazioneAutoRimozioneGaranziaAbbinataModal,
  QuotazioneAutoRimozionePacchettoGaranzieNecessarieModal,
} from '@next-nx/shared-ui/organisms'
import {
  PacchettoGaranziaQuotazioneAutoType,
  QuotazioneAutoGaranzieModalVariant,
  QuotazioneAutoType,
} from '@next-nx/utils/types'
import { GaranziaQuotazioneAutoType } from '@next-nx/utils/types'
import { sendGTMData } from 'libs/shared-ui/utils/gtm'
import { useSite } from '@next-nx/hooks'
import { BANCA } from 'libs/shared-ui/utils/settings'

interface Props {
  variant: QuotazioneAutoGaranzieModalVariant
  pacchetto: PacchettoGaranziaQuotazioneAutoType | undefined
  garanzia: GaranziaQuotazioneAutoType | undefined
  quotazione: QuotazioneAutoType
  garanzie: GaranziaQuotazioneAutoType[]
  garanzieSelezionate: string[]
  handleGaranzieAndPacchetti: (
    variant: 'pacchetto' | 'garanzia',
    action: 'add' | 'remove' | 'substitute',
    pacchettoCodici: string[],
    garanzieCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto?: boolean,
    lazy?: boolean,
    garanzieSostituite?: string[]
  ) => string[] | undefined
  open: boolean
  setOpen: (v: boolean) => void
  modalsQueue?: any[]
  setModalsQueue?: (v: any) => void
  className?: string
}

const QuotazioneAutoGaranzieModal = (props: Props) => {
  const {
    variant,
    pacchetto,
    garanzia,
    quotazione,
    garanzie,
    garanzieSelezionate,
    handleGaranzieAndPacchetti = () => undefined,
    open = false,
    setOpen = () => undefined,
    modalsQueue = [],
    setModalsQueue = () => undefined,
    className = '',
  } = props

  const site = useSite()

  const handleGtm = (
    sceltaOpzioni:
      | 'no_grazie'
      | 'aggiungi_garanzie'
      | 'chiudi_popup'
      | 'rimuovi_garanzie'
      | 'sostituisci_garanzie'
      | 'annulla_rimozione'
  ) => {
    sendGTMData({
      event: 'GAevent',
      eventCategory: `preventivatore${site === BANCA ? '_banca' : ''}`,
      eventAction: quotazione.prodotto?.titolo?.toGTMFormat(),
      eventLabel: 'step2_1',
      sceltaOpzioni: sceltaOpzioni,
    })
  }

  const handleSetOpen = (v: boolean) => {
    setOpen(v)
    if (!v) {
      setModalsQueue([])
    }
  }

  /**
   * E' un wrapper che esegue la funzione handleGaranzieAndPacchetti
   * e se ha successo (valore di ritorno non è undefined)
   * allora esegue tutte le funzioni salvate nella queue
   */
  const newHandleGaranzieAndPacchetti = (
    variant: 'pacchetto' | 'garanzia',
    action: 'add' | 'remove' | 'substitute',
    pacchettoCodici: string[],
    garanzieCodici: string[],
    garanzieSelezionate: string[],
    checkPacchetto?: boolean,
    lazy?: boolean,
    garanzieSostituite?: string[]
  ) => {
    let newGaranzieSelezionate = handleGaranzieAndPacchetti(
      variant,
      action,
      pacchettoCodici,
      garanzieCodici,
      garanzieSelezionate,
      checkPacchetto,
      lazy,
      garanzieSostituite
    )

    if (newGaranzieSelezionate) {
      // ogni funzione della queue deve usare come garanzieSelezionate
      // il valore di ritorno della funzione precedente
      modalsQueue.forEach((funArray) => {
        const fun = funArray[0]
        funArray.splice(5, 1, newGaranzieSelezionate)
        newGaranzieSelezionate = fun(...funArray.slice(1))
      })
    }

    return newGaranzieSelezionate
  }

  return (
    <div className={`${styles.root} ${className ? ` ${className}` : ''}`}>
      {variant === 'upselling' ? (
        <QuotazioneAutoUpsellingModal
          garanzia={garanzia}
          pacchetto={pacchetto}
          quotazione={quotazione}
          garanzie={garanzie}
          garanzieSelezionate={garanzieSelezionate}
          handleGaranzieAndPacchetti={newHandleGaranzieAndPacchetti}
          open={open}
          setOpen={handleSetOpen}
          handleGtm={handleGtm}
        />
      ) : variant === 'garanzie_abbinate' ? (
        <QuotazioneAutoGaranzieAbbinateModal
          garanzia={garanzia}
          pacchetto={pacchetto}
          quotazione={quotazione}
          garanzie={garanzie}
          garanzieSelezionate={garanzieSelezionate}
          handleGaranzieAndPacchetti={newHandleGaranzieAndPacchetti}
          open={open}
          setOpen={handleSetOpen}
          handleGtm={handleGtm}
        />
      ) : variant === 'garanzie_necessarie_pacchetto' ? (
        <QuotazioneAutoGaranzieNecessariePacchettoModal
          garanzia={garanzia}
          pacchetto={pacchetto}
          quotazione={quotazione}
          garanzie={garanzie}
          garanzieSelezionate={garanzieSelezionate}
          handleGaranzieAndPacchetti={newHandleGaranzieAndPacchetti}
          open={open}
          setOpen={handleSetOpen}
          handleGtm={handleGtm}
        />
      ) : variant === 'garanzie_escluse' ? (
        <QuotazioneAutoGaranzieEscluseModal
          garanzia={garanzia}
          pacchetto={pacchetto}
          quotazione={quotazione}
          garanzie={garanzie}
          garanzieSelezionate={garanzieSelezionate}
          handleGaranzieAndPacchetti={newHandleGaranzieAndPacchetti}
          open={open}
          setOpen={handleSetOpen}
          handleGtm={handleGtm}
        />
      ) : variant === 'rimozione_pacchetto' ? (
        <QuotazioneAutoRimozionePacchettoModal
          pacchetto={pacchetto}
          quotazione={quotazione}
          garanzie={garanzie}
          garanzieSelezionate={garanzieSelezionate}
          handleGaranzieAndPacchetti={newHandleGaranzieAndPacchetti}
          open={open}
          setOpen={handleSetOpen}
          handleGtm={handleGtm}
        />
      ) : variant === 'rimozione_garanzia' ? (
        <QuotazioneAutoRimozioneGaranziaModal
          pacchetto={pacchetto}
          garanzia={garanzia}
          quotazione={quotazione}
          garanzie={garanzie}
          garanzieSelezionate={garanzieSelezionate}
          handleGaranzieAndPacchetti={newHandleGaranzieAndPacchetti}
          open={open}
          setOpen={handleSetOpen}
          handleGtm={handleGtm}
        />
      ) : variant === 'rimozione_garanzia_abbinata' ||
        variant === 'rimozione_garanzia_necessaria_pacchetto' ? (
        <QuotazioneAutoRimozioneGaranziaAbbinataModal
          variant={variant}
          pacchetto={pacchetto}
          garanzia={garanzia}
          quotazione={quotazione}
          garanzie={garanzie}
          garanzieSelezionate={garanzieSelezionate}
          handleGaranzieAndPacchetti={newHandleGaranzieAndPacchetti}
          open={open}
          setOpen={handleSetOpen}
          handleGtm={handleGtm}
        />
      ) : variant === 'rimozione_pacchetto_garanzia_necessaria_pacchetto' ? (
        <QuotazioneAutoRimozionePacchettoGaranzieNecessarieModal
          variant={variant}
          pacchetto={pacchetto}
          garanzia={garanzia}
          quotazione={quotazione}
          garanzie={garanzie}
          garanzieSelezionate={garanzieSelezionate}
          handleGaranzieAndPacchetti={newHandleGaranzieAndPacchetti}
          open={open}
          setOpen={handleSetOpen}
          handleGtm={handleGtm}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default QuotazioneAutoGaranzieModal
