import React, { useContext } from 'react'
import { ArrowRight } from '@next-nx/shared-ui/icons'
import { Button, Chip, IntroTitle, VideoEmbed } from '@next-nx/shared-ui/atoms'
import styles from './VideoCampagnaSection.module.sass'
import { MenuType } from '@next-nx/utils/types'
import { sendGTMData } from '../../../utils/gtm'

interface Props {
  item?: MenuType
  className?: string
}

const VideoCampagnaSection = (props: Props) => {
  const { item = undefined, className = '' } = props

  return item ? (
    <div className={`${styles.videoCampagnaSection} ${className ? ` ${className}` : ''}`}>
      {!!item.name && <IntroTitle label={item.name} className={styles.intro} withIcon={true} />}
      {!!item.description && (
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: item.description }} />
      )}

      {!!item.children?.edges.length &&
        item.children.edges.map((child) => {
          if (child.node) {
            if (child.node.key && child.node.key.includes('__subtitle')) {
              if (child.node.description) {
                return (
                  <div
                    className={styles.subtitle}
                    dangerouslySetInnerHTML={{ __html: child.node.description }}
                  />
                )
              }
            }

            if (child.node.key && child.node.key.includes('__embed')) {
              if (child.node.name) {
                return <VideoEmbed embedId={child.node.name} />
              }
            }
          }
          return null
        })}
      {!!item.ctaLabel && !!item.url && (
        <div className={styles.ctaBox}>
          <Button
            label={item.ctaLabel}
            rightIcon={<ArrowRight />}
            variant="ghost"
            href={item.url}
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default VideoCampagnaSection
