import React, { useEffect, useState } from 'react'
import { useUtente } from '@next-nx/hooks'
import { PageBuilder } from '@next-nx/pagebuilder'

interface Props {
  contentTypeId: number
  objectId: number
  pkey: string
}

const PlaceholderReact = (props: Props) => {

  const {
    contentTypeId = 0,
    objectId = 0,
    pkey = '',
  } = props

  const { utente } = useUtente()
  const [content, setContent] = useState<any>(null)

  useEffect(() => {
    let content = null
    if (utente && utente.edit) {
      content = (
        <div className="pb-content active">
          <iframe
            className="pagebuilder-placeholder"
            src={`/pagebuilder/?contentTypeId=${contentTypeId}&objectId=${objectId}&key=${pkey}`}
            title="Pagebuilder"
          />
        </div>
      )
    } else {
      content = (
        <div className="pb-content">
          <PageBuilder contentTypeId={contentTypeId} objectId={objectId} pkey={pkey} />
        </div>
      )
    }
    setContent(content)
  }, [utente])

  return <>{!!content && content}</>
}

export default PlaceholderReact
