import React, { useEffect, useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useFindMenu, useUtente, useTrans, useSite, useIsMobile } from '@next-nx/hooks'
import { scrollTo } from '@next-nx/utils/safe'
import { Button, Breadcrumbs, WContainer, WImage, Modal } from '@next-nx/shared-ui/atoms'
import { AllegatiBox } from '@next-nx/shared-ui/molecules'
import { Page, ContattaciForm } from '@next-nx/shared-ui/organisms'
import styles from './ContattaciTemplate.module.sass'
import { ASSICURAZIONI } from 'libs/shared-ui/utils/settings'


interface Props {
  pagina?: any  
}

const ContattaciTemplate = (props: Props) => {

  const { 
    pagina = {},
  } = props
  
  const site = useSite()
  const t = useTrans()
  const isMobile = useIsMobile()
  const { utente } = useUtente()
  const toolbarPresente = utente && (utente.isStaff || utente.isImpersonate)

  const [mobileModalOpen, setMobileModalOpen] = useState<boolean>(false)

  const formContattiRef = useRef<HTMLDivElement>(null)
  const [menuActive, setMenuActive] = useState<string|null>('')
  const menuRef = useRef<HTMLDivElement>(null)
  const sectionRefs = useRef<HTMLDivElement[]>([])

  const addSectionRef = (ref: HTMLDivElement) => {
    if (ref){
      if (sectionRefs.current.filter((x) => x.id === ref.id).length > 0)
        sectionRefs.current = sectionRefs.current.map((x) => x.id === ref.id ? ref : x)
      else
        sectionRefs.current.push(ref)
    }
  }

  const handleScroll = () => {
    if (menuRef.current){
      if (window.scrollY >= menuRef.current.offsetTop - (toolbarPresente ? 40 : 0) - 80) document.body.classList.add('contattaci-menu-sticky')
      else document.body.classList.remove('contattaci-menu-sticky')
    }

    // Calcolo se sono all'altezza di un box del menu
    const scrollOffset = window.scrollY + (toolbarPresente ? 40 : 0) + 80 + 80 + 20 + 10 // + header + menu + margine + correzzione
    const match = sectionRefs.current.filter(
      (x) => (scrollOffset >= x.offsetTop) && (scrollOffset <= x.offsetTop + x.clientHeight)
    )
    // Potrebbero esserci box sovrapposti, prendo l'ultimo match
    setMenuActive(match.length > 0 ? match[match.length - 1].id?.replace('menu_', '') : null)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const menuContattaci = useFindMenu("contattaci")

  return (
    <Page
      pagina={pagina}
      item={{}}
      contentClassName={styles.chiSiamoContent}
      recaptchaRequired
    >
      {pagina?.breadcrumbs &&
        <WContainer>
          <Breadcrumbs breadcrumbs={pagina?.breadcrumbs} className={styles.contattaciBreadcrumbs} />
        </WContainer>
      }
      <div className={styles.head}>
        <div className={styles.imageBox}>
          <WImage
            src={isMobile ? pagina.previewImage : pagina.mainImage}
            maxWidth={1920}
            maxHeight={340}
            layout="intrinsic"
            objectFit="cover"
            priority={true}
            loading="eager"
            key={`contattaci-main-image${isMobile ? '-mobile' : ''}`}
          />
        </div>
        <div className={styles.heroBox}>
          <p className={styles.heroTitle}>{pagina.nome}</p>
          {pagina.descrizione && <div className={styles.heroDescription} dangerouslySetInnerHTML={{ __html: pagina.descrizione }} />}
        </div>     
      </div>
      <div className={styles.mainSection}>
        <WContainer>
          <div className={styles.main}>
            <div className={styles.formMobile}>
              <p className={styles.formMobileTitle}>{t("Hai bisogno di assistenza?")}</p>
              <div className={styles.formMobileCtaBox}>
                <Button label={t("Invia messaggio")} className={styles.formMobileCta} onClick={() => setMobileModalOpen(true)} />
              </div>
            </div>  
            <div className={styles.formBox}>
              <ContattaciForm />
            </div>
            <div className={`${styles.content} custom-content`} dangerouslySetInnerHTML={{ __html: menuContattaci?.description || '' }} />
          </div>
        </WContainer>
      </div>
      {menuContattaci && menuContattaci.children?.edges && (
        <>
          {site === ASSICURAZIONI &&
          <div className={styles.menuSectionRef} ref={menuRef}>
            <div className={styles.menuSection}>
              <WContainer>
                <div className={styles.menu}>
                  <Swiper spaceBetween={0} slidesPerView="auto">
                    {menuContattaci.children.edges.map((child, index) => (
                      <SwiperSlide key={child.node.pk} >
                        <a 
                          href={`#menu_${child.node.pk}`} 
                          className={`${styles.menuItem} ${menuActive === `${child.node.pk}` ? styles['menuItem--active'] : ''}`}
                          onClick={(e) => {
                            e.preventDefault()
                            scrollTo(sectionRefs.current[index], toolbarPresente, 100) // Offset: 80 di menu + 20 di margine
                          }}
                        >
                          <span dangerouslySetInnerHTML={{ __html: child.node.name || '' }} />
                        </a>
                      </SwiperSlide>
                    ))}
                  </Swiper>                  
                </div>
              </WContainer>
            </div>
          </div>
          }
          <WContainer variant="xs">
            <div className={styles.list}>
              {menuContattaci.children.edges
                .filter((child) => child.node.style !== 'allegati')
                .map((child) => (
                  <div
                    id={`menu_${child.node.pk}`}
                    key={child.node.pk}
                    className={`${styles.item} custom-content`}
                    dangerouslySetInnerHTML={{
                      __html: child.node.description || '',
                    }}
                    ref={addSectionRef}
                  />
                ))}
            </div>
          </WContainer>
          {menuContattaci.children.edges
            .filter((child) => child.node.style === 'allegati').length > 0 && (
              <div className={styles.allegati}>
                <WContainer>
                {menuContattaci.children.edges
                  .filter((child) => child.node.style === 'allegati')
                  .map((child) => (
                    <div 
                      id={`menu_${child.node.pk}`}
                      ref={addSectionRef}
                      key={child.node.pk}
                    >
                      <AllegatiBox item={child.node} />
                    </div>
                  ))
                }
                </WContainer>
              </div>
            )
          }
        </>
      )}
      <div className={styles.formMobile}>
        <Modal open={mobileModalOpen} setOpen={setMobileModalOpen} closeButton className={styles.formModal}>
          <div className={styles.formModalContent} ref={formContattiRef}>
            <ContattaciForm id="mobile" formContattiRef={formContattiRef} />
          </div>
        </Modal>
      </div>
    </Page>
  )
}

export default ContattaciTemplate
